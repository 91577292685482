import { MultiToggleField } from "PFComponents/multi_toggle/multi_toggle_field";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import CheckIcon from "PFIcons/check.svg";
import CrossIcon from "PFIcons/x.svg";
import { FeedbackQuestion } from "PFTypes/activity_feedback";

import css from "../../feedback_form.module.scss";
import { useFeedbackFormContext } from "../../feedback_form_context_provider";
import { useFeedbackFormMutations } from "../../hooks/use_feedback_form";

type QuestionInputProps = {
  question: FeedbackQuestion;
  answer: string | boolean | null | undefined;
  onChange: (val: string | boolean, question: FeedbackQuestion) => void;
  error: string | string[] | undefined;
};

type QuestionProps = {
  question: FeedbackQuestion;
  readOnly: boolean;
};

const idToBool = (id: string) => id.split("-")[0] === "true";

export const Question = ({ question, readOnly }: QuestionProps) => {
  const { handleAnswerChange, updateErrorsOnChange } = useFeedbackFormMutations();
  const { formData, errors } = useFeedbackFormContext();
  const answer = formData?.answers?.find(({ questionId }) => questionId === question.id);
  if (readOnly) {
    return <ReadOnlyQuestion answer={answer?.value} question={question} />;
  }
  const changeHandler = (val: string | boolean, question: FeedbackQuestion) => {
    handleAnswerChange(val, question);
    updateErrorsOnChange(val, question.id);
  };

  return (
    <div className={css.question}>
      <div className={css.label}>{question.text}</div>
      {question.type === "boolean" ? (
        <BooleanInput
          answer={answer?.value}
          question={question}
          onChange={changeHandler}
          error={errors[question.id]}
        />
      ) : (
        <TextInput
          answer={answer?.value}
          question={question}
          onChange={changeHandler}
          error={errors[question.id]}
        />
      )}
    </div>
  );
};

const BooleanInput = ({ question, answer, onChange, error }: QuestionInputProps) => (
  <MultiToggleField
    options={[
      { id: `true-${question.id}`, value: "Yes" },
      { id: `false-${question.id}`, value: "No" }
    ]}
    controlledValue={`${answer?.toString()}-${question.id}`}
    onChange={(id) => onChange(idToBool(id), question)}
    errors={error}
  />
);

const TextInput = ({ question, answer, onChange, error }: QuestionInputProps) => (
  <InputFieldSet
    className={css.textQuestion}
    minHeight={100}
    maxLength={400}
    onChange={(val) => onChange(val, question)}
    value={String(answer || "")}
    inputType="textarea"
    error={error}
  />
);

export const ReadOnlyQuestion = ({ question, answer }: Omit<QuestionInputProps, "onChange" | "error">) => {
  if (question.type === "boolean") {
    return (
      <div className={css.readOnlyBooleanQuestion}>
        <div>{answer ? <CheckIcon width={15} height={15} /> : <CrossIcon width={15} height={15} />}</div>
        {question.text}
      </div>
    );
  }
  return (
    <div className={css.question}>
      <div className={css.readOnlyLabel}>{question.text}</div>
      {answer}
    </div>
  );
};
