import { CoreContext, CoreContextType } from "PFCore/core_context";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { CurrentAccount } from "PFTypes";
import { PropsWithChildren, useMemo } from "react";

export const getAccountDateFormatData = (
  currentAccount: CurrentAccount
): Partial<Pick<CoreContextType, "dateFormat" | "isAM">> => {
  if (!currentAccount) {
    return {};
  } else {
    const { customization } = currentAccount;
    const { display_date_format: dateFormat, twelve_hour_clock: isAM } = customization || {};
    return { dateFormat, isAM };
  }
};

export const CoreContextProvider = ({ children }: PropsWithChildren) => {
  const { data: currentAccount } = useCurrentAccount();
  const { dateFormat, isAM } = getAccountDateFormatData(currentAccount);

  const value = useMemo<CoreContextType>(
    () => ({
      dateFormat,
      isAM
    }),
    [dateFormat, isAM]
  );

  return <CoreContext.Provider value={value}>{children}</CoreContext.Provider>;
};
