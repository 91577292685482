import AutoSelect from "PFComponents/select/autoselect";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { BookingCategory, FeatureFlag } from "PFTypes";
import { useTranslation } from "react-i18next";

import CategoryOption from "../booking_form/booking_category_select/category_option/category_option";
import { transform } from "./booking_category_select.utils";

type BookingCategorySelectProps = {
  category: BookingCategory | null;
  className?: string;
  error?: string;
  onCategoryChange?: (value: BookingCategory | null) => void;
  required?: boolean;
  demand?: boolean;
  label?: string;
  letClear?: boolean;
  restrictedTextWidth?: boolean;
  disabled?: boolean;
  withoutLabel?: boolean;
};

export const BookingCategorySelect = ({
  category,
  className,
  error,
  onCategoryChange,
  required,
  demand,
  label,
  letClear,
  restrictedTextWidth,
  disabled,
  withoutLabel
}: BookingCategorySelectProps) => {
  const { t } = useTranslation("bookingModule");
  const isBookingModuleEnabled = useIsFeatureEnabled()(FeatureFlag.BookingModule);
  const { bookingCategories } = useBookingCategories();

  return (
    <AutoSelect
      //@ts-ignore
      label={withoutLabel ? undefined : label || t("bookings.create.selectCategory")}
      disabled={!isBookingModuleEnabled || disabled}
      rootClassName={className}
      values={transform.input(category)}
      displayValues={
        category && <CategoryOption category={category} restrictedTextWidth={restrictedTextWidth} />
      }
      letClear={letClear}
      closeOnChange
      multi={false}
      formatOption={transform.formatOption}
      query={() => transform.query(bookingCategories, demand)}
      handleChange={(value) => onCategoryChange?.(transform.output(value, bookingCategories))}
      error={error}
      required={required}
      cache={false}
      hideDisplayValuesOnFocus
    />
  );
};
