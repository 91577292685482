import { ProfileAvatar } from "PFComponents/profile_avatar/profile_avatar";
import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import EnvelopeIcon from "PFIcons/envelope.svg";
import LeaveIcon from "PFIcons/leave.svg";
import { PermissionRule } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CommonNavItemProps, NavItem } from "../parts/nav_item";
import { NavItem as NavItemType, NavSwitchItem } from "../types";
import { Tips } from "./tips";

export const YouNavItem = (props: CommonNavItemProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "navBar.youNav" });
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const profileName = currentProfile.first_name || t("yourProfile");
  const isPermittedTo = useIsPermittedTo(currentProfile);
  const isAccountSwitched = currentProfile.account_id && currentProfile.account_id !== currentAccount.id;
  const items: NavItemType[] = useMemo(() => {
    const signOutNavItem: NavSwitchItem = {
      qaIdName: "sign_out",
      name: t("logout"),
      displayItem: (
        <>
          <LeaveIcon width={14} height={14} style={{ marginRight: 10, verticalAlign: "middle" }} />
          <span style={{ verticalAlign: "middle" }}>{t("logout")}</span>
        </>
      ),
      title: t("logout"),
      href: "/sign_out",
      id: "sign_out_link"
    };

    return isAccountSwitched
      ? [signOutNavItem]
      : [
          {
            name: t("myProfile"),
            href: "/profiles/me",
            badge: t("percentComplete", { value: currentProfile?.statistics?.progress_completion }),
            id: "my_profile"
          },
          { name: t("settings"), href: "/profile/edit", id: "profile_settings" },
          { sep: true },
          isPermittedTo(PermissionRule.ContactForm) && {
            name: t("sendFeedback"),
            displayItem: (
              <>
                <EnvelopeIcon width={14} height={14} style={{ marginRight: 10, verticalAlign: "middle" }} />
                <span style={{ verticalAlign: "middle" }}>{t("sendFeedback")}</span>
              </>
            ),
            title: t("sendFeedback"),
            href: "/contact_us"
          },
          { sep: true },
          signOutNavItem,
          { content: <Tips closeSubMenu={props.closeSubMenu} /> }
        ].filter(Boolean);
  }, [currentProfile, isAccountSwitched, isPermittedTo, props.closeSubMenu, t]);

  return (
    <NavItem
      icon={<ProfileAvatar profile={currentProfile} size={30} />}
      name={profileName}
      href={"/profiles/me"}
      isActiveTest={(path) => path.match(/^\/profiles\/me/) || path.match(/^\/profile\/edit/)}
      id="you"
      aria-label={t("myProfile")}
      hoverEffect={false}
      {...props}
      items={items}
    />
  );
};
