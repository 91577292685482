import { Accordion } from "PFComponents/accordion/accordion";
import { Typography } from "PFComponents/typography";

import { Attribute, AttributeData } from "./attribute_items/attribute";
import css from "./booking_history_item.module.scss";
import { useBookingHistory } from "./use_booking_history";

type AttributesSectionProps = {
  attributes: AttributeData[];
};

export const AttributesSection = ({ attributes }: AttributesSectionProps) => {
  const { getUserFriendlyAttributeName } = useBookingHistory();
  const hasManyAttributes = attributes.length > 1;
  return (
    <div className={css.attributesContainer}>
      {hasManyAttributes ? (
        <Accordion
          header={
            <Typography withMargin variant="bodyBold" tag="span">
              {`${attributes.length} fields`}
            </Typography>
          }
        >
          <div className={css.attributes}>
            {attributes.map((attribute) => (
              <Attribute
                key={attribute.attributeId}
                title={getUserFriendlyAttributeName(attribute.attributeId)}
                attribute={attribute}
              />
            ))}
          </div>
        </Accordion>
      ) : (
        <Attribute attribute={attributes[0]} />
      )}
    </div>
  );
};
