import { Alert } from "PFComponents/alert";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

type BlockedSkillsAlertProps = {
  blockedSkills: string[];
};

export const BlockedSkillsAlert = ({ blockedSkills }: BlockedSkillsAlertProps) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.skillsModal" });

  const message = t("errors.blockedSkill", {
    value: blockedSkills.join(", "),
    count: blockedSkills.length
  });

  return (
    <Alert
      title={
        <Typography withMargin variant="bodyRegular" tag="span">
          {message}
        </Typography>
      }
      kind="error"
    />
  );
};
