import { LoadingDots } from "PFComponents/loading_dots";
import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";
import { useProfile } from "PFCore/hooks/queries/profile/use_profile";

import css from "./profile_attribute.modal.scss";

type ProfileAttributeProps = {
  profileId: number;
};

export const ProfileAttribute = ({ profileId }: ProfileAttributeProps) => {
  const { data, isLoading } = useProfile(profileId);

  return (
    <div className={css.root}>
      {isLoading ? (
        <LoadingDots />
      ) : (
        <>
          <Typography withMargin variant="bodyBold" tag="span">
            {getProfileName(data)}
          </Typography>
          <Typography withMargin variant="bodyRegular" tag="span">
            {data?.email}
          </Typography>
        </>
      )}
    </div>
  );
};
