import NotFoundRoute from "PFApp/routes/not_found_route";
import { EnsureLoggedInAndRegistered, WithLayout } from "PFApp/routes/route_helpers";
import { Route, Routes } from "react-router-dom";

import ProfileEditController from "../profiles/edit/profile_edit_controller";

export const ProfileRoutes = () => (
  <EnsureLoggedInAndRegistered>
    <WithLayout>
      <Routes>
        <Route path="edit" element={<ProfileEditController />} />
        <Route path="edit/:section" element={<ProfileEditController />} />
        <Route path="*" element={<NotFoundRoute />} />
      </Routes>
    </WithLayout>
  </EnsureLoggedInAndRegistered>
);
