import { LandingLayoutWithLandingDescription } from "PFApp/components/landing_layout";
import { Button } from "PFComponents/button";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useRequestAccess } from "PFCore/hooks/queries/auth/use_request_access";
import { FeatureFlag } from "PFTypes";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Navigate } from "react-router-dom";

const RequestAccessPage = () => {
  const { t } = useTranslation();
  const { getFormattedErrors } = useResponseErrors();
  const [data, setData] = useState({ name: "", company: "", email: "" });

  const { data: currentAccount } = useCurrentAccount();
  const isEnabled = useIsFeatureEnabled();

  const { mutate: requestAccess, isLoading, isSuccess, error, isError } = useRequestAccess();

  const errors = useMemo(
    () => (isError ? getFormattedErrors(error.response) : {}),
    [isError, error, getFormattedErrors]
  );

  if (!isEnabled(FeatureFlag.RequestAccess)) {
    return <Navigate to="/" replace />;
  }

  const handleSubmitClick = () => {
    requestAccess({
      ...data,
      accountId: currentAccount.id
    });
  };

  return (
    <LandingLayoutWithLandingDescription>
      <strong data-qa-id="RequestAccessPage.header">{t("signUpSignIn.requestAccess")}</strong>

      {isSuccess && <div style={{ margin: "30px 0" }}>{t("signUpSignIn.accessRequestReceived")}</div>}

      <InputFieldSet
        kind="white"
        qaId="RequestAccessPage.name"
        label={t("signUpSignIn.fullName")}
        error={errors.name ? errors.name[0] : null}
        style={{ display: "block", marginBottom: 20 }}
        onChange={(name) => setData((data) => ({ ...data, name }))}
      />

      <InputFieldSet
        kind="white"
        qaId="RequestAccessPage.company"
        label={t("signUpSignIn.company")}
        error={errors.company ? errors.company[0] : null}
        style={{ display: "block", marginBottom: 20 }}
        onChange={(company) => setData((data) => ({ ...data, company }))}
      />

      <InputFieldSet
        kind="white"
        qaId="RequestAccessPage.email"
        inputType="email"
        label={t("signUpSignIn.companyEmail")}
        error={errors.email ? errors.email[0] : null}
        style={{ display: "block", marginBottom: 30 }}
        onChange={(email) => setData((data) => ({ ...data, email }))}
      />

      <Button
        kind="primary"
        style={{ width: "100%" }}
        onClick={handleSubmitClick}
        qaId="RequestAccessPage.signUpButton"
        disabled={data.name.trim() === "" || data.company.trim() === "" || data.email.trim() === ""}
      >
        {isLoading ? "..." : t("signUpSignIn.requestAccess")}
      </Button>

      <div style={{ marginTop: 30, marginBottom: 20 }}>
        {t("signUpSignIn.accessAlreadyGranted")}
        <br />
        <Link to="/users/signup" data-qa-id="RequestAccessPage.signUpLink">
          {t("signUpSignIn.signUpHere")}
        </Link>
      </div>

      <div style={{ marginBottom: 20 }}>
        {t("signUpSignIn.alreadyHaveAccount")}
        <br />
        <Link to="/" data-qa-id="RequestAccessPage.signUpLink">
          {t("signUpSignIn.logInHere")}
        </Link>
      </div>
    </LandingLayoutWithLandingDescription>
  );
};

export default RequestAccessPage;
