import isNil from "lodash/isNil";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { IconName } from "PFTheme/graphics/icons";

import css from "./booking_detail_boolean_property.module.scss";

const getIconName = (checked?: boolean): IconName => {
  if (isNil(checked)) {
    return "question";
  }
  return checked ? "check" : "cross";
};

type BookingDetailBooleanPropertyProps = {
  checked?: boolean;
  label: string;
};

export const BookingDetailBooleanProperty = ({ checked, label }: BookingDetailBooleanPropertyProps) => (
  <div className={css.booleanProperty}>
    <Icon name={getIconName(checked)} />
    <Typography variant="bodyBold">{label}</Typography>
  </div>
);
