import { Typography } from "PFComponents/typography";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { Booking } from "PFTypes";

import { BookingPill } from "../../../../components/pills/booking_pill";
import css from "./booking_category_column.module.scss";

const COLLAPSED_MODE_WIDTH = 60;
const EXPANDED_MODE_WIDTH = 120;

type BookingCategoryColumnProps = {
  booking: Booking;
  isExpanded: boolean;
};

export const BookingCategoryColumn = ({ booking, isExpanded }: BookingCategoryColumnProps) => {
  const { getCategory } = useBookingCategories();
  const category = getCategory(booking);

  return (
    <BookingPill
      category={category}
      className={css.root}
      style={{
        maxWidth: isExpanded ? EXPANDED_MODE_WIDTH : COLLAPSED_MODE_WIDTH,
        width: "80%",
        borderWidth: 1
      }}
      iconSize="xs"
    >
      <Typography withMargin variant="labelRegular" clipOverflow>
        {category?.display_as}
      </Typography>
    </BookingPill>
  );
};
