import { ActivityFeedback } from "PFTypes/activity_feedback";
import { FeedbackSkill } from "PFTypes/activity_participant";
import React, { createContext, useEffect, useState } from "react";

export type FeedbackFormData = Omit<ActivityFeedback, "skills" | "role" | "recipient" | "provider"> & {
  skills: SkillToRank[];
};

export type SkillToRank = FeedbackSkill & { isActive?: boolean };

export type FeedbackFormContextProps = {
  onUpdate?: (formData: FeedbackFormData | null) => void;
  defaultData: FeedbackFormData | undefined;
};

export type FeedbackFormContext = {
  setFormData: React.Dispatch<React.SetStateAction<FeedbackFormData | undefined>>;
  formData: FeedbackFormData | undefined;
  errors: Record<number, string | string[]>;
  setErrors: React.Dispatch<React.SetStateAction<Record<number, string | string[]>>>;
};

const FeedbackFormContext = createContext({} as FeedbackFormContext);

export const FeedbackFormContextProvider = ({
  children,
  onUpdate,
  defaultData
}: React.PropsWithChildren<FeedbackFormContextProps>) => {
  const [formData, setFormData] = useState<FeedbackFormData>();
  const [errors, setErrors] = useState<Record<number, string | string[]>>({});

  useEffect(() => {
    if (defaultData) {
      setFormData(defaultData);
    }
  }, [defaultData]);

  useEffect(() => {
    onUpdate?.(formData || null);
  }, [formData, onUpdate]);

  return (
    <FeedbackFormContext.Provider
      value={{
        setFormData,
        formData,
        errors,
        setErrors
      }}
    >
      {children}
    </FeedbackFormContext.Provider>
  );
};

export const useFeedbackFormContext = () => React.useContext(FeedbackFormContext);
