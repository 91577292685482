import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { ApiRoute, UiRoute } from "PFCore/utilities/routes";
import { Navigate } from "react-router-dom";

const SamlSignInPage = () => {
  const { data: currentAccount } = useCurrentAccount();
  const { isSignedIn } = useCurrentProfile();

  if (isSignedIn) {
    return <Navigate to="/" replace />;
  } else {
    window.location = ApiRoute(
      `/api/oauth/authorize?${$.param({
        account: currentAccount.full_domain,
        client_id: PF.config.client_id,
        grant_type: "saml",
        redirect_uri: UiRoute("/")
      })}`
    );
  }

  return null;
};

export default SamlSignInPage;
