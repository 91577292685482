import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import PageCard from "PFComponents/page/page_card";
import { useActivityFeedbackParticipants } from "PFCore/hooks/queries/activity/use_activity_feedback_participants";

import { useFeedbackContext } from "./feedback_context_provider";
import { FormSection } from "./form_section";
import { ParticipantsList } from "./participants_list";
import css from "./participants_overview.module.scss";

export const ParticipantsOverview = (): React.ReactElement | null => {
  const { task } = useActivityPageContext();
  const { selectedEntry } = useFeedbackContext();
  const { data } = useActivityFeedbackParticipants(task?.id);
  const participants = data?.entries ?? [];

  if (participants.length === 0) {
    return null;
  }

  return (
    <div className={css.overviewContainer}>
      <PageCard variant="spaced" classes={{ wrap: css.listSection, card: css.listSectionInner }}>
        <ParticipantsList participants={participants} />
      </PageCard>
      {selectedEntry && (
        <PageCard variant="spaced" classes={{ wrap: css.formSection }}>
          <FormSection />
        </PageCard>
      )}
    </div>
  );
};
