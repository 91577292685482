import compact from "lodash/compact";
import map from "lodash/map";
import partition from "lodash/partition";
import { useFiltersContext } from "PFApp/components/filters/context/filters_context";
import { filterElements } from "PFApp/use_filtered_collection";
import { Tile } from "PFComponents/tile";
import { RefObject } from "react";

import { useActivityPropertiesNames } from "../../matches/hooks/use_activity_properties_names";
import { ActivityFilters } from "./parts/activity_filters";
import { AvailabilityFilter } from "./parts/availability_filter/availability_filter";
import { FiltersFooter } from "./parts/filters_footer/filters_footer";
import { FiltersHeader } from "./parts/filters_header";
import { OtherFilters } from "./parts/other_filters";
import css from "./side_filters.module.scss";

const OMITTED_FILTERS = ["term", "availability"];

export type SideFiltersProps = {
  separateActivityFilters?: boolean;
  disabled?: boolean;
  moreFiltersTitle?: string;
  clearMoreFiltersTitle?: string;
  scrollableContainerRef: RefObject<HTMLDivElement | null>;
};

export const SideFilters = ({
  separateActivityFilters,
  disabled = false,
  moreFiltersTitle,
  clearMoreFiltersTitle,
  scrollableContainerRef
}: SideFiltersProps) => {
  const { meta } = useFiltersContext();
  const schemaPropertiesNames = useActivityPropertiesNames();

  const allFilters = filterElements(meta?.filters, OMITTED_FILTERS);
  const [activityFiltersElements, otherFiltersElements] = partition(allFilters, ({ name }) =>
    schemaPropertiesNames.includes(name!)
  );

  const activityFiltersBlacklist = compact([...OMITTED_FILTERS, ...map(otherFiltersElements, "name")]);
  const otherFiltersBlacklist = compact([...OMITTED_FILTERS, ...map(activityFiltersElements, "name")]);

  return (
    <Tile className={css.root} paddingVariant="lg" light>
      <FiltersHeader />
      <AvailabilityFilter disabled={disabled} />
      {separateActivityFilters && (
        <ActivityFilters
          disabled={disabled}
          filterElements={activityFiltersElements}
          filterBlacklist={activityFiltersBlacklist}
        />
      )}
      <OtherFilters
        disabled={disabled}
        title={moreFiltersTitle}
        clearTitle={clearMoreFiltersTitle}
        filterElements={
          separateActivityFilters ? otherFiltersElements : filterElements(meta?.filters, OMITTED_FILTERS)
        }
        filterBlacklist={separateActivityFilters ? otherFiltersBlacklist : OMITTED_FILTERS}
      />
      <FiltersFooter scrollableContainerRef={scrollableContainerRef} />
    </Tile>
  );
};
