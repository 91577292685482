import classNames from "classnames";
import { ActionIcon, ActionIconProps } from "PFComponents/action_icon";
import { HTMLAttributes } from "react";

import css from "./carousel_actions.module.scss";

type ArrowProps = {
  onClick: VoidFunction;
  index: number;
} & Omit<ActionIconProps, "name" | "ref" | "fill">;

export const LeftArrow = ({ index, classes = {}, ...props }: ArrowProps) => (
  <ActionIcon
    name="chevron-left"
    size="md"
    classes={{ ...classes, root: classNames(classes.root, { [css.iconDisabled]: index === 0 }) }}
    {...props}
  />
);

type RightArrowProps = ArrowProps & {
  length: number;
};

export const RightArrow = ({ index, length, classes = {}, ...props }: RightArrowProps) => (
  <ActionIcon
    name="chevron-right"
    size="md"
    classes={{ ...classes, root: classNames(classes.root, { [css.iconDisabled]: index >= length - 1 }) }}
    {...props}
  />
);

type CarouselActionsProps = {
  onClickLeft: VoidFunction;
  onClickRight: VoidFunction;
  index: number;
  scrollLength: number;
  actionsClassName?: HTMLAttributes<HTMLDivElement>["className"];
  label?: React.ReactElement;
};
export const CarouselActions = ({
  onClickLeft,
  onClickRight,
  index,
  scrollLength,
  actionsClassName,
  label
}: CarouselActionsProps) => (
  <div className={classNames(actionsClassName, css.actions)}>
    <LeftArrow onClick={onClickLeft} index={index} />
    {label ? <div>{label}</div> : <div className={css.separator} />}
    <RightArrow onClick={onClickRight} index={index} length={scrollLength} />
  </div>
);
