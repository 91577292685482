import { Typography } from "PFComponents/typography";
import { Profile } from "PFTypes";
import React from "react";

import { useReassignCustomInfo } from "../hooks/use_reassign_custom_info";

type CustomColumnProps = {
  profile: Profile;
};

export const CustomColumn = ({ profile }: CustomColumnProps) => {
  const { reassignCustomInfo } = useReassignCustomInfo({ profile });
  const customTypeValue = reassignCustomInfo?.value ?? "-";

  return (
    <Typography withMargin variant="bodyRegular" tag="span">
      {customTypeValue}
    </Typography>
  );
};
