import moment from "moment";
import { Booking } from "PFTypes";

import { CalendarPeriod } from "./bookings_calendar.types";

export const fromCalendarPeriod = (booking: CalendarPeriod): Booking => ({
  ...booking,
  start_date: booking.start_date.toISOString(),
  end_date: booking.end_date.toISOString()
});

export const toCalendarPeriod = (booking: Booking): CalendarPeriod => ({
  ...booking,
  start_date: moment.utc(booking.start_date),
  end_date: moment.utc(booking.end_date)
});
