import isNil from "lodash/isNil";
import useBookingModulePermissions from "PFApp/use_booking_module_permissions";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";
import { Profile } from "PFTypes";
import { useTranslation } from "react-i18next";

import { BookingDetailItem } from "../../booking_detail_item";
import { useDetailsPanelApiContext } from "../../details_panel";

type WorkforceMemberReadonlyProps = {
  profile?: Profile;
};

export const WorkforceMemberReadonly = ({ profile }: WorkforceMemberReadonlyProps) => {
  const { t } = useTranslation("bookingModule");
  const { openDetailsPanel, canOpenDetailsPanelOfType } = useDetailsPanelApiContext();
  const { isEnabled: isBMEnabled } = useBookingModulePermissions();

  const openProfileSidePanel = (id: number) => {
    openDetailsPanel({
      type: "profile",
      id
    });
  };

  if (isNil(profile)) {
    return <BookingDetailItem label={t("bookings.create.workforceMember")} value={<LoadingDots />} />;
  }

  const profileNameElement = <Typography variant="bodyBold">{getProfileName(profile)}</Typography>;

  return (
    <BookingDetailItem
      label={t("bookings.create.workforceMember")}
      value={
        isBMEnabled && canOpenDetailsPanelOfType("profile") ? (
          <Button type="button" kind="text" onClick={() => openProfileSidePanel(profile.id)}>
            {profileNameElement}
          </Button>
        ) : (
          profileNameElement
        )
      }
    />
  );
};
