import { useTranslation } from "react-i18next";

import css from "./tooltip_content.module.scss";

type TooltipLabelKeys =
  | "bookings.tooltip.title"
  | "bookings.tooltip.bookingRef"
  | "bookings.tooltip.description";

type Props = {
  labelKey: TooltipLabelKeys;
  value: string;
  withLimit?: boolean;
  lengthLimit?: number;
};

export const TooltipElement = ({ labelKey, value }: Props) => {
  const { t } = useTranslation("bookingModule");
  const displayedValue = `${t(labelKey)}: ${value}`;
  return <p className={css.element}>{displayedValue}</p>;
};
