/* eslint-disable camelcase */
import classNames from "classnames";
import useIsAvailabilityPermitted from "PFApp/use_is_availability_permitted";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useScrollTop } from "PFCore/helpers/use_scroll";
import useWindowSize from "PFCore/helpers/use_window_size";
import { Activity } from "PFTypes";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./compare_details_page.module.scss";
import CompareProfileHeader from "./compare_profile_header";
import PropNode from "./parts/prop_node";
import { ComparableProfile, Row } from "./parts/use_cell_data";
import { useCellData } from "./parts/use_cell_data";

type CompareDetailsPageProps = {
  task: Activity;
  comparableProfiles: ComparableProfile[];
  removeProfileId: (id: number) => void;
  changeTab: (tab: string) => void;
  isBlindModeOn: boolean;
};

const ITEM_SECTIONS = [
  "compare_item_1",
  "compare_item_2",
  "compare_item_3",
  "compare_item_4",
  "compare_item_5",
  "compare_item_6"
];

export const CompareDetailsPage = ({
  task,
  comparableProfiles,
  removeProfileId,
  changeTab,
  isBlindModeOn
}: CompareDetailsPageProps) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const { windowWidth, windowHeight } = useWindowSize();
  const [topOffsets, setTopOffsets] = useState<number[]>([]);

  const { customTypes, loadForSections } = useCustomTypes();

  const { t } = useTranslation("activities");
  const isAvailabilityPermitted = useIsAvailabilityPermitted();
  const { getCellData, recalcOffsetCounter } = useCellData(task);

  useEffect(() => {
    // we use these offset to align (both size and position) the left-hand side headers
    // (cf names) to the table rows
    if (!tableRef.current) {
      return;
    }
    const { slice } = Array.prototype;
    const children = slice.call(tableRef.current ? tableRef.current.tBodies[0].children : [], 0);
    setTopOffsets(children.map((ch) => ch.offsetTop));
  }, [comparableProfiles, tableRef.current, windowWidth, windowHeight, recalcOffsetCounter]);

  const rows = useMemo(() => {
    const result: Row[] = [];

    const basicTypes = ["job_title", "skills"];

    const addRow = (name: string) => {
      const ct = customTypes.find((ct) => ct.name === name);
      if (ct && !result.find((row) => row.name === ct.name)) {
        result.push(ct);
      }
    };

    const addRowsForSection = (customTypeSection: string) => {
      loadForSections(customTypeSection)
        .filter(({ name }) => !result.find((row) => row.name === name))
        .forEach(({ name }) => addRow(name));
    };

    if (!isBlindModeOn) {
      result.push({ id: "bio", name: "bio", display_as: t("compare.shortBio") });
    }

    basicTypes.forEach((typeName) => addRow(typeName));

    if (isAvailabilityPermitted) {
      result.push({ id: "availability", name: "availability", display_as: t("compare.availability") });
    }

    ITEM_SECTIONS.forEach(addRowsForSection); // custom, client-defined cfs
    return result;
  }, [customTypes, isAvailabilityPermitted, isBlindModeOn, loadForSections, t]);

  // display data
  const rowsData = useMemo(
    () =>
      rows
        .map((row) => ({
          ...row,
          values: comparableProfiles.map((profile) => getCellData(row, profile))
        }))
        .filter((row) => row.id === "bio" || row.values.some((value) => value.node)) // hide empty rows
        .map((row, i) => {
          const top = topOffsets[i];
          return {
            ...row,
            propNode: <PropNode key={row.id} row={row} top={top} task={task} changeTab={changeTab} />
          };
        }),
    [rows, changeTab, comparableProfiles, getCellData, task, topOffsets]
  );

  const [headerY, setHeaderY] = useState(0);
  useScrollTop(window, () => {
    if (tableRef.current) {
      setHeaderY(Math.max(0, -tableRef.current.getBoundingClientRect().top));
    }
  });

  const profilesRef = useRef<HTMLDivElement>(null);
  const avoidMenuTrigger = useCallback((event) => {
    if (profilesRef.current?.scrollLeft !== 0) {
      event.stopPropagation();
    }
  }, []);

  return (
    <div className={css.wrap}>
      <div className={css.properties}>{rowsData.map((row) => row.propNode)}</div>
      <div className={css.profiles} onTouchEnd={avoidMenuTrigger} ref={profilesRef}>
        <table className={css.table} ref={tableRef}>
          <thead>
            <tr
              className={classNames(css.stickyHeader, { [css.stickyHeaderSticked]: headerY > 0 })}
              style={{ transform: `translate(0, ${headerY}px` }}
            >
              {comparableProfiles.map((item) => (
                <CompareProfileHeader
                  key={item.id}
                  comparableProfile={item}
                  task={task}
                  removeProfileId={removeProfileId}
                  isUnbiased={isBlindModeOn}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {rowsData.map((row) => (
              <tr key={row.id}>
                {row.values.map((item) => (
                  <td key={item.profile.id} className={classNames(css.td)}>
                    <div className={css.tdInner}>
                      {item.mobileName}
                      {item.node}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
