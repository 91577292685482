import classNames from "classnames";
import includes from "lodash/includes";
import { Checkbox } from "PFComponents/checkbox";
import YesNo from "PFComponents/icons/yes_no";
import Tooltip from "PFComponents/tooltip/tooltip";
import { daysFromNowFormat } from "PFCore/helpers/date";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import ExpiresSoon from "PFIcons/expires_soon.svg";
import { Id } from "PFTypes";
import React, { forwardRef, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { columnSizeToClass, getRawValue } from "./helpers";
import { useStringValueParser } from "./hooks/use_string_value_parser";
import MarkReadButton from "./mark_read_button";
import MatchValueTooltipped from "./match_value";
import NotificationItem from "./notification_item";
import PriorityBadge from "./priority_badge";
import ProfileCell from "./profile_cell";
import css from "./row.module.scss";
import { TableColumn, TableItem } from "./table_types";

type RowPropsType = {
  columns: TableColumn[];
  item: TableItem;
  dimUnselectedRows?: boolean;
  isSelectMode?: boolean;
  isSelected: boolean;
  isLoading?: boolean;
  isPinned: boolean;
  onSelectClick: (...args: any[]) => any;
  renderCell?: (item: TableItem, column: TableColumn) => React.ReactNode;
  renderActions?: (item: TableItem) => React.ReactNode;
  markAsRead?: (id: Id) => void;
  className?: string;
  isSelectionEnabled: boolean;
  utmSource?: string;
  search?: string;
  classes?: {
    unselected?: string;
    mediumColumn?: string;
  };
};

const Row = forwardRef<HTMLDivElement, RowPropsType>(
  (
    {
      className,
      item,
      columns,
      dimUnselectedRows = true,
      isSelectMode,
      isSelected,
      isLoading,
      isPinned,
      onSelectClick,
      renderCell,
      renderActions,
      markAsRead,
      isSelectionEnabled,
      utmSource,
      search,
      classes
    },
    lastItemRef
  ) => {
    const { t } = useTranslation("workflow");
    const { formatDateTime } = useDateFormatter();
    const { formatDate } = useDateFormatter();
    const rowRef = useRef<HTMLDivElement | null>(null);
    const { formatStringValue } = useStringValueParser();

    const getCellValue = (item, column) => {
      const rawValue = getRawValue(item, column);
      const excludedTypes = ["match_score", "score"];
      if (!rawValue && !includes(excludedTypes, column.type)) {
        return null;
      }

      if (column.name === "name") {
        return (
          <Link
            style={{ fontWeight: "bold" }}
            to={{
              pathname: `/activities/${item.id}`,
              search
            }}
            state={utmSource}
            data-qa-id={`WorkflowPageRowActivityLink.${item.id}`}
          >
            {rawValue}
          </Link>
        );
      } else if (column.name === "due") {
        return item.state === "complete" ? "-" : daysFromNowFormat(item.post_until);
      } else {
        switch (column.type) {
          case "noOfDays":
            return t("parts.day", { count: rawValue });
          case "date":
            if (["start_date", "end_date"].includes(column.name)) {
              return formatDate(rawValue[column.name]);
            } else {
              return formatDate(rawValue);
            }
          case "date-time":
            return formatDateTime(rawValue);
          case "custom_field":
            if (column.name === "priority") {
              return <PriorityBadge level={String(rawValue[0].text).toLowerCase()} />;
            } else if (Array.isArray(rawValue)) {
              return rawValue.map(({ text }) => formatStringValue(text, column.name)).join(", ");
            } else {
              return formatStringValue(String(rawValue), column.name);
            }
          case "toggle":
            return <YesNo yes={rawValue} />;
          case "profile":
            return <ProfileCell profile={rawValue} qaId={`WorkflowPageRowProfileLink.${item.id}`} />;
          case "score":
            return <MatchValueTooltipped scoreName={column.name} scores={rawValue} />;
          case "link":
            return (
              <div className={css.link}>
                <a href={rawValue} target="blank" title={rawValue}>
                  {rawValue}
                </a>
              </div>
            );
          default:
            return String(rawValue);
        }
      }
    };

    const renderCellFunction = (item, column) =>
      (renderCell && renderCell(item, column)) || getCellValue(item, column);

    const hasNotifications = (item.notifications || []).length > 0;

    const tooltipContent = hasNotifications ? (
      <div>
        {item.notifications?.map((notification) => (
          <NotificationItem key={notification.id} notification={notification} />
        ))}
      </div>
    ) : (
      ""
    );

    return (
      <div
        ref={(el) => {
          rowRef.current = el;
          if (lastItemRef) {
            //@ts-ignore - current is not available
            lastItemRef.current = el;
          }
        }}
        className={classNames(css.container, { [css.pinned]: isPinned })}
      >
        {isSelectionEnabled && (
          <div className={css.iconBox}>
            {isSelectMode ? (
              <Checkbox checked={isSelected} onChange={onSelectClick} />
            ) : item.expires_soon ? (
              <Tooltip content={t("parts.expiresSoon")}>
                <div>
                  <ExpiresSoon />
                </div>
              </Tooltip>
            ) : null}
          </div>
        )}
        <Tooltip
          content={tooltipContent}
          placement="bottom-start"
          arrow={false}
          popperOptions={{
            modifiers: [
              {
                name: "flip",
                enabled: false
              },
              {
                name: "preventOverflow",
                options: {
                  altBoundary: true
                }
              }
            ]
          }}
        >
          <div
            className={classNames(
              css.item,
              {
                [classes?.unselected || css.unselected]: isSelectMode && !isSelected && dimUnselectedRows,
                [css.loading]: isLoading
              },
              className
            )}
            data-qa-id={`WorkflowPageRow.${item.id}`}
          >
            {columns.map((column) => (
              <div
                key={column.name}
                className={classNames(css.cell, css[columnSizeToClass(column.size)], {
                  [classes?.mediumColumn || ""]:
                    classes?.mediumColumn && columnSizeToClass(column.size) === "medium"
                })}
              >
                {renderCellFunction(item, column)}
              </div>
            ))}
            {renderActions && renderActions(item)}
          </div>
        </Tooltip>
        {hasNotifications && <MarkReadButton rowRef={rowRef} onClick={() => markAsRead?.(item.id)} />}
      </div>
    );
  }
);

Row.displayName = "Row";

export default Row;
