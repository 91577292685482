import { Typography } from "PFComponents/typography";

import css from "./table_header.module.scss";

type TableHeaderProps = {
  title: string;
};

export const TableHeader = ({ title }: TableHeaderProps) => (
  <Typography variant="bodyRegular" className={css.tableHeader}>
    {title}
  </Typography>
);
