import { createContext, useContext } from "react";

import { DeleteBookingModalData } from "./delete_booking_modal";
import { DeleteBookingModalController } from "./delete_booking_modal_controller";
import { useDeleteBookingModal } from "./use_delete_booking_modal";

export type DeleteBookingModalDataStateType = {
  isOpen: boolean;
  data?: DeleteBookingModalData;
};

export type DeleteBookingModalType = {
  state: DeleteBookingModalDataStateType;
  open: (data: DeleteBookingModalData) => void;
  close: () => void;
};

type DeleteBookingModalContextType = {
  modal: DeleteBookingModalType;
};

const DeleteBookingModalContext = createContext({} as DeleteBookingModalContextType);

export const DeleteBookingModalContextProvider = ({ children }: React.PropsWithChildren) => {
  const modal = useDeleteBookingModal();
  return (
    <DeleteBookingModalContext.Provider value={{ modal }}>
      <DeleteBookingModalController />
      {children}
    </DeleteBookingModalContext.Provider>
  );
};

export const useDeleteBookingModalContext = () => useContext(DeleteBookingModalContext);
