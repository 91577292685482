import classNames from "classnames";
import findIndex from "lodash/findIndex";
import isNumber from "lodash/isNumber";
import uniqueId from "lodash/uniqueId";
import DropDown, { DropdownOption, GroupDropdownOption } from "PFComponents/dropdown/dropdown";
import { InputFieldSet, InputFieldSetProps } from "PFComponents/text/input_field_set";
import { isActionKeyPressed } from "PFCore/helpers/is_action_key_pressed";
import React, {
  forwardRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";

import css from "./select.module.scss";

const DEFAULT_DISPLAY_VALUES_HEIGHT = 24;
const DISPLAY_VALUES_PADDING_OFFSET = 8;
const FOCUS_OFFSET = 32;

type SelectProps = {
  qaId?: string;
  placeholder?: string;
  value?: string | React.ReactElement | null;

  selectedIndex?: number;
  options: DropdownOption[] | GroupDropdownOption[];
  preOptions?: React.ReactElement;
  popperOptions?: object;
  portalRef?: MutableRefObject<HTMLDivElement | null>;

  inline?: boolean;
  readOnly?: boolean;
  required?: boolean;
  closeOnChange?: boolean;

  controlledValue?: boolean;
  displayValues?: React.ReactNode;
  displayValuesBelow?: boolean;
  hideDisplayValuesOnFocus?: boolean;

  onInputChange?: (value: string) => void;
  onChange?: (item: any, option: any) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onOpen?: () => void;
  onClose?: () => void;
  onDropDownToggle?: (isOpen: boolean) => void;

  fitDropdownContent?: boolean;
  dropdownStyle?: React.CSSProperties;
  dropdownClassName?: string;
  inputFieldSetProps?: Partial<
    Omit<InputFieldSetProps, "type" | "id" | "onChange" | "onKeyDown" | "onBlur" | "onClick" | "value">
  >;

  isMultiSelect?: boolean;
  allowSameValueInOptions?: boolean;
  initialSelectedItems?: DropdownOption[];
  selectedItems?: DropdownOption[];
} & Omit<InputFieldSetProps, "value">;

export const Select = forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      id,
      className,
      label,
      labelTooltip,
      labelHidden,
      title,
      active,
      error,
      tip,
      locked,
      lockedTip,
      lockedTipPosition,
      disabled,
      qaId,
      style,
      displayValues,
      displayValuesBelow,
      hideDisplayValuesOnFocus,
      value,
      placeholder,
      options,
      controlledValue,
      readOnly,
      required,
      inline,
      closeOnChange = true,
      onInputChange,
      onChange,
      onOpen,
      onClose,
      onDropDownToggle,
      preOptions,
      portalRef,
      popperOptions,
      icon,
      inputFieldSetProps,
      fitDropdownContent,
      dropdownClassName,
      isMultiSelect,
      allowSameValueInOptions = true,
      ...dropdownProps
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const [selectedIndex, setSelectedIndex] = useState(dropdownProps.selectedIndex);
    const [displayValuesOffsetStyles, setDisplayValuesOffsetStyles] = useState({});

    const popperRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const dropDownRef = useRef(null);

    const inputUniqueName = useMemo(() => uniqueId("select-input"), []);

    useImperativeHandle(ref, () => inputRef.current!, []);

    useEffect(() => {
      if (controlledValue) {
        setSelectedIndex(dropdownProps.selectedIndex);
      }
    }, [controlledValue, dropdownProps.selectedIndex]);

    const {
      styles,
      attributes,
      update: updatePopper
    } = usePopper(popperRef.current, popperElement, {
      placement: "bottom-start",
      ...popperOptions
    });

    const inputValue = useMemo(
      //@ts-ignore
      () => (controlledValue ? value : isNumber(selectedIndex) ? options[selectedIndex].displayElement : ""),
      [controlledValue, value, selectedIndex, options]
    );

    const displayValuesMeasureRef = useCallback(
      (node) => {
        if (node === null || displayValuesBelow || !displayValues) {
          setDisplayValuesOffsetStyles({});
          return;
        }

        const customHeight = node.getBoundingClientRect().height;
        let newHeight = DEFAULT_DISPLAY_VALUES_HEIGHT;

        if (customHeight > DEFAULT_DISPLAY_VALUES_HEIGHT) {
          newHeight = customHeight;
        }

        if (hideDisplayValuesOnFocus || readOnly || !isOpen) {
          setDisplayValuesOffsetStyles({
            height: newHeight + DISPLAY_VALUES_PADDING_OFFSET
          });
        } else {
          setDisplayValuesOffsetStyles({
            height: newHeight + DISPLAY_VALUES_PADDING_OFFSET + FOCUS_OFFSET,
            paddingTop: newHeight
          });
        }
      },
      [displayValuesBelow, displayValues, hideDisplayValuesOnFocus, readOnly, isOpen]
    );

    const focusInput = () => {
      if (inputRef.current) {
        try {
          inputRef.current.focus();
        } catch {}
      }
    };

    const handleChangeFnc = (item, option) => {
      if (!isMultiSelect && allowSameValueInOptions) {
        //@ts-ignore
        const newSelectedIndex = findIndex(options, (option) => option.item === item);
        setSelectedIndex(newSelectedIndex);
      }

      if (closeOnChange) {
        handleCloseFnc();
      } else {
        focusInput();
        updatePopper && updatePopper();
      }

      onChange && onChange(item, option);
    };

    const handleOpenFnc = () => {
      if (isOpen) {
        return;
      }

      setIsOpen(true);
      onDropDownToggle && onDropDownToggle(true);
      onOpen && onOpen();
    };

    const handleBlur = (event) => {
      const target = event.relatedTarget;
      if (
        target &&
        (target.hasAttribute("data-dropdown-root") ||
          target.hasAttribute("data-dropdown-item") ||
          target.hasAttribute("data-dropdown-list") ||
          target.getAttribute("data-input-name") === inputUniqueName)
      ) {
        return;
      }

      handleCloseFnc();
    };

    const handleCloseFnc = () => {
      if (!isOpen) {
        return;
      }

      setIsOpen(false);

      onDropDownToggle && onDropDownToggle(false);
      onClose && onClose();
    };

    const handleKeyDown = (event) => {
      if (isActionKeyPressed(event)) {
        handleOpenFnc();
      }

      // TODO: [PROF-7192] It should be refactored as a part of Dropdown refactor ticket
      // This is a hack to make the dropdown work with keyboard navigation
      // The same implementation is done in dropdown_button.tsx
      // @ts-ignore
      // const handleKeyDown = (event) => dropDownRef.current && dropDownRef.current.handleKeyDown(event);
      dropDownRef.current && dropDownRef.current.handleKeyDown(event);
    };

    const Dropdown = (
      <div
        id="dropdown"
        ref={setPopperElement}
        className={classNames({
          [css.hideDropdown]: portalRef && attributes.popper?.["data-popper-reference-hidden"]
        })}
        style={{ ...styles.popper, zIndex: 20000 }}
        {...attributes.popper}
      >
        <DropDown
          {...dropdownProps}
          options={options}
          ref={dropDownRef}
          rootClassName={dropdownClassName}
          style={{
            ...(!fitDropdownContent && {
              width: popperRef.current?.offsetWidth,
              maxWidth: popperRef.current?.offsetWidth
            }),
            ...dropdownProps.dropdownStyle
          }}
          selectedIndex={selectedIndex}
          preOptions={preOptions}
          isMultiSelect={isMultiSelect}
          handleChange={handleChangeFnc}
          handleClose={handleCloseFnc}
        />
      </div>
    );

    return (
      <div
        role="combobox"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-controls="dropdown"
        onBlur={handleBlur}
        ref={ref}
        className={classNames(css.select, className)}
        style={style}
        data-qa-id={qaId}
      >
        <div ref={popperRef} className={css.inputWrapper}>
          {displayValuesBelow && displayValues && <div className={css.selectedValuesBackgroundExtension} />}
          <InputFieldSet
            id={id}
            placeholder={placeholder}
            ref={inputRef}
            label={label}
            labelTooltip={labelTooltip}
            labelHidden={labelHidden}
            title={title}
            active={active}
            error={error}
            inline={inline}
            tip={tip}
            locked={locked}
            lockedTip={lockedTip}
            lockedTipPosition={lockedTipPosition}
            disabled={disabled}
            value={inputValue}
            readOnly={readOnly}
            onChange={onInputChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onClick={handleOpenFnc}
            icon={icon || "chevron-down"}
            inputStyle={displayValuesOffsetStyles}
            required={required}
            dataInputName={inputUniqueName}
            {...(inputFieldSetProps as InputFieldSetProps)}
          />
        </div>
        {displayValues && (
          <div
            ref={displayValuesMeasureRef}
            className={classNames(css.displayValues, {
              [css.noLabel]: inline || !label,
              [css.hiddenValueOnFocus]: isOpen && hideDisplayValuesOnFocus,
              [css.displayValuesInside]: !displayValuesBelow,
              [css.displayValuesBelow]: displayValuesBelow
            })}
          >
            {displayValues}
          </div>
        )}
        {isOpen &&
          !disabled &&
          (portalRef?.current ? ReactDOM.createPortal(Dropdown, portalRef.current) : Dropdown)}
      </div>
    );
  }
);

Select.displayName = "Select";
