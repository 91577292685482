import { Button } from "PFComponents/button";
import ButtonLink from "PFComponents/button/button_link";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import css from "./not_found.module.scss";

type NotFoundComponentProps = {
  children?: React.ReactNode;
  message?: string;
  buttonMessage?: string;
  buttonDisabled?: boolean;
  buttonHidden?: boolean;
  buttonUrl?: string;
  handleClick?: VoidFunction;
  style?: React.CSSProperties;
};

const NotFoundComponent = ({
  message,
  buttonMessage,
  buttonDisabled,
  buttonHidden,
  buttonUrl,
  children,
  handleClick,
  style
}: NotFoundComponentProps) => {
  const { t } = useTranslation("core");
  const navigate = useNavigate();

  const handleDefaultClick = (event) => {
    if (!handleClick && !buttonUrl) {
      event.preventDefault();
      navigate(-1);
    } else if (handleClick) {
      handleClick();
    }
  };

  return (
    <div className={css.emptyBlock} style={style}>
      {children}
      <p className={css.emptyText}>{message || t("notFound.message")}</p>
      {!buttonHidden && (
        <>
          {buttonUrl ? (
            <ButtonLink kind="primary" href={buttonUrl} onClick={handleDefaultClick}>
              {buttonMessage || t("notFound.buttonMessage")}
            </ButtonLink>
          ) : (
            <Button kind="primary" disabled={buttonDisabled} onClick={handleDefaultClick}>
              {buttonMessage || t("notFound.buttonMessage")}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default NotFoundComponent;
