/* eslint-disable camelcase */
// Accepting App component because Profile Card is deprecated and will be replaced in future
import { ProfileCustomValuesList } from "PFApp/components/custom_values_list";
import { Stack } from "PFComponents/containers/flex";
import { Typography } from "PFComponents/typography";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { customValuesShapePropType, scoresShapePropType } from "../../../base/prop_types/common_prop_types";

const SKILLS_LIMIT = 5;

const ProfileCardSkills = ({ style, profileResponse, isBlindModeOn }) => {
  const { t } = useTranslation("core", { keyPrefix: "profileCardSkills" });
  const { profile } = profileResponse;

  const matchingSkills = profileResponse.custom_values || profileResponse.customValues;
  const { direct, indirect, related } = { direct: [], indirect: [], related: [], ...matchingSkills };

  // response from matches endpoint
  const data = useMemo(() => {
    if (direct.length || related.length || indirect.length) {
      return [
        {
          header: t("matchingAttributes"),
          skillsToShow: direct.map((item) => ({ ...item, ...item?.custom_value }))
        },
        {
          header: t("relatedAttributes"),
          skillsToShow: related.map((item) => ({ ...item, ...item?.custom_value }))
        },
        {
          header: t("indirectAttributes"),
          skillsToShow: indirect.map((item) => ({ ...item, ...item?.custom_value }))
        }
      ].filter((row) => row.skillsToShow?.length);
    }

    // response from profiles endoint
    const skills = profile.skills || [];
    const topSkills = skills.filter(({ top }) => top);
    const isTopSkills = topSkills.length > 0;
    const skillsToShow = isTopSkills ? topSkills : skills;
    if (!skillsToShow.length) {
      return [];
    }
    return [
      {
        header: isTopSkills ? t("coreSkills") : t("skills"),
        skillsToShow
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileResponse, t]);

  if (!data.length) {
    return null;
  }

  return (
    <Stack style={style}>
      {data.map(({ skillsToShow, header }) => (
        <Stack key={header} columnGap="lg">
          <Typography variant="labelRegular" noMargin>
            {header}
          </Typography>
          {skillsToShow.length > 0 && (
            <ProfileCustomValuesList
              customFieldName="skills"
              customValues={skillsToShow}
              profileId={profile?.id}
              limit={SKILLS_LIMIT}
              isBlindModeOn={isBlindModeOn}
            />
          )}
        </Stack>
      ))}
    </Stack>
  );
};

const profileResponseShapePropType = PropTypes.shape({
  profile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }),
  scores: scoresShapePropType,
  custom_values: customValuesShapePropType,
  optional_fields: PropTypes.shape({
    available_minutes: PropTypes.number
  })
});
ProfileCardSkills.propTypes = {
  style: PropTypes.object,
  profileResponse: profileResponseShapePropType.isRequired,
  isBlindModeOn: PropTypes.bool
};

export default ProfileCardSkills;
