import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

import css from "./no_items.module.scss";

export const NoItems = () => {
  const { t } = useTranslation("core");
  return (
    <div className={css.root}>
      <Icon name="missing" color="paletteBlue2" />
      <Typography variant="bodyRegular">{t("components.selectV2.noItemsToShow")}</Typography>
    </div>
  );
};
