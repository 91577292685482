import classNames from "classnames";
import { ActionDiv } from "PFComponents/containers";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";

import { Option, OptionOriginal } from "../../../select_v2.types";
import css from "./list_item.module.scss";

type ListItemSingleProps<T> = {
  id: string;
  option: Option<T>;
  selected: boolean;
  onClick: VoidFunction;
};

export const ListItemSingle = <T extends OptionOriginal = OptionOriginal>({
  id,
  option,
  selected,
  onClick
}: ListItemSingleProps<T>) => (
  <ActionDiv id={id} onClick={onClick} ariaLabel={option.value} className={css.listComponent} role="option">
    <Icon name="check" size="sm" className={classNames({ [css.iconHidden]: !selected })} />
    <Typography variant="bodyRegular" clipOverflow>
      {option.displayElement ?? option.value}
    </Typography>
  </ActionDiv>
);
