import { decamelizeKeys } from "humps";
import { broadcastLogIn } from "PFApp/bootstrapper/use_log_in_out_broadcasts";
import api from "PFCore/api";
import { lockSimple } from "PFCore/helpers/lock_simple";
import { initializeMsTeams } from "PFCore/helpers/ms_teams";
import { useQueryParams } from "PFCore/helpers/use_query_params";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfileSet } from "PFCore/hooks/queries/profile";
import { fetchCurrentProfile } from "PFCore/services/current_profile";
import { UiRoute } from "PFCore/utilities/routes";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useDeeplink } from "../use_deeplink";
import { startHeartbeat } from "./heartbeat";
import { useAccessTokens } from "./use_access_tokens";
import { useSignOut } from "./use_sign_out";
import { setTokensForAccount } from "./utils";

export const useSession = () => {
  const { data: currentAccount, refetch: refetchCurrentAccount } = useCurrentAccount();

  const setCurrentProfile = useCurrentProfileSet();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useQueryParams();
  const { saveDeeplink } = useDeeplink();
  const { getAccessToken, getRefreshToken, clearTokens } = useAccessTokens();
  const { signOut } = useSignOut();

  const expireSession = useCallback(
    (message?: string) => {
      if (location.pathname !== "/sign_out") {
        saveDeeplink();
      }
      storage.removeItem("onboarding__show_job_title");
      const error = message || "sign_in.session_expired";
      signOut({ error });
    },
    [location.pathname, saveDeeplink, signOut]
  );

  const startHeartbeatFunction = useCallback(
    () =>
      startHeartbeat(() => {
        if (!getRefreshToken()) {
          return expireSession();
        }
      }),
    [getRefreshToken, expireSession]
  );

  const refreshToken = () =>
    lockSimple("refresh_token", () => {
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();

      if (accessToken && !refreshToken) {
        expireSession();
        return Promise.resolve();
      }

      return api({
        url: "oauth/token",
        method: "post",
        data: {
          grantType: "refresh_token",
          refreshToken
        }
      })
        .then((resp) => {
          setTokensForAccount(resp.accessToken, resp.refreshToken, currentAccount, true);
        })
        .catch((resp) => {
          // Couldn't refresh the token so we sign out
          if (resp.response.status === 401) {
            expireSession();
          } else {
            signOut();
          }
        });
    });

  const signIn = (username: string, password: string, options: { onlySuccess?: boolean } = {}) =>
    api({
      url: "oauth/token",
      method: "post",
      data: {
        grantType: "password",
        clientId: PF.config.client_id,
        username,
        password
      }
    }).then(
      (resp) => {
        setTokensForAccount(resp.accessToken, resp.refreshToken, currentAccount);
        if (typeof sessionStorage !== "undefined" && sessionStorage !== null) {
          sessionStorage.removeItem("signedOut");
        }

        broadcastLogIn();

        return fetchCurrentProfile().then(
          (profile) => {
            setCurrentProfile(profile);
            refetchCurrentAccount();
            return Promise.resolve(resp);
          },
          (resp) => {
            if (!options.onlySuccess) {
              signOut();
            }
            return Promise.reject(resp);
          }
        );
      },
      (resp) => {
        if (!options.onlySuccess) {
          signOut();
        }
        return Promise.reject(resp);
      }
    );

  const setup = async () => {
    const code = queryParams.get("code");
    const resetPasswordToken = queryParams.get("reset_password_token");
    const isMsTeams = queryParams.get("msTeams");
    const account = queryParams.get("account");
    if (resetPasswordToken) {
      await signOut(
        location.pathname === "/password_edit"
          ? { redirectUri: `${location.pathname}${location.search}` }
          : {}
      );
    } else if (code) {
      try {
        const response = await api({
          url: "oauth/token",
          method: "post",
          data: {
            grantType: "authorization_code",
            code,
            clientId: PF.config.client_id,
            redirectUri: UiRoute("/")
          }
        });
        if (isMsTeams) {
          const oauthRedirectMethod = queryParams.get("oauthRedirectMethod");
          const authId = queryParams.get("authId");
          response.account = account;
          if (oauthRedirectMethod === "deeplink") {
            const { accessToken, refreshToken } = response;
            const result = `${accessToken}-${refreshToken}-${account}`;
            window.location.href = `msteams://teams.microsoft.com/l/auth-callback?authId=${authId}&result=${result}`;
            return navigate("/ms_teams/end");
          } else {
            const microsoftTeams = (await initializeMsTeams({ force: true })) as any;
            return microsoftTeams.authentication.notifySuccess(decamelizeKeys(response));
          }
          // teams tab (ms_teams_sign_in.jsx), or bot should handle tokens from here
        }

        // Clear code param from url so we don't perform authentication twice
        navigate("/");
        setTokensForAccount(response.accessToken, response.refreshToken, currentAccount);
        broadcastLogIn();
        return response.accessToken;
      } catch {
        await signOut();
      }
    }
  };

  return {
    getAccessToken,
    signIn,
    signOut,
    refreshToken,
    startHeartbeat: startHeartbeatFunction,
    expireSession,
    clearTokens,
    setup
  };
};
