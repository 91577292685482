import isEmpty from "lodash/isEmpty";
import { RequirementType } from "PFTypes";
import { useWatch } from "react-hook-form";

import { BookingFormCommonInputs } from "./booking_form_common_inputs";
import { BookingFormValues, BookingType } from "./booking_form_provider";

export const BookingFormGlobalInputs = () => {
  const [bookings, bookingType] = useWatch<BookingFormValues, ["bookings", "bookingType"]>({
    name: ["bookings", "bookingType"]
  });

  const isOverridesNonWorkingDaysGlobalDisabled =
    isEmpty(bookings) ||
    bookings.some((item) => item.requirement?.type === RequirementType.Load) ||
    bookingType === BookingType.Repeated;

  return (
    <BookingFormCommonInputs
      overridesDiaryTimeFormValueName="globalOverridesDiaryTime"
      overridesNonWorkingTimeFormValueName="globalOverridesNonWorkingTime"
      categoryFormValueName="globalCategory"
      categoryFormErrorKeys={[
        "globalCategory",
        "globalCategoryErrorAPI.submit",
        "globalCategoryErrorAPI.potential-warnings"
      ]}
      titleFormValueName="globalTitle"
      descriptionFormValueName="globalDescription"
      isOverridesNonWorkingDaysDisabled={isOverridesNonWorkingDaysGlobalDisabled}
    />
  );
};
