import DeleteBookingModal from "./delete_booking_modal";
import { useDeleteBookingModalContext } from "./delete_booking_modal_context_provider";

export const DeleteBookingModalController = (): React.ReactElement | null => {
  const {
    modal: {
      state: { isOpen, data }
    }
  } = useDeleteBookingModalContext();

  if (!isOpen || !data) {
    return null;
  }
  return <DeleteBookingModal {...data} />;
};
