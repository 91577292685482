import classNames from "classnames";
import CloseIcon from "PFIcons/close.svg";
import OkIcon from "PFIcons/ok.svg";
import { CSSProperties } from "react";

import css from "./yes_no.module.scss";

type YesNoProps = {
  yes: boolean;
  style?: CSSProperties;
};

const YesNo = ({ yes, style }: YesNoProps) => (
  <div className={classNames(css.root, { [css.yes]: yes })} style={style}>
    {yes ? <OkIcon width={20} height={20} /> : <CloseIcon width={22} height={22} />}
  </div>
);

export default YesNo;
