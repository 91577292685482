import { Placement } from "@popperjs/core";
import { TippyProps } from "@tippyjs/react";
import classNames from "classnames";
import Tooltip, { TooltipProps } from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import React, { PropsWithChildren, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import css from "./simple_badge.module.scss";

export const PlaceholderTooltipContent = () => {
  const { t } = useTranslation("core", { keyPrefix: "simpleBadge" });

  return (
    <div style={{ padding: 10 }}>
      {t("newEntry")}
      <br />
      <small>{t("awaitingAdminApproval")}</small>
    </div>
  );
};

export type BadgeKind = "primary" | "secondary" | "selected" | "faded" | "danger" | "success" | "highlight";

export type SimpleBadgeProps = PropsWithChildren<{
  icon?: ReactNode;
  text: string | number;
  kind?: BadgeKind;
  classes?: Record<string, string>;
  showTooltip?: boolean;
  small?: boolean;
  style?: React.CSSProperties;
  onClick?: ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void) | null;
  isLoading?: boolean;
  topRightIcon?: React.ReactElement;
  tooltipContent?: ReactNode;
  tooltipTheme?: TooltipProps["theme"];
  tooltipPlacement?: Placement;
  tooltipAppendTo?: TippyProps["appendTo"];
  disabled?: boolean;
}>;

const SimpleBadge = ({
  icon = null,
  text,
  children,
  kind = "primary",
  showTooltip,
  small,
  style,
  onClick,
  isLoading,
  topRightIcon,
  tooltipContent = <PlaceholderTooltipContent />,
  tooltipTheme = "pf-skill",
  tooltipPlacement = "auto",
  tooltipAppendTo,
  classes,
  disabled
}: SimpleBadgeProps) => {
  const Tag = onClick ? "a" : "div";
  const title = typeof text === "string" ? text : typeof text === "number" ? text.toString() : "";

  return (
    <Tag
      style={style}
      className={classNames(css.root, css[kind], classes?.root, {
        [css.disabled]: disabled,
        [css.small]: small,
        [css.loading]: isLoading
      })}
      onClick={(event) => onClick?.(event)}
    >
      <Tooltip
        theme={tooltipTheme}
        disabled={!showTooltip}
        content={tooltipContent}
        placement={tooltipPlacement}
        appendTo={tooltipAppendTo}
        lazy
        interactive
      >
        <div className={css.headerWrapper}>
          <div className={css.textWrapper}>
            {topRightIcon && <div className={css.topRightIcon}>{topRightIcon}</div>}
            {icon && <div className={css.icon}>{icon}</div>}

            <Typography
              withMargin
              variant={small ? "labelRegular" : "bodyRegular"}
              tag="span"
              title={title}
              className={css.text}
              clipOverflow
            >
              {text}
              {children}
            </Typography>
          </div>
        </div>
      </Tooltip>
    </Tag>
  );
};

export default SimpleBadge;
