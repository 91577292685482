import { Icon } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { ReactNode } from "react";

import css from "./booking_detail_item.module.scss";

const isTextValue = (value: any): boolean => typeof value === "string" || typeof value === "number";

type BookingDetailItemProps = {
  label: string;
  value?: ReactNode;
  infoContent?: ReactNode;
  tooltipInteractive?: boolean;
  tooltipMaxWidth?: number;
};

export const BookingDetailItem = ({
  label,
  value,
  infoContent,
  tooltipInteractive,
  tooltipMaxWidth
}: BookingDetailItemProps) => (
  <div className={css.root}>
    <Typography variant="labelRegular">{label}</Typography>
    <div className={css.container}>
      {isTextValue(value) ? <Typography variant="bodyBold">{value ?? ""}</Typography> : value}
      {!!infoContent && (
        <Tooltip
          content={infoContent}
          appendTo={"parent"}
          interactive={tooltipInteractive}
          maxWidth={tooltipMaxWidth}
        >
          <div className={css.container}>
            <Icon name="info" size="sm" />
          </div>
        </Tooltip>
      )}
    </div>
  </div>
);
