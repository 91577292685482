import isEmpty from "lodash/isEmpty";
import { ActionIcon } from "PFComponents/action_icon";
import { Slider } from "PFComponents/slider";
import { IntegerValue, NumberFilter } from "PFTypes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type IntegerItemProps = {
  label: string;
  filter: NumberFilter;
  handleChange: (value: IntegerValue | null) => void;
  disabled?: boolean;
};

export const IntegerItem = ({ label, filter, handleChange, disabled }: IntegerItemProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "filters.number" });

  const { value: originalValue, ...rest } = filter;
  const filterStringified = JSON.stringify(rest);
  const [statistics, setStatistics] = useState(filter.statistics || { min: 0, max: 0 });

  useEffect(() => {
    const { query } = filter;
    if (query && !filter.statistics) {
      query({ value: null, term: null }).then((resp) => {
        setStatistics(resp.statistics);
      });
    }
  }, [filterStringified]);

  const { min, max } = statistics;

  const showClearLink = !isEmpty(filter.value);

  const value = {
    min: Math.max((filter.value as IntegerValue)?.gte ?? min, min),
    max: Math.min((filter.value as IntegerValue)?.lte ?? max, max)
  };

  return (
    <Slider
      multiRange
      title={label}
      value={value}
      min={min}
      max={max}
      onChange={({ min, max, sliderId }) => handleChange({ gte: min, lte: max, sliderId })}
      disabled={disabled}
      showSteps={false}
      showMinMax={false}
      tip={t("minMax", { min, max })}
      small
      actions={
        showClearLink && (
          <ActionIcon name="filter-clean" size="sm" onClick={() => handleChange(null)} color="paletteBlue0" />
        )
      }
    />
  );
};
