import { BookingCategory } from "PFTypes";

import CategoryOption from "../booking_form/booking_category_select/category_option/category_option";

type Value = {
  id: string;
  displayElement: React.ReactElement;
  item: string;
};

type TransformBookingCategory = {
  input: (category: BookingCategory | null) => BookingCategory[] | null;
  output: (value: string[], bookingCategories: BookingCategory[]) => BookingCategory | null;
  formatOption: (category: BookingCategory) => Value;
  query: (bookingCategories: BookingCategory[], demand?: boolean) => Promise<BookingCategory[]>;
};

export const transform: TransformBookingCategory = {
  input: (category) => (category ? [category] : null),
  output: (value, bookingCategories) => bookingCategories.find((item) => item.key === value[0]) ?? null,
  formatOption: (category) => ({
    id: category.key,
    displayElement: <CategoryOption category={category} />,
    item: category.key
  }),
  query: (bookingCategories, demand) =>
    Promise.resolve(
      bookingCategories.filter(
        (item) =>
          !item.archived && !item.readonly && (demand ? item.activity_assignable : !item.activity_assignable)
      )
    )
};
