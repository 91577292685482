import range from "lodash/range";
import { Moment } from "moment";
import { BookingCategory } from "PFTypes";

import { ISO_DATE_FORMAT } from "../../../../../helpers/date";
import { CalendarPeriod } from "../../bookings_calendar.types";
import { fromCalendarPeriod } from "../../bookings_calendar.utils";
import { PeriodTooltip } from "./period_tooltip";
import css from "./period_tooltips.module.scss";

type PeriodTooltipsProps = {
  startWeekDate: Moment;
  period: CalendarPeriod;
  bookingCategories: BookingCategory[];
  jobCodeDisplayAs: string;
  daysDuration: number;
};

export const PeriodTooltips = ({
  startWeekDate,
  period,
  bookingCategories,
  jobCodeDisplayAs,
  daysDuration
}: PeriodTooltipsProps) => {
  const startDate = period.start_date.isBefore(startWeekDate) ? startWeekDate : period.start_date;
  return (
    <div>
      {range(0, daysDuration).map((day) => {
        const dayInPeriodWidth = 100 / daysDuration;
        const currentDay = startDate.clone().add(day, "days");
        const formattedDay = currentDay.format(ISO_DATE_FORMAT);
        return (
          <PeriodTooltip
            key={`period-tooltip-${formattedDay}-${period.id}`}
            booking={fromCalendarPeriod(period)}
            bookingCategories={bookingCategories}
            jobCodeDisplayAs={jobCodeDisplayAs}
            date={formattedDay}
          >
            <div
              className={css.dayTooltip}
              style={{
                width: `${dayInPeriodWidth}%`,
                left: `${day * dayInPeriodWidth}%`
              }}
            />
          </PeriodTooltip>
        );
      })}
    </div>
  );
};
