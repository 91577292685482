import classNames from "classnames";
import groupBy from "lodash/groupBy";
import { Button } from "PFComponents/button";
import { DoubleSectionSelectPill } from "PFComponents/double_section_pill/double_section_select_pill";
import AutoSelect from "PFComponents/select/autoselect";
import { canAddMatchableTypeValue, canEditOrRemoveMatchableTypeValues } from "PFCore/helpers/activities";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { fetchAutocompleteCustomValues } from "PFCore/services/autocomplete/fetch_autocomplete_custom_values";
import AddIcon from "PFIcons/add.svg";
import CloseIcon from "PFIcons/close.svg";
import { Importance } from "PFTypes";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./keywords_sliders.less";

const TypeSection = ({
  remove,
  handleChange,
  search,
  pushWithNewImportance,
  type,
  keywords,
  error,
  initialOpen = false,
  importanceOptions,
  onChangeOpen
}) => {
  const { t } = useTranslation("search");
  const [open, setOpen] = useState(initialOpen);

  const notChosen = (option) => !keywords.find(({ id }) => id === option.id);

  const handleOpen = (open) => {
    setOpen(open);
    onChangeOpen?.(open);
  };

  return (
    <li
      onClick={open ? null : () => handleOpen(true)}
      onKeyDown={open ? null : () => handleOpen(true)}
      className={classNames(css.section, { [css.sectionClosed]: !open, [css.error]: error })}
    >
      <Button kind="blank" className={css.sectionToggle} onClick={() => handleOpen(!open)}>
        {open ? <CloseIcon width={20} height={20} /> : <AddIcon width={20} height={20} />}
      </Button>
      <span className={css.typeName}>
        {type.display_as} ({keywords.length}) {!search && ` - ${t("parts.keywords.editOriginalWarn")}`}
      </span>
      {error && <span className={css.errorMessage}>{error}</span>}
      {open && (
        <section className={css.sectionList}>
          {handleChange && (
            <AutoSelect
              key={keywords.map(({ id }) => id).join("")}
              alwaysEmpty
              query={(term) => fetchAutocompleteCustomValues({ term, type: type.name })}
              handleChange={handleChange}
              letClear={false}
              closeOnChange={true}
              multi={false}
              values={[]}
              placeholder={t("parts.keywords.addAttribute")}
              filterOptions={(options) => options.filter(notChosen)}
              formatOption={(option) => {
                if (typeof option?.text === "object") {
                  // ignore location. this is considered misconfiguration (missing NON_MATCHABLE section)
                  // want to avoid the whole page breaking up in such a case though.
                  return false;
                }

                return {
                  id: option?.id,
                  displayElement: <span>{option?.text}</span>,
                  item: {
                    ...option,
                    name: type.name,
                    type
                  }
                };
              }}
            />
          )}
          <div className={css.pillsList}>
            {keywords.map((keyword) => (
              <DoubleSectionSelectPill
                key={keyword.id}
                leftContent={String(keyword.value)}
                attribute={
                  importanceOptions.find(({ item }) => item === keyword.importance)?.displayElement || ""
                }
                attributeOptions={importanceOptions}
                onAttributeChange={(newImportance) => pushWithNewImportance(keyword, newImportance)}
                onDelete={remove ? () => remove(keyword) : null}
              />
            ))}
          </div>
        </section>
      )}
    </li>
  );
};

TypeSection.propTypes = {
  type: PropTypes.shape({
    display_as: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number
  }).isRequired,
  keywords: PropTypes.array,
  handleChange: PropTypes.func,
  remove: PropTypes.func,
  search: PropTypes.bool,
  pushWithNewImportance: PropTypes.func,
  importanceOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      item: PropTypes.number,
      displayElement: PropTypes.any
    })
  ).isRequired,
  error: PropTypes.string,
  initialOpen: PropTypes.bool,
  onChangeOpen: PropTypes.func
};

const KeywordsSliders = ({
  remove,
  search,
  pushWithNewImportance,
  handleAddOne,
  choosenKeywords,
  matchableTypes,
  style,
  errors,
  typesOpened = [],
  onChangeOpen
}) => {
  const { customTypes } = useCustomTypes();
  const { t } = useTranslation("search");

  const matchableTypeNames = matchableTypes.map((type) => type.name);

  const keywords = choosenKeywords.filter(({ type }) => matchableTypeNames.includes(type.name));

  const notChosen = (option) => !keywords.find(({ id }) => id === option.id);

  const keywordsByType = groupBy(keywords, (keyword) => keyword.type.id);
  const typesToDisplay = customTypes
    .filter((type) => matchableTypeNames.includes(type.name))
    .sort((typeA, typeB) => (typeA.display_as > typeB.display_as ? 1 : -1));

  const importanceOptions = useMemo(
    () => [
      {
        id: Importance.Supporting,
        item: Importance.Supporting,
        displayElement: t("parts.keywords.supporting")
      },
      { id: Importance.Essential, item: Importance.Essential, displayElement: t("parts.keywords.essential") },
      { id: Importance.Required, item: Importance.Required, displayElement: t("parts.keywords.hardFilter") }
    ],
    [t]
  );

  return (
    <div style={style}>
      {typesToDisplay.length > 0 && (
        <ul className={css.list}>
          {typesToDisplay.map((type) => (
            <TypeSection
              key={type.id}
              search={search}
              pushWithNewImportance={pushWithNewImportance}
              remove={canEditOrRemoveMatchableTypeValues(type, matchableTypes) ? remove : null}
              type={type}
              handleChange={
                canAddMatchableTypeValue(type, matchableTypes)
                  ? (options) => {
                      if (notChosen(options[0])) {
                        handleAddOne(options[0]);
                      }
                    }
                  : null
              }
              keywords={keywordsByType[type.id] || []}
              error={errors && errors[type.name]}
              initialOpen={typesOpened.includes(type.name)}
              importanceOptions={importanceOptions}
              onChangeOpen={(open) => onChangeOpen?.(open, type.name)}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

KeywordsSliders.propTypes = {
  remove: PropTypes.func,
  search: PropTypes.bool,
  pushWithNewImportance: PropTypes.func,
  handleAddOne: PropTypes.func,
  choosenKeywords: PropTypes.array,
  matchableTypes: PropTypes.arrayOf(
    PropTypes.shape({
      compliance_field: PropTypes.bool,
      name: PropTypes.string.isRequired
    })
  ),
  style: PropTypes.object,
  errors: PropTypes.object,
  typesOpened: PropTypes.arrayOf(PropTypes.string),
  onChangeOpen: PropTypes.func
};

export default KeywordsSliders;
