import classNames from "classnames";
import { BookingPill } from "PFApp/components/pills/booking_pill";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { BookingCategory } from "PFTypes";
import React from "react";
import { useTranslation } from "react-i18next";

import { UpdatedBookingChange } from "../overbookings_management_modal";
import { BookingPillContent } from "./components/booking_pill_content";
import css from "./suggested_changes.module.scss";

type SuggestedChangesIgnoreProps = {
  bookingChange: UpdatedBookingChange;
  category: BookingCategory;
};

export const SuggestedChangesIgnore = ({ bookingChange, category }: SuggestedChangesIgnoreProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "bookings.createWithOverbooking.modal" });

  const { booking } = bookingChange;

  return (
    <div className={css.flexColumnContainer}>
      <div className={css.categoryContainer}>
        <Icon name="error" color="paletteRed0" />
        <Typography withMargin variant="bodyBold" tag="span">
          {category.display_as}
        </Typography>
      </div>
      <Typography withMargin variant="bodyRegular" tag="span">
        {t("noBookingPermission")}
      </Typography>
      <div className={classNames(css.flexColumnContainer, css.singleChangeContainer)}>
        <BookingPill
          category={category}
          className={css.bookingPill}
          style={{
            borderWidth: 1
          }}
          withCategoryIcon={false}
        >
          <BookingPillContent startDate={booking.startDate} endDate={booking.endDate} />
        </BookingPill>
      </div>
    </div>
  );
};
