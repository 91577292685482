import classNames from "classnames";
import { useAppContext } from "PFApp/app_context";
import { useTemplate } from "PFApp/hooks";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import { ProfilesAvatarStack } from "PFComponents/profile_avatar/profiles_avatar_stack";
import SidePanel from "PFComponents/side_panel/side_panel";
import { Typography } from "PFComponents/typography";
import safePrune from "PFCore/helpers/safe_prune";
import { useActivity } from "PFCore/hooks/queries/activity";
import { useActivityShortlists } from "PFCore/hooks/queries/shortlists/use_activity_shortlists";
import { useShortlistCreate } from "PFCore/hooks/queries/shortlists/use_shortlist_create";
import { useShortlistDelete } from "PFCore/hooks/queries/shortlists/use_shortlist_delete";
import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import { ACTIVITY_PREVIEW_ACTIONS } from "PFReducers/activity_preview_reducer";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useOwners } from "../../activities/show/parts/hooks/use_owners";
import ActivityAudience from "../activity_commons/activity_audience";
import ActivityDetailsMoreInfo from "../activity_commons/activity_details_more_info";
import ActivityDetailsQuestions from "../activity_commons/activity_details_questions";
import ActivityDetailsTimeAndDate from "../activity_commons/activity_details_time_and_date";
import { ActivityDetailsVacancies } from "../activity_commons/activity_details_vacancies";
import css from "./activity_preview.module.scss";

export const ActivityPreview = () => {
  const { t } = useTranslation();
  const {
    dispatch,
    store: {
      activityPreview: { id, profile, show }
    }
  } = useAppContext();

  const navigate = useNavigate();
  const location = useLocation();
  const growlErrors = useErrorsGrowl();

  const handleError = useCallback((response) => growlErrors(response), [growlErrors]);

  const { data: activity, isPending: isLoadingActivity } = useActivity(id, {
    enabled: Boolean(show)
  });

  const { data: shortlists, isFetching: isLoadingShortlists } = useActivityShortlists(
    id,
    {},
    {
      enabled: Boolean(show && profile && activity?.coowned),
      onError: handleError
    }
  );

  const template = useTemplate(activity?.template_id);
  const isRole = template?.key === "role";

  const { owners, canView: canViewOwners } = useOwners(activity);

  const shortlistItem = (shortlists?.entries || []).find((item) => item.profile.id === profile?.id);

  const { mutate: createShortlist, isPending: isCreatingShortlist } = useShortlistCreate({
    onError: handleError
  });

  const { mutate: deleteShortlist, isPending: isDeletingShortlist } = useShortlistDelete({
    options: {
      onError: handleError
    },
    activityId: id
  });

  useEffect(() => {
    if (!location.pathname?.includes("matching_roles")) {
      // to close panel while navigating
      handleClose();
    }
  }, [location.pathname]);

  const handleAvatarClick = ({ id }) => navigate(`/profiles/${id}`);
  const handleClose = () => dispatch({ type: ACTIVITY_PREVIEW_ACTIONS.ACTIVITY_PREVIEW_HIDE });
  const handleShortlistProfile = () => createShortlist({ activityId: id, profileId: profile.id });
  const handleRemoveShortlist = () => {
    if (!shortlistItem) {
      return;
    }
    deleteShortlist(shortlistItem.id);
  };

  const isAssigned = shortlistItem?.state === "assigned";

  const prunedName = safePrune(activity?.name, 32);
  const shortlistLoading = isLoadingShortlists || isCreatingShortlist || isDeletingShortlist;

  return (
    <SidePanel
      show={show}
      onClose={handleClose}
      title={
        <Link to={{ pathname: `/activities/${id}` }} state={{ utmSource: location?.state?.utmSource }}>
          <Typography variant="h4">{prunedName}</Typography>
        </Link>
      }
      footerRenderer={() => (
        <div className={css.buttons}>
          <Button text={t("activityPreview.done")} kind="secondary" onClick={handleClose} />
          {activity?.coowned && profile && (
            <Button
              kind={shortlistItem ? "primary" : "secondary"}
              qaId="shortlist-button"
              disabled={isAssigned || shortlistLoading}
              onClick={shortlistItem ? handleRemoveShortlist : handleShortlistProfile}
              icon={shortlistItem && !shortlistLoading ? "check" : undefined}
              text={
                !shortlistLoading && shortlistItem
                  ? t("activityPreview.shortlisted")
                  : t("activityPreview.shortlist")
              }
            />
          )}
        </div>
      )}
    >
      {isLoadingActivity ? (
        <div className={css.loading}>
          <LoadingDots pageViewEnabled circlesEnabled circleSize="sm" />
        </div>
      ) : (
        <>
          {activity && (
            <div className={classNames(css.section, css.separator)}>
              {/* @ts-ignore */}
              <ActivityDetailsTimeAndDate activity={activity} renderTopLine={false} />
            </div>
          )}
          {activity && (
            <div className={classNames(css.section, css.separator)}>
              <ActivityAudience activity={activity} />
            </div>
          )}
          {activity?.coowned && isRole && (
            <div className={classNames(css.section, css.separator)}>
              <ActivityDetailsVacancies task={activity || {}} />
            </div>
          )}
          <ActivityDetailsMoreInfo
            activity={activity}
            metadataToOmit={["interest_questions", "interest_statement_enabled"]}
            className={classNames(css.section, css.separator)}
          />

          {activity?.metadata?.interest_questions && (
            <ActivityDetailsQuestions
              questions={activity.metadata.interest_questions}
              className={classNames(css.section, css.separator)}
            />
          )}

          {canViewOwners && (
            <div className={css.section}>
              <div className={css.ownership}>
                <Typography withMargin variant="bodyBold">
                  {t("owner_other")}
                </Typography>
                {/* @ts-ignore */}
                <ProfilesAvatarStack
                  avatarSize={40}
                  max={10}
                  profiles={owners}
                  handleAvatarClick={handleAvatarClick}
                />
              </div>
            </div>
          )}
        </>
      )}
    </SidePanel>
  );
};
