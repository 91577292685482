import { Typography } from "PFComponents/typography";
import { convertToPercentage } from "PFCore/utilities/math";
import { useMemo } from "react";

import css from "./completion_rate_badge.module.scss";

type CompletionRateBadgeProps = {
  series: {
    data: { value: number; unit?: string }[];
  }[];
  coverage?: number;
};

const sumEverySecondValue = (series: CompletionRateBadgeProps["series"], isEven = true) =>
  series
    .filter((item, index) => index % 2 === (isEven ? 0 : 1))
    .map(({ data }) => data.map(({ value }) => value))
    .flat()
    .reduce((acc, curr) => acc + curr, 0);

export const CompletionRateBadge = ({ series, coverage }: CompletionRateBadgeProps) => {
  const completionRate = useMemo(() => {
    if (coverage) {
      return coverage;
    }
    const requiredValues = sumEverySecondValue(series);
    const filledValues = sumEverySecondValue(series, false);

    if (!requiredValues) {
      return null;
    }

    return Math.round((filledValues / requiredValues) * 100);
  }, [series, coverage]);

  if (completionRate === null) {
    return null;
  }

  const incomplete = completionRate < 100;

  return (
    <Typography variant="h4" className={incomplete ? css.coverageDanger : css.coverageSuccess}>
      {convertToPercentage(completionRate, { isNormalized: true, decimalPlaces: 1, skipTrailingZeros: true })}
    </Typography>
  );
};
