import { Dispatch, MutableRefObject, SetStateAction, useState } from "react";

import { Option, OptionOriginal, SearchableVariant, SelectV2Props } from "../select_v2.types";
import { flattenOptions } from "../select_v2.utils";
import { checkIsSearchable } from "./dropdown.utils";
import { getKeyDownHandler, GetKeyDownHandlerReturn } from "./get_key_down_handler";
import { useDropdownOptions, UseDropdownOptionsReturn } from "./use_dropdown_options";
import { HighlightedIndex, useHighlightedIndex } from "./use_highlighted_index";
import { useOptionCreate } from "./use_option_create";

type UseDropdown<T> = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  triggerRef: MutableRefObject<HTMLDivElement | null>;
  dropdownId: string;
} & Pick<
  SelectV2Props<T>,
  | "value"
  | "options"
  | "fetchOptions"
  | "multiple"
  | "onChange"
  | "searchableVariant"
  | "onCreateOption"
  | "cacheTime"
>;

export type DropdownData<T> = UseDropdownOptionsReturn<T> &
  GetKeyDownHandlerReturn & {
    isSearchable: boolean;
    searchTerm: string;
    setSearchTerm: Dispatch<SetStateAction<string>>;
    highlightedIndex: HighlightedIndex;
    setHighlightedIndex: Dispatch<SetStateAction<HighlightedIndex>>;
    handleCreateOption?: VoidFunction;
    handleChange: (option: Option<T>, selected: boolean) => void;
  };

export const useDropdown = <T extends OptionOriginal = OptionOriginal>({
  dropdownId,
  isOpen,
  setIsOpen,
  value,
  triggerRef,
  options,
  fetchOptions,
  multiple,
  onChange,
  onCreateOption,
  searchableVariant = SearchableVariant.Dynamic,
  cacheTime = 300000
}: UseDropdown<T>): DropdownData<T> => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const isSearchable = checkIsSearchable(
    searchableVariant,
    flattenOptions(options || []).length ?? 0,
    !!fetchOptions
  );

  const dropdownOptions = useDropdownOptions<T>({
    dropdownId,
    searchTerm,
    value,
    options,
    fetchOptions,
    isDropdownOpen: isOpen,
    cacheTime,
    multiple,
    onChange,
    isSearchable
  });

  const {
    resultOptions,
    selectedOptions,
    isFetchingAvailableOptions,
    isFetchingSelectedOptions,
    isFetchingOptionsEnabled
  } = dropdownOptions;

  const { handleCreateOption } = useOptionCreate({
    searchTerm,
    onCreateOption,
    setIsOpen,
    dropdownId,
    isFetchingOptionsEnabled,
    resultOptions
  });

  const isFetching = isFetchingAvailableOptions || isFetchingSelectedOptions;

  const [highlightedIndex, setHighlightedIndex] = useHighlightedIndex({
    optionsCount: resultOptions.length,
    isFetching
  });

  const handleChange = (option, selected) => {
    if (multiple) {
      return onChange(
        selected ? [...selectedOptions, option] : selectedOptions.filter(({ id }) => id !== option.id)
      );
    } else {
      setIsOpen(false);
      return onChange(option);
    }
  };

  const { handleKeyDown } = getKeyDownHandler({
    value,
    triggerRef,
    options: flattenOptions(resultOptions),
    onOptionChange: handleChange,
    setIsOpen,
    highlightedIndex,
    setHighlightedIndex
  });

  return {
    ...dropdownOptions,
    isSearchable,
    searchTerm,
    setSearchTerm,
    highlightedIndex,
    setHighlightedIndex,
    handleKeyDown,
    handleCreateOption,
    handleChange
  };
};
