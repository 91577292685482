import moment from "moment";
import { SearchInput } from "PFApp/components/search_input";
import { UNDETERMINED_FUTURE, UNDETERMINED_PAST } from "PFApp/constants/dates";
import { CalendarSearch } from "PFApp/profiles/common/profile_bookings_provider";
import { RecentSearchesPageKey } from "PFTypes/recent_searches";

import { CalendarSelectProps, MonthSelector } from "../../month_selector";
import css from "./calendar_navigation.module.scss";
import { ChangeMonthButton } from "./change_month_button";

const DEFAULT_MIN_DATE = UNDETERMINED_PAST.toISOString();
const DEFAULT_MAX_DATE = UNDETERMINED_FUTURE.toISOString();

type CalendarNavigationProps = CalendarSelectProps & {
  showArrows?: boolean;
  label?: string;
  calendarSearch: CalendarSearch | undefined;
};

export const CalendarNavigation = ({
  label,
  showArrows,
  displayMonth,
  minDate = DEFAULT_MIN_DATE,
  maxDate = DEFAULT_MAX_DATE,
  setDisplayMonth,
  calendarSearch
}: CalendarNavigationProps) => {
  const selectProps = { minDate, maxDate, displayMonth, setDisplayMonth };
  const buttonProps = { displayMonth, setDisplayMonth };

  return (
    <div className={css.navigation}>
      {calendarSearch && calendarSearch.isSearchEnabled && (
        <SearchInput
          term={calendarSearch.search}
          pageKey={RecentSearchesPageKey.BookingsCalendar}
          onTermChange={calendarSearch.setSearch}
          classes={{ root: css.calendarSearch }}
        />
      )}
      <div className={css.title}>
        {label && <strong data-qa-id="CalendarHeader.label">{label}</strong>}
        <MonthSelector {...selectProps} className={css.monthSelector} />

        {showArrows && (
          <div className={css.arrowsContainer}>
            <ChangeMonthButton
              {...buttonProps}
              direction="prev"
              dateLow={displayMonth}
              dateHigh={moment(minDate)}
            />
            <div className={css.separator} />
            <ChangeMonthButton {...buttonProps} direction="next" dateLow={maxDate} dateHigh={displayMonth} />
          </div>
        )}
      </div>
    </div>
  );
};
