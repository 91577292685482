import { useAppContext } from "PFApp/app_context";
import { ProfileCustomValuesList } from "PFApp/components/custom_values_list";
import { ProfilePreviewHeader } from "PFApp/components/profile_preview/parts/profile_preview_header";
import SkillCategorySection from "PFApp/components/profile_preview/parts/skills/skills_category_section";
import { Button } from "PFComponents/button";
import { useCategorizedSkills } from "PFCore/helpers/use_categorized_skills";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "./profile_preview_skills.module.scss";

const ProfilePreviewSkills = ({ masterExpand, setMasterExpand }) => {
  const { t } = useTranslation("core");
  const {
    store: {
      profilePreview: { profile, ready }
    }
  } = useAppContext();

  const [skills, topSkills] = useMemo(() => {
    const allSkills = profile?.skills || [];
    return [allSkills.filter((skill) => !skill.top), allSkills.filter((skill) => skill.top)];
  }, [ready, profile]);

  const [skillCategories, other] = useCategorizedSkills(skills, profile?.skill_categories);

  const skillCategoryNames = Object.keys(skillCategories);

  if (!ready || !profile) {
    return null;
  }

  if (profile.permissions_group === undefined) {
    return null; // probably not needed because "ready" is taking care but left just in case
  }

  if (topSkills.length === 0 && skills.length === 0) {
    return null;
  }

  return (
    <>
      <div key="ProfilePreviewCustomFields.top_skills">
        {topSkills.length > 0 && (
          <>
            <ProfilePreviewHeader>{t("profilePreview.topSkills")}</ProfilePreviewHeader>
            <ProfileCustomValuesList
              customFieldName="skills"
              customValues={topSkills}
              profileId={profile.id}
              limit={10}
            />
          </>
        )}
      </div>
      <div key="ProfilePreviewCustomFields.skills">
        {skillCategoryNames.length > 0 ? (
          <>
            <ProfilePreviewHeader
              actions={
                <Button
                  kind="text"
                  className={css.expandButton}
                  onClick={() => setMasterExpand(!masterExpand)}
                  text={masterExpand ? t("profilePreview.collapseAll") : t("profilePreview.expandAll")}
                />
              }
              headerClassName={css.header}
            >
              {topSkills.length === 0 ? t("profilePreview.skills") : t("profilePreview.otherSkills")}
            </ProfilePreviewHeader>
            <div>
              {skillCategoryNames
                // eslint-disable-next-line id-length
                .sort((a, b) => (skillCategories[a].length > skillCategories[b].length ? -1 : 1))
                .map((key) => (
                  <SkillCategorySection
                    key={key}
                    name={key}
                    values={skillCategories[key]}
                    expanded={masterExpand}
                    profile={profile}
                  />
                ))}
              {other.length > 0 && (
                <SkillCategorySection name="Other" values={other} expanded={masterExpand} profile={profile} />
              )}
            </div>
          </>
        ) : (
          <>
            {skills.length > 0 && <ProfilePreviewHeader>Skills</ProfilePreviewHeader>}
            <ProfileCustomValuesList
              customFieldName="skills"
              customValues={skills}
              profileId={profile.id}
              limit={20}
            />
          </>
        )}
      </div>
    </>
  );
};

ProfilePreviewSkills.propTypes = {
  profile: PropTypes.shape({ summary: PropTypes.text }),
  masterExpand: PropTypes.bool,
  setMasterExpand: PropTypes.func
};

export default ProfilePreviewSkills;
