import { Notes } from "PFComponents/notes/notes";
import { getProfileName } from "PFCore/helpers/profile";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";

import { useMockNotesData } from "./hooks/use_mock_notes_data";
import { MOCK_NOTES } from "./mock_notes";

export const ActivityDetailsNotes = () => {
  const { data: currentProfile } = useCurrentProfile();
  const currentUserName = getProfileName(currentProfile);

  const { notes, handleCreateNote, handleDeleteNote, handleEditNote } = useMockNotesData(MOCK_NOTES);

  return (
    <Notes
      notes={notes}
      onCreateNote={(content) => handleCreateNote(currentUserName, currentProfile.id, content)}
      onDeleteNote={handleDeleteNote}
      onEditNote={handleEditNote}
      canUserEditNoteStrategy={(note) => note.authorId === currentProfile.id}
      canUserAddNote
    />
  );
};
