import { useAppContext } from "PFApp/app_context";
import { CalendarMode } from "PFApp/booking/types";
import { useIsExternalUsage } from "PFCore/hooks/queries/bookings/use_is_external_usage";

import ProfileDetailContextProvider from "../../profile_detail_context_provider";
import { ProfileDetailContent, ProfileDetailContentProps } from "./profile_detail_content";

export const ProfileDetail = ({ data }: ProfileDetailContentProps) => {
  const {
    store: {
      pages: { booking }
    }
  } = useAppContext();
  const { changeTab } = booking ?? {};
  const isExternal = useIsExternalUsage();

  const bookingClickHandler = (name: string) => {
    changeTab("overview", {
      calendarMode: CalendarMode.Workforce,
      data: { filters: { fields: { term: name } } }
    });
  };

  return (
    <ProfileDetailContextProvider onBookingClick={!changeTab || isExternal ? undefined : bookingClickHandler}>
      <ProfileDetailContent data={data} />
    </ProfileDetailContextProvider>
  );
};
