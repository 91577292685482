import { useQueryClient } from "@tanstack/react-query";
import { dataDogRemoveUser } from "PFApp/bootstrapper/use_datadog_rum";
import { broadcastLogOut } from "PFApp/bootstrapper/use_log_in_out_broadcasts";
import api from "PFCore/api";
import { isMsTeams } from "PFCore/helpers/ms_teams";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { accountKeys } from "PFCore/hooks/queries/account/query_keys";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { stopHeartbeat } from "./heartbeat";
import { useAccessTokens } from "./use_access_tokens";

export const useSignOut = () => {
  const { clearTokens, getAccessToken } = useAccessTokens();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data: currentAccount } = useCurrentAccount();

  const [signoutPath, setSignoutPath] = useState<string | null>(null);

  useEffect(() => {
    if (signoutPath) {
      navigate(signoutPath, { replace: !!currentAccount.slo_target_url });
    }
  }, [signoutPath, navigate]); // eslint-disable-line react-hooks/exhaustive-deps

  const navigateToLanding = useCallback(
    (options: { redirectUri?: string; error?: string }) => {
      if (typeof sessionStorage !== "undefined" && sessionStorage !== null) {
        sessionStorage.setItem("signedOut", "true");
      }
      clearTokens();
      stopHeartbeat();
      queryClient.clear();
      queryClient.setQueryData(accountKeys.current(), currentAccount);
      dataDogRemoveUser();

      let path = "/users/signin";

      if (options.redirectUri) {
        path = options.redirectUri;
      } else if (isMsTeams() || location.pathname.match(/ms_teams/)) {
        path = "/ms_teams/signin";
      } else {
        if (options.error) {
          path = `${path}?error=${options.error}`;
        }
        setSignoutPath(path);
      }

      if (typeof broadcastLogOut === "function") {
        broadcastLogOut();
      }
    },
    [clearTokens, queryClient, currentAccount]
  );

  const signOut = useCallback(
    (options = {}) =>
      api({
        url: "oauth/revoke",
        params: {
          clientId: PF.config.client_id,
          token: getAccessToken()
        }
      }).then(
        () => navigateToLanding(options),
        () => navigateToLanding(options)
      ),
    [getAccessToken, navigateToLanding]
  );

  return { signOut };
};
