import compact from "lodash/compact";
import { Flex } from "PFComponents/containers";
import { Typography } from "PFComponents/typography";
import roundToDecimals from "PFCore/helpers/round_to_decimals";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useDateFormatter } from "PFCore/hooks";
import { usePhases } from "PFCore/hooks/queries/phases/use_phases";
import { AvailabilityMode, FeatureFlag, StandardRange } from "PFTypes";
import { useTranslation } from "react-i18next";

import { getFormattedRangeDates, getRangePhaseText } from "../../readable_availability_rules.utils";

type ReadableStandardRangeProps = {
  range: StandardRange;
  mode: AvailabilityMode;
  multiline: boolean;
};

export const ReadableStandardRange = ({ range, mode, multiline }: ReadableStandardRangeProps) => {
  const { t } = useTranslation(["core", "translation"]);
  const { formatDate } = useDateFormatter();
  const hasActivityPhases = useIsFeatureEnabled()(FeatureFlag.ActivityPhases);

  const { data: phases } = usePhases(null, {
    enabled: hasActivityPhases,
    placeholderData: { entries: [], meta: { total: 0 } }
  });

  const getDuration = (mode: string, value: number): string => {
    if (mode === "days") {
      return t("core:time.inWords.days", { count: value });
    }

    const durationValue = mode === "minutes" ? roundToDecimals(value / 60) : value;
    return t("core:time.inWords.hours", { count: durationValue });
  };

  const modeText = {
    [AvailabilityMode.Within]: t("translation:availabilityRequirement.withinPeriod"),
    [AvailabilityMode.PerWeek]: t("translation:availabilityRequirement.perWeek"),
    [AvailabilityMode.PerMonth]: t("translation:availabilityRequirement.perMonth")
  }[mode];

  const phase = getRangePhaseText(phases?.entries || [], range);
  const duration = getDuration(range.duration.mode, range.duration.value);
  const dateRange = getFormattedRangeDates(range, formatDate);

  const phaseWithDateRange = compact([phase, dateRange]).join(": ");
  const details = `(${duration} ${modeText})`;

  return (
    <Flex direction={multiline ? "column" : "row"} gap="spacingSm">
      <Typography variant="bodyRegular">{phaseWithDateRange}</Typography>
      <Typography variant="labelRegular">{details}</Typography>
    </Flex>
  );
};
