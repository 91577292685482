import { Button } from "PFComponents/button/button";
import { Shortlist } from "PFTypes";
import { useTranslation } from "react-i18next";

import { useBookingFormContext } from "../../../../../components/booking_form";
import { BookingFormMode } from "../../../../../components/booking_form/use_booking_form";
import css from "../shortlist_actions.modules.scss";

type BookButtonProps = {
  shortListItem: Shortlist;
  disabled?: boolean;
};

export const BookButton = ({ shortListItem, disabled = false }: BookButtonProps) => {
  const { t } = useTranslation();
  const {
    modal: { open: openModal }
  } = useBookingFormContext();

  const bookingModalInitialData = {
    profileId: shortListItem.profile.id,
    activityId: shortListItem.activity_id,
    shortlistId: shortListItem.id
  };

  return (
    <Button
      text={t("book")}
      className={css.button}
      kind="secondary"
      onClick={() => openModal(bookingModalInitialData, { mode: BookingFormMode.Create })}
      disabled={disabled}
    />
  );
};
