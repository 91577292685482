import { Typography } from "PFComponents/typography";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormValues, QuestionData } from "../questions_modal.types";
import { Question } from "./question";

type QuestionsSectionProps = {
  questionFields: QuestionData[];
  control: Control<FormValues>;
};

export const QuestionsSection = ({ questionFields, control }: QuestionsSectionProps) => {
  const { t } = useTranslation("activities");

  return (
    <>
      <Typography variant="bodyRegular">{t("questionsModal.pleaseAnswerQuestions")}</Typography>

      {questionFields.map(({ id, label, name }) => (
        <Question key={id} id={id} name={name} label={label} control={control} />
      ))}
    </>
  );
};
