import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import displayShortlistReasons from "PFApp/activities/show/parts/display_shortlist_reasons";
import { Divider } from "PFComponents/divider";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

import { InfoSection } from "../../../components/info_section";
import { useMatchCardContext } from "../../../match_card_context";

type ShortlistStateSectionProps = {
  showDivider?: boolean;
};

export const ShortlistStateSection = ({ showDivider }: ShortlistStateSectionProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.matches.card" });

  const { shortlist } = useMatchCardContext();
  const { task } = useActivityPageContext();

  const stateDecisionReasons = displayShortlistReasons(shortlist?.reasons || []);

  const decision = shortlist?.state === "declined" ? "declined" : task.coowned ? "accepted" : "approved";

  if (stateDecisionReasons.length === 0) {
    return null;
  }

  return (
    <>
      {showDivider && <Divider color="paletteNeutral0" />}
      <InfoSection title={t("shortlistResolved", { decision })}>
        <Typography withMargin variant="labelBold">
          {stateDecisionReasons}
        </Typography>
      </InfoSection>
    </>
  );
};
