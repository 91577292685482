/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from "classnames";
import find from "lodash/find";
import { ChatConversation } from "PFApp/components/chat/chat_conversation";
import ChatMenu from "PFApp/components/chat/chat_menu";
import ChatParticipants from "PFApp/components/chat/chat_participants";
import { LOADING, NORMAL, PARTICIPANTS } from "PFApp/components/chat/chat_states";
import InviteModal from "PFApp/conversations/invite/invite_modal";
import { useGrowl } from "PFApp/use_growl";
import { ProfileAvatar } from "PFComponents/profile_avatar/profile_avatar";
import { getTargetType, getTargetUrl } from "PFCore/helpers/conversation";
import { getProfileName } from "PFCore/helpers/profile";
import useForceUpdate from "PFCore/helpers/use_force_update";
import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { followParticipant } from "PFCore/services/chat/follow_participant";
import CloseIcon from "PFIcons/close.svg";
import EllipsisIcon from "PFIcons/ellipsis.svg";
import ExpandIcon from "PFIcons/external_link.svg";
import MinimizeIcon from "PFIcons/minimize.svg";
import ShrinkIcon from "PFIcons/shrink.svg";
import { PermissionRule } from "PFTypes";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import css from "./chat.module.scss";
import { setChatSendOnEnter } from "./send_on_enter";

const preventEvent = (event) => {
  event?.preventDefault?.();
  event?.stopPropagation?.();
};

const getCurrentParticipant = (participants, conversation, currentProfile) =>
  find(participants, ({ profile }) => profile?.id === currentProfile.id) || {
    conversation_id: conversation.id,
    profile_id: currentProfile.id
  };

const Chat = ({
  chatState,
  conversation,
  currentProfile,
  emptyMessage,
  expandable = true,
  expanded,
  forceUpdateAll,
  onClose,
  onExpand,
  onMinimize,
  onSelect,
  onUnMinimize,
  onLoadPreviousMessages,
  isBelow,
  messages,
  minimized,
  participants,
  selected,
  setChatState,
  style,
  flashTitle,
  isFetchingPreviousMessages
}) => {
  const { t } = useTranslation("core", { keyPrefix: "chat.conversation.subtitle" });
  const isPermittedTo = useIsPermittedTo(currentProfile);

  const forceUpdate = useForceUpdate();
  const growl = useGrowl();
  const [attachments, setAttachments] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [inviteModalShown, setInviteModalShown] = useState(false);
  const [currentParticipant, setCurrentParticipant] = useState(
    getCurrentParticipant(participants, conversation, currentProfile)
  );

  const dropdownFocusRef = useRef();

  useEffect(() => {
    dropdownFocusRef.current?.addEventListener("blur", () => setDropdownOpen(false));
  }, [chatState, messages, minimized]);

  useEffect(() => {
    dropdownFocusRef.current?.focus();
  }, []);

  useEffect(() => {
    setCurrentParticipant(getCurrentParticipant(participants, conversation, currentProfile));
  }, [participants]);

  const handleAddAttachment = useCallback(
    (attachment) => {
      setAttachments([...attachments, attachment]);
    },
    [attachments]
  );

  const handleRemoveAttachment = useCallback(
    (attachment) => {
      // when removed before it has been loaded
      if (attachment.uploadPromise) {
        attachment.uploadPromise.abort();
      }

      setAttachments(attachments.filter((other) => other !== attachment));
    },
    [attachments]
  );

  const handleAttachmentSaveFailed = useCallback((resp) => {
    if (resp.statusText === "abort") {
      return;
    }

    const errors = resp.responseJSON && resp.responseJSON.errors;
    const error = errors && errors[0];
    // the fallback copy -> most likely a 500
    const message = error.detail || "Your attachment upload failed. Please try again.";

    growl({ message, kind: "error" });
  }, []);

  const handleUnMinimize = useCallback(
    (event) => {
      preventEvent(event);
      onSelect?.(conversation.id);
      onUnMinimize?.(conversation.id);
    },
    [conversation.id, onSelect, onUnMinimize]
  );

  const handleExpand = useCallback(
    (event) => {
      preventEvent(event);
      onExpand?.(conversation.id, !expanded);
    },
    [conversation.id, expanded, onExpand]
  );

  const handleMinimize = useCallback(
    (event) => {
      preventEvent(event);
      onMinimize?.(conversation.id); // no handleMinimize when embeded in the page (rather then floating)
      setDropdownOpen(false);
    },
    [conversation.id, onMinimize]
  );

  const handleClose = useCallback(
    (event) => {
      preventEvent(event);
      onClose(conversation.id);
    },
    [conversation.id, onClose]
  );

  const handleClearAttachments = () => setAttachments([]);

  const showParticipants = useCallback(() => {
    setChatState(PARTICIPANTS);
  }, [setChatState]);

  let { title } = conversation;
  let isFollowing = false;
  let targetUrl = getTargetUrl(conversation || "");
  const isPrivate = conversation.kind === "normal";
  const { unread_count: unreadCount } = conversation;

  // there will be only one other participant for now
  const otherParticipant = find(participants, ({ profile }) => profile.id !== currentProfile.id);
  const otherPerson = otherParticipant?.profile || null;

  if (chatState === LOADING) {
    title = "...";
  } else {
    if (getTargetType(conversation) === "Profile") {
      title = otherPerson ? getProfileName(otherPerson) : "";
      targetUrl = otherPerson ? `/profiles/${otherPerson.id}` : false;
    }

    isFollowing = currentParticipant.notification_state === "following";
  }

  return (
    <div
      className={classNames(css.root, {
        [css.minimized]: minimized,
        [css.selected]: selected,
        [css.expanded]: expanded
      })}
      style={style}
      onClick={() => onSelect(conversation.id)}
      data-qa-id="chat-root"
    >
      {inviteModalShown && (
        <InviteModal
          conversation={conversation}
          participants={participants}
          handleClose={() => setInviteModalShown(false)}
        />
      )}
      <div
        className={classNames(css.header, { [css.incognito]: isPrivate, [css.flashTitle]: flashTitle })}
        onClick={handleUnMinimize}
        data-qa-id="chat-window-header"
      >
        <div
          className={classNames(css.title, { [css.titleBelow]: !selected && isBelow })}
          data-qa-id="chat-window-title"
        >
          {selected && !minimized && (
            //this is the open chat header
            <Link to={targetUrl} className={css.wrapper}>
              {otherPerson && <ProfileAvatar profile={otherPerson} size={22} />}
              <div className={css.titleName}>{title}</div>
            </Link>
          )}
          {/* here the chat minimed is rendered */}
          {(!selected || (selected && minimized)) && <span>{title}</span>}
        </div>
        {minimized && (
          <span onClick={handleClose} className={css.minimizedActions}>
            {unreadCount > 0 && <div className={css.unreaded}>{unreadCount}</div>}
            <CloseIcon width={20} height={20} />
          </span>
        )}

        {selected && !minimized && (
          <div className={css.headerActions} data-qa-id="chat-all-header">
            <span onClick={handleMinimize}>
              <MinimizeIcon width={20} height={20} />
            </span>
            {expandable && (
              <span onClick={handleExpand}>
                {expanded ? <ShrinkIcon width={15} height={15} /> : <ExpandIcon width={20} height={20} />}
              </span>
            )}
            {dropdownOpen && (
              <span onMouseDown={() => setDropdownOpen(false)}>
                <EllipsisIcon key={"dropdownOff"} width={20} height={20} />
              </span>
            )}
            {!dropdownOpen && (
              <span onClick={chatState === LOADING ? null : () => setDropdownOpen(true)}>
                <EllipsisIcon key={"dropdownOn"} width={20} height={20} />
              </span>
            )}
            <span onClick={handleClose}>
              <CloseIcon width={20} height={20} />
            </span>
          </div>
        )}

        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-noninteractive-tabindex */}
        <a ref={dropdownFocusRef} tabIndex={0} className={css.dropdownFocus} />
        {!minimized && dropdownOpen && (
          <ChatMenu
            isOwned={currentProfile.id === conversation.owner?.id}
            conversation={conversation}
            showParticipants={showParticipants}
            targetUrl={targetUrl}
            handleChange={(fn) => {
              setDropdownOpen(false);
              fn?.();
            }}
            setSendOnEnter={(val) => {
              setChatSendOnEnter(val);
              forceUpdateAll && forceUpdateAll();
            }}
            isFollowing={isFollowing}
            handleFollowingChange={(follow) => {
              followParticipant(currentParticipant.id, follow).then((resp) => setCurrentParticipant(resp));
            }}
            handleSendOnEnter={(val) => {
              setChatSendOnEnter(val);
              forceUpdate();
            }}
            handleInviteToChat={() => setInviteModalShown(true)}
          />
        )}
      </div>
      {!minimized && (
        <div className={css.bodyWrap}>
          {(chatState === NORMAL || chatState === LOADING) && (
            <ChatConversation
              attachments={attachments}
              clearAttachments={handleClearAttachments}
              conversationId={conversation.id}
              currentProfile={currentProfile}
              emptyMessage={emptyMessage}
              isPrivate={isPrivate}
              loading={chatState === LOADING}
              messages={messages}
              onAttachmentRemove={handleRemoveAttachment}
              onAttachmentSaved={handleAddAttachment}
              onAttachmentSaveFailed={handleAttachmentSaveFailed}
              onLoadPreviousMessages={onLoadPreviousMessages}
              onMinimize={handleMinimize}
              onOpenParticipants={showParticipants}
              showMentionables={conversation.kind === "primary" && isPermittedTo(PermissionRule.ProfileList)}
              subtitle={isFollowing ? t("following") : t("notFollowing")}
              isFetchingPreviousMessages={isFetchingPreviousMessages}
            />
          )}
          {chatState === PARTICIPANTS && (
            <ChatParticipants
              conversationId={conversation.id}
              withMessage={conversation.kind === "primary"}
              ownerId={conversation.owner?.id}
              isPrivate={isPrivate}
              handleClose={() => setChatState(NORMAL)}
              handleMinimize={handleMinimize}
            />
          )}
        </div>
      )}
    </div>
  );
};

Chat.propTypes = {
  chatState: PropTypes.oneOf([LOADING, NORMAL, PARTICIPANTS]).isRequired,
  messages: PropTypes.any.isRequired,
  conversation: PropTypes.any.isRequired,
  participants: PropTypes.any.isRequired,
  selected: PropTypes.bool.isRequired,
  setChatState: PropTypes.func.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  currentProfile: PropTypes.object.isRequired,
  forceUpdateAll: PropTypes.func.isRequired,
  flashTitle: PropTypes.bool,
  isFetchingPreviousMessages: PropTypes.bool,

  style: PropTypes.any,
  isBelow: PropTypes.bool,
  minimized: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  expandable: PropTypes.bool.isRequired,

  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onMinimize: PropTypes.func,
  onExpand: PropTypes.func,
  onUnMinimize: PropTypes.func,
  onLoadPreviousMessages: PropTypes.func
};

export default Chat;
