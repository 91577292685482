import Availability, { AvailabilityContext } from "PFApp/profiles/common/availability";
import ProfileEditPanel from "PFApp/profiles/edit/profile_edit_panel";
import { getProfileSectionText } from "PFApp/profiles/edit/profile_edit_sections";
import useIsAvailabilityPermitted from "PFApp/use_is_availability_permitted";
import { Button } from "PFComponents/button";
import NotFoundComponent from "PFComponents/not_found/not_found";
import { useBookingApprovalsInvalidate } from "PFCore/hooks/queries/bookings/approvals/use_booking_approvals_invalidate";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { Profile } from "PFTypes";
import { useTranslation } from "react-i18next";

import { useBookingFormContext } from "../../../booking/components/booking_form";
import { BookingFormMode } from "../../../booking/components/booking_form/use_booking_form";
import { DetailsPanel } from "../../../booking/components/details_panel";
import ViewInBookingModuleButton from "../../../components/view_in_booking_module_button";
import useBookingModulePermissions from "../../../use_booking_module_permissions";
import { useProfileBookingsContext } from "../../common/profile_bookings_provider";
import css from "./availability_section.module.scss";

type AvailabilitySectionProps = {
  adminPage?: boolean;
  profile: Profile;
};

const AvailabilitySection = ({ adminPage = false, profile }: AvailabilitySectionProps) => {
  const { t } = useTranslation(["profiles", "availability"]);
  const { modal } = useBookingFormContext();

  /* @ts-ignore */
  const isAvailabilityAllAccessPermitted = useIsAvailabilityPermitted(profile, ["rw", "ro"]);
  const { isPermittedToEdit: isPermittedToEditBookings, canProfileCreateBooking } =
    useBookingModulePermissions();
  const { bookingCategories } = useBookingCategories();
  const hasWriteAccessToAtLeastOneCategory = bookingCategories
    .filter(({ activity_assignable: demandRelated }) => !demandRelated)
    .some((i) => !i.readonly);
  const canSeeBookingModule = canProfileCreateBooking(profile?.id);

  const { refreshBookings } = useProfileBookingsContext();
  const { invalidate: invalidateBookingApprovals } = useBookingApprovalsInvalidate();
  const handleBookingsUpdate = () => {
    invalidateBookingApprovals();
    refreshBookings();
  };

  if (!isAvailabilityAllAccessPermitted) {
    return <NotFoundComponent message={t("profiles:edit.sections.availability.contentCouldntBeLoaded")} />;
  }

  return (
    <ProfileEditPanel title={getProfileSectionText("availability")}>
      <>
        <div className={css.action}>
          {canSeeBookingModule && <ViewInBookingModuleButton workforceMemberId={profile?.id} />}
          {isAvailabilityAllAccessPermitted && (
            <Button
              kind="primary"
              onClick={() =>
                modal.open(
                  { profileId: profile.id },
                  { mode: BookingFormMode.Create, onSuccess: handleBookingsUpdate }
                )
              }
              disabled={!hasWriteAccessToAtLeastOneCategory}
            >
              {t("availability:actions.book")}
            </Button>
          )}
        </div>
        {isAvailabilityAllAccessPermitted && (
          <Availability
            activeViews={["calendar", "list"]}
            profile={profile}
            isAdmin={adminPage}
            readOnly={!isPermittedToEditBookings}
            showTip={true}
          />
        )}
      </>
    </ProfileEditPanel>
  );
};

export const AvailabilitySectionWrapper = (props: AvailabilitySectionProps) => {
  const { adminPage, profile } = props;
  return (
    // @ts-ignore
    <AvailabilityContext isAdmin={adminPage} profile={profile}>
      <AvailabilitySection {...props} />
      {/* the panel handles details panel open from header (i.e. overbookings side panel in booking form) */}
      <DetailsPanel />
    </AvailabilityContext>
  );
};
