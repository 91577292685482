import { SortDirection } from "@tanstack/react-table";
import classNames from "classnames";
import { DropdownOption } from "PFComponents/dropdown/dropdown";
import { Select } from "PFComponents/select/select";
import { Typography } from "PFComponents/typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import css from "../../../../parts/overview/detail_view/detail_view.module.scss";
import cssWarningsDetail from "./warning_detail.module.scss";

type WarningsDetailProps = {
  summary: string;
  customHeader?: React.ReactNode;
  children: (selectedSort: SortDirection) => React.ReactNode;
};

export const WarningsDetail = ({ summary, customHeader, children }: WarningsDetailProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "details.warnings" });

  const sortOptions: DropdownOption[] = [
    { id: "asc", displayElement: t("dateAsc"), item: "asc" },
    { id: "desc", displayElement: t("dateDesc"), item: "desc" }
  ];

  const [selectedSort, setSelectedSort] = useState<SortDirection>(sortOptions[0].item);

  return (
    <div className={classNames(css.root)}>
      <div className={cssWarningsDetail.header}>
        {customHeader}
        <div className={cssWarningsDetail.sortContainer}>
          <Select
            className={cssWarningsDetail.select}
            controlledValue
            options={sortOptions}
            value={sortOptions.find(({ item }) => item === selectedSort)?.displayElement}
            onChange={(value: string) => setSelectedSort(value as SortDirection)}
          />
          <Typography withMargin variant="labelRegular" className={css.subtitle}>
            {summary}
          </Typography>
        </div>
      </div>
      {children(selectedSort)}
    </div>
  );
};
