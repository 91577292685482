import isNil from "lodash/isNil";
import { TemplateKey } from "PFApp/constants/templates";
import { useTemplate } from "PFApp/hooks";
import { Button } from "PFComponents/button";
import Tooltip from "PFComponents/tooltip/tooltip";
import { AvailabilityRule, Template } from "PFTypes";
import { useTranslation } from "react-i18next";

import { ReadableAvailabilityRules } from "../../../readable_availability_rules";
import css from "./availability_filter.module.scss";
import { UseAvailabilityStateReturn } from "./hooks/use_availability_state";
import { AvailabilityLimitWrapper } from "./parts/availability_limit_wrapper";
import { AvailabilityThresholdSlider } from "./parts/availability_threshold_slider";

type AvailabilityFilterItemCompressedProps = {
  availability: UseAvailabilityStateReturn["availability"];
  onAvailabilityChange: UseAvailabilityStateReturn["updateAvailability"];
  scope: number;
  templateKey: Template["key"];
  onEditAvailability: VoidFunction;
  disabled?: boolean;
};

export const AvailabilityFilterItemCompressed = ({
  availability,
  onAvailabilityChange,
  scope = 0,
  templateKey = TemplateKey.Task,
  onEditAvailability,
  disabled
}: AvailabilityFilterItemCompressedProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.sidePanel.filters" });
  const { t: tTranslation } = useTranslation();
  const template = useTemplate(templateKey);

  if (!template || !availability) {
    return null;
  }

  const filteredAvailability = {
    ...availability,
    ranges: (availability.ranges || []).filter(({ start, end }) => !isNil(start) && !isNil(end))
  };

  const isAvailabilitySet = filteredAvailability.ranges.length > 0;

  return (
    <AvailabilityLimitWrapper className={css.rootCompressed} scope={scope}>
      <AvailabilityThresholdSlider
        availability={availability}
        onChange={onAvailabilityChange}
        template={template}
        disabled={disabled || !isAvailabilitySet}
        tooltip={
          !isAvailabilitySet
            ? tTranslation("availabilityRequirement.tooltips.addRequirementsBeforeThreshold")
            : undefined
        }
      />
      {isAvailabilitySet && (
        <Tooltip
          content={
            <div className={css.availabilityTooltip}>
              <ReadableAvailabilityRules availability={availability as AvailabilityRule} />
            </div>
          }
          maxWidth={400}
          interactive
        >
          <div>
            <ReadableAvailabilityRules availability={availability as AvailabilityRule} rangesLimit={1} />
          </div>
        </Tooltip>
      )}
      <Button
        className={css.button}
        text={t("editAvailabilityRequirements")}
        kind="text"
        onClick={onEditAvailability}
      />
    </AvailabilityLimitWrapper>
  );
};
