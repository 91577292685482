import classNames from "classnames";
import { Icon } from "PFComponents/icon";
import { RequiredText } from "PFComponents/required_text";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { IconName } from "PFTheme/graphics/icons";

import css from "../input_field_set.module.scss";

type LabelProps = {
  label: string;
  labelTooltip?: {
    content: string;
    icon: IconName;
  };
  required?: boolean;
  hidden?: boolean;
  variant?: "labelRegular" | "bodyRegular";
  wrap?: boolean;
  tagId: string;
};

export const Label = ({
  label,
  labelTooltip,
  required,
  hidden,
  variant = "labelRegular",
  wrap,
  tagId
}: LabelProps) => (
  <label
    htmlFor={tagId}
    className={classNames(css.label, { [css.hidden]: hidden, [css.wrap]: wrap })}
    title={label}
  >
    <Typography tag="span" variant={variant}>
      {required ? <RequiredText label={label} /> : label}
    </Typography>
    {labelTooltip && (
      <Tooltip
        content={
          <Typography withMargin variant="bodyRegular" tag="span">
            {labelTooltip.content}
          </Typography>
        }
      >
        <span aria-label={labelTooltip.content} className={css.iconContainer}>
          <Icon size="sm" name={labelTooltip.icon} className={css.icon} />
        </span>
      </Tooltip>
    )}
  </label>
);
