import { Button } from "PFComponents/button";
import { getProfileName } from "PFCore/helpers/profile";
import NoProfilesIcon from "PFIcons/no_profiles.svg";
import { useTranslation } from "react-i18next";

import { useFeedbackContext } from "./feedback_context_provider";
import css from "./form/feedback_form.module.scss";

type NoFeedbackPlaceholderProps = {
  onClick: () => void;
};

export const NoFeedbackPlaceholder = ({ onClick }: NoFeedbackPlaceholderProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.feedback" });
  const { selectedParticipant } = useFeedbackContext();

  return (
    <>
      <div className={css.formHeader}>
        <h2>{getProfileName(selectedParticipant)}</h2>
      </div>
      <div className={css.noFeedbacksContainer}>
        <NoProfilesIcon />
        <span>{t("noFeedback")}</span>
        <Button text={t("addFeedback")} onClick={onClick} />
      </div>
    </>
  );
};
