import { Inline } from "PFComponents/containers/flex/inline";
import { Stack } from "PFComponents/containers/flex/stack";
import { Divider } from "PFComponents/divider";
import { Icon } from "PFComponents/icon";
import { Tile } from "PFComponents/tile";
import { Typography } from "PFComponents/typography";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { IconName } from "PFTheme/graphics/icons";
import { Certificate } from "PFTypes/certificate";
import { useTranslation } from "react-i18next";

import css from "./certificate_tile.module.scss";

type CertificateTileProps = {
  certificate: Certificate;
};

type PropertyProps = {
  iconName: IconName;
  label: string;
  value: string;
};

const Property = ({ label, iconName, value }: PropertyProps) => (
  <Stack className={css.property}>
    <Inline gap="spacingSm">
      <Icon name={iconName} size="sm" />
      <Typography withMargin variant="labelRegular">
        {label}
      </Typography>
    </Inline>
    <Typography withMargin variant="bodyBold">
      {value}
    </Typography>
  </Stack>
);

export const CertificateTile = ({ certificate }: CertificateTileProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "customValueSidepanel.certificates" });
  const { formatDate } = useDateFormatter();

  const { title, issuer, issueDate, expiryDate } = certificate;

  return (
    <Tile paddingVariant="lg" selected>
      <Stack>
        <Typography variant="bodyBold">{title}</Typography>
        <Typography variant="bodyRegular">{issuer}</Typography>
        <Divider />
        <Inline>
          <Property iconName="booking" label={t("issued")} value={issueDate ? formatDate(issueDate) : "-"} />
          <Property
            iconName="calendar-delete"
            label={t("expires")}
            value={expiryDate ? formatDate(expiryDate) : "-"}
          />
        </Inline>
      </Stack>
    </Tile>
  );
};
