import { useActivityCurrentUserRole } from "PFApp/activities/hooks";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useNotesPermissions } from "PFApp/activities/show/notes/use_notes_permissions";
import { ShortlistItemButtons } from "PFApp/activities/show/shortlist/shortlist_item_buttons";
import { ShortlistItemNotes } from "PFApp/activities/show/shortlist/shortlist_item_notes/shortlist_item_notes";
import { useRoleProfileActionButtonsContext } from "PFApp/components/role_profile_action_buttons/context/role_profile_action_buttons_context";
import { ShortlistStateLabel } from "PFApp/components/role_profile_action_buttons/parts/shortlist_state_label";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { Shortlist } from "PFTypes";
import React from "react";
import { useTranslation } from "react-i18next";

import css from "./audit_shortlist_buttons.module.scss";

type AuditShortlistButtonsProps = {
  customButton?: React.ReactNode;
};

export const AuditShortlistButtons = ({ customButton }: AuditShortlistButtonsProps) => {
  const { role, profile, shortlist } = useRoleProfileActionButtonsContext();
  const { shouldDisplayNotes } = useNotesPermissions({ activity: role });
  const { t } = useTranslation("activities");
  const { task } = useActivityPageContext();
  const { isReviewerUnbiased } = useActivityCurrentUserRole(task);

  const { invalidate } = useActivityInvalidate();
  const isInterested = role.watchers && role.watchers.find(({ profile: { id } }) => id === profile.id);

  const isShortlisted = !!shortlist;

  const hasException = task.workflow_state === "exception";

  const handleUndoClick = () => {
    invalidate([role.id]);
  };

  return (
    <div className={css.root}>
      {(isShortlisted || !!customButton) && (
        <div className={css.labelAndIcons}>
          {isShortlisted && (
            <ShortlistStateLabel isUndoDisabled={hasException} onUndoClick={handleUndoClick} />
          )}
          {customButton}
        </div>
      )}
      {shouldDisplayNotes && <ShortlistItemNotes />}
      {/* @ts-ignore */}
      <ShortlistItemButtons shortlistedProfile={shortlist as Shortlist} isBlindModeOn={isReviewerUnbiased} />
      {isInterested && (
        <small className={css.interested}>{t("show.shortlist.personInterestedInActivity")}</small>
      )}
    </div>
  );
};
