import { AvailabilityMode, AvailabilityRule } from "PFTypes";

import { ReadableStandardRuleAvailability } from "./parts/readable_standard_rule_availability";
import { ReadableTimeRuleAvailability } from "./parts/readable_time_rule_availability";

export type ReadableAvailabilityRulesProps = {
  availability?: AvailabilityRule;
  multiline?: boolean;
  rangesLimit?: number;
};

export const ReadableAvailabilityRules = ({
  availability,
  multiline = true,
  rangesLimit
}: ReadableAvailabilityRulesProps) => {
  if (!availability) {
    return null;
  }

  if (availability.mode === AvailabilityMode.TimeRule) {
    return (
      <ReadableTimeRuleAvailability
        availability={availability}
        multiline={multiline}
        rangesLimit={rangesLimit}
      />
    );
  }

  return (
    <ReadableStandardRuleAvailability
      availability={availability}
      multiline={multiline}
      rangesLimit={rangesLimit}
    />
  );
};
