import { Modal } from "PFComponents/modal";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBlocker } from "react-router-dom";

import { Typography } from "../typography";

type RouterPromptProps = {
  when: boolean;
  onOK?: () => boolean;
  onCancel?: () => boolean;
  title?: string;
  labelOk?: string;
  labelCancel?: string;
  message?: string;
};

export const RouterPrompt = ({
  when,
  onOK = () => true,
  onCancel = () => false,
  title,
  labelOk,
  labelCancel,
  message
}: RouterPromptProps) => {
  const { t } = useTranslation(["translation", "core"]);

  const [showPrompt, setShowPrompt] = useState(false);

  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) => {
      if (when && currentLocation.pathname !== nextLocation.pathname) {
        setShowPrompt(true);
        return true;
      }
      return false;
    },
    [when]
  );

  const blocker = useBlocker(shouldBlock);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        blocker.proceed?.();
      }
    }
  }, [onOK, blocker]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        blocker.reset?.();
      }
    }
    setShowPrompt(false);
  }, [onCancel, blocker]);

  return showPrompt ? (
    <Modal
      title={title ?? t("core:routerPrompt.title")}
      onOK={handleOK}
      onCancel={handleCancel}
      onClose={() => setShowPrompt(false)}
      labelOK={labelOk ?? t("translation:confirm")}
      labelCancel={labelCancel}
    >
      <Typography withMargin variant="bodyRegular" tag="span">
        {message ?? t("core:routerPrompt.message")}
      </Typography>
    </Modal>
  ) : null;
};
