import Tooltip from "PFComponents/tooltip/tooltip";
import InfoIcon from "PFIcons/info.svg";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "../match_gauge.module.scss";

type AvailabilityIconProps = {
  availabilityScore: number;
};

const AvailabilityIcon = ({ availabilityScore }: AvailabilityIconProps) => {
  const { t } = useTranslation("core", { keyPrefix: "matchRating" });

  const ratingsArray = useMemo(() => [t("weak"), t("decent"), t("good"), t("best")], [t]);

  const level = availabilityScore >= 1 ? 4 : availabilityScore <= 0 ? 0 : Math.floor(availabilityScore * 4);
  const availabilityTipContent = `${ratingsArray[level - 1] || t("noMatch")} ${t("description")}`;

  return (
    <Tooltip content={availabilityTipContent}>
      <div className={css.infoIcon}>
        <InfoIcon width={20} height={20} />
      </div>
    </Tooltip>
  );
};

type AvailabilityInfoProps = {
  availabilityScore: number | null | undefined;
  icon?: React.ReactElement;
};

export const AvailabilityInfo = ({ availabilityScore, icon }: AvailabilityInfoProps) => {
  const { t } = useTranslation("core");

  const availabilityPercent = (Math.min(availabilityScore || 0, 1) * 100).toFixed(1);
  const availabilityIcon = icon || <AvailabilityIcon availabilityScore={availabilityScore ?? 0} />;

  return (
    <div className={css.availability}>
      {availabilityScore !== null && (
        <>
          <div className={css.icon}>{availabilityIcon}</div>
          <div>
            <strong>{`${availabilityPercent}% `}</strong>
            {t("availability")}
          </div>
        </>
      )}
    </div>
  );
};
