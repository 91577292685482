import isNil from "lodash/isNil";
import commonCss from "PFApp/activities/show/activity_page/activity_page.module.scss";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useActivityMatchesFilters } from "PFApp/activities/show/hooks/use_activity_matches_filters";
import RefreshAction from "PFApp/activities/show/parts/refresh_action";
import LazyLocalExportTrigger from "PFApp/components/document_buttons/lazy_local_export_trigger";
import { SearchEntity } from "PFApp/components/search_autocomplete/search_autocomplete.types";
import { SearchInput } from "PFApp/components/search_input";
import { checkFiltersAreClear, getValueFilters, letFiltersRestore } from "PFApp/use_filtered_collection";
import { TableProperties, TablePropertiesButton } from "PFApp/workflow/parts/table_properties";
import { Alert } from "PFComponents/alert";
import { getTermFilterData } from "PFCore/helpers/get_term_filter_data";
import { useMatchesInvalidate } from "PFCore/hooks/queries/matches/use_matches_invalidate";
import { useShortlistsInvalidate } from "PFCore/hooks/queries/shortlists/use_shortlists_invalidate";
import { getExportProfiles } from "PFCore/services/export_profiles";
import { RecentSearchesPageKey } from "PFTypes/recent_searches";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ProfilesViewMode } from "../../../../../../types/profiles_view_mode";
import { OrderSelector } from "../../components/order_selector";
import { ProfileViewModeToggle } from "../../components/profiles_view_mode_toggle";
import css from "./matches_header.module.scss";

export const MatchesHeader = () => {
  const { t } = useTranslation(["activities", "translation"]);
  const {
    task,
    matches,
    matchesMeta,
    availableMatchesShortlistColumns,
    selectedMatchesShortlistColumns,
    profilesViewMode,
    setSelectedMatchesShortlistColumns,
    updateMatchesParams
  } = useActivityPageContext();

  const { id, coowned, state } = task || {};
  const { invalidateShortlists } = useShortlistsInvalidate();
  const { invalidate: invalidateMatches } = useMatchesInvalidate();
  const [isTablePropertiesVisible, setTablePropertiesVisibility] = useState(false);

  useEffect(() => {
    if (profilesViewMode === ProfilesViewMode.Cards) {
      setTablePropertiesVisibility(false);
    }
  }, [profilesViewMode, setTablePropertiesVisibility]);

  const valueFilters = getValueFilters(matchesMeta.filters);
  const filtersAreClear = checkFiltersAreClear(matchesMeta.filters);

  const isCompleted = state === "complete";

  const handleOrderChange = (value) => updateMatchesParams({ order: value, page: 1 });

  const { resetFilters, updateFilter } = useActivityMatchesFilters();
  const letRestoreAll = letFiltersRestore(valueFilters, matchesMeta.default_filters);

  const { termFilter, termValue, isTermFilterAvailable } = getTermFilterData(matchesMeta);

  const paginationLabel = useMemo(() => {
    const { page, perPage, total } = matchesMeta;

    if (total === 0 || isNil(total)) {
      return null;
    }
    const start = (page - 1) * perPage + 1;
    const end = Math.min(page * perPage, total);

    return t(
      `activities:show.matches.${
        filtersAreClear ? "matchesPaginationInfo" : "matchesPaginationInfoFiltered"
      }`,
      { start, end, count: total }
    );
  }, [filtersAreClear, matchesMeta, t]);

  return (
    <>
      <div className={css.container}>
        <SearchInput
          disabled={!isTermFilterAvailable}
          term={termValue}
          pageKey={RecentSearchesPageKey.ActivityMatches}
          autocompleteConfig={[
            {
              entity: SearchEntity.Profile,
              useAsTerm: true
            }
          ]}
          includeRecentSearches={false}
          classes={{ root: commonCss.searchInput }}
          onTermChange={(newTerm: string) => {
            updateFilter(termFilter!, newTerm);
          }}
          qaId="ActivityMatchesTabSearchInput"
        />
        <OrderSelector
          meta={matchesMeta}
          onOrderChange={handleOrderChange}
          showSelect={!isCompleted}
          label={paginationLabel}
          className={css.sortByBlock}
        />
        <ProfileViewModeToggle />
        {profilesViewMode === ProfilesViewMode.Table && (
          <TablePropertiesButton onClick={() => setTablePropertiesVisibility(true)} />
        )}
        {/* @ts-ignore */}
        <LazyLocalExportTrigger
          quantity={matches?.length || 0}
          getProfilesCollection={() => {
            const items = (matches || []).map(({ profile }) => profile);
            return getExportProfiles({
              profileIds: items.map(({ id }) => id),
              activityId: id
            }).then((resp) => ({
              entries: resp.map((profile) => ({
                profile
              })),
              meta: { total: resp.length, page: 1 }
            }));
          }}
          buttonKind="secondary"
          icon="export"
          qaId="ActivityMatchesTabExportButton"
          tooltipContent={t("activities:show.iconsTooltips.export")}
        />
        {!isCompleted && coowned && (
          <RefreshAction
            activityId={id}
            onUpdate={() =>
              invalidateMatches().then(() => {
                invalidateShortlists(id);
              })
            }
            showLabel={false}
            qaId="ActivityMatchesTabRefreshMatchesButton"
          />
        )}
      </div>
      {letRestoreAll && (
        <Alert
          title={t("activities:show.matches.filtersChangedWarning")}
          action={{
            text: t("translation:filters.restoreAll"),
            icon: "history",
            kind: "text",
            onClick: () => resetFilters(matchesMeta.default_filters)
          }}
          small
        />
      )}
      <TableProperties
        isVisible={isTablePropertiesVisible}
        onCloseClick={() => setTablePropertiesVisibility(false)}
        defaultColumns={availableMatchesShortlistColumns}
        selectedColumns={selectedMatchesShortlistColumns}
        onColumnsChange={setSelectedMatchesShortlistColumns}
      />
    </>
  );
};
