import { LoadingDots } from "PFComponents/loading_dots";
import { Booking, Profile } from "PFTypes";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import useBookingModulePermissions from "../../../../use_booking_module_permissions";
import { BookingDetailItem } from "../../booking_detail_item";
import { BookingFormValues } from "../booking_form_provider";
import { BookingFormData, BookingFormDataCreateMode, BookingFormMode } from "../use_booking_form";
import { useInitialWorkforceMember } from "./use_initial_workforce_member";
import { WorkforceMemberReadonly } from "./workforce_member_readonly";
import { WorkforceMemberSelectDropdown } from "./workforce_member_select_dropdown";

type WorkforceMemberSelectProps = {
  initialData: BookingFormData;
  mode: BookingFormMode;
  booking?: Booking;
  readonly: boolean;
};

export const WorkforceMemberSelect = ({
  initialData,
  mode,
  booking,
  readonly
}: WorkforceMemberSelectProps) => {
  const { t } = useTranslation("bookingModule");
  const { isPermittedToEdit: isPermittedToEditBookings } = useBookingModulePermissions();

  const profileId =
    mode === BookingFormMode.Create
      ? (initialData as BookingFormDataCreateMode).profileId
      : booking?.profile_id;
  const { loading } = useInitialWorkforceMember(profileId);

  const workforceMember = useWatch<BookingFormValues, "workforceMember">({ name: "workforceMember" });

  if (loading) {
    return <BookingDetailItem label={t("bookings.create.workforceMember")} value={<LoadingDots />} />;
  }

  const isSelectWorkforceMemberEnabled =
    !readonly &&
    mode === BookingFormMode.Create &&
    !(initialData as BookingFormDataCreateMode).activityId &&
    isPermittedToEditBookings;

  if (!isSelectWorkforceMemberEnabled) {
    return <WorkforceMemberReadonly profile={workforceMember as Profile} />;
  }

  return <WorkforceMemberSelectDropdown initialData={initialData} mode={mode} />;
};
