import classNames from "classnames";
import isNil from "lodash/isNil";
import { ActionIcon } from "PFComponents/action_icon";
import { IconName } from "PFTheme/graphics/icons";
import colors from "PFTheme/tokens/colors";
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { useUnmount } from "react-use";

import { Button } from "../button";
import { Icon } from "../icon";
import { Typography } from "../typography";
import css from "./growl.module.scss";

const clearTimeoutIfExists = (timeoutId?: NodeJS.Timeout) => {
  if (!isNil(timeoutId)) {
    clearTimeout(timeoutId);
  }
};

type KindData = { [key in "success" | "alert" | "error"]: { icon: IconName; color: keyof typeof colors } };
const KIND_DATA: KindData = {
  error: {
    icon: "error",
    color: "paletteWhite0"
  },
  alert: {
    icon: "warning",
    color: "paletteBlue0"
  },
  success: {
    icon: "check",
    color: "paletteWhite0"
  }
};

export type GrowlProps = {
  message: string | React.ReactElement;
  kind?: "success" | "alert" | "error";
  title?: string | React.ReactElement;
  url?: string;
  ttl?: number | null;
  buttonText?: string;
  onClose?: VoidFunction;
  onClick?: VoidFunction;
  onButtonClick?: VoidFunction;
  buttonHref?: string;
  hidePreviousByTypeAndId?: boolean;
};

export const Growl = ({
  kind = "success",
  message,
  title,
  url,
  ttl = 10000,
  buttonText,
  onClose,
  onClick,
  onButtonClick,
  buttonHref
}: GrowlProps) => {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const displayTimeoutId = useRef<NodeJS.Timeout>(undefined);
  const closeTimeoutId = useRef<NodeJS.Timeout>(undefined);
  const closeAnimationTimeoutId = useRef<NodeJS.Timeout>(undefined);

  const handleClose = useCallback(
    (event?: SyntheticEvent) => {
      event?.preventDefault();
      event?.stopPropagation();

      setIsClosing(true);

      if (onClose) {
        closeAnimationTimeoutId.current = setTimeout(onClose, 500);
      }
    },
    [onClose]
  );

  useEffect(() => {
    displayTimeoutId.current = setTimeout(() => setIsDisplayed(true), 100);
    clearTimeoutIfExists(closeTimeoutId.current);
    if (ttl) {
      closeTimeoutId.current = setTimeout(() => handleClose(), ttl);
    }
  }, [title, message]);

  useUnmount(() => {
    clearTimeoutIfExists(displayTimeoutId.current);
    clearTimeoutIfExists(closeTimeoutId.current);
    clearTimeoutIfExists(closeAnimationTimeoutId.current);
  });

  const handleClick = useCallback(
    (event: SyntheticEvent) => {
      if (onClick) {
        onClick();
      } else if (url) {
        window.location.assign(url);
      }

      if (!(buttonHref || onButtonClick)) {
        handleClose(event);
      }
    },
    [handleClose, onClick, url, buttonHref, onButtonClick]
  );

  const ContentTag = url || onClick ? "a" : "div";
  const isError = kind === "error";
  const kindData = KIND_DATA[kind];

  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <div
      role="alert"
      className={classNames(
        css.growl,
        { [css.incoming]: !isDisplayed },
        { [css.outgoing]: isClosing },
        { [css.error]: isError },
        { [css.alert]: kind === "alert" }
      )}
      data-qa-id="growl"
      style={{ color: colors[kindData?.color] }}
      onClick={(event) => {
        // Avoid closing dialog when clicking on growl padding area
        event.stopPropagation();
      }}
    >
      <ContentTag className={css.content} href={url} onClick={handleClick} data-qa-id="growl-content">
        {!!kindData && <Icon name={kindData.icon} size="md" color={kindData.color} />}
        {title && (
          <Typography variant="h5" className={css.title}>
            {title}
          </Typography>
        )}
        <Typography variant="bodyBold" className={css.message}>
          {message}
        </Typography>
        {buttonText &&
          (onButtonClick ? (
            <Button text={buttonText} onClick={() => onButtonClick()} />
          ) : (
            buttonHref && <Button text={buttonText} href={buttonHref} tag="a" target="_blank" />
          ))}
      </ContentTag>
      <ActionIcon
        aria-hidden="true"
        name="cross"
        color={kindData?.color}
        size="xs"
        onClick={handleClose}
        classes={{ root: css.close }}
        data-qa-id="growl-close-icon"
      />
    </div>
  );
};
