import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCallback, useMemo } from "react";

import { getTokenStorageForAccount } from "./utils";

export const useAccessTokens = () => {
  const { data: currentAccount } = useCurrentAccount();

  const tokenStorage = useMemo(() => getTokenStorageForAccount(currentAccount), [currentAccount]);

  const getAccessToken = useCallback(() => tokenStorage.getItem("profinda_auth_access"), [tokenStorage]);
  const getRefreshToken = useCallback(() => tokenStorage.getItem("profinda_auth_refresh"), [tokenStorage]);

  const clearTokens = useCallback(() => {
    tokenStorage.removeItem("profinda_auth_refresh");
    tokenStorage.removeItem("profinda_auth_access");
  }, [tokenStorage]);

  return {
    getAccessToken,
    getRefreshToken,
    clearTokens
  };
};
