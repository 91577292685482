import classNames from "classnames";
import get from "lodash/get";
import { BookingCategorySelect as BookingCategorySelectInput } from "PFApp/booking/components/booking_category_select/booking_category_select";
import { BookingCategory } from "PFTypes";
import { Controller, FieldPath, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BookingDetailCategorySettings } from "../../booking_detail_category_settings";
import { BookingDetailItem } from "../../booking_detail_item";
import { truncateTitle } from "../booking_form_common_inputs";
import { BookingFormValues } from "../booking_form_provider";
import { useCategoryError } from "../use_category_error";
import css from "./booking_category_select.modules.scss";

interface BookingCategorySelectProps {
  categoryFormValueName: FieldPath<BookingFormValues>;
  categoryFormErrorKeys: string[];
  titleFormValueName: FieldPath<BookingFormValues>;
  className: string;
}

export const BookingCategorySelect = ({
  categoryFormValueName,
  categoryFormErrorKeys,
  titleFormValueName,
  className
}: BookingCategorySelectProps) => {
  const { t } = useTranslation("bookingModule");
  const {
    control,
    setValue,
    clearErrors,
    formState: { errors }
  } = useFormContext<BookingFormValues>();
  const [activityId, title] = useWatch<BookingFormValues>({ name: ["activityId", titleFormValueName] });
  const category = useWatch<BookingFormValues>({ name: categoryFormValueName }) as any;
  useCategoryError({ categoryFormValueName });

  const handleChange = (newCategory: BookingCategory | null, onChange: (value?: BookingCategory) => void) => {
    clearErrors(categoryFormErrorKeys as (keyof BookingFormValues)[]);
    const isTitlePreviousCategoryName = truncateTitle(category?.display_as) === title;
    if (newCategory && (!title || isTitlePreviousCategoryName)) {
      setValue(titleFormValueName, truncateTitle(newCategory.display_as));
    }
    onChange(newCategory ?? undefined);
  };

  const errorKey = categoryFormErrorKeys.find((key) => !!get(errors, key));
  const error = errorKey ? get(errors, errorKey) : undefined;

  return (
    <div className={css.root}>
      <BookingDetailItem
        label={`${t("bookings.create.category")} *`}
        value={
          <Controller
            name={categoryFormValueName}
            control={control}
            rules={{
              required: true
            }}
            render={({ field }) => (
              <BookingCategorySelectInput
                withoutLabel
                category={field.value as BookingCategory}
                onCategoryChange={(newCategory) => handleChange(newCategory, field.onChange)}
                className={className}
                error={error?.message as string}
                required
                demand={!!activityId}
              />
            )}
          />
        }
      />
      <div
        className={classNames({
          [css.boxDisabled]: !category
        })}
      >
        <BookingDetailCategorySettings
          billable={category?.billable}
          affectsAvailability={category?.affects_availability}
        />
      </div>
    </div>
  );
};
