import { useFiltersContext } from "PFApp/components/filters/context/filters_context";
import Toggle from "PFComponents/toggle/toggle";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

import css from "./filters_header.module.scss";

export const FiltersHeader = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.sidePanel.filters" });
  const { isAutoRefresh, setIsAutoRefresh } = useFiltersContext();

  return (
    <header className={css.header}>
      <Typography variant="h4">{t("title")}</Typography>
      <Toggle
        inline
        className={css.autoRefreshToggle}
        label={t("autoRefresh")}
        checked={isAutoRefresh}
        onChange={setIsAutoRefresh}
        reversed
        compact
      />
    </header>
  );
};
