import safePrune from "PFCore/helpers/safe_prune";
import PropTypes from "prop-types";

const Description = ({ description, descriptionLength = 140 }) => (
  <div>{safePrune(description, descriptionLength)}</div>
);

Description.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  descriptionLength: PropTypes.number
};

export default Description;
