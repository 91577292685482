import classNames from "classnames";
import SortBy from "PFApp/components/sort_by";
import { Typography } from "PFComponents/typography";
import { Meta } from "PFTypes";

import css from "./order_selector.module.scss";

type OrderSelectorProps = {
  meta: Meta;
  onOrderChange: (newOrder: any) => void;
  label: string | null;
  showSelect?: boolean;
  className?: string;
};

export const OrderSelector = ({
  showSelect = true,
  meta,
  onOrderChange,
  label,
  className
}: OrderSelectorProps) => {
  const metaOrder = meta?.order;

  return (
    <div className={classNames(css.root, className)}>
      {showSelect && !!metaOrder && (
        <SortBy
          sort={metaOrder.value?.text}
          options={metaOrder.options.map((option) => ({
            id: option.text,
            displayElement: option.text,
            item: option
          }))}
          onSortChange={onOrderChange}
          className={css.sortBy}
          noLabel
        />
      )}
      {label && (
        <Typography withMargin variant="labelRegular" className={css.label}>
          {label}
        </Typography>
      )}
    </div>
  );
};
