import css from "./tooltip_content.module.scss";

type Props = {
  jobCodeDisplayAs: string;
  jobCode: string | number | undefined;
};

export const TooltipJobCode = ({ jobCodeDisplayAs, jobCode }: Props) => (
  <p className={css.element}>
    {jobCodeDisplayAs}: {jobCode}
  </p>
);
