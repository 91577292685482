import classNames from "classnames";
import { PropsWithChildren } from "react";

import css from "./button_label.module.scss";

type ButtonLabelProps = PropsWithChildren & {
  className?: string;
  style?: React.CSSProperties;
};

// label that imitates button
const ButtonLabel = ({ children, className, style }: ButtonLabelProps) => (
  <div className={classNames(css.root, className)} style={style}>
    {children}
  </div>
);

export default ButtonLabel;
