import classNames from "classnames";
import { useActivityTemplateType } from "PFApp/activities/hooks";
import { BookingDetailActivity } from "PFApp/booking/components/booking_detail_activity";
import { useDetailsPanelRoleStateContext } from "PFApp/booking/components/details_panel/details_panel_context/details_panel_context";
import css from "PFApp/booking/parts/overview/detail_view/detail_view.module.scss";
import { useBookingActivityContext } from "PFApp/booking/parts/providers/booking_activity_context_provider";
import { Divider } from "PFComponents/divider";
import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { H5 } from "../parts/h5";
import { Label } from "../parts/label";
import { Buttons } from "./buttons";
import { CustomFields } from "./custom_fields";
import { Details } from "./details";
import { Duration } from "./duration";
import { Owners } from "./owners";
import { Skills } from "./skills";

type RoleDetailProps = {
  setAllocateManually: Dispatch<SetStateAction<boolean>>;
};

export const RoleDetail = ({ setAllocateManually }: RoleDetailProps) => {
  const { openAllocateManually } = useDetailsPanelRoleStateContext();

  const { t } = useTranslation();
  const { activity, parentActivity, canEdit, isParentActivityFetching, isParentActivityError } =
    useBookingActivityContext();
  const { bookingCategories } = useBookingCategories();
  const hasWriteAccessToAtLeastOneCategory = bookingCategories
    .filter(({ activity_assignable: demandRelated }) => !!demandRelated)
    .some((i) => !i.readonly);

  const { isAudit, isRole } = useActivityTemplateType(activity);
  const isAuditRole = isAudit && isRole;

  useEffect(() => {
    if (!openAllocateManually) {
      return;
    }
    if (isAuditRole !== undefined && hasWriteAccessToAtLeastOneCategory) {
      setAllocateManually(!isAuditRole);
    }
  }, [openAllocateManually, isAuditRole, hasWriteAccessToAtLeastOneCategory]);

  if (!activity) {
    return null;
  }

  return (
    <>
      <div className={classNames(css.root, css.roleDetail)}>
        <Owners activity={activity} />
        <Divider />
        <div className={css.item}>
          <H5>{t("description")}</H5>
          <Typography variant="bodyRegular">{activity.description}</Typography>
        </div>
        {parentActivity && !isParentActivityError && (
          <>
            <Divider />
            <div className={css.item}>
              <BookingDetailActivity activity={parentActivity} isLoading={isParentActivityFetching} />
            </div>
            <Divider />
          </>
        )}
        {!!activity.metadata.availability && <Duration availability={activity.metadata.availability} />}
        <Details activity={activity} />
        <Divider />
        {canEdit && !isAuditRole && hasWriteAccessToAtLeastOneCategory && (
          <>
            <Buttons setAllocateManually={setAllocateManually} />
            <Divider />
          </>
        )}
        <Skills activity={activity} />
        <CustomFields activity={activity} />
        <Divider />
        <div className={css.item}>
          <Label text={t("creator")} />
          <Typography variant="bodyBold">{getProfileName(activity.profile)}</Typography>
        </div>
      </div>
    </>
  );
};
