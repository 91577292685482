import classNames from "classnames";
import keys from "lodash/keys";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import { useGrowl } from "PFApp/use_growl";
import { ActionIcon } from "PFComponents/action_icon";
import { Button } from "PFComponents/button";
import { Divider } from "PFComponents/divider";
import Hr from "PFComponents/hr/hr";
import { LoadingDots } from "PFComponents/loading_dots";
import { Typography } from "PFComponents/typography";
import { useActivities } from "PFCore/hooks/queries/activities/use_activities";
import { useActivityEconomicsScenario } from "PFCore/hooks/queries/activity";
import { useActivityEconomicsScenarioClone } from "PFCore/hooks/queries/activity/use_activity_economics_scenario_clone";
import { useActivityEconomicsScenarioInvalidate } from "PFCore/hooks/queries/activity/use_activity_economics_scenario_invalidate";
import { Id } from "PFTypes";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUnmount } from "react-use";

import { useActivityPageContext } from "../../activity_page_context";
import { RemoveScenarioModal } from "../remove_scenario_modal";
import { ScenarioNameModal } from "../scenario_name_modal";
import { ShareScenarioModal } from "../share_scenario_modal";
import { useEconomicsPermissions } from "../use_economics_permissions";
import { useScenarioNavigate } from "../use_scenario_navigate";
import { getEconomicsUrl } from "../utils";
import { BudgetVsCostChart } from "./charts/budget_vs_cost_chart";
import { GradeChart } from "./charts/grade_chart";
import { HoursChart } from "./charts/hours_chart";
import { SkillsChart } from "./charts/skills_chart";
import { RoleCard } from "./role_card";
import css from "./scenario_view.module.scss";
import { useScenarioFillAndBook } from "./use_scenario_fill_and_book";
import { groupVacanciesByRole } from "./utils";

type ScenarioViewProps = {
  scenarioId: string;
};

export const ScenarioView = ({ scenarioId }: ScenarioViewProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario" });
  const growl = useGrowl();
  const navigate = useNavigate();

  const { task: activity } = useActivityPageContext();
  const { canCreateScenarios, canManageScenario } = useEconomicsPermissions();
  const { openScenario, closeScenario } = useScenarioNavigate();

  const { mutate: cloneScenario } = useActivityEconomicsScenarioClone(activity.id);
  const { data: scenario, isFetching } = useActivityEconomicsScenario(scenarioId, {
    onError: (error) => {
      if (error.status === 422) {
        // Handle URL manual replacement and potential API permission checks
        navigate(getEconomicsUrl(activity.id), { replace: true });
        growl({ message: t("notPermitted"), kind: "error" });
      }
    }
  });
  const { invalidate: invalidateScenario } = useActivityEconomicsScenarioInvalidate();

  const isEditable = useMemo(
    () => (scenario ? canManageScenario(scenario.creatorId) : false),
    [scenario, canManageScenario]
  );

  const [isEditNameModalOpen, setIsEditNameModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  // A list of Ids that have been selected by the user (excluding filled/booked people)
  const [selectedVacancies, setSelectedVacancies] = useState<Id[]>([]);

  const groupedVacancies = useMemo(
    () => (scenario?.vacancies ? groupVacanciesByRole(scenario.vacancies) : {}),
    [scenario?.vacancies]
  );

  const rolesIds = keys(groupedVacancies);

  const { data: activitiesData, isLoading: isLoadingActivities } = useActivities(
    {
      filters: {
        fields: {
          id: rolesIds
        }
      }
    },
    {
      enabled: rolesIds.length > 0
    }
  );

  const activities = activitiesData?.entries.map(({ activity }) => activity) || [];

  const {
    hasFillAndBookButton,
    isLoadingShortlists,
    fillAndBookCounter,
    handleFill,
    handleFillAndBook,
    checkCanSelectVacancy
  } = useScenarioFillAndBook({
    selectedVacancies,
    setSelectedVacancies,
    scenario,
    activities
  });

  //matches may not be ready so we want to reaload
  //scenario in case matches updated for select input options
  useUnmount(() => invalidateScenario(scenarioId));

  const closeEditNameModal = useCallback(() => {
    setIsEditNameModalOpen(false);
  }, []);

  const handleClone = () => {
    cloneScenario(scenarioId, {
      onSuccess: (newScenario) => {
        growl({
          kind: "success",
          message: t("cloneSuccess")
        });

        openScenario(newScenario.id);
      },
      onError: () => {
        growl({
          kind: "error",
          message: t("cloneError")
        });
      }
    });
  };

  const onSelectVacancy = useCallback((vacancyId: Id, select: boolean) => {
    if (select) {
      setSelectedVacancies((selection) => [...selection, vacancyId]);
    } else {
      setSelectedVacancies((selection) => selection.filter((id) => id !== vacancyId));
    }
  }, []);

  const isDataForRolesLoaded = !isLoadingActivities && !isLoadingShortlists;

  return (
    <main className={css.root}>
      <section className={classNames(css.content, { [css.disabled]: isFetching })}>
        <header>
          <span className={css.editableHeader}>
            <Typography variant="h3" clipOverflow>
              {scenario?.name}
            </Typography>
            {isEditable && <ActionIcon name="edit" size="sm" onClick={() => setIsEditNameModalOpen(true)} />}
          </span>
          <Divider />
        </header>
        {isDataForRolesLoaded ? (
          <article className={css.scenarioDetails}>
            {map(groupedVacancies, (roleVacancies, roleId) => {
              const roleSelectedVacancies = selectedVacancies.filter(
                (vacancyId) => roleVacancies.findIndex(({ id }) => id === vacancyId) >= 0
              );

              const orderedRoleVacancies = orderBy(roleVacancies, (vacancy) => vacancy.assignment?.updatedAt);

              return (
                <RoleCard
                  key={roleId}
                  scenarioId={scenarioId}
                  vacancies={orderedRoleVacancies}
                  isEditable={isEditable}
                  selectedVacancies={roleSelectedVacancies}
                  onSelectVacancy={onSelectVacancy}
                  checkCanSelectVacancy={checkCanSelectVacancy}
                />
              );
            })}
          </article>
        ) : (
          <LoadingDots circlesEnabled circleSize="sm" centered className={css.detailsLoading} />
        )}
      </section>
      <aside className={css.sidebar}>
        <div className={css.toolbar}>
          {!!activities.length &&
            (hasFillAndBookButton ? (
              <Button
                text={t("fillAndBookButton", { count: fillAndBookCounter })}
                onClick={handleFillAndBook}
                disabled={!isEditable || fillAndBookCounter === 0}
              />
            ) : (
              <Button
                text={t("fillButton", { count: selectedVacancies.length })}
                onClick={handleFill}
                disabled={!isEditable || selectedVacancies.length === 0}
              />
            ))}
          {canCreateScenarios && <Button icon="duplicate" kind="secondary" onClick={handleClone} />}
          {isEditable && <Button icon="share" kind="secondary" onClick={() => setIsShareModalOpen(true)} />}
          {isEditable && <Button icon="remove" kind="secondary" onClick={() => setIsRemoveModalOpen(true)} />}
          <Divider type="vertical" />
          <ActionIcon name="cross" onClick={closeScenario} />
        </div>
        {scenario && (
          <>
            <BudgetVsCostChart
              className={css.budgetCostChart}
              budget={activity.metadata.budget}
              cost={scenario.cost}
            />
            {!!scenario?.skillsRadarData && (
              <>
                <Hr />
                <SkillsChart
                  skillsRadarData={scenario.skillsRadarData}
                  skillsCoveredPercentage={scenario.skillsCoveredPercentage}
                />
              </>
            )}
            {!!scenario?.durationInHours && (
              <>
                <Hr />
                <HoursChart
                  hoursTotal={scenario.durationInHours}
                  coverage={scenario.hoursCoveredPercentage}
                />
              </>
            )}
            {!!scenario?.costFieldData?.length && (
              <>
                <Hr />
                <GradeChart data={scenario.costFieldData} />
              </>
            )}
          </>
        )}
      </aside>
      {isEditNameModalOpen && (
        <ScenarioNameModal
          isEdit
          scenario={scenario}
          onClose={closeEditNameModal}
          onSave={closeEditNameModal}
        />
      )}
      {isRemoveModalOpen && (
        <RemoveScenarioModal
          scenarioId={scenarioId}
          activityId={activity.id}
          onRemove={closeScenario}
          onClose={() => setIsRemoveModalOpen(false)}
        />
      )}
      {isShareModalOpen && (
        <ShareScenarioModal scenarioId={scenarioId} onClose={() => setIsShareModalOpen(false)} />
      )}
    </main>
  );
};
