import compact from "lodash/compact";
import head from "lodash/head";
import { RowType } from "PFApp/booking/types/row";
import { Icon } from "PFComponents/icon";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { validateCategoryIcon } from "PFModules/admin/booking/categories/booking_categories.utils";

const ICONS_BY_TYPE = {
  role: "role",
  engagement: "engagement",
  audit_role: "role-audit",
  audit_engagement: "engagement-audit",
  profile: "profile",
  hiddenCategory: "locked"
};

type EntityIconProps = {
  templateId?: number | null;
  type: string;
  bookingCategoryIds?: number[];
};

export const EntityIcon = ({ templateId, type, bookingCategoryIds }: EntityIconProps) => {
  const { data: currentProfile } = useCurrentProfile();
  const { getBookingCategory } = useBookingCategories();

  if (type === RowType.Category) {
    const bookingCategory = head(
      compact(bookingCategoryIds?.map((categoryId) => getBookingCategory(categoryId)))
    );
    const iconName = validateCategoryIcon(bookingCategory?.icon);
    return iconName && <Icon name={iconName} size="md" color="paletteBlue1" />;
  }

  const templateKey = ![RowType.Profile as string, "hiddenCategory"].includes(type)
    ? currentProfile.templates?.find(({ id }) => id === templateId)?.key
    : null;

  const iconName = ICONS_BY_TYPE[templateKey || type];

  return iconName && <Icon name={iconName} size="md" />;
};
