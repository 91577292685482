import classNames from "classnames";
import { SimplePagination } from "PFComponents/pagination/simple_pagination";
import { Profile } from "PFTypes";
import { useTranslation } from "react-i18next";

import { UseInterestsReturn } from "../../query/use_interests";
import { PageSection } from "../page_section";
import Interests from "./interests";
import css from "./interests.module.scss";

type InterestsSectionProps = {
  className: string;
  updateHighlight: (recalc?: boolean) => void;
  interestsCollection: UseInterestsReturn;
  profile: Profile;
};

const InterestsSection = ({
  interestsCollection,
  className,
  profile,
  updateHighlight
}: InterestsSectionProps) => {
  const { t } = useTranslation("profiles");
  const { isLoading, entries, currentPage, totalPages, setCurrentPage } = interestsCollection;

  return (
    <PageSection
      title={t("show.parts.interestedIn")}
      className={classNames(css.section, className, { [css.loading]: isLoading })}
    >
      <Interests interests={entries} profile={profile} updateHighlight={updateHighlight} />
      <SimplePagination
        totalPages={totalPages}
        currentPage={currentPage}
        handleClick={setCurrentPage}
        scrollOnClick={false}
      />
    </PageSection>
  );
};

export default InterestsSection;
