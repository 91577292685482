import { Modal } from "PFComponents/modal";
import { Typography } from "PFCore/components/typography/typography";
import { useTranslation } from "react-i18next";

type RemoveNoteModalProps = {
  onClose: VoidFunction;
  onRemove: VoidFunction;
};

export const RemoveNoteModal = ({ onClose, onRemove }: RemoveNoteModalProps) => {
  const { t } = useTranslation(["core", "translation"]);
  return (
    <Modal
      title={t("core:notes.removeNote")}
      labelOK={t("translation:remove")}
      kindOK="danger"
      onOK={onRemove}
      onClose={onClose}
    >
      <Typography withMargin variant="bodyRegular" tag="span">
        {t("core:notes.removeNoteQuestion")}
      </Typography>
    </Modal>
  );
};
