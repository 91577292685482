import classNames from "classnames";
import css from "PFApp/booking/parts/overview/detail_view/detail_view.module.scss";
import { useMetadata } from "PFApp/components/activity_commons/use_metadata";
import { Icon } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { Activity } from "PFTypes";
import { useTranslation } from "react-i18next";

import { Label } from "../parts/label";

type CustomFieldsProps = {
  activity: Activity;
};

export const CustomFields = ({ activity }: CustomFieldsProps) => {
  const { t } = useTranslation(["bookingModule", "translation"]);

  const metadata = useMetadata({ activity });

  const filteredMetadata = metadata.filter(({ name }) => !["availability", "skills"].includes(name));

  return (
    <span className={classNames(css.details, css.item)}>
      <div className={css.item}>
        <Label text={t("translation:privacy")} />
        <span className={css.alignCenter}>
          <Typography variant="bodyBold">
            {t(activity.private ? "translation:private" : "translation:public")}
          </Typography>
          {activity.private && (
            <Tooltip content={t("bookingModule:details.privacyTooltipText")} appendTo={"parent"}>
              <span className={css.alignCenter}>
                <Icon name="info" />
              </span>
            </Tooltip>
          )}
        </span>
      </div>
      {filteredMetadata.map(({ title, text }) => (
        <div key={title} className={css.item}>
          <Label text={title} />
          <Typography variant="bodyBold">{text}</Typography>
        </div>
      ))}
    </span>
  );
};
