import classNames from "classnames";
import { CSSProperties, PropsWithChildren } from "react";

import { BasicPill } from "./basic_pill";
import css from "./pill.module.scss";

type EngagementPillProps = PropsWithChildren & {
  onClick?: () => void;
  className?: string;
  style?: CSSProperties;
  state: string;
};

const EngagementPill = ({ children, className, style, state }: EngagementPillProps) => {
  const engagementClassName =
    {
      booked: "engagementBooked",
      partially_booked: "engagementPartiallyBooked"
    }[state] || "engagementNotBooked";

  return (
    <BasicPill className={classNames(css[engagementClassName], className)} style={style}>
      {children}
    </BasicPill>
  );
};

export default EngagementPill;
