import classNames from "classnames";
import { ActionDiv } from "PFComponents/containers";
import { ExperienceLevel, ExperienceLevelProps } from "PFComponents/experience_level";
import { Icon } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { Experience } from "PFTypes";
import React, { AriaAttributes, PropsWithChildren, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "./custom_value_pill.module.scss";
import { useCustomValueAriaLabel } from "./hooks/use_custom_value_aria_label";

type CustomValuePillProps = Pick<ExperienceLevelProps, "theme" | "showTooltip"> & {
  className?: string;
  name: string;
  customTypeAriaLabel?: string;
  experience?: Experience;
  requiredExperience?: Experience;
  isCore?: boolean;
  isDevelopmental?: boolean;
  isVerified?: boolean;
  isVerifiedCredly?: boolean;
  isVerifiedOthers?: boolean;
  isRequirement?: boolean;
  isPendingApproval?: boolean;
  maxNameLength?: number;
  onClick?: VoidFunction;
  experienceTooltipDisabled?: boolean;
  customLeftIconName?: "check" | "cross";
  customRightContent?: ReactNode;
};

const ClickableWrapper = ({
  children,
  onClick,
  "aria-label": ariaLabel
}: PropsWithChildren<Pick<CustomValuePillProps, "onClick">> & AriaAttributes) => (
  <ActionDiv onClick={onClick} tabIndex={0} aria-label={ariaLabel} className={css.actionDiv}>
    {children}
  </ActionDiv>
);

export const CustomValuePill = ({
  className,
  name,
  customTypeAriaLabel,
  isCore,
  isDevelopmental,
  isVerified,
  isVerifiedCredly,
  isVerifiedOthers,
  isRequirement,
  isPendingApproval,
  maxNameLength = 40,
  onClick,
  theme,
  experience,
  requiredExperience,
  showTooltip = true,
  customLeftIconName,
  customRightContent
}: CustomValuePillProps) => {
  const { t } = useTranslation("core", { keyPrefix: "customValue" });
  const ariaLabel = useCustomValueAriaLabel({
    name,
    customTypeAriaLabel,
    isRequirement,
    experience,
    requiredExperience
  });
  const Wrapper = useMemo(() => (onClick ? ClickableWrapper : React.Fragment), [onClick]);

  const isNameTooLong = name.length > maxNameLength;

  return (
    <Wrapper onClick={onClick} aria-label={ariaLabel}>
      <span
        className={classNames(css.customValuePill, { [css.pendingApproval]: isPendingApproval }, className)}
      >
        {(experience || requiredExperience) && (
          <ExperienceLevel
            theme={theme}
            isRequirement={isRequirement}
            experience={experience}
            requiredExperience={requiredExperience}
            showTooltip={showTooltip}
          />
        )}
        {customLeftIconName && (
          <Icon
            name={customLeftIconName}
            color={customLeftIconName === "cross" ? "paletteRed0" : undefined}
            size={customLeftIconName === "cross" ? "sm" : undefined}
          />
        )}
        <Tooltip
          content={
            <Typography withMargin variant="labelButton">
              {t("pendingApproval")}
            </Typography>
          }
          disabled={!isPendingApproval}
        >
          <div className={css.nameWrapper} title={name}>
            <Typography
              variant="labelButton"
              className={classNames(css.name, { [css.darkTheme]: theme === "dark" })}
            >
              {name.slice(0, maxNameLength)}
              {isNameTooLong && "..."}
            </Typography>
          </div>
        </Tooltip>
        {isCore && <Icon name="core" />}
        {isDevelopmental && <Icon name="development" />}
        {isVerified && <Icon name={isVerifiedCredly ? "verified-credly" : "verified"} />}
        {isVerifiedOthers && <Icon name="verified-others" />}
        {customRightContent}
      </span>
    </Wrapper>
  );
};
