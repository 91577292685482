import { useDateFormatter } from "PFCore/hooks/use_date_formatter";

import css from "./tooltip_content.module.scss";

type Props = {
  startDate: string;
  endDate: string;
};

export const TooltipDates = ({ startDate, endDate }: Props) => {
  const { dateFormatString, utc } = useDateFormatter();
  const startText = utc(startDate).format(dateFormatString);
  const endText = utc(endDate).format(dateFormatString);
  const datesDisplay = `${startText} - ${endText}`;

  return <p className={css.dates}>{datesDisplay}</p>;
};
