import classNames from "classnames";
import { Button } from "PFComponents/button";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { prune, truncate } from "underscore.string";

import { Typography } from "../typography";
import css from "./toggleable_text.less";

const ToggleableText = ({
  text,
  toggleLength = 200,
  shortLengthFactor = 1.25,
  afterToggle,
  style,
  sameLine = true
}) => {
  const { t } = useTranslation("core");
  const [showAll, setShowAll] = useState(false);
  const isShort = text && text.length < toggleLength * Math.min(1, shortLengthFactor);

  const preparedText = useMemo(() => {
    let raw = (text || "").trim();
    if (!isShort && !showAll) {
      raw = raw.split(" ")[0].length > toggleLength ? truncate(raw, toggleLength) : prune(raw, toggleLength);
    }

    return raw.replace(/\n\n+/g, "\n\n");
  }, [isShort, showAll]);

  return (
    <div className={css.root} style={style}>
      <Typography withMargin variant="bodyRegular" tag="span">
        {preparedText}
      </Typography>
      {!sameLine && <br />}
      {text && !isShort && (
        <Button
          kind="text"
          rootClassName={classNames(css.more, { [css.sameLine]: sameLine })}
          onClick={() => {
            setShowAll(!showAll);
            afterToggle && window.setTimeout(afterToggle, 1);
          }}
        >
          {showAll ? `${t("less")}...` : `${t("more")}...`}
        </Button>
      )}
    </div>
  );
};

ToggleableText.propTypes = {
  text: PropTypes.string,
  toggleLength: PropTypes.number,
  shortLengthFactor: PropTypes.number,
  afterToggle: PropTypes.func,
  style: PropTypes.object,
  sameLine: PropTypes.bool
};

export default ToggleableText;
