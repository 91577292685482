import { InlineCalendar } from "PFComponents/calendar/inline_calendar";
import { Modal } from "PFComponents/modal";
import { useActivityReviewDeadlineCreate } from "PFCore/hooks/queries/activity/use_activity_deadline_create";
import { useActivityReviewDeadlineUpdate } from "PFCore/hooks/queries/activity/use_activity_deadline_update";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useActivityPageContext } from "../../activity_page_context";

type AddDeadlineModalProps = {
  onClose: () => void;
};

export const DeadlineModal = ({ onClose }: AddDeadlineModalProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.shortlist.review" });
  const growlErrors = useErrorsGrowl();
  const { formatISODate, utc, isDatePassed } = useDateFormatter();
  const { task } = useActivityPageContext();

  const isEditMode = task.review?.deadline;

  const { mutate: updateReviewDeadline } = useActivityReviewDeadlineUpdate({
    onError: (error) => {
      growlErrors(error, { display_all: true });
    }
  });
  const { mutate: createReviewDeadline } = useActivityReviewDeadlineCreate({
    onError: (error) => {
      growlErrors(error, { display_all: true });
    }
  });

  const [deadlineDate, setDeadlineDate] = useState(task.review?.deadline);

  const deadlinePassed = isDatePassed(deadlineDate);

  const handleSubmit =
    deadlineDate && deadlineDate !== task.review?.deadline
      ? () => (isEditMode ? updateReviewDeadline : createReviewDeadline)({ id: task.id, date: deadlineDate })
      : undefined;

  return (
    <Modal title={t("reviewIsReady")} labelOK={t("notifyReviewer")} onOK={handleSubmit} onClose={onClose}>
      {/* @ts-ignore */}
      <InlineCalendar
        label={t("reviewDeadline")}
        selectedDate={deadlineDate ? formatISODate(deadlineDate) : null}
        minDate={utc().add(1, "days")}
        qaId={"review-deadline-date"}
        handleChange={setDeadlineDate}
        required
        warning={deadlinePassed && t("deadlinePassed")}
      />
    </Modal>
  );
};
