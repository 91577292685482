import { isFeatureEnabled } from "PFCore/helpers/use_is_feature_enabled";
import { CurrentAccount, FeatureFlag } from "PFTypes";

export const getTokenStorageForAccount = (account: CurrentAccount | null) => {
  const isEnabled = isFeatureEnabled(account);
  if (isEnabled(FeatureFlag.SessionStorage) && window.pfSessionStorage) {
    return window.pfSessionStorage;
  } else {
    return window.storage;
  }
};

const getRefreshExpirationTime = (account: CurrentAccount | null) =>
  (account?.session_ttl_in_minutes || 1200) * 60;

export const setTokensForAccount = (
  accessToken: string,
  refreshToken: string,
  account: CurrentAccount | null,
  refresh = false
) => {
  const accountTokenStorage = getTokenStorageForAccount(account);
  const refreshExpirationTime = getRefreshExpirationTime(account);

  const refreshTokenOptions = refresh ? { preserveTime: true } : { expiresIn: refreshExpirationTime };

  accountTokenStorage.setItem("profinda_auth_access", accessToken);
  accountTokenStorage.setItem("profinda_auth_refresh", refreshToken, refreshTokenOptions);
};
