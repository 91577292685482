import { isDateWithTime } from "PFApp/booking/parts/helpers";
import { Typography } from "PFComponents/typography";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";

import css from "./start_end_dates.module.scss";

type StartEndDatesProps = {
  startDate: string | number | undefined;
  endDate: string | number | undefined;
};

export const StartEndDates = ({ startDate, endDate }: StartEndDatesProps) => {
  const { formatDate, formatDateTime, utc } = useDateFormatter();
  const isStartWithTime = isDateWithTime(utc(startDate));
  const isEndWithTime = isDateWithTime(utc(endDate));
  const startDateFormatted = isStartWithTime ? formatDateTime(utc(startDate)) : formatDate(utc(startDate));
  const endDateFormatted = isEndWithTime ? formatDateTime(utc(endDate)) : formatDate(utc(endDate));

  if (!isStartWithTime && !isEndWithTime) {
    return <Typography variant="bodyBold">{[startDateFormatted, endDateFormatted].join(" - ")}</Typography>;
  }

  return (
    <div className={css.root}>
      <Typography variant="bodyBold">{`${startDateFormatted} -`}</Typography>
      <Typography variant="bodyBold">{endDateFormatted}</Typography>
    </div>
  );
};
