import isEmpty from "lodash/isEmpty";
import { ExpiryDateModal, PostUntilData } from "PFApp/components/expiry_date_modal";
import { ActionIcon } from "PFComponents/action_icon";
import { InlineCalendar } from "PFComponents/calendar/inline_calendar";
import Panel from "PFComponents/panel/panel";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { PostUntilChangeReason } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useActivityEditFormContext } from "../../activity_edit_form_context";
import { useActivityEditPageContext } from "../../activity_edit_page_context";
import css from "../sections.module.scss";
import { ExpiryDateEditMode } from "./expiry_date_edit_mode";

type ExpiryDateSectionProps = {
  isEditMode: boolean;
  qaIdPrefix?: string;
};

export const ExpiryDateSection = ({ isEditMode, qaIdPrefix }: ExpiryDateSectionProps) => {
  const { template } = useActivityEditPageContext();
  const { postUntil, updatePostUntil, postUntilChangeReason, updatePostUntilChangeReason, errors } =
    useActivityEditFormContext();
  const [modalOpened, setModalOpened] = useState(false);
  const { formatISODate } = useDateFormatter();
  const { t } = useTranslation("activities");

  const today = new Date();
  const tomorrow = today.setDate(today.getDate() + 1);

  const isEngagement = template.key === "engagement";
  const postUntilReasonChangeExists = !isEmpty(template?.localized_post_until_change_reasons);

  const handleChange = ({ date, reason }: PostUntilData) => {
    updatePostUntil(date);
    updatePostUntilChangeReason(reason as PostUntilChangeReason); //handleChange is used only in case reasons are defined so it means reason.text is always present at this point
  };
  const handleClose = () => setModalOpened(false);

  return (
    <>
      <Panel className={css.wrapper}>
        <div className={css.header}>
          <div>
            {isEngagement
              ? t("edit.sections.expiryDateSection.resourcingCompletedBy")
              : t("edit.sections.expiryDateSection.expiryDate")}{" "}
            *
          </div>
          {isEditMode && postUntilReasonChangeExists && (
            <ActionIcon name="edit" size="sm" onClick={() => setModalOpened(true)} />
          )}
        </div>
        {isEditMode && !!postUntil && postUntilReasonChangeExists ? (
          <ExpiryDateEditMode postUntil={postUntil} reason={postUntilChangeReason} />
        ) : (
          // @ts-ignore
          <InlineCalendar
            label={t("edit.sections.expiryDateSection.expiryDate")}
            selectedDate={postUntil ? formatISODate(postUntil) : null}
            minDate={formatISODate(tomorrow)}
            qaId={`${qaIdPrefix}-post_until`}
            error={errors.post_until}
            handleChange={updatePostUntil}
            style={isEngagement ? {} : { maxWidth: 140 }}
            required
            calendarStyle={{ width: 350 }}
          />
        )}
      </Panel>
      {modalOpened && (
        <ExpiryDateModal
          title={t("edit.sections.expiryDateSection.modal.title")}
          reasonLabel={t("edit.sections.expiryDateSection.modal.reasonLabel")}
          reason={postUntilChangeReason}
          date={formatISODate(postUntil)}
          template={template}
          onChange={handleChange}
          onClose={handleClose}
        />
      )}
    </>
  );
};
