import { ActionIcon } from "PFComponents/action_icon";
import { Typography, TypographyProps } from "PFComponents/typography";
import { IconName } from "PFTheme/graphics/icons";

import { CompletionRateBadge } from "../completion_rate_badge";
import css from "./header.module.scss";

export type HeaderProps = {
  coverage?: number;
  title: string;
  series: {
    data: { value: number; unit?: string }[];
  }[];
  headerIconName?: IconName;
  onHeaderIconClick?: VoidFunction;
  variant?: TypographyProps["variant"];
  hideCoverage?: boolean;
};

export const Header = ({
  coverage,
  hideCoverage,
  series,
  title,
  headerIconName,
  onHeaderIconClick,
  variant
}: HeaderProps) => (
  <div className={css.header}>
    <span className={css.titleWithIcon}>
      <Typography variant={variant || "h4"} clipOverflow>
        {title}
      </Typography>
      {headerIconName && onHeaderIconClick && (
        <ActionIcon name={headerIconName} size="sm" onClick={onHeaderIconClick} />
      )}
    </span>
    {!hideCoverage && <CompletionRateBadge series={series} coverage={coverage} />}
  </div>
);
