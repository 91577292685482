import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { useFeedbackFormContext } from "../../feedback_form_context_provider";
import { SkillRow } from "./skill_row";
import css from "./skills_evaluation.module.scss";

type SkillsTableProps = {
  readOnly?: boolean;
};

export const SkillsTable = ({ readOnly }: SkillsTableProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.feedback" });
  const { formData } = useFeedbackFormContext();

  return (
    <div className={css.skillsList}>
      <div className={classNames(css.skillRow, css.headerRow)}>
        <div className={css.skillCheckboxCell}>{t("headers.used")}</div>
        <div className={css.skillNameCell}>{t("headers.skill")}</div>
        <div className={css.skillRankCell}>{t("headers.rank")}</div>
      </div>
      {(formData?.skills || []).map((skill) => (
        <SkillRow readOnly={readOnly} key={skill.id || skill.globalId} skill={skill} />
      ))}
    </div>
  );
};
