import classNames from "classnames";
import BackIcon from "PFIcons/back_icon.svg";
import PropTypes from "prop-types";

import css from "./chat_subheader.module.scss";

const ChatSubheader = ({ handleClose, title, center, children }) => (
  <div className={css.root} data-qa-id="chat-subheader">
    {handleClose && (
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <a onClick={handleClose} className={css.back} data-qa-id="chat-window-button-back">
        <BackIcon width={16} height={16} />
      </a>
    )}
    {(title || children) && (
      <h3 className={classNames(css.title, { [css.center]: center })}>
        {title}
        {children}
      </h3>
    )}
  </div>
);

ChatSubheader.propTypes = {
  handleClose: PropTypes.func,
  title: PropTypes.node,
  center: PropTypes.bool,
  children: PropTypes.any
};

export default ChatSubheader;
