import { useTranslation } from "react-i18next";

type ProfilePositionHeadlineProps = {
  jobTitle?: string;
  companyName?: string;
};

const ProfilePositionHeadline = ({ jobTitle, companyName }: ProfilePositionHeadlineProps) => {
  const { t } = useTranslation("profiles");

  return (
    <>
      {!jobTitle && !companyName && t("unknown")}
      {!companyName && jobTitle}
      {jobTitle && companyName && t("jobAtCompany", { jobTitle, companyName })}
    </>
  );
};

export default ProfilePositionHeadline;
