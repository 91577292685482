import { decamelizeKeys } from "humps";
import { ReadableAvailabilityRules } from "PFApp/components/readable_availability_rules";
import Tooltip from "PFComponents/tooltip/tooltip";
import InfoIcon from "PFIcons/icon_info.svg";
import { Activity, AuditRole, AvailabilityRule } from "PFTypes";

import { useReadableAvailability } from "../../../helpers/use_readable_availability";
import css from "./role_availability.module.scss";

type RoleAvailabilityProps = {
  role: AuditRole | Activity;
};

export const RoleAvailability = ({ role }: RoleAvailabilityProps) => {
  // TODO: [SP-2590] It might be that role comes camelized. Remove decamelizeKeys when app is camelized
  const availability = decamelizeKeys(role.availability) as AvailabilityRule;

  const { readableAvailability } = useReadableAvailability({
    availability
  });

  return (
    <>
      {readableAvailability}
      {availability?.ranges && (
        <Tooltip
          content={
            <div className={css.availabilityTooltip}>
              <ReadableAvailabilityRules availability={availability} />
            </div>
          }
          maxWidth={400}
          interactive
        >
          <span>
            <InfoIcon width={20} height={20} style={{ transform: "translate(0px, 5px)" }} />
          </span>
        </Tooltip>
      )}
    </>
  );
};
