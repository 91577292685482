import classNames from "classnames";
import { Typography } from "PFComponents/typography";

import { TabVariant } from "../../tabs";
import css from "./count_tag.module.scss";

type CountTagProps = {
  count: number;
  highlight: boolean;
  variant?: TabVariant;
};

export const CountTag = ({ count, variant = TabVariant.Default, highlight = true }: CountTagProps) => (
  <Typography
    withMargin
    variant="labelButton"
    className={classNames(css.tag, css[variant], { [css.highlight]: highlight })}
  >
    {count}
  </Typography>
);
