const Cover = (props = {}) => (
  <div
    style={{
      ...props.style,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "#fff",
      opacity: 0.5,
      zIndex: 10
    }}
  />
);

export default Cover;
