import classNames from "classnames";
import { HTMLAttributes } from "react";

import css from "./pill.module.scss";

type BasicPillProps = React.PropsWithChildren<{
  className?: string;
  style?: React.CSSProperties;
}> &
  HTMLAttributes<HTMLDivElement>;

export const BasicPill = ({ children, className, style, ...props }: BasicPillProps) => (
  <div className={classNames(css.pill, className)} style={style} {...props}>
    {children}
  </div>
);
