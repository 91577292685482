import { Typography } from "PFComponents/typography";

import css from "./description.module.scss";

type DescriptionProps = {
  text: string;
};

export const Description = ({ text }: DescriptionProps) => (
  // prevents the auto select dropdown from closing when clicking on the description
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
  <div
    onMouseDown={(e) => {
      e.stopPropagation();
      e.preventDefault();
    }}
  >
    <Typography variant="bodyRegular" className={css.description}>
      {text}
    </Typography>
  </div>
);
