import { Button } from "PFComponents/button";
import { useTranslation } from "react-i18next";

import css from "./filters_button.scss";

type Props = {
  filtersAreClear?: boolean;
  onClick?: () => void;
  withLabel?: boolean;
  disabled?: boolean;
  className?: string;
  qaId?: string;
  style?: React.CSSProperties;
};

const FiltersButton = ({ filtersAreClear, onClick, withLabel, disabled, className, qaId, style }: Props) => {
  const { t } = useTranslation("bookingModule");

  return (
    <div className={css.root} style={style}>
      {withLabel && <span>{t("filters.label")}</span>}
      <Button
        className={className}
        icon={filtersAreClear ? "filter" : "filter-applied"}
        kind="secondary"
        disabled={disabled}
        onClick={onClick}
        id="filters_button"
        data-qa-id={qaId}
      />
    </div>
  );
};

export default FiltersButton;
