import { useEffect, useMemo, useRef, useState } from "react";

type AnimatedTextProps = {
  value: number;
  incrementsPerSecond?: number;
  className?: string;
};

const AnimatedText = ({ value, incrementsPerSecond = 50, className }: AnimatedTextProps) => {
  const [displayValue, setDisplayValue] = useState(0);
  const timeoutRef = useRef<NodeJS.Timeout>(null);
  const updateTimeInMs = useMemo(() => 1000 / incrementsPerSecond, [incrementsPerSecond]);

  useEffect(() => {
    animateValue();

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    animateValue();
  }, [value]);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => animateValue(), updateTimeInMs);
  }, [displayValue]);

  const isAnimating = () => value !== displayValue;

  const animateValue = () => {
    if (isAnimating()) {
      const valueChange = value > displayValue ? 1 : -1;
      setDisplayValue(displayValue + valueChange);
    }
  };

  return <span className={className}>{displayValue}</span>;
};

export default AnimatedText;
