import { Typography } from "PFComponents/typography";
import { convertToPercentage } from "PFCore/utilities/math";

type LoadAttributeProps = {
  value: number;
};

export const LoadAttribute = ({ value }: LoadAttributeProps) => (
  <Typography withMargin variant="bodyRegular" tag="span">
    {convertToPercentage(value)}
  </Typography>
);
