/* eslint-disable camelcase */
import extend from "lodash/extend";
import omit from "lodash/omit";
import { useTemplate } from "PFApp/hooks";
import { getCustomFieldNames, getVisibleProperties, propertyToTitle } from "PFCore/helpers/templates";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { Activity } from "PFTypes";

import { usePropertyRenderer } from "./use_property_renderer";

type UseMetadata = {
  activity?: Pick<Activity, "template_id" | "custom_fields" | "metadata">;
  metadataToOmit?: string[];
  valuesSeparator?: string;
};

export const useMetadata = ({ activity, metadataToOmit = [], valuesSeparator }: UseMetadata) => {
  const { customTypes } = useCustomTypes();
  const propertyToString = usePropertyRenderer();
  const template = useTemplate(activity?.template_id);

  if (!activity || !template) {
    return [];
  }

  const metadataCfValues = (() => {
    const cfNames = getCustomFieldNames(template);
    const metadataCfs = (customTypes || []).filter((cf) => cfNames.includes(cf.name));

    const cfTypeIds = metadataCfs.map(({ id }) => id);

    // opposite of getNonMetadataCfValues
    return (
      activity.custom_fields
        // Added ? to cfVal as there is a condition on the update in which type is not present
        .filter((cfVal) => cfTypeIds.includes(cfVal?.type?.id))
        .map((cfVal) => ({
          name: metadataCfs.find(({ id }) => id === cfVal?.type?.id)?.name ?? "",
          values: cfVal.values
        }))
        .reduce((memo, val) => {
          // turn array into object
          memo[val.name] = (memo[val.name] || []).concat(val.values);
          return memo;
        }, {})
    );
  })();

  const metadata = omit(extend({}, activity.metadata, metadataCfValues), metadataToOmit);

  return getVisibleProperties(template)
    .filter((prop) => metadata[prop.name] != null) // eslint-disable-line eqeqeq
    .map((prop) => {
      const ct = (customTypes || []).find(({ name }) => name === prop.name);
      const valueType = ct?.value_type ?? null;
      const text = propertyToString(template, prop.name, metadata[prop.name], valueType, valuesSeparator);

      return {
        name: prop.name,
        title: propertyToTitle(template, prop.name, customTypes),
        text: text,
        raw: metadata[prop.name],
        type: prop.type
      };
    });
};
