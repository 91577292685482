import ButtonLink from "PFComponents/button/button_link";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const AddRoleButton = ({ activityId, roleKey = "role" }) => {
  const { t } = useTranslation("activities");

  const label = roleKey === "audit_role" ? t("show.parts.addAuditRole") : t("show.parts.addRole");
  return <ButtonLink href={`/activities/${roleKey}/new/parent_activity/${activityId}`}>{label}</ButtonLink>;
};

AddRoleButton.propTypes = {
  activityId: PropTypes.number,
  roleKey: PropTypes.string
};

export default AddRoleButton;
