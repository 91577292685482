import compact from "lodash/compact";
import lowerCase from "lodash/lowerCase";
import { useExperienceLabelMap } from "PFApp/hooks/use_experience_label_map";
import { Experience } from "PFTypes";
import { useTranslation } from "react-i18next";

type UseCustomValueAriaLabel = {
  name: string;
  customTypeAriaLabel?: string;
  isRequirement?: boolean;
  experience?: Experience;
  requiredExperience?: Experience;
};

export const useCustomValueAriaLabel = ({
  name,
  customTypeAriaLabel,
  isRequirement,
  experience,
  requiredExperience
}: UseCustomValueAriaLabel) => {
  const { t } = useTranslation("core", { keyPrefix: "skillRatings" });
  const experienceLabelMap = useExperienceLabelMap();

  const clickActionText = lowerCase(t("openCustomValueDetails"));

  if (isRequirement) {
    const requirementOnlyExperienceText = experience ? experienceLabelMap[experience] : "";

    return compact([customTypeAriaLabel, name, requirementOnlyExperienceText, clickActionText]).join(", ");
  }

  const profileExperienceText = experience
    ? `${t("profile")}: ${experienceLabelMap[experience]}`
    : t("experienceMissing");
  const requiredExperienceText = requiredExperience
    ? `${t("required")}: ${experienceLabelMap[requiredExperience]}`
    : "";

  return compact([
    customTypeAriaLabel,
    name,
    profileExperienceText,
    requiredExperienceText,
    clickActionText
  ]).join(", ");
};
