import { useAppContext } from "PFApp/app_context";
import useBookingModulePermissions from "PFApp/use_booking_module_permissions";
import useIsAuditPlannerPermitted from "PFApp/use_is_audit_planner_permitted";
import useIsMarketplacePermitted from "PFApp/use_is_marketplace_permitted";
import useIsWorkflowPermitted from "PFApp/use_is_workflow_permitted";
import { useAccessTokens } from "PFApp/use_session";
import { useStoreMsTeamsLastVisitedPath } from "PFCore/helpers/ms_teams";
import { isSaasAdmin } from "PFCore/helpers/profile";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { APP_LAYOUT_ACTIONS } from "PFReducers/app_layout_reducer";
import { PermissionRule } from "PFTypes";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { startsWith } from "underscore.string";

import { useDeeplink } from "../use_deeplink";

export const WithLayout = ({ children, layout = { sidebar: "sidebar" } }) => {
  const { dispatch } = useAppContext();

  useEffect(() => {
    dispatch({
      type: APP_LAYOUT_ACTIONS.APP_LAYOUT_SET_LAYOUT,
      payload: { layout: layout.sidebar }
    });
  }, []);

  return children;
};

export const EnsureLoggedIn = ({ children }) => {
  const location = useLocation();
  const { isSignedIn } = useCurrentProfile();

  const { clearTokens } = useAccessTokens();
  useStoreMsTeamsLastVisitedPath();
  const { saveDeeplink } = useDeeplink();

  if (isSignedIn) {
    return children;
  } else {
    if (location.pathname !== "/sign_out") {
      saveDeeplink();
    }
    clearTokens();
    return <Navigate to="/users/signin" replace />;
  }
};

EnsureLoggedIn.propTypes = {
  children: PropTypes.node.isRequired
};

export const EnsureLoggedOut = ({ children }) => {
  const { isSignedIn } = useCurrentProfile();

  if (!isSignedIn) {
    return children;
  } else {
    return <Navigate to="/" replace />;
  }
};

EnsureLoggedOut.propTypes = {
  children: PropTypes.node.isRequired
};

export const EnsureRegistrationComplete = ({ children }) => {
  const location = useLocation();
  const { data: currentAccount } = useCurrentAccount();

  const { data: currentProfile } = useCurrentProfile();

  if (shouldShowTerms(currentAccount, currentProfile)) {
    return <Navigate to="/terms" replace />;
  } else if (isRegistrationComplete(currentProfile)) {
    const path = location.pathname;
    const isAdminPath = startsWith(path, "/admin") || startsWith(path, "/saas_admin");

    if (!isAdminPath && isAccountSwitched(currentProfile, currentAccount)) {
      return <Navigate to="/admin" replace />;
    }

    return children;
  } else {
    return <Navigate to="/onboarding" replace />;
  }
};

EnsureRegistrationComplete.propTypes = {
  children: PropTypes.node.isRequired
};

export const EnsureRegistrationNotComplete = ({ children }) => {
  const { data: currentProfile } = useCurrentProfile();

  if (storage.getItem("OnboardingInitiated") || !isRegistrationComplete(currentProfile)) {
    return children;
  } else {
    return <Navigate to="/" replace />;
  }
};

EnsureRegistrationNotComplete.propTypes = {
  children: PropTypes.node.isRequired
};

export const EnsureFeatureEnabled = ({ feature, redirectTo = "/", children }) => {
  const isEnabled = useIsFeatureEnabled();

  if (isEnabled(feature)) {
    return children;
  } else {
    return <Navigate to={redirectTo} replace />;
  }
};

EnsureFeatureEnabled.propTypes = {
  children: PropTypes.node.isRequired,
  redirectTo: PropTypes.string,
  feature: PropTypes.string
};

export const EnsureProfilePermission = ({ permittedTo, redirectTo = "/", children }) => {
  const { data: currentProfile } = useCurrentProfile();

  const isPermittedTo = useIsPermittedTo(currentProfile);

  if (isPermittedTo(permittedTo)) {
    return children;
  } else {
    return <Navigate to={redirectTo} replace />;
  }
};

EnsureProfilePermission.propTypes = {
  children: PropTypes.node.isRequired,
  redirectTo: PropTypes.string,
  permittedTo: PropTypes.string
};

export const EnsureBookingModulePermission = ({ redirectTo = "/", children }) => {
  const { isEnabled, isPermittedToAccess } = useBookingModulePermissions();

  if (isEnabled && isPermittedToAccess) {
    return children;
  } else {
    return <Navigate to={redirectTo} replace />;
  }
};

EnsureBookingModulePermission.propTypes = {
  children: PropTypes.node.isRequired,
  redirectTo: PropTypes.string
};

export const EnsureWorkflowPermission = ({ redirectTo = "/", children }) => {
  const isWorkflowPermitted = useIsWorkflowPermitted();

  if (isWorkflowPermitted) {
    return children;
  } else {
    return <Navigate to={redirectTo} replace />;
  }
};

EnsureWorkflowPermission.propTypes = {
  children: PropTypes.node.isRequired,
  redirectTo: PropTypes.string
};

export const EnsureAuditPlannerPermission = ({ redirectTo = "/", children }) => {
  const auditPlannerPermitted = useIsAuditPlannerPermitted();

  if (auditPlannerPermitted) {
    return children;
  } else {
    return <Navigate to={redirectTo} replace />;
  }
};

EnsureAuditPlannerPermission.propTypes = {
  children: PropTypes.node.isRequired,
  redirectTo: PropTypes.string
};

export const EnsureIsAdmin = ({ children, limitUserManager }) => {
  const { data: currentProfile } = useCurrentProfile();

  const isPermittedTo = useIsPermittedTo(currentProfile);

  if (limitUserManager && isPermittedTo(PermissionRule.UserManager)) {
    return <Navigate to="/" replace />;
  } else if (currentProfile.roles && currentProfile.roles.indexOf("admin") >= 0) {
    return children;
  } else {
    return <Navigate to="/" replace />;
  }
};

EnsureIsAdmin.propTypes = {
  children: PropTypes.node.isRequired,
  limitUserManager: PropTypes.bool
};

export const EnsureIsSaasAdmin = ({ children }) => {
  const { data: currentProfile } = useCurrentProfile();

  if (isSaasAdmin(currentProfile)) {
    return children;
  } else {
    return <Navigate to="/" replace />;
  }
};

EnsureIsSaasAdmin.propTypes = {
  children: PropTypes.node.isRequired
};

export const EnsureLoggedInAndRegistered = ({ children }) => (
  <EnsureLoggedIn>
    <EnsureRegistrationComplete>{children}</EnsureRegistrationComplete>
  </EnsureLoggedIn>
);

EnsureLoggedInAndRegistered.propTypes = {
  children: PropTypes.node.isRequired
};

export const EnsureMarketplacePermission = ({ redirectTo = "/", children }) => {
  const isMarketplacePermitted = useIsMarketplacePermitted();

  if (isMarketplacePermitted) {
    return children;
  } else {
    return <Navigate to={redirectTo} replace />;
  }
};

EnsureMarketplacePermission.propTypes = {
  children: PropTypes.node.isRequired,
  redirectTo: PropTypes.string
};

export const shouldShowTerms = (account, profile) =>
  account.customization.show_terms_and_conditions &&
  profile.registration_status === "active" &&
  !profile.agree_to_terms_and_conditions;

export const isRegistrationComplete = (profile) => profile.registration_status === "active";

export const isAccountSwitched = (profile, account) => profile.account_id !== account.id;
