import { MsTeamsAuthSaml, MsTeamsDeadEnd, MsTeamsRootPage, MsTeamsSignIn } from "PFApp/ms_teams";
import NotFoundRoute from "PFApp/routes/not_found_route";
import { WithLayout } from "PFApp/routes/route_helpers";
import { headerOnly } from "PFCore/base/page_layouts";
import { Route, Routes } from "react-router-dom";

export const MsTeamsRoutes = () => (
  <WithLayout layout={{ sidebar: headerOnly }}>
    <Routes>
      <Route path="/" element={<MsTeamsRootPage />} />
      <Route path="signin" element={<MsTeamsSignIn />} />
      <Route path="authsaml" element={<MsTeamsAuthSaml />} />
      <Route path="end" element={<MsTeamsDeadEnd />} />
      <Route path="*" element={<NotFoundRoute />} />
    </Routes>
  </WithLayout>
);
