import CompareController from "PFApp/activities/compare/compare_controller";
import ActivityEditController from "PFApp/activities/edit/activity_edit_controller";
import ActivityShowController from "PFApp/activities/show/activity_show_controller";
import NotFoundRoute from "PFApp/routes/not_found_route";
import { EnsureLoggedInAndRegistered, WithLayout } from "PFApp/routes/route_helpers";
import { Route, Routes, useLocation } from "react-router-dom";

const ActivitiesRoutes = () => {
  const location = useLocation();

  return (
    <EnsureLoggedInAndRegistered>
      <WithLayout>
        <Routes>
          <Route
            path=":templateType/new"
            element={<ActivityEditController pageType="new" key={location.key} />}
          />
          <Route
            path=":templateType/new/:id"
            element={<ActivityEditController pageType="clone" key={location.key} />}
          />
          <Route
            path=":templateType/new/:parent_activity/:parentId"
            element={<ActivityEditController pageType="new" key={location.key} />}
          />
          <Route path=":id/compare/:tab" element={<CompareController />} />
          <Route path=":id/compare/:tab/:ids" element={<CompareController />} />
          <Route path=":id/compare/:tab/:ids/:type" element={<CompareController />} />
          <Route path=":id" element={<ActivityShowController />} />
          <Route path=":id/edit" element={<ActivityEditController pageType="edit" key={location.key} />} />
          <Route path=":id/:section" element={<ActivityShowController />} />
          <Route path=":id/:section/:params" element={<ActivityShowController />} />
          <Route path=":templateType/conversation/:conversationId" element={<ActivityShowController />} />
          <Route path="*" element={<NotFoundRoute />} />
        </Routes>
      </WithLayout>
    </EnsureLoggedInAndRegistered>
  );
};

export default ActivitiesRoutes;
