import classNames from "classnames";
import { BookingPill } from "PFApp/components/pills/booking_pill";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { getContrastingTextColor } from "PFCore/utilities/color";
import { validateCategoryIcon } from "PFModules/admin/booking/categories/booking_categories.utils";
import { BookingCategory } from "PFTypes";

import css from "./activity_details_category_section.module.scss";

const SMALL_WIDTH = 60;
const LARGE_WIDTH = 120;

type ActivityCategoryProps = {
  category?: BookingCategory;
  shouldSliceText?: boolean;
  bookingPillSize?: "large" | "small";
};

export const ActivityCategory = ({
  category,
  bookingPillSize = "large",
  shouldSliceText = false
}: ActivityCategoryProps) => {
  const fillColor = category ? getContrastingTextColor(category.color, 1).hex() : undefined;
  const categoryIcon = category ? validateCategoryIcon(category.icon) : "locked";

  // TODO: [SP-2979] Refactor Workflow page
  // Use BookingPill logic to display category icon (svg fill is overwritten by a deprecated css)
  // icon is visible in activity category in case the category was changed from demand to non-demand
  return (
    <div className={css.categoryContainer}>
      {category && (
        <Typography
          variant="bodyRegular"
          className={classNames(css.categoryName, { [css.textSliced]: shouldSliceText })}
          title={category.display_as}
        >
          {category.display_as}
        </Typography>
      )}
      <BookingPill
        style={{
          borderWidth: 1,
          width: bookingPillSize === "large" ? LARGE_WIDTH : SMALL_WIDTH
        }}
        className={css.bookingPill}
        category={category}
        withCategoryIcon={false}
        center
      >
        {!!categoryIcon && <Icon name={categoryIcon} style={{ fill: fillColor }} />}
      </BookingPill>
    </div>
  );
};
