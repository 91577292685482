import { Id } from "PFTypes";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";

type UseSidePanelScroll = {
  selectedTabId?: Id;
};

type UseSidePanelScrollReturn = {
  bodyRef: RefObject<HTMLDivElement | null>;
  hasBorderShadow: boolean;
  hasScroll: boolean;
  handleScroll: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
};

const getScrollInformation = (element: HTMLDivElement | null) => {
  const clientHeight = element?.clientHeight ?? 0;
  const scrollHeight = element?.scrollHeight ?? 0;
  const scrollTop = element?.scrollTop ?? 0;

  return {
    isScrolledToBottom: scrollHeight - scrollTop <= clientHeight,
    hasScroll: scrollHeight > clientHeight
  };
};

export const useSidePanelScroll = ({ selectedTabId }: UseSidePanelScroll): UseSidePanelScrollReturn => {
  const bodyRef = useRef<HTMLDivElement>(null);

  const initialScrollState = getScrollInformation(bodyRef.current);

  const [hasBorderShadow, setHasBorderShadow] = useState(!initialScrollState.isScrolledToBottom);
  const [hasScroll, setHasScroll] = useState(initialScrollState.hasScroll);

  useEffect(() => {
    if (bodyRef.current) {
      const { isScrolledToBottom, hasScroll } = getScrollInformation(bodyRef.current);
      setHasScroll(hasScroll);
      setHasBorderShadow(!isScrolledToBottom);
    }
  }, [selectedTabId, initialScrollState.isScrolledToBottom, initialScrollState.hasScroll]);

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { isScrolledToBottom } = getScrollInformation(event.currentTarget);

    setHasBorderShadow(!isScrolledToBottom);
  }, []);

  return {
    bodyRef,
    hasBorderShadow,
    hasScroll,
    handleScroll
  };
};
