import head from "lodash/head";
import isEqual from "lodash/isEqual";
import { CustomValuesEditField } from "PFApp/components/custom_values_edit_field";
import LegacyForm from "PFApp/components/form/legacy_form";
import PositionPeriodPicker from "PFApp/profiles/edit/fields/position_period_picker";
import PROFILE_SECTIONS from "PFApp/profiles/profile_sections";
import { useGrowl } from "PFApp/use_growl";
import { InlineCalendar } from "PFComponents/calendar/inline_calendar";
import SidePanel from "PFComponents/side_panel/side_panel";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import Toggle from "PFComponents/toggle/toggle";
import { isProfileFieldPermitted } from "PFCore/helpers/profile_permissions";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { editProfile } from "PFCore/services/admin/profiles/edit_profile";
import { editCurrentProfile } from "PFCore/services/current_profile";
import { ApiRoute } from "PFCore/utilities/routes";
import { AccessLevel } from "PFTypes";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./edit_position_form.module.scss";
import { sanitizePosition } from "./positon_custom_types";

const EditPositionForm = ({
  position = {},
  profile,
  adminPage,
  handleSuccess,
  handleRemove,
  permissionsGroup,
  onClose
}) => {
  const growl = useGrowl();
  const { t } = useTranslation("profiles", { keyPrefix: "common.experience" });
  const { loadForSections, customTypes } = useCustomTypes();

  const profilePermissionsGroup = adminPage ? permissionsGroup : profile?.permissions_group;

  const { formatISODate } = useDateFormatter();
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({ ...position });

  const isMain = position.main;
  const isNew = !position.id;

  let experienceCustomTypes = loadForSections(
    isMain
      ? [PROFILE_SECTIONS.current_experience_title_1, PROFILE_SECTIONS.current_experience_title_2]
      : [
          PROFILE_SECTIONS.past_experience_title_1,
          PROFILE_SECTIONS.past_experience_title_2,
          PROFILE_SECTIONS.profile_past_experience
        ]
  );

  const prefix = isMain ? "main_position" : "other_positions";
  const targetGroupId = profile?.permissions_group?.id;

  experienceCustomTypes = experienceCustomTypes
    .filter((ct) =>
      isProfileFieldPermitted(
        profilePermissionsGroup,
        targetGroupId,
        `${prefix}/${ct.name}`,
        AccessLevel.ReadOnly
      )
    )
    .sort((customTypeA, customTypeB) => customTypeA.display_as.localeCompare(customTypeB.display_as));

  const whiteList = {
    id: true,
    main: true,
    _destroy: true
  };

  const disableDate = () =>
    !isProfileFieldPermitted(
      profilePermissionsGroup,
      profile?.permissions_group?.id,
      `${prefix}/dates`,
      AccessLevel.ReadWrite
    );

  const disableSummary = () =>
    !isProfileFieldPermitted(
      profilePermissionsGroup,
      profile?.permissions_group?.id,
      `${prefix}/summary`,
      AccessLevel.ReadWrite
    );

  const showDates = () =>
    isProfileFieldPermitted(
      profilePermissionsGroup,
      profile?.permissions_group?.id,
      `${prefix}/dates`,
      AccessLevel.ReadOnly
    );

  const showSummary = () =>
    isProfileFieldPermitted(
      profilePermissionsGroup,
      profile?.permissions_group?.id,
      `${prefix}/summary`,
      AccessLevel.ReadOnly
    );

  const showCustomType = (customType) =>
    isProfileFieldPermitted(
      profilePermissionsGroup,
      profile?.permissions_group?.id,
      `${prefix}/${customType.name}`,
      AccessLevel.ReadOnly
    );

  const disableCustomType = (customType) =>
    !isProfileFieldPermitted(
      profilePermissionsGroup,
      profile?.permissions_group?.id,
      `${prefix}/${customType.name}`,
      AccessLevel.ReadWrite
    );

  const handleSuccessFunc = () => {
    growl({ message: isNew ? t("position.created") : t("position.updated") });
    handleSuccess && handleSuccess();
  };

  const handleRemoveFunc = () => {
    const titleCustomType = head(
      loadForSections(
        position.main ? PROFILE_SECTIONS.current_experience_title_1 : PROFILE_SECTIONS.past_experience_title_1
      )
    );

    if (isNew) {
      if (!isEqual(formData, position)) {
        if (window.confirm(t("confirmCloseDialog"))) {
          handleRemove && handleRemove(formData);
        }
      } else {
        handleRemove && handleRemove(formData);
      }
    } else {
      const experienceTitle = titleCustomType && position[titleCustomType.name]?.[0]?.value;
      const message = experienceTitle
        ? t("translation:sureToDelete", { key: experienceTitle })
        : t("position.edit.sureToDeletePosition");

      if (window.confirm(message)) {
        handleRemove &&
          handleRemove({
            positions: [
              {
                _destroy: true,
                id: position.id
              }
            ]
          });
      }
    }
  };

  const handleError = (errors) => {
    setErrors(errors);
    if (errors.base) {
      growl({
        message: errors.base,
        kind: "error"
      });
    }
  };

  const handleMainPositionChange = () => {
    if (formData.main) {
      $.ajax({
        url: adminPage ? ApiRoute(`/api/admin/profiles/${profile.id}`) : ApiRoute("/api/me"),
        contentType: "application/json",
        data: JSON.stringify({ main_position_id: position.id }),
        method: "PUT",
        api_version: 2
      }).catch(() => {
        growl({
          message: t("position.edit.mainPosition.error"),
          kind: "error"
        });
      });
    }
  };

  if (!disableDate()) {
    whiteList.current = true;
    whiteList.start_date = true;
    whiteList.end_date = true;
    whiteList.duration = true;
  }

  if (!disableSummary()) {
    whiteList.summary = true;
  }

  // whitelist Custom Types
  experienceCustomTypes.forEach((ct) => {
    if (!disableCustomType(ct)) {
      whiteList[ct.name] = true;
    }
  });

  const handleSubmit = () => {
    handleMainPositionChange();

    const positionToSave = sanitizePosition(formData, whiteList, customTypes, adminPage);

    if (adminPage) {
      return editProfile(profile.id, positionToSave);
    }

    return editCurrentProfile(positionToSave);
  };

  const customTypeToComponent = (customType) => {
    const { name } = customType;
    const values = formData[name] || [];

    if (!showCustomType(customType)) {
      return;
    }

    return (
      <CustomValuesEditField
        key={name}
        position={position}
        customType={customType}
        values={values}
        qaIdPrefix="profile-position"
        errors={errors[`positions[0][${name}]`]}
        adminPage={adminPage}
        profile={profile}
        handleChange={(values) => setFormData({ ...formData, [name]: values })}
      />
    );
  };

  return (
    <SidePanel
      show
      fullHeight
      isOnTop
      size="medium"
      title={isNew ? t("position.newExperience") : t("position.editPosition")}
      onClose={onClose}
    >
      <LegacyForm
        isNew={isNew}
        isDirty={!isEqual(formData, position)}
        onSuccess={handleSuccessFunc}
        onError={handleError}
        onRemove={isMain ? null : handleRemoveFunc}
        onSubmit={handleSubmit}
        qaIdPrefix="profile-edit-past-experience"
        blockNavigationOnChange
        growlErrors
      >
        <div className={css.form}>
          {experienceCustomTypes.map(customTypeToComponent)}
          {!isMain && showDates() && (
            <PositionPeriodPicker
              label={t("position.edit.startDate.label")}
              error={
                errors["positions[0][duration]"] ||
                errors["positions[0][start_date]"] ||
                errors["positions[0][end_date]"] ||
                errors["positions[0][current]"]
              }
              showLabel
              letDuration
              duration={formData.duration || ""}
              currentPosition={formData.current}
              startDate={formData.start_date ? formatISODate(formData.start_date) : null}
              endDate={formData.end_date ? formatISODate(formData.end_date) : null}
              maxDate={formatISODate()}
              locked={disableDate()}
              lockedTip={t("position.edit.noPermissionsTip")}
              handleToggleClick={(current) =>
                setFormData((currentFormData) => ({ ...currentFormData, current }))
              }
              handleChange={(start_date, end_date, durationValue, showDuration) =>
                setFormData((currentFormData) => ({
                  ...currentFormData,
                  start_date: showDuration ? null : start_date,
                  end_date: showDuration ? null : end_date,
                  duration: (showDuration && durationValue) || null
                }))
              }
            />
          )}
          {isMain && showDates() && (
            <div data-qa-id="profile-edit-current-position-start-date">
              <InlineCalendar
                disabled={disableDate()}
                selectedDate={formData.start_date ? formatISODate(formData.start_date) : null}
                label={t("position.edit.startDate")}
                error={errors["positions[0][start_date]"]}
                handleChange={(start_date) => setFormData({ ...formData, start_date })}
                locked={disableDate()}
                lockedTip={t("position.edit.noPermissionsTip")}
              />
            </div>
          )}

          {showSummary() && (
            <InputFieldSet
              inputType="textarea"
              label={t("position.edit.summary")}
              qaId="summary"
              value={formData.summary || ""}
              errors={errors["summary"]}
              locked={disableSummary()}
              lockedTip={t("position.edit.noPermissionsTip")}
              onChange={(summary) => setFormData({ ...formData, summary })}
            />
          )}
          {!isNew && (
            <Toggle
              label={t("position.edit.mainPosition.label")}
              disabled={isMain}
              checked={isMain ? isMain : formData.main}
              inline
              tip={isMain ? t("position.edit.mainPosition.tipIs") : t("position.edit.mainPosition.tipSet")}
              onChange={(main) => setFormData({ ...formData, main })}
            />
          )}
        </div>
      </LegacyForm>
    </SidePanel>
  );
};

EditPositionForm.propTypes = {
  position: PropTypes.object.isRequired,
  permissionsGroup: PropTypes.object,
  profile: PropTypes.shape({
    id: PropTypes.number,
    permissions_group: PropTypes.object
  }),
  adminPage: PropTypes.bool,
  handleSuccess: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  onClose: PropTypes.func
};

export default EditPositionForm;
