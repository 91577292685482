import head from "lodash/head";
import CVUpload from "PFApp/profiles/common/cv_upload";
import { useGrowl } from "PFApp/use_growl";
import { Button } from "PFComponents/button";
import { gidToPath } from "PFCore/helpers/gid";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { fetchChangeRequests } from "PFCore/services/change_requests/fetch_change_requests";
import UploadFileIcon from "PFIcons/upload_file.svg";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import css from "./cv_upload.less";

const CvUploader = () => {
  const navigate = useNavigate();
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("profiles", { keyPrefix: "common.cvUpload" });
  const growl = useGrowl();

  const handleSuccess = (response) => {
    if (response && response.resource_gid) {
      const path = gidToPath(response.resource_gid);
      navigate(path);
    } else {
      fetchChangeRequests({
        profileId: currentProfile.id,
        diffOnly: true,
        applied: false,
        page: 1,
        perPage: 1
      })
        .then(({ entries }) => {
          const changeRequest = head(entries);
          navigate(`/change_requests/${changeRequest.id}`);
        })
        .catch(() => {
          growl({
            message: t("dataAlreadyImported"),
            kind: "success"
          });
          navigate("/profiles/me");
        });
    }
  };

  const handleTimeout = () => navigate("/profiles/me");

  return (
    <CVUpload
      processingMessage={<div className={css.information}>{t("processingCV")}</div>}
      handleSuccess={handleSuccess}
      handleTimeout={handleTimeout}
    />
  );
};

CvUploader.propTypes = {
  profile: PropTypes.any.isRequired
};

export const UploadCVButton = ({ className, handleClick, id }) => {
  const { t } = useTranslation("profiles", { keyPrefix: "common.cvUpload" });

  return (
    <div className={className} id={id}>
      <Button kind="primary" onClick={handleClick} qaId={"Profile.curriculumUploadButton"}>
        <UploadFileIcon width={12} height={12} /> {` ${t("uploadNewCV")}`}
      </Button>
    </div>
  );
};

UploadCVButton.propTypes = {
  className: PropTypes.string,
  handleClick: PropTypes.func,
  id: PropTypes.string
};

export default CvUploader;
