import findIndex from "lodash/findIndex";
import uniqueId from "lodash/uniqueId";
import { DropdownOption } from "PFComponents/dropdown";
import { Select } from "PFComponents/select/select";
import { CSSProperties, useMemo } from "react";
import { useTranslation } from "react-i18next";

type SortByProps = {
  className?: string;
  sort: string;
  options: DropdownOption[];
  noLabel?: boolean;
  dropdownStyle?: CSSProperties;
  disabled?: boolean;
  title?: string;
  dropdownListClassName?: string;
  onSortChange: (sort) => void;
};

const SortBy = ({
  className,
  sort,
  onSortChange,
  options,
  noLabel,
  dropdownStyle,
  disabled,
  title,
  dropdownListClassName
}: SortByProps) => {
  const { t } = useTranslation();
  const selectedIndex = findIndex(options, { id: sort });

  // Just in case there is still some places where the sort text has not been updated
  const mappedOptions = options.map((item) => {
    if (item.id === "Term similarity") {
      return {
        ...item,
        displayElement: t("mostRelevant")
      };
    } else {
      return item;
    }
  });

  const handleSortChange = (newSort) => {
    if (newSort !== sort) {
      onSortChange(newSort);
    }
  };

  const uniqId = useMemo(() => uniqueId("sortBy"), []);

  return (
    <Select
      title={title}
      className={className}
      inline
      id={uniqId}
      label={noLabel ? undefined : t("sortBy")}
      onChange={handleSortChange}
      value={sort ? mappedOptions.find(({ id }) => id === sort)?.displayElement : undefined}
      placeholder={t("chooseAnOption")}
      options={mappedOptions}
      selectedIndex={selectedIndex}
      controlledValue
      dropdownStyle={dropdownStyle}
      disabled={disabled}
      dropdownClassName={dropdownListClassName}
    />
  );
};

export default SortBy;
