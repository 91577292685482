import classNames from "classnames";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { AddActivityProfilesButton } from "PFApp/activities/show/parts/activity_profiles/add_activity_profiles_button";
import { ShareButton } from "PFApp/components/activity_share/share_button";
import ViewInBookingModuleButton from "PFApp/components/view_in_booking_module_button";
import ButtonLink from "PFComponents/button/button_link";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { useActivityNameAndId } from "PFCore/helpers/activities";

import ActivityShowMenuButton from "../../details/activity_details_menu_button";
import { useTranslatedActivityType } from "../../hooks/use_translated_activity_type";
import { Actions } from "../../parts/actions";
import { Breadcrumbs } from "./breadcrumbs";
import css from "./header.module.scss";
import { useCanEditAuditRoles } from "./use_can_edit_audit_roles";

type HeaderProps = {
  className?: string;
};

export const Header = ({ className }: HeaderProps) => {
  const {
    canSeeViewInBookingModuleButton,
    shortlistsMeta,
    suspendedShortlistsMeta,
    task: activity,
    tabActiveId,
    isAudit,
    isRole
  } = useActivityPageContext();

  const getActivityNameId = useActivityNameAndId();
  const templateName = useTranslatedActivityType();

  const { canEditAuditRole } = useCanEditAuditRoles();

  if (!activity) {
    return null;
  }

  const nameAndId = getActivityNameId(activity);

  const shortListCount = (shortlistsMeta?.total || 0) + (suspendedShortlistsMeta?.total || 0);

  const isAuditRole = isAudit && isRole;

  const isEditRegularRolesEnabled = !isAuditRole && activity.coowned;
  const isEditEnabled = isEditRegularRolesEnabled || canEditAuditRole;

  return (
    <div className={classNames(css.header, className)}>
      <div className={css.title}>
        <Breadcrumbs />
        <Typography variant="h1" className={css.roleTitle}>
          <span>{`${templateName}: ${nameAndId.name}`}</span>
        </Typography>
        {nameAndId.id && <Typography variant="bodyRegular">{`ID: ${nameAndId.id}`}</Typography>}
      </div>
      <div className={css.actions}>
        <Actions task={activity} />
        {isEditEnabled && (
          <ButtonLink rootClassName={css.editLink} href={`/activities/${activity.id}/edit`} kind="secondary">
            <Icon name="edit" size="sm" />
          </ButtonLink>
        )}
        <AddActivityProfilesButton />
        {canSeeViewInBookingModuleButton && (
          <ViewInBookingModuleButton
            openAllocateManually={tabActiveId === "shortlist" && shortListCount > 0}
            className={css.viewInBMButton}
          />
        )}
        {activity.state !== "draft" && activity.coowned && !isAudit && (
          <ShareButton activityType="role" kind="secondary" activityIds={[activity.id]} withTitle={false} />
        )}
        <ActivityShowMenuButton />
      </div>
    </div>
  );
};
