import css from "../../feedback_form.module.scss";
import { useFeedbackFormContext } from "../../feedback_form_context_provider";
import { Question } from "./question";

type QuestionsProps = {
  readOnly: boolean;
};

export const Questions = ({ readOnly }: QuestionsProps) => {
  const { formData } = useFeedbackFormContext();
  return (
    <div className={css.questionList}>
      {(formData?.questions || []).map((question) => (
        <Question key={question.id} question={question} readOnly={readOnly} />
      ))}
    </div>
  );
};
