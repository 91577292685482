import { useAppContext } from "PFApp/app_context";
import { Avatar } from "PFComponents/avatar";
import ProfileCardTitle from "PFComponents/profile_card/parts/profile_card_title";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useNavigate } from "react-router-dom";

import css from "./profile_preview_basic_info.module.scss";

const ProfilePreviewBasicInfo = () => {
  const {
    store: {
      profilePreview: { profile }
    }
  } = useAppContext();
  const { data: currentProfile } = useCurrentProfile();

  const navigate = useNavigate();

  const handleClick = () => {
    if (profile && profile.viewable) {
      navigate(`/profiles/${profile.id}`);
    }
  };

  return (
    <div className={css.root} data-qa-id="ProfilePreviewBasicInfo">
      {profile ? (
        <ProfileCardTitle
          avatarSize={90}
          currentProfile={currentProfile}
          handleClick={handleClick}
          href={profile.viewable ? `/profiles/${profile.id}` : undefined}
          onAvatarClick={handleClick}
          profile={profile}
        />
      ) : (
        <div className={css.avatar}>
          <Avatar size={90} text="?" />
        </div>
      )}
    </div>
  );
};

export default ProfilePreviewBasicInfo;
