import { Inline } from "PFComponents/containers/flex/inline";
import { Typography } from "PFComponents/typography";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

import css from "./matches_chart.module.scss";

type MatchesChartYAxisTitleProps = {
  style?: CSSProperties;
};

export const MatchesChartYAxisTitle = ({ style }: MatchesChartYAxisTitleProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.matches" });

  return (
    <Inline spread style={style}>
      <Typography withMargin variant="labelSmallRegular" className={css.scoreLevelTitle}>
        {t("chart.low")}
      </Typography>
      <Typography withMargin variant="labelSmallBold" className={css.axisTitle}>
        {t("chart.matchPercentage")}
      </Typography>
      <Typography withMargin variant="labelSmallRegular" className={css.scoreLevelTitle}>
        {t("chart.high")}
      </Typography>
    </Inline>
  );
};
