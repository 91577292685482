import { StandardRange } from "PFTypes";

import { ReadableAvailabilityRulesProps } from "../../readable_availability_rules";
import { getSortedRanges, getVisibleRangesData } from "../../readable_availability_rules.utils";
import { ReadableRangesWrapper } from "../readable_ranges_wrapper";
import { ReadableStandardRange } from "./readable_standard_range";

type ReadableStandardRuleAvailabilityProps = Required<Omit<ReadableAvailabilityRulesProps, "rangesLimit">> &
  Pick<ReadableAvailabilityRulesProps, "rangesLimit">;

export const ReadableStandardRuleAvailability = ({
  availability,
  multiline,
  rangesLimit
}: ReadableStandardRuleAvailabilityProps) => {
  const sortedRanges = getSortedRanges(availability.ranges);
  const { rangesToDisplay, isAnyRangeHidden } = getVisibleRangesData(sortedRanges, rangesLimit);

  return (
    <ReadableRangesWrapper availability={availability} showEllipsis={isAnyRangeHidden}>
      {rangesToDisplay.map((range, index) => (
        <ReadableStandardRange
          key={`${range.start}_${range.end}_${index}`}
          range={range as StandardRange}
          mode={availability.mode}
          multiline={multiline}
        />
      ))}
    </ReadableRangesWrapper>
  );
};
