import classNames from "classnames";
import moment from "moment";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import PropTypes from "prop-types";

import css from "./day_strips.module.scss";

const DayStrips = ({
  startDate,
  availabilityInfo,
  dayWidth,
  calculatedMonthsLimit,
  matchMonthsLimit = 0,
  showToday
}) => {
  const days = Math.ceil(calculatedMonthsLimit * 31) + 21; // + 3 weeks padding just in case
  const start = availabilityInfo ? availabilityInfo.start : "9999"; // every date bigger then this one
  const end = availabilityInfo ? availabilityInfo.end : "0000"; // every date smaller then this one
  const style = { width: dayWidth };
  const { formatDate, formatISODate } = useDateFormatter();

  return (
    <div className={css.root}>
      {Array.apply(null, { length: days }).map((_, i) => {
        const date = moment.utc(startDate).add(i, "days");
        const iso = formatISODate(date);
        const isWeekend = date.day() === 0 || date.day() === 6;
        const inRange = start <= iso && iso <= end;
        const formatedDate = formatDate(date);
        const isToday = formatedDate === formatDate();
        const matchMonthsLimitDate = moment.utc().add(matchMonthsLimit, "months").endOf("month");
        const offLimit = matchMonthsLimit && date > matchMonthsLimitDate;

        return (
          <div
            title={formatedDate}
            key={iso}
            style={style}
            className={classNames(css.day, {
              [css.weekend]: isWeekend,
              [css.req]: inRange,
              [css.offLimit]: offLimit,
              [css.today]: showToday && isToday
            })}
          />
        );
      })}
    </div>
  );
};

export default DayStrips;

DayStrips.propTypes = {
  startDate: PropTypes.string.isRequired,
  dayWidth: PropTypes.number.isRequired,
  calculatedMonthsLimit: PropTypes.number.isRequired,
  matchMonthsLimit: PropTypes.number,

  showToday: PropTypes.bool,
  availabilityInfo: PropTypes.shape({ start: PropTypes.string, end: PropTypes.string })
};
