import throttle from "lodash/throttle";
import { useFiltersContext } from "PFApp/components/filters/context/filters_context";
import { Button } from "PFComponents/button";
import { RefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { BorderShadow } from "./border_shadow";
import css from "./filters_footer.module.scss";

// Throttle time needs to be at least Accordion animation duration
const STICKNESS_CHECK_THROTTLE_TIME = 200;

type FiltersFooterProps = {
  scrollableContainerRef: RefObject<HTMLDivElement | null>;
};

export const FiltersFooter = ({ scrollableContainerRef }: FiltersFooterProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.sidePanel.filters" });
  const { isAutoRefresh, isEdited, applyChanges, resetTempFiltersChanges } = useFiltersContext();
  const footerRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const scrollableContainer = scrollableContainerRef.current;
    if (!scrollableContainer) {
      return;
    }

    const checkIsFooterSticky = throttle(
      () => {
        const footer = footerRef.current;
        if (!footer) {
          return;
        }

        const { bottom } = footer.getBoundingClientRect();
        setIsSticky(bottom >= window.innerHeight);
      },
      STICKNESS_CHECK_THROTTLE_TIME,
      { leading: false }
    );

    checkIsFooterSticky();

    const mutationObserver = new MutationObserver(checkIsFooterSticky);
    mutationObserver.observe(scrollableContainer, { childList: true, subtree: true });
    scrollableContainer.addEventListener("scroll", checkIsFooterSticky);

    return () => {
      scrollableContainer.removeEventListener("scroll", checkIsFooterSticky);
      mutationObserver.disconnect();
    };
  }, [scrollableContainerRef]);

  if (isAutoRefresh) {
    return null;
  }

  return (
    <div ref={footerRef} className={css.footerWrapper}>
      <BorderShadow enabled={isSticky} />
      <footer className={css.applyFooter}>
        <Button kind="secondary" text={t("cancel")} disabled={!isEdited} onClick={resetTempFiltersChanges} />
        <Button kind="primary" text={t("apply")} disabled={!isEdited} onClick={applyChanges} />
      </footer>
    </div>
  );
};
