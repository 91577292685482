import classNames from "classnames";
import { CSSProperties, PropsWithChildren } from "react";

import css from "./panel.less";

type PanelProps = PropsWithChildren & {
  style?: CSSProperties;
  qaId?: string;
  className?: string;
  id?: string;
};

const Panel = ({ children, style, className, qaId, id }: PanelProps) => (
  <div className={classNames(css.panel)} id={id}>
    <div className={classNames(css.panelBody)}>
      <div className={className} style={style} data-qa-id={qaId}>
        {children}
      </div>
    </div>
  </div>
);

export default Panel;
