import { Typography } from "PFComponents/typography";
import { PropsWithChildren } from "react";

import css from "./form_section.module.scss";

type FormSectionProps = {
  title: string;
};

export const FormSection = ({ title, children }: PropsWithChildren<FormSectionProps>) => (
  <section className={css.section} aria-label={title}>
    <Typography variant="h5">{title}</Typography>
    <div className={css.content}>{children}</div>
  </section>
);
