import find from "lodash/find";
import isNumber from "lodash/isNumber";
import orderBy from "lodash/orderBy";
import take from "lodash/take";
import { AvailabilityRange } from "PFTypes";
import { Phase } from "PFTypes/phase";

export const getVisibleRangesData = (ranges: AvailabilityRange[], rangesLimit?: number) => {
  const rangesToDisplay = isNumber(rangesLimit) ? take(ranges, rangesLimit) : ranges;
  const isAnyRangeHidden = rangesToDisplay.length < ranges.length;

  return {
    rangesToDisplay,
    isAnyRangeHidden
  };
};

export const getSortedRanges = (ranges: AvailabilityRange[]) =>
  orderBy(ranges, ({ start }) => new Date(start || "").getTime());

export const getRangePhaseText = (phases: Phase[], range: AvailabilityRange) =>
  find(phases, ["sourceId", range.phase_source_id])?.name;

export const getFormattedRangeDates = (range: AvailabilityRange, formatDate: (date: string) => string) =>
  `${formatDate(range.start || "")} - ${formatDate(range.end || "")}`;
