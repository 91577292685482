import Tooltip from "PFCore/components/tooltip/tooltip";
import { Typography } from "PFCore/components/typography/typography";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import css from "./more_periods.module.scss";
import { DAY_WIDTH, MOBILE_QUERY, MOBILE_VERTICAL_OFFSET, VERTICAL_OFFSET } from "./utils";

type MorePeriodsProps = {
  visibleLevels: number;
  count: number;
  dayNumber: number;
};

export const MorePeriods = ({ count, visibleLevels, dayNumber }: MorePeriodsProps) => {
  const { t } = useTranslation();
  const mobileMatches = useMediaQuery({ query: MOBILE_QUERY });
  const mobileOffset = mobileMatches ? MOBILE_VERTICAL_OFFSET : 0;
  return (
    <Tooltip content={<span>{t("moreBookings", { count })}</span>}>
      <div
        className={css.morePeriods}
        style={{
          top: visibleLevels * VERTICAL_OFFSET + mobileOffset,
          left: `${DAY_WIDTH * dayNumber}%`
        }}
      >
        <Typography withMargin variant="labelBold" tag="span" className={css.ellipsis}>
          ...
        </Typography>
      </div>
    </Tooltip>
  );
};
