import { BookingPill } from "PFApp/components/pills/booking_pill";
import { SuggestedChange } from "PFCore/services/bookings_suggested_changes";
import { BookingCategory } from "PFTypes";
import React from "react";

import css from "../suggested_changes.module.scss";
import { BookingPillContent } from "./booking_pill_content";

type ChangedBookingProps = {
  suggestedChange: SuggestedChange;
  category: BookingCategory;
};

export const ChangedBooking = ({ suggestedChange, category }: ChangedBookingProps) => (
  <BookingPill
    category={category}
    className={css.bookingPill}
    style={{
      borderWidth: 1
    }}
    withCategoryIcon={false}
  >
    <BookingPillContent startDate={suggestedChange.data?.startDate} endDate={suggestedChange.data?.endDate} />
  </BookingPill>
);
