import classNames from "classnames";
import { useSourceLabel } from "PFApp/hooks/use_source_label";
import { BadgesList } from "PFComponents/badges_list/badges_list";
import { Button } from "PFComponents/button";
import ButtonLink from "PFComponents/button/button_link";
import { Card } from "PFComponents/card";
import { Icon } from "PFComponents/icon";
import Pill from "PFComponents/pill/pill";
import { Typography } from "PFComponents/typography";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Certificate } from "PFTypes/certificate";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./certificate_card.module.scss";

type CertificateCardProps = {
  certificate: Certificate;
  isMe?: boolean;
  onEdit: (certificate: Certificate) => void;
};

export const CertificateCard = ({ certificate, isMe, onEdit }: CertificateCardProps) => {
  const { t } = useTranslation("profiles", { keyPrefix: "common.certificates" });
  const { formatDate } = useDateFormatter();
  const { getSourceLabel } = useSourceLabel();

  const [showImage, setShowImage] = useState(true);

  const { skills, source, issueDate, imageUrl, issuer, title, url } = certificate;

  const anySkills = skills.length > 0;

  const hasSource = !!source;
  const isEditable = !["credly", "imported"].includes(source || "") && isMe;

  return (
    <Card className={css.certificateCard}>
      <div className={css.container}>
        <div className={css.header}>
          <div className={css.text}>
            <Typography variant="h4">{formatDate(issueDate)}</Typography>
            <ButtonLink kind="text" href={url} target="_blank">
              <Icon name="open" size="sm" />
              {t("viewCertificate")}
            </ButtonLink>
          </div>
          <span className={css.imageWithEditButton}>
            {showImage && (
              <img
                alt="certificate"
                src={imageUrl || url}
                className={css.image}
                onError={() => setShowImage(false)}
              />
            )}
            {isEditable && (
              <Button
                aria-label={t("editCertificate")}
                aria-haspopup="dialog"
                aria-controls="edit-certificate"
                onClick={() => onEdit(certificate)}
                icon="edit"
                kind="secondary"
              />
            )}
          </span>
        </div>

        <div className={css.details}>
          <Typography variant="h4" title={title} clipOverflow>
            {title}
          </Typography>
          <Typography withMargin variant="bodyRegular" tag="span">
            {issuer}
          </Typography>
          {anySkills && (
            <div className={classNames(css.skills, { [css.withSource]: hasSource })}>
              <Typography withMargin variant="labelRegular" tag="span">{`${t("skillsGained")}:`}</Typography>
              <BadgesList values={skills} />
            </div>
          )}
        </div>
      </div>
      {hasSource && <Pill className={css.source}>{getSourceLabel(source)}</Pill>}
    </Card>
  );
};
