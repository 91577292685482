import { ProfileCustomValuesList } from "PFApp/components/custom_values_list";
import AddCollapseIcon from "PFComponents/icons/add_collapse_icon";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import css from "./skills_category_section.module.scss";

const SkillCategorySection = ({ name, values, expanded, profile }) => {
  const [displayed, setDisplayed] = useState(false);

  useEffect(() => {
    setDisplayed(expanded);
  }, [expanded]);

  return (
    <div className={css.root} data-qa-id="skills-category">
      <button className={css.title} onClick={() => setDisplayed(!displayed)}>
        <span className={css.text}>
          {name} ({values.length})
        </span>
        <span>
          <AddCollapseIcon size={12} add={displayed} />
        </span>
      </button>
      <div className={css.body} style={{ display: displayed ? "block" : "none" }}>
        <ProfileCustomValuesList
          customFieldName="skills"
          customValues={values}
          profileId={profile.id}
          limit={20}
        />
      </div>
    </div>
  );
};

SkillCategorySection.propTypes = {
  name: PropTypes.string,
  values: PropTypes.array,
  expanded: PropTypes.bool,
  profile: PropTypes.shape({
    id: PropTypes.number.isRequired
  })
};

export default SkillCategorySection;
