import { EngagementItem, EngagementOption } from "./engagement_item";

type Value<ENGAGEMENT extends object> = {
  id: number;
  displayElement?: React.ReactElement;
  item: ENGAGEMENT;
};

type TransformEngagements = {
  input: (selectedEngagement: EngagementOption | null) => EngagementOption[] | null;
  output: <ENGAGEMENT extends object>(engagement: ENGAGEMENT[]) => ENGAGEMENT;
  formatOption: <ENGAGEMENT extends object>(
    item: ENGAGEMENT,
    parseEngagementToOption: (engagement: ENGAGEMENT) => EngagementOption
  ) => Value<ENGAGEMENT>;
};

export const transform: TransformEngagements = {
  input: (selectedEngagement) => (selectedEngagement ? [selectedEngagement] : null),
  output: (engagement) => engagement[0],
  formatOption: (item, parseEngagementToOption) => {
    const option = parseEngagementToOption(item);
    return {
      id: option.id,
      displayElement: <EngagementItem item={option} />,
      item: item
    };
  }
};
