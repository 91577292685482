import moment from "moment";
import { isBookingWithTime } from "PFApp/booking/parts/helpers";
import { usePastDaysLimit } from "PFApp/hooks/use_past_days_limit";
import { InlineCalendar } from "PFComponents/calendar/inline_calendar";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { RequirementType } from "PFTypes";
import { RefObject, useMemo, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BookingFormCommonInputs } from "../../booking_form_common_inputs";
import { BookingFormValues, BookingType } from "../../booking_form_provider";
import { PhasesSelector } from "../../phases_selector";
import { RequirementSelector } from "../../requirement_selector";
import { BookingFormMode } from "../../use_booking_form";
import { calculateRepeatDays } from "./calculate_repeat_days";
import css from "./range_item.module.scss";
import { Repeats } from "./repeats";
import { TimeSelector } from "./time_selector";

type RangeItemProps = {
  itemIndex: number;
  monthsForwardLimit: number;
  portalRef: RefObject<HTMLDivElement | null>;
  mode: BookingFormMode;
};

export const RangeItem = ({ itemIndex, monthsForwardLimit, portalRef, mode }: RangeItemProps) => {
  const pastDaysLimit = usePastDaysLimit();
  const { formatISODate, formatISODateTime, utc } = useDateFormatter();
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<BookingFormValues>();

  const bookingType = useWatch<BookingFormValues>({ name: "bookingType" });

  const start = useWatch<BookingFormValues>({ name: `bookings.${itemIndex}.startDate` });
  const end = useWatch<BookingFormValues>({ name: `bookings.${itemIndex}.endDate` });
  const requirementType = useWatch<BookingFormValues>({ name: `bookings.${itemIndex}.requirement.type` });

  const isRepeated = bookingType === BookingType.Repeated;

  const [withTime, setWithTime] = useState(
    !!start && !!end && isBookingWithTime(utc(start), utc(String(end)))
  );

  const isTimeInDateAllowed = withTime && !isRepeated;
  const maxDate = formatISODate(moment().add(monthsForwardLimit, "months").endOf("month"));
  const isNotCreateMode = mode !== BookingFormMode.Create;
  const isOverridesNonWorkingDaysDisabled = requirementType === RequirementType.Load || isRepeated;

  const toggleWithTime = () => {
    setWithTime((prev) => !prev);
  };

  const hoursDiffBetweenStartEndWithoutTime = useMemo(() => {
    const daysCount =
      utc(formatISODate(utc(String(end)))).diff(utc(formatISODate(utc(String(start)))), "days") + 1;
    return daysCount * 24;
  }, [start, end]);

  return (
    <div className={css.wrapper}>
      <PhasesSelector
        phaseFormValueName={`bookings.${itemIndex}.phaseSourceId`}
        errorKeys={[
          `bookings.${itemIndex}.phaseSourceIdErrorAPI.submit`,
          `bookings.${itemIndex}.phaseSourceIdErrorAPI.potential-warnings`
        ]}
      />
      <div className={css.datesContainer}>
        <Controller
          name={`bookings.${itemIndex}.startDate`}
          control={control}
          render={({ field }) => (
            // @ts-ignore
            <InlineCalendar
              label={t(`${isTimeInDateAllowed ? "startDateAndTime" : "startDate"}`)}
              showTime={isTimeInDateAllowed}
              selectedDate={utc(field.value)}
              shouldCloseOnSelection
              minDate={formatISODateTime(
                utc()
                  .startOf("day")
                  .subtract(pastDaysLimit ?? 0, "days")
              )}
              handleChange={(date) => {
                field.onChange(date);
                const newEndDate = utc(String(end))?.isBefore(utc(date)) ? utc(date).endOf("day") : null;
                if (newEndDate) {
                  setValue(`bookings.${itemIndex}.endDate`, formatISODateTime(newEndDate));
                }
                setValue(
                  `bookings.${itemIndex}.repeatDays`,
                  calculateRepeatDays({ start: utc(date), end: newEndDate ?? utc(String(end)) })
                );
              }}
              clearDate={formatISODateTime(utc().startOf("day"))}
              calendarStyle={{ width: 350 }}
              portalRef={portalRef}
            />
          )}
        />
        <Controller
          name={`bookings.${itemIndex}.endDate`}
          control={control}
          render={({ field }) => (
            // @ts-ignore
            <InlineCalendar
              label={t(`${isTimeInDateAllowed ? "endDateAndTime" : "endDate"}`)}
              showTime={isTimeInDateAllowed}
              minDate={formatISODateTime(utc(String(start)))}
              maxDate={maxDate}
              selectedDate={field.value}
              shouldCloseOnSelection
              handleChange={(date) => {
                field.onChange(date);
                const newStartDate = utc(String(start))?.isAfter(utc(date)) ? utc(date).startOf("day") : null;
                if (newStartDate) {
                  setValue(`bookings.${itemIndex}.startDate`, formatISODateTime(newStartDate));
                }
                setValue(
                  `bookings.${itemIndex}.repeatDays`,
                  calculateRepeatDays({ start: newStartDate ?? utc(String(start)), end: utc(date) })
                );
              }}
              clearDate={formatISODateTime(utc().endOf("day"))}
              calendarStyle={{ width: 350 }}
              portalRef={portalRef}
            />
          )}
        />
      </div>
      {isRepeated && <TimeSelector itemIndex={itemIndex} portalRef={portalRef} />}
      {!isRepeated && (
        <RequirementSelector
          itemIndex={itemIndex}
          withTime={withTime}
          onWithTimeChange={toggleWithTime}
          startEndHoursDiff={hoursDiffBetweenStartEndWithoutTime}
        />
      )}
      {isRepeated && <Repeats itemIndex={itemIndex} />}
      {isNotCreateMode && (
        <BookingFormCommonInputs
          overridesDiaryTimeFormValueName={`bookings.${itemIndex}.overridesDiaryTime`}
          overridesNonWorkingTimeFormValueName={`bookings.${itemIndex}.overridesNonWorkingTime`}
          categoryFormValueName={`bookings.${itemIndex}.category`}
          categoryFormErrorKeys={[
            `bookings.${itemIndex}.category`,
            `bookings.${itemIndex}.categoryErrorAPI.submit`,
            `bookings.${itemIndex}.categoryErrorAPI.potential-warnings`
          ]}
          titleFormValueName={`bookings.${itemIndex}.title`}
          descriptionFormValueName={`bookings.${itemIndex}.description`}
          isOverridesNonWorkingDaysDisabled={isOverridesNonWorkingDaysDisabled}
        />
      )}
    </div>
  );
};
