import classNames from "classnames";
import { Button, ButtonKind } from "PFComponents/button";
import { Typography } from "PFCore/components/typography";
import { ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useModalContext } from "../modal_context";
import css from "./modal_layout.module.scss";

export type ModalLayoutFooterProps = {
  labelOK?: React.ReactNode;
  kindOK?: ButtonKind;
  disableOKButton?: boolean;
  showOKButton?: boolean;
  ariaLabelOK?: string;
  labelCancel?: string;
  footerLabel?: ReactNode | string;
  showCancelButton?: boolean;
  ariaLabelCancel?: string;
  secondaryButtonLabel?: string;
  showSecondaryButton?: boolean;
  onOK?: () => Promise<void> | void;
  onCancel?: VoidFunction;
  onSecondaryButtonClick?: VoidFunction;
};

export const ModalLayoutFooter = ({
  labelOK,
  kindOK,
  disableOKButton,
  showOKButton,
  ariaLabelOK,
  labelCancel,
  footerLabel,
  showCancelButton,
  ariaLabelCancel,
  secondaryButtonLabel,
  showSecondaryButton,
  onSecondaryButtonClick,
  onOK,
  onCancel
}: ModalLayoutFooterProps) => {
  const { t } = useTranslation();
  const { closeWithAnimation } = useModalContext();
  const okButtonRef = useRef<HTMLButtonElement>(null);

  if (!showCancelButton && !footerLabel && !showOKButton && !showSecondaryButton) {
    return null;
  }

  const handleCancel = () => {
    onCancel && onCancel();
    closeWithAnimation();
  };

  const handleOK = () => {
    const handleOkResult = onOK && onOK();

    // if callback returns promise, close when promise is fulfilled
    if (handleOkResult && handleOkResult.then !== undefined) {
      handleOkResult
        .then(() => closeWithAnimation(false))
        .catch(() => {
          okButtonRef.current?.focus();
        });
    } else {
      closeWithAnimation(false);
    }
  };

  return (
    <footer
      className={classNames(css.footer, css.buttons, {
        [css.noCancelButton]: !showCancelButton
      })}
    >
      {showCancelButton && (
        <Button onClick={handleCancel} kind="secondary" aria-label={ariaLabelCancel}>
          {labelCancel || t("cancel")}
        </Button>
      )}
      <div className={css.footerWithLabel}>
        {footerLabel && (
          <Typography withMargin variant="labelRegular" tag="span">
            {footerLabel}
          </Typography>
        )}
        {showSecondaryButton && (
          <Button onClick={onSecondaryButtonClick} kind="secondary">
            {secondaryButtonLabel}
          </Button>
        )}
        {showOKButton && (
          <Button
            ref={okButtonRef}
            disabled={!onOK || disableOKButton}
            onClick={handleOK}
            kind={kindOK}
            aria-label={ariaLabelOK}
          >
            {labelOK || t("accept")}
          </Button>
        )}
      </div>
    </footer>
  );
};
