import classNames from "classnames";
import { Button } from "PFComponents/button";
import { Typography } from "PFComponents/typography";
import CheckIcon from "PFIcons/check.svg";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import css from "./share_bar.module.scss";
import { ShareButton } from "./share_button";

export const ShareWrapper = ({ activitiesToShare, onShare, activityId, disabled, children }) => {
  const shareMode = !!activitiesToShare?.length;
  const addedToShare = activitiesToShare.includes(activityId);

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  return (
    <div
      className={classNames(css.shareWrapper, {
        [css.shareMode]: shareMode,
        [css.sharing]: addedToShare,
        [css.disabled]: disabled
      })}
      onClick={shareMode ? () => onShare(activityId) : null}
    >
      {addedToShare && <CheckIcon className={css.addedToShareIcon} />}
      <div className={classNames(css.content, { [css.shareMode]: shareMode, [css.sharing]: addedToShare })}>
        {children}
      </div>
    </div>
  );
};

ShareWrapper.propTypes = {
  activitiesToShare: PropTypes.array.isRequired,
  onShare: PropTypes.func.isRequired,
  activityId: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.element.isRequired
};

const ShareBar = ({ activityIds, onCancel, activityType, style }) => {
  const { t } = useTranslation("core");
  const show = activityIds.length;

  return (
    <div className={classNames(css.root, { [css.hide]: !show })} style={style}>
      <h3 className={css.title}>
        {t("activityShare.barHeader", { activityType: activityType || "activity" })}
      </h3>
      <span className={css.roleSelected}>
        <span>
          {t("activityShare.barSelectActivities", { activityType: activityType || "activities" })}:&nbsp;
        </span>
        <Typography withMargin variant="bodyBold" tag="span" className={css.rolesCountText}>
          {activityType
            ? t("activityShare.shareActivityTemplates", {
                count: activityIds.length,
                templateKey: activityType
              })
            : t("activityShare.shareActivities", { count: activityIds.length })}
        </Typography>
        <div className={css.shareActions}>
          <Button kind="secondary" onClick={onCancel}>
            {t("translation:cancel")}
          </Button>
          <ShareButton
            activityType={activityType}
            activityIds={activityIds}
            onSuccess={() => {
              onCancel && onCancel();
            }}
          />
        </div>
      </span>
    </div>
  );
};

ShareBar.propTypes = {
  activityIds: PropTypes.array.isRequired,
  onCancel: PropTypes.func,
  activityType: PropTypes.string,
  style: PropTypes.object
};

export default ShareBar;
