import "normalize.css";
import "./styles/application.scss";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AppContext from "PFApp/app_context";
import { SuspenseWrapper } from "PFApp/components/suspense_wrapper";
import { CoreContextProvider } from "PFApp/core_context_provider";
import Routes from "PFApp/routes";
import { useBootstrapper } from "PFApp/use_bootstrapper";
import { ErrorBoundary } from "PFComponents/error_boundary";
import initialState from "PFCore/initial_state/index";
import { mainDispatch } from "PFCore/reducers/main_dispatch";
import { mainReducer } from "PFCore/reducers/main_reducer";
import { ONE_DAY_MS } from "PFCore/utilities/time_consts";
import React, { useCallback, useReducer } from "react";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";

import ReactAppLayout from "./react_app_layout";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: ONE_DAY_MS,
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  }
});

const ReactApp = () => {
  const [store, reducerDispatch] = useReducer(mainReducer, initialState());
  const dispatch = useCallback(mainDispatch(reducerDispatch, store), []);

  // we need to use createBrowserRouter to be able to use useBlocker
  // https://stackoverflow.com/questions/75135147/react-router-dom-v6-useblocker
  const router = createBrowserRouter(createRoutesFromElements(<Route path="*" element={<Bootstrapper />} />));

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider value={{ store, dispatch }}>
        <CoreContextProvider>
          <RouterProvider router={router} />
        </CoreContextProvider>
      </AppContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

const Bootstrapper = () => {
  const { initialized } = useBootstrapper();

  if (!initialized) {
    return;
  }

  return (
    <ReactAppLayout>
      <ErrorBoundary>
        <SuspenseWrapper>
          <Routes />
        </SuspenseWrapper>
      </ErrorBoundary>
    </ReactAppLayout>
  );
};

export default ReactApp;
