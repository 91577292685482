import identity from "lodash/identity";
import uniqueId from "lodash/uniqueId";
import { Typography } from "PFComponents/typography";
import { ReactNode, useMemo } from "react";

import { SliderContextProvider, SliderContextProviderProps } from "./context/slider_context";
import { MultiRangeSlider, MultiRangeSliderProps } from "./multirange_slider";
import { SingleRangeSlider, SingleRangeSliderProps } from "./single_range_slider";
import { SliderAttributes } from "./sliders.common";
import css from "./sliders.module.scss";

type SliderConditionalProps =
  | ({
      multiRange: true;
    } & MultiRangeSliderProps)
  | ({
      multiRange?: false;
    } & SingleRangeSliderProps);

type SliderProps = Partial<Omit<SliderAttributes, "multiRange">> & {
  title: string;
  actions?: ReactNode;
  tip?: string;
} & SliderConditionalProps;

export const Slider = ({
  multiRange = false,
  min = 0,
  max = 100,
  step = 1,
  showSteps = true,
  showMinMax = true,
  formatMinMax = identity,
  title,
  actions,
  tip,
  small = false,
  ...rest
}: SliderProps) => {
  const sliderId = useMemo(() => uniqueId("slider"), []);
  const props: SliderContextProviderProps = {
    min,
    max,
    step,
    title,
    showSteps,
    showMinMax,
    multiRange,
    formatMinMax,
    sliderId,
    small,
    ...rest
  };

  return (
    <SliderContextProvider {...props}>
      <div className={css.root}>
        <span className={css.header}>
          <Typography withMargin variant="labelRegular" htmlFor={sliderId}>
            {title}
          </Typography>
          {actions}
        </span>
        {multiRange ? <MultiRangeSlider /> : <SingleRangeSlider />}
        {tip && (
          <Typography withMargin variant="labelRegular" htmlFor={sliderId} className={css.tip}>
            {tip}
          </Typography>
        )}
      </div>
    </SliderContextProvider>
  );
};
