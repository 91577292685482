import classNames from "classnames";
import { CSSProperties, PropsWithChildren } from "react";

import css from "./typography.module.scss";
import { VariantBody, VariantHeader, VariantLabel } from "./typography.types";
import { VARIANTS } from "./typography.utils";

type CommonProps = {
  id?: string;
  className?: string;
  withMargin?: boolean;
  qaId?: string;
  clipOverflow?: boolean;
  wrapText?: boolean;
  title?: string;
  style?: CSSProperties;
};

type ConditionalVariantBody =
  | {
      variant: VariantBody;
      tag?: "a";
      href?: string;
      htmlFor?: string;
    }
  | {
      variant: VariantBody;
      tag?: "span" | "p" | "div";
      href?: never;
      htmlFor?: string;
    };

type ConditionalVariantLabel =
  | {
      variant: VariantLabel;
      tag?: "a";
      href?: string;
      htmlFor?: string;
    }
  | {
      variant: VariantLabel;
      tag?: "span" | "p" | "label";
      href?: never;
      htmlFor?: string;
    };

type ConditionalProps =
  | ConditionalVariantBody
  | ConditionalVariantLabel
  | {
      variant: VariantHeader;
      tag?: never;
      href?: never;
      htmlFor?: never;
    };

export type TypographyProps = CommonProps & ConditionalProps;

export const Typography = ({
  id,
  variant,
  tag,
  href,
  htmlFor,
  className,
  withMargin = false,
  qaId,
  clipOverflow = false,
  wrapText = false,
  title,
  style,
  children
}: PropsWithChildren<TypographyProps>) => {
  const { variantTag, className: variantClassName } = VARIANTS[variant];
  const Element = tag ?? variantTag;

  return (
    <Element
      id={id}
      className={classNames(
        variantClassName,
        { [css.noMargin]: !withMargin, [css.clipOverflow]: clipOverflow, [css.wrap]: wrapText },
        className
      )}
      href={href}
      htmlFor={htmlFor}
      data-qa-id={qaId}
      title={title}
      style={style}
    >
      {children}
    </Element>
  );
};
