import classNames from "classnames";
import { ProfileItem } from "PFApp/components/profile_item";
import { MultiToggle } from "PFComponents/multi_toggle";
import AutoSelect from "PFComponents/select/autoselect";
import { _BasicProfile, Collection } from "PFTypes";
import { CSSProperties, RefObject } from "react";
import { useTranslation } from "react-i18next";

import { DisplayOption } from "./display_option";
import css from "./matches_named_resources_selector.module.scss";
import { OptionsTableHeader } from "./options_table_header";

export type Option = _BasicProfile & {
  grade?: string;
  scores?: { normalizedScore: number | null; availabilityScore: number | null };
};

export enum ProfilesSelector {
  Matches = "matches",
  NamedResource = "named-resource"
}

export type CostField = {
  label: string;
  getValue: (option: Option) => string | undefined;
};

type MatchesNamedResourcesSelectorProps = {
  profilesSelector: ProfilesSelector | null;
  onProfilesSelectorChange: (value: ProfilesSelector) => void;
  onNamedResourcesChange: (options: Option[]) => void;
  onMatchesChange: (options: Option[]) => void;
  fetchNamedResourcesOptions: (term?: string) => Promise<Collection<Option[]>>;
  fetchMatchesOptions: (term?: string) => Promise<Collection<Option[]>>;
  matchesDisabled?: boolean;
  namedResourceDisabled?: boolean;
  matchesNotReady?: boolean;
  classes?: { root?: string; select?: string; namedResourceDropdown?: string };
  styles?: { root?: CSSProperties };
  costField?: CostField;
  portalRef?: RefObject<HTMLDivElement | null>;
  displayNamedResourceJobTitle?: boolean;
};

export const MatchesNamedResourcesSelector = ({
  profilesSelector,
  onProfilesSelectorChange,
  onNamedResourcesChange,
  onMatchesChange,
  fetchNamedResourcesOptions,
  fetchMatchesOptions,
  matchesDisabled,
  namedResourceDisabled,
  matchesNotReady,
  classes = {},
  styles = {},
  costField,
  portalRef,
  displayNamedResourceJobTitle
}: MatchesNamedResourcesSelectorProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(css.root, classes.root)} style={styles.root}>
      {profilesSelector === ProfilesSelector.NamedResource && (
        <AutoSelect
          portalRef={portalRef}
          rootClassName={classes.select}
          dropDownClassName={classes.namedResourceDropdown}
          query={fetchNamedResourcesOptions}
          formatOption={(option) => ({
            ...option,
            displayElement: <ProfileItem profile={option} displayJobTitle={displayNamedResourceJobTitle} />,
            item: option
          })}
          values={[]}
          disabled={namedResourceDisabled}
          handleChange={onNamedResourcesChange}
          closeOnChange
          fitDropdownContent
          cache={false}
        />
      )}
      {profilesSelector === ProfilesSelector.Matches && (
        <AutoSelect
          portalRef={portalRef}
          rootClassName={classes.select}
          query={fetchMatchesOptions}
          formatOption={(option) => ({
            ...option,
            displayElement: <DisplayOption option={option} costField={costField} />,
            item: option
          })}
          preOptions={(options) => options.length > 0 && <OptionsTableHeader costField={costField} />}
          noOptionsText={t("profilesSelector.noMatches")}
          values={[]}
          disabled={matchesDisabled}
          handleChange={onMatchesChange}
          closeOnChange
          cache={false}
          fitDropdownContent
        />
      )}
      <MultiToggle<ProfilesSelector>
        options={[
          {
            id: ProfilesSelector.Matches,
            value: t("profilesSelector.matches"),
            disabled: matchesDisabled,
            tooltipContent: matchesNotReady && t("profilesSelector.matchesNotReady")
          },
          {
            id: ProfilesSelector.NamedResource,
            value: t("profilesSelector.namedResource"),
            disabled: namedResourceDisabled
          }
        ]}
        controlledValue={profilesSelector}
        onChange={onProfilesSelectorChange}
        fluid
      />
    </div>
  );
};
