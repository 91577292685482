import classNames from "classnames";
import ToggleableText from "PFComponents/toggleable_text/toggleable_text";
import { Typography } from "PFComponents/typography";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import css from "./activity_details_common.module.scss";

const ActivityDetailsToggleableDesc = ({ description }) => {
  const { t } = useTranslation("activities");

  return (
    <div className={classNames(css.infoItem, css.itemContainer)} data-qa-id="ActivityDetailsToggleableDesc">
      <Typography variant="h5">{t("show.details.description")}</Typography>
      <ToggleableText text={description} />
    </div>
  );
};

ActivityDetailsToggleableDesc.propTypes = {
  description: PropTypes.string
};

export default ActivityDetailsToggleableDesc;
