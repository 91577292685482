import { RadioItem } from "PFComponents/radios";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { BookingQuestions, BookingSelectOption } from "./booking_questions";
import css from "./booking_questions.module.scss";

type SpecificTimeBookingsQuestionsProps = {
  value: BookingSelectOption;
  onChange: (selectedOption: BookingSelectOption) => void;
  isEditMode?: boolean;
  label: string;
};

export const SpecificTimeBookingsQuestions = ({
  value,
  onChange,
  isEditMode,
  label
}: SpecificTimeBookingsQuestionsProps) => {
  const { t } = useTranslation("bookingModule");
  const DELETE_OPTIONS: RadioItem<BookingSelectOption>[] = useMemo(
    () => [
      { id: BookingSelectOption.This, label: t("bookings.delete.options.this") },
      { id: BookingSelectOption.Future, label: t("bookings.delete.options.future") },
      { id: BookingSelectOption.All, label: t("bookings.delete.options.all") }
    ],
    []
  );

  const UPDATE_OPTIONS: RadioItem<BookingSelectOption>[] = useMemo(
    () => [
      { id: BookingSelectOption.Future, label: t("bookings.delete.options.future") },
      { id: BookingSelectOption.All, label: t("bookings.delete.options.all") }
    ],
    []
  );

  return (
    <BookingQuestions
      name="timeBookingQuestions"
      text={label}
      className={css.specificTimeOptions}
      value={value}
      items={isEditMode ? UPDATE_OPTIONS : DELETE_OPTIONS}
      onChange={onChange}
    />
  );
};
