import orderBy from "lodash/orderBy";
import some from "lodash/some";
import { useGrowl } from "PFApp/use_growl";
import { Alert } from "PFComponents/alert";
import { Icon } from "PFComponents/icon";
import TooltipIcon from "PFComponents/tooltip_icon/tooltip_icon";
import { Typography } from "PFComponents/typography";
import { useActivityEconomicsScenarioReset } from "PFCore/hooks/queries/activity/use_activity_economics_scenario_reset";
import { EconomicsVacancy, Id } from "PFTypes";
import { useTranslation } from "react-i18next";

import { ClosedVacanciesTable } from "../vacancies_table";
import css from "./reset_vacancies_row.module.scss";

type ResetVacanciesRowProps = {
  vacancies: EconomicsVacancy[];
  scenarioId: Id;
  isEditable: boolean;
};

export const ResetVacanciesRow = ({ vacancies, scenarioId, isEditable }: ResetVacanciesRowProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario" });
  const growl = useGrowl();

  const { mutateAsync: resetScenario } = useActivityEconomicsScenarioReset(scenarioId);

  const closedVacancies = orderBy(
    vacancies.filter(({ opened }) => !opened),
    [(vacancy) => !!vacancy.profile, (vacancy) => vacancy.closureReason === "filled_externally"],
    ["desc", "desc"]
  );
  const filledVacancies = vacancies.filter(({ profile }) => profile);
  const assigneesIds = vacancies.map(({ assignment }) => assignment?.profile?.id).filter(Boolean);
  const isResetButtonVisible = some(filledVacancies, ({ profile }) => !assigneesIds.includes(profile!.id));

  const onResetVacancies = () => {
    const { id: activityId, name: roleName } = vacancies[0].activity;

    resetScenario({ scenarioId, activityId }).then(() => {
      growl({
        message: t("roleVacanciesHaveBeenReset", { roleName })
      });
    });
  };

  return (
    <Alert
      kind="general"
      small
      hideIcon
      title={
        <>
          <TooltipIcon
            content={
              <div className={css.tooltipTableWrapper}>
                <ClosedVacanciesTable vacancies={closedVacancies} />
              </div>
            }
            IconComponent={(props) => <Icon name="info" {...props} />}
            height={20}
            width={20}
            tooltipProps={{
              interactive: true,
              maxWidth: 500
            }}
          />
          <Typography variant="bodyRegular">
            {t("vacanciesClosed", { closed: closedVacancies.length, all: vacancies.length })}
          </Typography>
        </>
      }
      action={
        isResetButtonVisible
          ? { text: t("resetVacancies"), kind: "text", onClick: onResetVacancies, disabled: !isEditable }
          : undefined
      }
      className={css.alert}
    />
  );
};
