import classNames from "classnames";
import { Button } from "PFComponents/button";
import { Typography } from "PFComponents/typography";
import { IconName } from "PFTheme/graphics/icons";
import NoProfilesIllustration from "PFTheme/graphics/illustrations/illustration-no-profiles.svg";
import { ReactElement } from "react";

import css from "./empty_state.module.scss";

type EmptyStateProps = {
  illustration?: ReactElement<any>;
  title?: string;
  message?: string;
  buttonIcon?: IconName;
  buttonLabel?: string;
  className?: string;
  onButtonClick?: VoidFunction;
};

export const EmptyState = ({
  illustration,
  title,
  message,
  buttonLabel,
  className,
  onButtonClick
}: EmptyStateProps) => (
  <div className={classNames(css.emptyState, className)}>
    {illustration || <NoProfilesIllustration />}
    {title && (
      <Typography variant="h1" className={css.title}>
        {title}
      </Typography>
    )}
    {message && <Typography variant="h4">{message}</Typography>}
    {onButtonClick && <Button onClick={onButtonClick}>{buttonLabel}</Button>}
  </div>
);
