import { useGrowl } from "PFApp//use_growl";
import { Button, ButtonKind } from "PFComponents/button";
import { getProfileName } from "PFCore/helpers/profile";
import { profileHash } from "PFCore/helpers/profile_hash";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { useShortlistFill } from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import { Shortlist } from "PFTypes";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

type FillButtonProps = {
  disabled?: boolean;
  item: Shortlist;
  onSuccess?: VoidFunction;
  qaPrefix?: string;
  style?: any;
  isUnbiased?: boolean;
  kind: ButtonKind;
};

export const FillButton = ({
  disabled,
  style,
  onSuccess,
  qaPrefix = "",
  item,
  isUnbiased,
  kind
}: FillButtonProps) => {
  const { t } = useTranslation("activities");
  const { mutate: fillShortlist, isUpdating } = useShortlistFill();
  const { invalidateVacancies } = useActivityInvalidate();

  const growl = useGrowl();

  const handleClick = useCallback(() => {
    fillShortlist(
      { id: item.id },
      {
        onSuccess: () => {
          onSuccess?.();
          invalidateVacancies(item.activity_id);
          const name = isUnbiased
            ? t("parts.candidateHash", { profileHash: profileHash(item.profile.id) })
            : getProfileName(item.profile);
          growl({
            kind: "success",
            message: t("parts.roleFilledNotification", { name: name })
          });
        },
        onError: () => {
          growl({
            kind: "error",
            message: t("parts.roleNotFilledError")
          });
        }
      }
    );
  }, [
    fillShortlist,
    item.activity_id,
    item.id,
    item.profile,
    invalidateVacancies,
    onSuccess,
    isUnbiased,
    t,
    growl
  ]);

  return (
    <Button
      text={t("parts.fillRole")}
      kind={kind || "primary"}
      style={style}
      disabled={disabled || isUpdating > 0}
      qaId={`${qaPrefix}-fill`}
      onClick={handleClick}
    />
  );
};
