import classNames from "classnames";
import { ActionIcon } from "PFComponents/action_icon";
import { Typography } from "PFComponents/typography";
import { SyntheticEvent } from "react";

import Pill from "../../pill/pill";
import css from "./double_section_pill.module.scss";

type PillKind = "primary" | "secondary" | "selected" | "faded";

export type DoubleSectionPillProps = {
  leftContent: React.ReactElement | string;
  rightContent: React.ReactElement | string;
  onDelete?: (event: SyntheticEvent) => void;
  kind?: PillKind;
  deleteTitle?: string;
  small?: boolean;
};

export const DoubleSectionPill = ({
  leftContent,
  rightContent,
  onDelete,
  kind = "primary",
  small,
  deleteTitle
}: DoubleSectionPillProps) => (
  <span className={classNames(css.wrapper, css[kind])}>
    <Pill small={small} className={classNames(css.leftSection, css.section)}>
      {onDelete && <ActionIcon name="cross" size="xs" onClick={onDelete} title={deleteTitle} />}
      <Typography variant="bodyButtons" tag="span" clipOverflow>
        {leftContent}
      </Typography>
    </Pill>
    <Pill small={small} className={classNames(css.rightSection, css.section)}>
      <Typography variant="bodyRegular" tag="span">
        {rightContent}
      </Typography>
    </Pill>
  </span>
);
