import camelCase from "lodash/camelCase";
import { Select } from "PFComponents/select/select";
import { FrequencyData } from "PFCore/helpers/use_notifications_settings";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type NotificationsSectionSelectProps = {
  name: string;
  options: FrequencyData[];
  defaultValue: string;
  onChange: any;
};

const NotificationsSectionSelect = ({
  name,
  options,
  defaultValue,
  onChange
}: NotificationsSectionSelectProps) => {
  const { t } = useTranslation("profiles", { keyPrefix: "edit.sections" });

  const dropdownOptions = useMemo(
    () =>
      options.map((option) => ({
        id: option.value,
        displayElement: option.label,
        item: option.value
      })),
    [options]
  );
  const selectedIndex = dropdownOptions.findIndex(({ id }) => id === defaultValue);

  const convertedName = camelCase(name);
  return (
    <div style={{ marginTop: 30 }}>
      <Select
        qaId={`select-componet-${convertedName}`}
        label={t(`notifications.settings.${convertedName}` as unknown as TemplateStringsArray)}
        tip={t(`notifications.settingsHints.${convertedName}` as unknown as TemplateStringsArray)}
        style={{ width: "100%" }}
        value={dropdownOptions.find(({ id }) => id === defaultValue)?.displayElement}
        selectedIndex={selectedIndex}
        controlledValue
        readOnly
        options={dropdownOptions}
        onChange={onChange}
      />
    </div>
  );
};

export default NotificationsSectionSelect;
