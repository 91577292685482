import { Profile } from "PFTypes";
import { useLayoutEffect } from "react";

import { Interest } from "../../query/api.types";
import InterestItem from "./interest_item";
import css from "./interests.module.scss";

type InterestsProps = {
  interests: Interest[];
  profile: Profile;
  updateHighlight: (value: boolean) => void;
};

const Interests = ({ interests, profile, updateHighlight }: InterestsProps) => {
  useLayoutEffect(
    () => updateHighlight(true),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [interests]
  );
  return (
    <div data-scroll-key={"interested_in"} className={css.root}>
      {interests.map((interest) => (
        <InterestItem key={interest.id} interest={interest} profile={profile} />
      ))}
    </div>
  );
};

export default Interests;
