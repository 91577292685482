import { ColumnHelper } from "@tanstack/react-table";
import { LoadingDots } from "PFComponents/loading_dots";
import Table from "PFComponents/table";
import { Typography } from "PFComponents/typography";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { useInfiniteScroll } from "PFCore/hooks/use_infinite_scroll";
import React from "react";
import { useTranslation } from "react-i18next";

import { CustomColumn } from "../columns/custom_column";
import { ScoreColumn } from "../columns/score_column";
import { WorkforceMemberColumn } from "../columns/workforce_member_column";
import { useReassignCustomInfo } from "../hooks/use_reassign_custom_info";
import { MatchesResponse, MatchesTableEntry } from "../hooks/use_reassign_matches";
import css from "./matches_content.module.scss";
import { RowSelection } from "./reassign_content";

type MatchesTableProps = {
  matchesResponse: MatchesResponse;
} & RowSelection;

export const MatchesTable = ({
  onTargetProfileChange,
  rowSelectionState,
  matchesResponse
}: MatchesTableProps) => {
  const { t } = useTranslation("bookingModule");
  const { data: currentProfile } = useCurrentProfile();

  const { reassignCustomInfo } = useReassignCustomInfo({ profile: currentProfile });

  const { matchesData, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } = matchesResponse;

  const lastRowRef = useInfiniteScroll<HTMLTableRowElement>({
    fetchMore: fetchNextPage,
    loading: isFetching,
    hasMore: hasNextPage
  });

  const columnsGenerator = (columnHelper: ColumnHelper<MatchesTableEntry>) => [
    columnHelper.accessor((row) => row, {
      id: "workforceMember",
      header: () => (
        <Typography withMargin variant="labelRegular" className={css.header}>
          {t("bookings.reassign.sidePanel.columns.workforceMember")}
        </Typography>
      ),
      cell: (info) => <WorkforceMemberColumn profile={info.getValue().profile} />,
      enableSorting: false,
      size: 150
    }),
    columnHelper.accessor((row) => row, {
      id: "customColumn",
      header: () => (
        <Typography withMargin variant="labelRegular" className={css.header}>
          {reassignCustomInfo?.customType.display_as}
        </Typography>
      ),
      cell: (info) => <CustomColumn profile={info.getValue().profile} />,
      enableSorting: false
    }),
    columnHelper.accessor((row) => row, {
      id: "match",
      header: () => (
        <Typography withMargin variant="labelRegular" className={css.header}>
          {t("bookings.reassign.sidePanel.columns.match")}
        </Typography>
      ),
      cell: (info) => <ScoreColumn value={info.getValue().matchScore} />,
      enableSorting: false,
      size: 40
    }),
    columnHelper.accessor((row) => row, {
      id: "availability",
      header: () => (
        <Typography withMargin variant="labelRegular" className={css.header}>
          {t("bookings.reassign.sidePanel.columns.availability")}
        </Typography>
      ),
      cell: (info) => <ScoreColumn value={info.getValue().availabilityScore} />,
      enableSorting: false,
      size: 40
    })
  ];

  if (isFetching && !isFetchingNextPage) {
    return <LoadingDots circlesEnabled circleSize="sm" centered />;
  }

  return (
    <>
      <Table<MatchesTableEntry>
        tableData={matchesData}
        generateColumns={columnsGenerator}
        enableRowSelection
        onRowSelectionChange={onTargetProfileChange}
        rowSelection={rowSelectionState}
        disableAutoResizing
        lastRowRef={lastRowRef}
        getRowId={(entry) => `${entry.profile.id}`}
      />
      {isFetchingNextPage && <LoadingDots circlesEnabled circleSize="xs" centered />}
    </>
  );
};
