import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Collection, Id } from "PFTypes";
import { Phase } from "PFTypes/phase";
import { QueryOptions } from "PFTypes/request_options";

import { fetchPhases } from "../../../services/phases";
import { phasesKeys } from "./query_keys";

export type PhasesResponseType = Collection<Phase[]>;

export const usePhases = (activityId?: Id | null, options?: QueryOptions<PhasesResponseType>) =>
  useQuery<PhasesResponseType>({
    queryKey: phasesKeys.byActivity(activityId),
    queryFn: () => fetchPhases(activityId),
    ...options
  });

export const usePhasesInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidate: (activityId?: number) => {
      if (!!activityId || activityId === 0) {
        return queryClient.invalidateQueries({ queryKey: phasesKeys.byActivity(activityId) });
      }
      return queryClient.invalidateQueries({ queryKey: phasesKeys.all() });
    }
  };
};
