import classNames from "classnames";
import { InputFieldSet } from "PFCore/components/text/input_field_set";
import { Typography } from "PFCore/components/typography";
import { PropsWithChildren, useRef } from "react";

import { useSliderContext } from "../../context/slider_context";
import { useAriaLabels } from "../../hooks/use_aria_labels";
import { MultiRangeValue } from "../../multirange_slider";
import css from "./side_tools.module.scss";
import { useSideTools } from "./use_side_tools";

export type SideToolsProps<Value> = {
  localSliderValue: Value;
  onChange: (newValue: Value) => void;
};

export const SideTools = <Value extends number | MultiRangeValue>({
  localSliderValue,
  onChange,
  children
}: PropsWithChildren<SideToolsProps<Value>>) => {
  const { min, max, unit, disabled, showMinMax, multiRange, sliderId, small, formatMinMax } =
    useSliderContext();
  const minInputRef = useRef<HTMLInputElement>(null);
  const maxInputRef = useRef<HTMLInputElement>(null);

  const {
    rangeMinValue,
    rangeMaxValue,
    localInputsValue,
    handleValueApply,
    handleInputChange,
    handleKeyDown
  } = useSideTools<Value>({ minInputRef, maxInputRef, localSliderValue, onChange });

  const { singleAriaLabel, minAriaLabel, maxAriaLabel } = useAriaLabels();

  return (
    <div className={css.horizontalLayout}>
      <InputFieldSet
        ref={minInputRef}
        inputType="number"
        min={min}
        max={rangeMaxValue ?? max}
        defaultValue={(localInputsValue as MultiRangeValue).min ?? localInputsValue}
        disabled={disabled}
        onChange={(newLocalValue) => handleInputChange(newLocalValue, "min")}
        onBlur={(event) => handleValueApply(event.target.valueAsNumber, "min")}
        onKeyDown={(event) => handleKeyDown(event, "min")}
        aria-label={multiRange ? minAriaLabel : singleAriaLabel}
        inputClassName={classNames(css.valueInput, { [css.small]: small })}
      />
      <div
        className={classNames(css.labelsAndChildrenWrapper, css.horizontalLayout, {
          [css.disabled]: disabled
        })}
      >
        {unit && (
          <Typography variant="bodyBold" className={css.inlineText}>
            {unit}
          </Typography>
        )}
        {showMinMax && (
          <Typography variant="labelRegular" htmlFor={sliderId} className={css.inlineText}>
            {formatMinMax(min)}
          </Typography>
        )}
        {children}
        {showMinMax && (
          <Typography variant="labelRegular" htmlFor={sliderId} className={css.inlineText}>
            {formatMinMax(max)}
          </Typography>
        )}
      </div>
      {multiRange && (
        <InputFieldSet
          ref={maxInputRef}
          inputType="number"
          min={rangeMinValue}
          max={max}
          defaultValue={(localInputsValue as MultiRangeValue).max}
          disabled={disabled}
          onChange={(newLocalValue) => handleInputChange(newLocalValue, "max")}
          onBlur={(event) => handleValueApply(event.target.valueAsNumber, "max")}
          onKeyDown={(event) => handleKeyDown(event, "max")}
          aria-label={maxAriaLabel}
          inputClassName={classNames(css.valueInput, { [css.small]: small })}
        />
      )}
    </div>
  );
};
