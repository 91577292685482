import { Typography } from "PFComponents/typography";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { PostUntilChangeReason } from "PFTypes";
import { useTranslation } from "react-i18next";

type ExpiryDateEditModeProps = {
  postUntil: string;
  reason: PostUntilChangeReason | null;
};

export const ExpiryDateEditMode = ({ postUntil, reason }: ExpiryDateEditModeProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "edit.sections.expiryDateSection" });
  const { formatDate } = useDateFormatter();
  return (
    <div>
      <Typography withMargin variant="labelRegular">
        {t("replyByDate")}
      </Typography>
      <Typography withMargin variant="bodyBold">
        {formatDate(postUntil)}
      </Typography>
      {reason && (
        <>
          <Typography withMargin variant="labelRegular">
            {t("reasonToChange")}
          </Typography>
          <Typography withMargin variant="bodyBold">
            {reason.text ?? ""}
          </Typography>
        </>
      )}
    </div>
  );
};
