import { InlineCalendar } from "PFComponents/calendar/inline_calendar";
import { Typography } from "PFComponents/typography";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import PropTypes from "prop-types";

import { letFilterRestore } from "../../use_filtered_collection";

const DateItem = ({ filter, handleChange, error, portalRef, disabled }) => {
  const { formatDate } = useDateFormatter();
  const letRestore = letFilterRestore(filter);

  return (
    <div style={{ position: "relative" }}>
      {filter.title && (
        <Typography withMargin variant="labelRegular">
          {filter.title}
        </Typography>
      )}
      <InlineCalendar
        style={{ marginLeft: -10 }}
        error={error}
        selectedDate={filter.value ? formatDate(filter.value) : null}
        displayValue={filter.value ? formatDate(filter.value) : null}
        handleChange={(value) => handleChange(value)}
        letClear
        onRestore={letRestore ? () => handleChange(filter.defaultValue) : undefined}
        portalRef={portalRef}
        disabled={disabled}
      />
    </div>
  );
};

DateItem.propTypes = {
  handleChange: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    defaultValue: PropTypes.string
  }).isRequired,
  error: PropTypes.string,
  portalRef: PropTypes.object,
  disabled: PropTypes.bool
};

export default DateItem;
