import { TippyProps } from "@tippyjs/react";
import classNames from "classnames";
import SimpleBadge, { SimpleBadgeProps } from "PFComponents/badges_list/simple_badge";
import { Button } from "PFComponents/button";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { CurrentProfile, CustomValue, Experience, Id } from "PFTypes";
import { CSSProperties, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./badges_list.module.scss";

type BadgesListProps = {
  values: (Pick<SimpleBadgeProps, "text" | "icon" | "showTooltip" | "kind" | "classes"> & {
    experience?: Experience;
    _key?: string;
    expiryDate?: CustomValue["expiryDate"];
  })[];
  rootClassName?: string;
  moreLimit?: number;
  handleItemClick?: (badgeProps: SimpleBadgeProps) => void;
  handleMoreClicked?: (revealed: boolean) => void;
  /** If provided we will display the "badges left" number
   * ie. "+123 more" instead of just "more" */
  customButton?: ReactNode;
  icon?: ReactNode;
  style?: CSSProperties;
  hasValuesWithExpiryDate?: boolean;
  currentProfile?: CurrentProfile;
  profileId?: Id;
  showTooltip?: boolean;
  tooltipAppendTo?: TippyProps["appendTo"];
};

export const BadgesList = ({
  values,
  rootClassName,
  customButton = null,
  icon,
  moreLimit = 1000000,
  style,
  hasValuesWithExpiryDate,
  handleMoreClicked,
  handleItemClick,
  showTooltip,
  tooltipAppendTo
}: BadgesListProps) => {
  const { t } = useTranslation("core");
  const { formatDate } = useDateFormatter();
  const [state, setState] = useState({ revealed: values.length < moreLimit });
  const { revealed } = state;

  const className = classNames(css.root, rootClassName);

  const shownValues = revealed ? values : values.slice(0, moreLimit);

  const renderMoreButton = () => {
    const { revealed } = state;

    if (moreLimit && moreLimit < values.length) {
      const handleToggleClick = () => {
        setState({ revealed: !revealed });
        handleMoreClicked?.(!revealed);
      };
      const qaId = revealed ? "BadgesList.show_less" : "BadgesList.show_more";

      return (
        <Button
          kind="text"
          aria-label={
            revealed
              ? t("viewLess")
              : t("components.badgesList.viewMoreText", { count: values.length - moreLimit })
          }
          text={revealed ? t("viewLess") : `+${values.length - moreLimit} ${t("viewMore")}`}
          onClick={handleToggleClick}
          qaId={qaId}
        />
      );
    } else {
      return false;
    }
  };

  const button = !customButton && renderMoreButton();

  return (
    <div className={className} style={style} data-qa-id="BadgesList">
      {shownValues.map((badgeProps, index) => (
        <SimpleBadge
          key={`${badgeProps._key || badgeProps.text}:${index}`}
          icon={icon}
          {...badgeProps}
          text={
            hasValuesWithExpiryDate && badgeProps?.expiryDate
              ? `${badgeProps.text} (${t("time.inWords.expiresShort")}: ${formatDate(badgeProps.expiryDate)})`
              : badgeProps.text
          }
          showTooltip={showTooltip}
          tooltipAppendTo={tooltipAppendTo}
          onClick={handleItemClick ? () => handleItemClick(badgeProps) : null}
        />
      ))}
      {button}
      {customButton}
    </div>
  );
};
