import moment from "moment";
import { BookingPill } from "PFApp/components/pills/booking_pill";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Booking, BookingCategory } from "PFTypes";
import { useTranslation } from "react-i18next";

import { TooltipActivityLink } from "./tooltip_activity_link";
import css from "./tooltip_content.module.scss";
import { TooltipDates } from "./tooltip_dates";
import { TooltipElement } from "./tooltip_element";
import { TooltipJobCode } from "./tooltip_job_code";

type TooltipContentProps = {
  booking: Booking;
  bookingCategories: BookingCategory[];
  jobCodeDisplayAs: string;
  utmSource?: string;
  date?: string;
  disableAvailableTime?: boolean;
  config?: { hideActivityLink?: boolean };
};

/* eslint-disable camelcase */
const TooltipContent = ({
  booking,
  bookingCategories,
  jobCodeDisplayAs,
  utmSource = "",
  date,
  disableAvailableTime,
  config
}: TooltipContentProps) => {
  const { t } = useTranslation("bookingModule");
  const { utc, formatDate } = useDateFormatter();
  const { activity_id, description, title, start_date, end_date } = booking;
  const { booking_ref, job_code } = booking.metadata || {};
  const { hideActivityLink } = config || {};
  const { getBookingCategory } = useBookingCategories();
  const category = bookingCategories?.length
    ? bookingCategories.find(({ id }) => id === booking.booking_category_id)
    : getBookingCategory(booking.booking_category_id);
  const partial = date && (booking.partial || []).find((elem) => moment(date).isSame(elem.date, "day"));
  const availableTime =
    partial && utc().startOf("day").add(partial.available_minutes, "minutes").format("H:mm");
  const jobCodeOrActivityId = job_code || activity_id;

  return category ? (
    <div className={css.root}>
      {title && <TooltipElement labelKey={"bookings.tooltip.title"} value={title} />}
      {jobCodeOrActivityId && (
        <TooltipJobCode jobCodeDisplayAs={jobCodeDisplayAs} jobCode={jobCodeOrActivityId} />
      )}
      {booking_ref && <TooltipElement labelKey={"bookings.tooltip.bookingRef"} value={booking_ref} />}
      {description && <TooltipElement labelKey={"bookings.tooltip.description"} value={description} />}
      {!hideActivityLink && activity_id && (
        <TooltipActivityLink activityId={activity_id} utmSource={utmSource} />
      )}
      <TooltipDates startDate={start_date} endDate={end_date} />
      <BookingPill category={category} className={css.bookingPill} iconSize="xs">
        {category.display_as}
      </BookingPill>
      {date && <div>{formatDate(date)}</div>}
      {!disableAvailableTime && date && (
        <div>{availableTime ? `Available: ${availableTime}` : "Not available"}</div>
      )}
    </div>
  ) : (
    <>
      <TooltipDates startDate={start_date} endDate={end_date} />
      {t("bookings.tooltip.noCategory")}
    </>
  );
};

export default TooltipContent;
