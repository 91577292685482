import { Stack } from "PFComponents/containers";
import { Typography } from "PFComponents/typography";
import { AvailabilityRule } from "PFTypes";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

type ReadableRangesWrapperProps = {
  availability: AvailabilityRule;
  showEllipsis: boolean;
};

export const ReadableRangesWrapper = ({
  availability,
  showEllipsis,
  children
}: PropsWithChildren<ReadableRangesWrapperProps>) => {
  const { t } = useTranslation();

  return (
    <Stack gap="spacingLg">
      <Stack gap="spacingSm">
        <Stack gap="spacingLg">{children}</Stack>
        {showEllipsis && <Typography variant="bodyRegular">...</Typography>}
      </Stack>
      {!showEllipsis && (
        <Typography variant="bodyRegular">
          {t("availabilityRequirement.matchingAtLeast", {
            percentage: availability.availability_threshold
          })}
        </Typography>
      )}
    </Stack>
  );
};
