import { IconName } from "PFTheme/graphics/icons";

export const DEFAULT_CATEGORY_ICON: IconName = "non-demand";
export const AVAILABLE_CATEGORY_ICONS: IconName[] = [
  DEFAULT_CATEGORY_ICON,
  "learning",
  "palm-tree",
  "person-minus",
  "profiles",
  "baby",
  "house-laptop",
  "car",
  "wine-glass",
  "plane",
  "flower-spa",
  "bell",
  "error",
  "core",
  "heart",
  "ghost",
  "phone",
  "snooze",
  "face-smile",
  "house-user",
  "head-heart",
  "book",
  "pen",
  "bug"
];
export const validateCategoryIcon = (iconNameToCheck?: string | null): IconName | null =>
  iconNameToCheck && AVAILABLE_CATEGORY_ICONS.includes(iconNameToCheck as IconName)
    ? (iconNameToCheck as IconName)
    : null;
