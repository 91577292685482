import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import css from "./tooltip_content.module.scss";

type Props = {
  activityId: number;
  utmSource?: string;
};

export const TooltipActivityLink = ({ activityId, utmSource }: Props) => {
  const { t } = useTranslation("bookingModule");
  return (
    <Link
      className={css.show_more}
      to={{
        pathname: `/activities/${activityId}`
      }}
      state={{ utmSource }}
    >
      {t("bookings.tooltip.goToActivity")}
    </Link>
  );
};
