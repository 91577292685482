import { Flex } from "PFComponents/containers";
import { Icon } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

import css from "./bulk_select_item.module.scss";
import { PROFILES_COUNT_LIMIT } from "./workforce_member_select.utils";

type BulkSelectItemProps = {
  count: number;
  disabled: boolean;
};

export const BulkSelectItem = ({ count, disabled }: BulkSelectItemProps) => {
  const { t } = useTranslation("bookingModule");

  const content = (
    <Flex alignItems="center" justifyContent="start">
      <div className={css.icon}>
        <Icon name="filter" />
      </div>
      <div>
        <Typography clipOverflow variant="bodyBold">
          {t("bookings.create.bulk.title")}
        </Typography>
        <Typography clipOverflow variant="labelRegular" tag="p">
          {t("bookings.create.bulk.select", { count })}
        </Typography>
      </div>
    </Flex>
  );

  return disabled ? (
    <Tooltip content={t("bookings.create.bulk.tooManyProfiles", { count: PROFILES_COUNT_LIMIT })}>
      {content}
    </Tooltip>
  ) : (
    content
  );
};
