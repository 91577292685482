import classNames from "classnames";
import { Card, CardProps } from "PFComponents/card";
import { Animate } from "PFComponents/containers";
import { forwardRef, ReactNode, useState } from "react";

import css from "./expandable_card.module.scss";
import { ExpandButton } from "./parts/expand_button";

const ANIMATION_DURATION_MS = 200;

type ExpandableCardProps = {
  header: ReactNode;
  footer?: ReactNode;
  animationTrigger?: any;
  className?: string;
} & CardProps;

export const ExpandableCard = forwardRef<HTMLDivElement, ExpandableCardProps>(
  ({ header, children, footer, animationTrigger, ...cardProps }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isHidden, setIsHidden] = useState(true);

    const onExpandButtonClick = (event) => {
      event.stopPropagation();

      if (isOpen) {
        setTimeout(() => {
          setIsHidden(true);
        }, ANIMATION_DURATION_MS);
      } else {
        setIsHidden(false);
      }

      setIsOpen((open) => !open);
    };

    return (
      <Card ref={ref} {...cardProps}>
        <span className={css.headerWrapper}>
          <div className={css.expandButtonColumn}>
            <ExpandButton isOpen={isOpen} onClick={onExpandButtonClick} />
          </div>
          <div className={css.header}>
            <Animate trigger={animationTrigger}>{header}</Animate>
          </div>
        </span>
        <Animate trigger={animationTrigger}>
          <div className={classNames({ [css.closed]: !isOpen, [css.hidden]: isHidden })}>{children}</div>
        </Animate>
        {footer && <Animate trigger={animationTrigger}>{footer}</Animate>}
      </Card>
    );
  }
);

ExpandableCard.displayName = "ExpandableCard";
