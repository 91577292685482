import { Button } from "PFComponents/button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useActivityEditPageContext, useActivityEditPageFlags } from "../../activity_edit_page_context";
import { useEngagementWizard } from "../../hooks";
import css from "./cancel_button.module.scss";

export const CancelButton = () => {
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const { engagementWizardState, handleEngagementWizardNavigation } = useEngagementWizard();

  const { isRole } = useActivityEditPageFlags();
  const { activity } = useActivityEditPageContext();

  const handleCancel = () => {
    if (isRole && engagementWizardState && handleEngagementWizardNavigation) {
      handleEngagementWizardNavigation(activity, { cancel: true });
    } else {
      navigate(-1);
    }
  };

  return <Button kind="secondary" text={t("cancel")} onClick={handleCancel} className={css.cancelButton} />;
};
