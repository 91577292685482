import classNames from "classnames";
import some from "lodash/some";
import { useAppContext } from "PFApp/app_context";
import { Button } from "PFComponents/button";
import Hr from "PFComponents/hr";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import SuggestionIcon from "PFIcons/chat_suggestion.svg";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ActivityDetailsOtherChats from "../details/activity_details_other_chats";
import css from "./chats_section.module.scss";

const ChatsSectionOtherChats = ({
  collapsed,
  handleChatSectionClick,
  participantsOfConversations,
  otherConversations,
  showMainChat,
  activity,
  qaId
}) => {
  const {
    store: { themeVars }
  } = useAppContext();
  const { t } = useTranslation("activities");

  const hasUnreadConversations = some(otherConversations || [], "unread_count");
  const otherChatsTitle = showMainChat
    ? t("show.parts.otherChats")
    : `${t("show.parts.chats")} (${otherConversations ? otherConversations.length : 0})`;

  return (
    <div className={classNames(css.section, { [css.isOpen]: !collapsed })}>
      <Hr />
      <Button kind="blank" className={css.sectionHeader} onClick={handleChatSectionClick} qaId={qaId}>
        <div className={css.headerAlign}>
          <SuggestionIcon style={hasUnreadConversations ? { fill: themeVars["--Palette-warning"] } : {}} />
          <Typography variant="h5">{otherChatsTitle}</Typography>
        </div>
        <Icon name={collapsed ? "add" : "subtract"} size="sm" />
      </Button>
      <div className={classNames(css.contentSection, { [css.collapsed]: collapsed })}>
        <ActivityDetailsOtherChats
          activity={activity}
          conversations={otherConversations || []}
          participantsOfConversations={participantsOfConversations}
        />
      </div>
    </div>
  );
};

ChatsSectionOtherChats.propTypes = {
  collapsed: PropTypes.bool,
  handleChatSectionClick: PropTypes.func,
  allParticipants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      profile: PropTypes.object.isRequired
    })
  ),
  otherConversations: PropTypes.array,
  participantsOfConversations: PropTypes.object,
  showMainChat: PropTypes.bool,
  activity: PropTypes.object,
  qaId: PropTypes.string
};

export default ChatsSectionOtherChats;
