import isEmpty from "lodash/isEmpty";
import css from "PFApp/booking/parts/overview/detail_view/detail_view.module.scss";
import { ActivityCustomValuesList } from "PFApp/components/custom_values_list";
import { Activity } from "PFTypes";
import { useTranslation } from "react-i18next";

import { Label } from "../parts/label";

const SKILLS_LIMIT = 5;

type SkillsProps = {
  activity: Activity;
};

export const Skills = ({ activity }: SkillsProps) => {
  const { t } = useTranslation();

  const { custom_fields: customFields } = activity;
  const skillsEntry = (customFields || []).find(({ type }) => type.name === "skills");

  if (isEmpty(skillsEntry?.values)) {
    return null;
  }

  return (
    <span className={css.item}>
      <Label text={t("skills")} />
      <ActivityCustomValuesList
        customFieldName="skills"
        activityId={activity.id}
        customValues={skillsEntry!.values}
        limit={SKILLS_LIMIT}
      />
    </span>
  );
};
