import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useGrowl } from "../../../use_growl";
import { useActivityPageContext } from "../activity_page_context";
import { EconomicsList } from "./economics_list/economics_list";
import { ScenarioView } from "./scenario_view";
import { useEconomicsPermissions } from "./use_economics_permissions";
import { getEconomicsUrl } from "./utils";

export const Economics = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario" });
  const navigate = useNavigate();
  const growl = useGrowl();
  const { params: scenarioId } = useParams<{ params: string }>();

  const { task: activity } = useActivityPageContext();
  const { canViewScenario } = useEconomicsPermissions();

  useEffect(() => {
    if (scenarioId && !canViewScenario) {
      navigate(getEconomicsUrl(activity.id), { replace: true });
      growl({ message: t("notPermitted"), kind: "error" });
    }
  }, [activity.id, canViewScenario, growl, navigate, scenarioId, t]);

  if (scenarioId && canViewScenario) {
    return <ScenarioView scenarioId={scenarioId} />;
  }

  return <EconomicsList />;
};
