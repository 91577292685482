import every from "lodash/every";
import last from "lodash/last";
import { ActivityParticipant, ParticipatedRole } from "PFTypes/activity_participant";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useFeedbackContext } from "./feedback_context_provider";
import { useSavedFeedbackForms } from "./hooks/use_saved_feedback_forms";
import { Participant } from "./participant";
import css from "./participants_list.module.scss";

type ParticipantsListProps = {
  participants: ActivityParticipant[];
};

export const ParticipantsList = ({ participants }: ParticipantsListProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.feedback" });
  const { setActiveFeedbackRound, savedForms, selectedEntry, setSelectedEntry } = useFeedbackContext();
  const { updateSavedForms } = useSavedFeedbackForms();

  const changeSelectedParticipant = useCallback(
    (profile: ActivityParticipant, role: ParticipatedRole) => {
      updateSavedForms();
      setActiveFeedbackRound(
        role.feedback.find(({ id }) => savedForms[`${profile.id}-${role.id}`]?.round === id) ||
          last(role.feedback) ||
          null
      );
      setSelectedEntry({ profileId: profile.id, roleId: role.id });
    },
    [savedForms, setActiveFeedbackRound, setSelectedEntry, updateSavedForms]
  );

  return (
    <>
      <h3 className={css.listHeader}>{t("headers.talentList")}</h3>
      <div className={css.list}>
        {participants.map((participant) =>
          participant.roles.map((role) => {
            const isEveryFeedbackSent = every(role.feedback, ({ status }) => status === "sent");

            return (
              <Participant
                key={role.id}
                role={role}
                participant={participant}
                onClick={changeSelectedParticipant}
                isActive={selectedEntry?.profileId === participant.id && selectedEntry?.roleId === role.id}
                isComplete={role.feedback.length > 0 && isEveryFeedbackSent}
              />
            );
          })
        )}
      </div>
    </>
  );
};
