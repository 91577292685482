import classNames from "classnames";
import { Markdown } from "PFComponents/markdown";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

import css from "./activity_details_common.module.scss";

type ActivityDetailsDescProps = {
  description: string | undefined;
};

export const ActivityDetailsDesc = ({ description }: ActivityDetailsDescProps) => {
  const { t } = useTranslation("activities");

  return (
    <div className={classNames(css.bigItem, css.itemContainer)} data-qa-id="ActivityDetailsDesc">
      <Typography variant="h5">{t("show.details.description")}</Typography>
      <Markdown raw={description} qaId="activity-details-details" className={css.markdown} emojify={false} />
    </div>
  );
};
