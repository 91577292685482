import { useTranslation } from "react-i18next";

import { ActionIcon } from "../../action_icon";
import { Typography } from "../../typography";
import css from "./rules_carousel_toolbar.module.scss";

type RulesCarouselToolbarProps = {
  onNext: () => void;
  onPrev: () => void;
  onCreate: () => void;
  onRemove: (index: number) => void;
  carouselIndex: number;
  rulesLength: number;
};

export const RulesCarouselToolbar = ({
  onNext,
  onPrev,
  onCreate,
  onRemove,
  carouselIndex,
  rulesLength
}: RulesCarouselToolbarProps) => {
  const { t } = useTranslation();

  return (
    <div className={css.carouselNavigation}>
      <ActionIcon
        name="chevron-left"
        size="sm"
        onClick={onPrev}
        disabled={carouselIndex === 0 || rulesLength <= 1}
      />
      <span className={css.title}>
        <Typography variant="bodyRegular" tag="span">
          {t("availabilityRequirement.ruleXofY", {
            index: carouselIndex + 1,
            total: rulesLength
          })}
        </Typography>
        <ActionIcon name="add" size="sm" onClick={onCreate} />
        <ActionIcon
          name="remove"
          size="sm"
          color="paletteRed0"
          onClick={() => onRemove(carouselIndex)}
          disabled={rulesLength === 0}
        />
      </span>
      <ActionIcon
        name="chevron-right"
        size="sm"
        onClick={onNext}
        disabled={carouselIndex === rulesLength - 1 || rulesLength <= 1}
      />
    </div>
  );
};
