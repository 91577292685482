import moment from "moment";
import { Button } from "PFComponents/button";
import CustomValuesList from "PFComponents/custom_values_list/custom_values_list";
import { Typography } from "PFComponents/typography";
import { durationFromMonths } from "PFCore/helpers/date";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { CustomType, Profile } from "PFTypes";

import { ExperienceDescription } from "./experience_description";
import css from "./experience_section.modules.scss";

type MainJobProps = {
  position: any;
  customTypes: CustomType[];
  profile: Profile;
  currentProfile: Profile;
  isCfPermitted: (cfName: string) => boolean;
  onEdit: () => void;
};

export const MainJob = ({
  customTypes,
  position,
  profile,
  currentProfile,
  isCfPermitted,
  onEdit
}: MainJobProps) => {
  const { data: currentAccount } = useCurrentAccount();
  const { formatDate } = useDateFormatter();

  const isMe = !!profile?.id && profile?.id === currentProfile?.id;
  const isOnlyDuration = position.duration && !position.start_date;
  const isStartDateValid = moment(position.start_date).isValid();
  const isEndDateValid = moment(position.end_date).isValid();

  const mainPositionCustomFields = Object.keys(position).filter((item) => isCfPermitted(item));

  const cfName = (name) => customTypes.find((ct) => ct?.name === name)?.display_as;

  const cfkeyToValue = mainPositionCustomFields.reduce(
    (acc, item) => ({
      ...acc,
      [item]: cfName(item)
    }),
    {}
  );

  return (
    <div className={css.row}>
      {isMe && <Button className={css.editIcon} kind="secondary" icon="edit" onClick={onEdit} />}

      <div className={css.experienceTitle} data-scroll-anchor={`internal-experience-${position.id}`}>
        {position.job_title && <Typography variant="h4">{position.job_title[0].value}</Typography>}
        {position.company && <Typography variant="h4">{position.company[0].value}</Typography>}
        <Typography variant="h4">
          {isOnlyDuration
            ? durationFromMonths(position.duration)
            : `${isStartDateValid ? formatDate(position.start_date) : ""}${
                isEndDateValid ? ` - ${formatDate(position.end_date)}` : ""
              }`}
        </Typography>
      </div>

      <div className={css.experienceGridWrapper}>
        {position.summary && <ExperienceDescription content={position.summary} maxLength={400} />}
        {mainPositionCustomFields.map(
          (cf) =>
            position[cf] &&
            Array.isArray(position[cf]) && (
              <div key={cf} className={css.experienceGrid}>
                <Typography variant="bodyBold" clipOverflow>
                  {cfkeyToValue[cf]}
                </Typography>
                <div>
                  <CustomValuesList
                    type={position[cf]}
                    currentProfile={currentProfile}
                    currentAccount={currentAccount}
                    profileId={profile.id}
                    moreLimit={6}
                    showMoreButton
                    customValues={position[cf].map((value) => ({ ...value, className: "light" }))}
                  />
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};
