import { MutableRefObject, useEffect, useRef } from "react";

interface UseInfiniteScroll {
  fetchMore: () => void;
  hasMore?: boolean;
  loading: boolean;
  rootMargin?: string;
}

export const useInfiniteScroll = <T extends Element>({
  fetchMore,
  hasMore,
  loading,
  rootMargin
}: UseInfiniteScroll): MutableRefObject<T | null> => {
  const lastItemRef = useRef<T | null>(null);
  const observer = useRef<IntersectionObserver>(undefined);

  useEffect(() => {
    const options = {
      root: document,
      rootMargin: rootMargin ?? "20px",
      threshold: 0
    };
    const callback = (entries) => {
      if (entries[0].isIntersecting && hasMore && !loading) {
        fetchMore();
      }
    };
    observer.current = new IntersectionObserver(callback, options);
    if (lastItemRef.current) {
      observer.current.observe(lastItemRef.current);
    }
    return () => {
      observer.current?.disconnect();
    };
  });

  return lastItemRef;
};
