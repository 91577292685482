import findIndex from "lodash/findIndex";
import { useNavigate } from "react-router-dom";

import { useActivityEditPageContext, useActivityEditPageFlags } from "../../activity_edit_page_context";
import { EngagementWizardState } from "../../hooks";

export const useEngagementPagination = (wizardState: EngagementWizardState) => {
  const navigate = useNavigate();
  const { isNew } = useActivityEditPageFlags();
  const { activity } = useActivityEditPageContext();

  const roles = [...(wizardState?.roles || []), null];
  const index = isNew ? roles.length - 1 : findIndex(roles, (value) => value === activity.id);

  const handleRoleChange = (roleId: number | null) => {
    if (roleId) {
      navigate(`/activities/${roleId}/edit`);
    } else if (wizardState?.newRoleUrl) {
      navigate(wizardState.newRoleUrl);
    } else {
      navigate(`/activities/role/new/parent_activity_id/${wizardState?.engagementId}`);
    }
  };

  const goToPreviousRole = () => handleRoleChange(roles[index - 1]);

  const goToNextRole = () => handleRoleChange(roles[index + 1]);

  const canNext = index < roles.length - 1;
  const canPrev = 0 < index;

  return {
    roles,
    index,
    canPrev,
    canNext,
    goToPreviousRole,
    goToNextRole
  };
};
