import { ProfileCustomValuesList } from "PFApp/components/custom_values_list";
import { ProfileAvatar } from "PFComponents/profile_avatar/profile_avatar";
import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";

import { useMatchProfileInfo } from "../../../../hooks/use_match_profile_info";
import { ShortlistForProfileButton } from "../../../../profiles/shortlist_for_profile";
import { ProfileCardContentProps } from "../profile_card_content";
import css from "./profile_card_profile_info.module.scss";

export const ProfileCardProfileInfo = ({ profile, onSizeChange }: ProfileCardContentProps) => {
  const { headerInfo } = useMatchProfileInfo(profile);
  const formattedHeaderProfileInfo = headerInfo.map(({ value }) => value).join(", ");

  const profileName = getProfileName(profile);

  return (
    <div className={css.wrapper}>
      <ProfileAvatar profile={profile} size={50} />
      <main className={css.main}>
        <Typography variant="h4">{profileName}</Typography>
        <Typography withMargin variant="labelRegular" className={css.matchInfo}>
          {formattedHeaderProfileInfo}
        </Typography>
        {profile.skills && (
          <div className={css.skills}>
            <ProfileCustomValuesList
              profileId={profile.id}
              customFieldName="skills"
              customValues={profile.skills}
              onMoreLessButtonClick={onSizeChange}
            />
          </div>
        )}
      </main>
      <ShortlistForProfileButton profile={profile} />
    </div>
  );
};
