import { ProfileCustomValuesList } from "PFApp/components/custom_values_list";
import SkillsExplorer from "PFApp/profiles/show/parts/charts/skills_explorer";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { Profile } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "../../../../parts/overview/detail_view/detail_view.module.scss";
import { Label } from "../parts/label";

const SKILLS_LIMIT = 5;

interface SkillsProps {
  profile: Profile;
}

export const Skills = ({ profile }: SkillsProps) => {
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("translation");
  const skillsExplorerAvailable = profile.skill_categories && profile.skill_categories.length > 0;
  const skills = profile.skills || [];

  return (
    <div className={css.item} style={{ height: 360 }}>
      <Label text={t("skills")} />
      {skillsExplorerAvailable ? (
        // @ts-ignore
        <SkillsExplorer
          profile={profile}
          skills={skills}
          selectedChart={currentProfile.preferences.display?.skills_insights || "bubble"}
        />
      ) : (
        <ProfileCustomValuesList
          customFieldName="skills"
          customValues={skills}
          profileId={profile.id}
          limit={SKILLS_LIMIT}
        />
      )}
    </div>
  );
};
