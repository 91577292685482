import isNil from "lodash/isNil";
import { Carousel } from "PFApp/booking/components/carousel";
import { Button } from "PFComponents/button";
import { Typography } from "PFComponents/typography";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "../../../detail_view.module.scss";
import { useBookingDetailSidePanelContext } from "../../booking_detail_side_panel_context";
import { SingleBooking } from "./single_booking";

type BookingSiblingsProps = {
  goToDate?: (date: Date) => void;
  checkCanGoToDate?: (date: string) => boolean;
};

export const BookingsCarousel = ({ goToDate, checkCanGoToDate }: BookingSiblingsProps) => {
  const { t } = useTranslation("bookingModule");
  const { siblingBookings, bookingTemplate, currentSiblingBookingIndex, setCurrentSiblingBookingIndex } =
    useBookingDetailSidePanelContext();

  const itemElements = useMemo<React.ReactElement[]>(
    () =>
      (siblingBookings ?? []).map((booking) => (
        <SingleBooking key={booking.id} booking={booking} bookingTemplate={bookingTemplate} />
      )),
    [siblingBookings, bookingTemplate]
  );

  if (isNil(currentSiblingBookingIndex)) {
    return null;
  }

  const currentBookingStartDate = (siblingBookings ?? [])[currentSiblingBookingIndex]?.start_date;
  const header = (
    <div className={css.carouselHeader}>
      <Typography variant="bodyRegular">
        {bookingTemplate
          ? t("bookings.repeatedBooking")
          : t("bookings.bookingOrdinalNumber", {
              current: currentSiblingBookingIndex + 1,
              total: itemElements.length
            })}
      </Typography>
      {goToDate && currentBookingStartDate && checkCanGoToDate?.(currentBookingStartDate) && (
        <Button kind="text" onClick={() => goToDate(new Date(currentBookingStartDate))}>
          {t("bookings.show")}
        </Button>
      )}
    </div>
  );

  return (
    <Carousel
      items={itemElements}
      activeIndex={currentSiblingBookingIndex}
      setActiveIndex={setCurrentSiblingBookingIndex}
      header={header}
    />
  );
};
