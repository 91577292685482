import isNil from "lodash/isNil";
import { ReactNode } from "react";

import { Typography } from "../../typography";
import { Option, OptionOriginal, SelectV2Props } from "../select_v2.types";
import { ValuesList } from "./values_list";

const isRenderingMultipleValues = <T extends OptionOriginal = OptionOriginal>(
  multiple: SelectV2Props<T>["multiple"],
  renderDisplayValue: SelectV2Props<T>["renderDisplayValue"]
): renderDisplayValue is (value: Option<T>[]) => ReactNode => !!multiple;

type UseDisplayValue<T> = Pick<
  SelectV2Props<T>,
  "multiple" | "renderDisplayValue" | "locked" | "onChange"
> & {
  selectedOptions: Option<T>[];
  displayValueRef?: (node: HTMLDivElement) => void;
};

type DisplayValueData = {
  displayElement: ReactNode;
};

export const useDisplayValue = <T extends OptionOriginal = OptionOriginal>({
  selectedOptions,
  multiple,
  renderDisplayValue,
  onChange,
  displayValueRef
}: UseDisplayValue<T>): DisplayValueData => {
  const getDisplayValue = (): DisplayValueData => {
    if (renderDisplayValue) {
      const displayElement = isRenderingMultipleValues(multiple, renderDisplayValue)
        ? renderDisplayValue(selectedOptions)
        : renderDisplayValue(selectedOptions[0]);
      return {
        displayElement
      };
    }
    if (multiple) {
      return {
        displayElement: <ValuesList selectedOptions={selectedOptions} onChange={onChange} />
      };
    }
    const displayElement = selectedOptions[0]?.displayElement ?? selectedOptions[0]?.value;
    return {
      displayElement
    };
  };

  const { displayElement: displayElementValue } = getDisplayValue();

  if (isNil(displayElementValue)) {
    return { displayElement: null };
  }

  const displayElement =
    typeof displayElementValue === "string" ? (
      <Typography variant="bodyBold" clipOverflow title={displayElementValue}>
        {displayElementValue}
      </Typography>
    ) : (
      <div ref={displayValueRef}>{displayElementValue}</div>
    );

  return { displayElement };
};
