import classNames from "classnames";
import { IconName } from "PFTheme/graphics/icons";

import { Icon } from "../../../icon";
import { Typography } from "../../../typography";
import { InputFieldSetProps } from "../input_field_set";
import css from "./tip.module.scss";

type TipProps = {
  error?: InputFieldSetProps["error"];
  warning?: InputFieldSetProps["warning"];
  tip?: InputFieldSetProps["tip"];
};

export const Tip = ({ error, warning, tip }: TipProps) => {
  const content = error || warning || tip;

  const icon: IconName = error ? "error" : warning ? "warning" : "info";

  return (
    <div
      className={classNames(css.tip, { [css.error]: error, [css.warning]: warning })}
      data-target-id="input_field_set__error"
    >
      <Icon name={icon} size="sm" />
      {Array.isArray(content) ? (
        content.map((err) => (
          <Typography tag="span" variant="labelRegular" wrapText key={err}>
            {err}
          </Typography>
        ))
      ) : (
        <Typography tag="span" variant="labelRegular" wrapText>
          {content}
        </Typography>
      )}
    </div>
  );
};
