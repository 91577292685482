/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import filter from "lodash/filter";
import { useAppContext } from "PFApp/app_context";
import ButtonLink from "PFComponents/button/button_link";
import AnalyticsPush from "PFCore/helpers/analytics_push";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import FilterCleanIcon from "PFIcons/filter-clean.svg";
import { SEARCH_ACTIONS } from "PFReducers/search_reducer/search_reducer";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import css from "./choosen_keyword.less";

export const ChoosenKeyword = ({ text, onRemove, tag: Tag = "li" }) => (
  <Tag className={css.item}>
    <div className={css.wrap}>
      {onRemove && (
        <a className={css.remove} onClick={() => onRemove()}>
          &times;
        </a>
      )}
      <span className={css.text}>{` ${text}`}</span>
    </div>
  </Tag>
);

ChoosenKeyword.propTypes = {
  text: PropTypes.string,
  onRemove: PropTypes.func,
  tag: PropTypes.oneOf(["li", "div"])
};

const ChoosenKeywords = ({ handleResetStateClick }) => {
  const {
    dispatch,
    store: {
      search: { choosenKeywords, term, suggestedKeywords }
    }
  } = useAppContext();
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("search");

  const handleRemove = (item) => {
    dispatch({ type: SEARCH_ACTIONS.SEARCH_REMOVE_CHOOSEN_KEYWORD, payload: item });
    AnalyticsPush(`search_recomendations`, `click in search suggestion`, {
      eventCategory: `search_recomendations`,
      eventAction: `click in search suggestion`,
      userId: currentProfile.id,
      action: "decline",
      searchText: term,
      recomendations: suggestedKeywords,
      selectedRecomendation: item.value,
      index: suggestedKeywords.findIndex(({ value }) => value === item.value)
    });
  };

  const keywords = filter(choosenKeywords, "match");
  return (
    <ul className={css.root}>
      {keywords.length > 0 && (
        <li className={css.clearButton}>
          <ButtonLink kind="text" href="/search" onClick={handleResetStateClick}>
            <FilterCleanIcon height={16} width={16} />
            <span>{t("searchBox.clear")}</span>
          </ButtonLink>
        </li>
      )}
      {keywords.map((keyword) => (
        <ChoosenKeyword
          key={keyword.id || keyword.global_id}
          text={keyword.text || keyword.value}
          isFilter={!!keyword.filterKey}
          onRemove={() => handleRemove(keyword)}
        />
      ))}
    </ul>
  );
};

ChoosenKeywords.propTypes = {
  handleResetStateClick: PropTypes.func
};

export default ChoosenKeywords;
