import ReviewPosition from "PFApp/onboarding/parts/review_position";
import { Card } from "PFComponents/card";
import Toggle from "PFComponents/toggle/toggle";
import { ChangeRequestPosition } from "PFTypes";

import css from "../change_request_page.module.scss";
import { DisabledItems, SetDisabledItems } from "../change_request_page.types";

type PositionProps = {
  position: ChangeRequestPosition;
  handleChangePosition: (change: ChangeRequestPosition) => void;
  index: number;
  disabledItems: DisabledItems;
  setDisabledItems: SetDisabledItems;
  errors: any;
};

export const Position = ({
  position,
  handleChangePosition,
  index,
  disabledItems,
  setDisabledItems,
  errors
}: PositionProps) => {
  const isDisabled = disabledItems.positions[index];

  return (
    <Card qaId={`ChangeRequest.Position.${position.ordinal}`}>
      <div className={css.cardToggle}>
        <Toggle
          checked={!isDisabled}
          onChange={(val) =>
            setDisabledItems((prev) => ({ ...prev, positions: { ...prev.positions, [index]: !val } }))
          }
        />
      </div>
      <div className={isDisabled ? css.fade : undefined}>
        {/* @ts-ignore */}
        <ReviewPosition errors={errors} position={position} handleChange={handleChangePosition} />
      </div>
    </Card>
  );
};
