import { ReadableAvailabilityRules } from "PFApp/components/readable_availability_rules";
import Tooltip from "PFComponents/tooltip/tooltip";
import { useReadableAvailability } from "PFCore/helpers/use_readable_availability";
import { AvailabilityRule } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "./availability_info_panel.module.scss";

type AvailabilityInfoPanelProps = {
  availability: AvailabilityRule;
};

export const AvailabilityInfoPanel = ({ availability }: AvailabilityInfoPanelProps) => {
  const { t } = useTranslation("activities");
  const { readableAvailability } = useReadableAvailability({ availability });

  if (!availability) {
    return null;
  }

  return (
    <div className={css.root}>
      <b>{t("compare.availabilityRequirement")}</b>
      <Tooltip
        maxWidth={400}
        content={
          <div className={css.availabilityTooltip}>
            <ReadableAvailabilityRules availability={availability} />
          </div>
        }
        interactive
      >
        <div>
          <div>{readableAvailability}</div>
          <div>{t("compare.matchingPercentage", { percentage: availability.availability_threshold })}</div>
        </div>
      </Tooltip>
    </div>
  );
};
