import { useExperienceLabelMap } from "PFApp/hooks/use_experience_label_map";
import { Inline } from "PFComponents/containers/flex/inline";
import { Stack } from "PFComponents/containers/flex/stack";
import { ExperienceLevel } from "PFComponents/experience_level";
import { MultiToggle } from "PFComponents/multi_toggle";
import { ReadOnlyField } from "PFComponents/read_only_field";
import { Typography } from "PFComponents/typography";
import { Experience } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type ExperienceToggleProps = {
  canEdit?: boolean;
  experience: Experience | null;
  isRequirement: boolean;
  onChange: (value: Experience) => void;
};

type ExperienceOptionProps = {
  isRequirement: boolean;
  experience: Experience;
  selected: boolean;
};

const ExperienceOption = ({ isRequirement, experience, selected }: ExperienceOptionProps) => {
  const experienceLabelMap = useExperienceLabelMap();

  return (
    <Stack center>
      <ExperienceLevel
        isRequirement={isRequirement}
        {...{ [isRequirement ? "requiredExperience" : "experience"]: experience }}
        theme={selected ? "dark" : "light"}
        showTooltip={false}
      />
      <Typography variant="bodyRegular">{experienceLabelMap[experience]}</Typography>
    </Stack>
  );
};

export const ExperienceToggle = ({ canEdit, experience, isRequirement, onChange }: ExperienceToggleProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "customValueSidepanel" });
  const experienceLabelMap = useExperienceLabelMap();

  const ExperienceOptions = useMemo(
    () =>
      [Experience.Basic, Experience.Intermediate, Experience.Advanced].map((experienceOption) => ({
        id: experienceOption,
        value: (
          <ExperienceOption
            experience={experienceOption}
            isRequirement={isRequirement}
            selected={experience === experienceOption}
          />
        )
      })),
    [isRequirement, experience]
  );

  return canEdit ? (
    <MultiToggle
      label={`${t("proficiency")} *`}
      fitHeightToContent
      options={ExperienceOptions}
      controlledValue={experience}
      onChange={onChange}
    />
  ) : (
    <ReadOnlyField
      label={t("proficiency")}
      value={
        <Inline alignItems="center" gap="spacingSm">
          <ExperienceLevel
            isRequirement={isRequirement}
            {...{ [isRequirement ? "requiredExperience" : "experience"]: experience }}
            showTooltip={false}
          />
          <span>{experienceLabelMap[experience!]}</span>
        </Inline>
      }
    />
  );
};
