import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";

import css from "./engagement_item.module.scss";

export type EngagementOption = {
  id: number;
  name: string;
  description?: string;
  isPrivate?: boolean;
};

type EngagementItemProps = {
  item: EngagementOption;
};

export const EngagementItem = ({ item }: EngagementItemProps) => {
  const { name, description, isPrivate } = item;
  return (
    <div className={css.root}>
      {isPrivate && <Icon name="locked" size="sm" />}
      <div className={css.textContainer}>
        <Typography
          withMargin
          variant={description ? "bodyBold" : "bodyBold"}
          tag="span"
          className={css.textOverflow}
        >
          {name}
        </Typography>
        {description && (
          <Typography withMargin variant="bodyRegular" tag="span" className={css.textOverflow}>
            {description}
          </Typography>
        )}
      </div>
    </div>
  );
};
