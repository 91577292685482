import useWindowSize from "PFCore/helpers/use_window_size";
import { RefObject, useEffect, useState } from "react";

const DEFAULT_OFFSET = 50;

type UseTableResize = {
  tableRef: RefObject<HTMLElement | null>;
  paginationRef: RefObject<HTMLElement | null>;
  bottomElementsSize: number | undefined;
  disableAutoResizing: boolean | undefined;
};

type UseTableResizeReturn = {
  maxHeight: number | undefined;
};

export const useTableResize = ({
  tableRef,
  paginationRef,
  bottomElementsSize,
  disableAutoResizing
}: UseTableResize): UseTableResizeReturn => {
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);

  const { windowHeight } = useWindowSize();

  const paginationOffset = paginationRef.current?.clientHeight;

  useEffect(
    () => {
      if (!disableAutoResizing && tableRef.current) {
        setMaxHeight(
          windowHeight -
            tableRef.current?.getBoundingClientRect().top -
            (paginationOffset ?? 0) -
            (bottomElementsSize ?? 0) -
            DEFAULT_OFFSET
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [windowHeight]
  );

  return {
    maxHeight: disableAutoResizing ? undefined : maxHeight
  };
};
