import moment from "moment";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { AvailabilityRule } from "PFTypes";
import { useCallback } from "react";

interface ReadableAvailabilityData {
  availability?: AvailabilityRule;
}

type ReadableAvailability = React.ReactElement | string | null;
interface UseReadableAvailabilityReturn {
  readableAvailability: ReadableAvailability;
  getReadableAvailability: (data: ReadableAvailabilityData) => ReadableAvailability;
}

export const useReadableAvailability = (
  data: ReadableAvailabilityData = {}
): UseReadableAvailabilityReturn => {
  const { availability } = data;
  const { dateFormatString } = useDateFormatter();

  const getReadableAvailability = useCallback(
    ({ availability }: ReadableAvailabilityData): ReadableAvailability => {
      if (!availability) {
        return null;
      }

      const minDate = moment
        .min(availability.ranges.map((range) => moment(range.start)))
        .format(dateFormatString);

      const maxDate = moment
        .max(availability.ranges.map((range) => moment(range.end)))
        .format(dateFormatString);

      return `${minDate} - ${maxDate}`;
    },
    [dateFormatString]
  );

  const readableAvailability = availability ? getReadableAvailability({ availability }) : null;

  return {
    readableAvailability,
    getReadableAvailability
  };
};
