import moment from "moment";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { useState } from "react";

import Calendar from "../calendar";
import Footer from "./footer";
import { isDateBetween } from "./helpers";
import Options from "./options";

const RangeCalendar = ({
  startDate: initStartDate,
  endDate: initEndDate,
  minDate,
  displayMonth,
  options,
  showFooterInfo = true,
  handleChange,
  checkHighlighted,
  onStartDateChange,
  portalRef,
  ...calendarProps
}) => {
  const { formatISODate } = useDateFormatter();
  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);
  const [hoverEndDay, setHoverEndDay] = useState(null);
  const [lastHoverEndDay, setLastHoverEndDay] = useState(displayMonth);

  const checkHover = (date) => {
    if (startDate && !endDate && hoverEndDay) {
      return isDateBetween(date, startDate, hoverEndDay);
    }
    return false;
  };

  const checkSelected = (date) => {
    if (startDate && endDate) {
      return isDateBetween(date, startDate, endDate);
    }
    return false;
  };

  const handleCheckHighlighted = (date) => {
    if (checkHighlighted) {
      return checkHighlighted(date, { startDate, endDate });
    } else if (startDate && endDate) {
      return isDateBetween(date, startDate, endDate);
    }
    return false;
  };

  const handleDateClick = (date) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(formatISODate(moment(date).startOf("day")));
      setEndDate(null);
      setLastHoverEndDay(null);
      onStartDateChange && onStartDateChange(formatISODate(moment(date).startOf("day")));
    } else {
      const newEndDate = formatISODate(moment(date).startOf("day"));
      setEndDate(newEndDate);
      setLastHoverEndDay(null);
      handleChange && handleChange(formatISODate(startDate), formatISODate(newEndDate));
    }
  };

  const handleMouseEnter = (day) => {
    setLastHoverEndDay(day);
    setHoverEndDay(day);
  };

  const handleMouseLeave = () => setHoverEndDay(null);

  const handleReset = () => {
    setStartDate(initStartDate);
    setEndDate(initEndDate);
  };

  const handleOptionSelect = (option) =>
    handleChange(formatISODate(moment(option.item.start)), formatISODate(moment(option.item.end)), option.id);

  return (
    <Calendar
      {...calendarProps}
      displayMonthValue={formatISODate(lastHoverEndDay || endDate || startDate || displayMonth)}
      minDate={startDate && !endDate ? startDate : minDate}
      checkHighlighted={handleCheckHighlighted}
      checkHover={checkHover}
      checkSelected={checkSelected}
      handleMouseEnter={handleMouseEnter}
      handleMouseLeave={handleMouseLeave}
      showTime={false}
      handleChange={handleDateClick}
      sidePanel={options && <Options options={options} onSelect={handleOptionSelect} />}
      portalRef={portalRef}
    >
      {showFooterInfo ? (
        <Footer
          startDate={startDate ? formatISODate(startDate) : null}
          endDate={endDate ? formatISODate(endDate) : null}
          onReset={handleReset}
          isRegularText={!!options}
        />
      ) : null}
    </Calendar>
  );
};

export default RangeCalendar;
