import chunk from "lodash/chunk";
import { useActivityCurrentUserRole, useActivityPermissions } from "PFApp/activities/hooks";
import commonCss from "PFApp/activities/show/activity_page/activity_page.module.scss";
import { TooltipButtonIcon } from "PFApp/activities/show/activity_page/components/tooltip_button_icon";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useActivityShortlistsFilters } from "PFApp/activities/show/hooks/use_activity_shortlists_filters";
import { useIsUnbiasedEnabled } from "PFApp/activities/show/hooks/use_is_unbiased_enabled";
import RefreshAction from "PFApp/activities/show/parts/refresh_action";
import LazyLocalExportTrigger from "PFApp/components/document_buttons/lazy_local_export_trigger";
import { SearchEntity } from "PFApp/components/search_autocomplete/search_autocomplete.types";
import { SearchInput } from "PFApp/components/search_input";
import { checkFiltersAreClear } from "PFApp/use_filtered_collection";
import { TableProperties, TablePropertiesButton } from "PFApp/workflow/parts/table_properties";
import { Alert } from "PFComponents/alert";
import { Modal } from "PFComponents/modal";
import { getTermFilterData } from "PFCore/helpers/get_term_filter_data";
import { useShortlistsInvalidate } from "PFCore/hooks/queries/shortlists/use_shortlists_invalidate";
import { getExportProfiles } from "PFCore/services/export_profiles";
import { Id, Shortlist } from "PFTypes";
import { RecentSearchesPageKey } from "PFTypes/recent_searches";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useToggle } from "react-use";

import { ProfilesViewMode } from "../../../../../../../../types/profiles_view_mode";
import { OrderSelector } from "../../../../components/order_selector";
import { ProfileViewModeToggle } from "../../../../components/profiles_view_mode_toggle";
import { AddShortlistModal } from "./modals/add_shortlist_modal";
import css from "./shortlist_header.module.scss";

const blockedList = ["availability"];

const getProfilesCollection = (
  shortlists: Shortlist[],
  activityId: Id,
  paginationPage = 1,
  paginationSize = 50
) => {
  const items = (shortlists || []).map(({ profile }) => profile);
  const paginatedArray = chunk(items, paginationSize);
  return getExportProfiles({
    profileIds: paginatedArray[paginationPage - 1].map(({ id }) => id),
    activityId: activityId
  }).then((resp) => ({
    entries: resp.map((profile) => ({
      profile
    })),
    meta: { total: resp.length, page: paginationPage }
  }));
};

type ShortlistHeaderProps = {
  handleClearShortlist: VoidFunction;
};

export const ShortlistHeader = ({ handleClearShortlist }: ShortlistHeaderProps) => {
  const { t } = useTranslation(["activities", "translation"]);

  const navigate = useNavigate();
  const location = useLocation();

  const {
    task: activity,
    shortlists,
    shortlistsMeta,
    suspendedShortlistsMeta,
    availableMatchesShortlistColumns,
    selectedMatchesShortlistColumns,
    profilesViewMode,
    setSelectedMatchesShortlistColumns
  } = useActivityPageContext();

  const { isReviewer, isReviewerUnbiased } = useActivityCurrentUserRole(activity);
  const isUnbiasedEnabled = useIsUnbiasedEnabled(activity);
  const { invalidateShortlists } = useShortlistsInvalidate();
  const { updateFilter, handleOrderChange, clearFilters } = useActivityShortlistsFilters();

  const [isClearShortlistConfirmModalOpen, toggleClearShortlistConfirmModalOpen] = useToggle(false);
  const [isAddShortlistModalOpen, toggleAddShortlistModalOpen] = useToggle(false);
  const [isTablePropertiesVisible, setTablePropertiesVisibility] = useState(false);

  useEffect(() => {
    if (profilesViewMode === ProfilesViewMode.Cards) {
      setTablePropertiesVisibility(false);
    }
  }, [profilesViewMode]);

  const { canAddShortlist } = useActivityPermissions(activity);
  const { termFilter, termValue, isTermFilterAvailable } = getTermFilterData(shortlistsMeta);

  const handleCompareAll = () => {
    navigate(`/activities/${activity!.id}/compare/details`, {
      state: { prevPath: `${location.pathname}${location.search}` }
    });
  };

  const filtersAreClear = checkFiltersAreClear(shortlistsMeta.filters, blockedList);

  const isBlindModeOn = isUnbiasedEnabled && isReviewerUnbiased;
  const isCompleted = activity.state === "complete";
  const isCompareAllDisabled = shortlists.length < 2;

  const resultsLabel = useMemo(() => {
    const total = shortlistsMeta.total + suspendedShortlistsMeta.total;

    if (total === 0) {
      return null;
    }

    return t(
      `activities:show.shortlist.${
        filtersAreClear ? "shortlistsPaginationInfo" : "shortlistsPaginationInfoFiltered"
      }`,
      { count: total }
    );
  }, [filtersAreClear, shortlistsMeta, suspendedShortlistsMeta, t]);

  return (
    <>
      <div className={css.container}>
        <SearchInput
          term={termValue}
          disabled={!isTermFilterAvailable}
          pageKey={RecentSearchesPageKey.ActivityShortlist}
          autocompleteConfig={[
            {
              entity: SearchEntity.Profile,
              useAsTerm: true
            }
          ]}
          includeRecentSearches={false}
          classes={{ root: commonCss.searchInput }}
          onTermChange={(newTerm: string) => {
            updateFilter(termFilter!, newTerm);
          }}
          qaId="ActivityShortlistTabSearchInput"
        />
        <OrderSelector
          meta={shortlistsMeta}
          onOrderChange={handleOrderChange}
          label={resultsLabel}
          className={css.sortByBlock}
        />
        <ProfileViewModeToggle />
        {profilesViewMode === ProfilesViewMode.Table && (
          <TablePropertiesButton onClick={() => setTablePropertiesVisibility(true)} />
        )}
        <TooltipButtonIcon
          icon="compare"
          onClick={handleCompareAll}
          tooltipContent={
            isCompareAllDisabled
              ? t("activities:show.iconsTooltips.compareAllDisabled")
              : t("activities:show.iconsTooltips.compareAll")
          }
          disabled={isCompareAllDisabled}
          qaId="ActivityShortlistTabCompareButton"
        />
        {!(isReviewer && isBlindModeOn) && canAddShortlist && (
          <>
            <TooltipButtonIcon
              icon="add-profile"
              onClick={() => toggleAddShortlistModalOpen()}
              tooltipContent={t("activities:show.iconsTooltips.addToShortlist")}
              qaId="ActivityShortlistTabAddProfileButton"
            />
            <TooltipButtonIcon
              icon="remove-all"
              onClick={() => toggleClearShortlistConfirmModalOpen()}
              tooltipContent={t("activities:show.iconsTooltips.clearShortlist")}
              qaId="ActivityShortlistTabRemoveAllButton"
            />
          </>
        )}
        {/* @ts-ignore */}
        <LazyLocalExportTrigger
          quantity={shortlists?.length || 0}
          getProfilesCollection={() => getProfilesCollection(shortlists, activity.id)}
          isBlindModeOn={isBlindModeOn}
          buttonKind="secondary"
          icon="export"
          tooltipContent={t("activities:show.iconsTooltips.export")}
          qaId="ActivityShortlistTabExportButton"
        />
        {!isCompleted && activity.coowned && (
          <RefreshAction
            activityId={activity.id}
            onUpdate={() => {
              invalidateShortlists();
            }}
            showLabel={false}
            qaId="ActivityShortlistTabRefreshMatchesButton"
          />
        )}
      </div>
      {!filtersAreClear && (
        <Alert
          title={t("activities:show.shortlist.filtersChangedWarning")}
          action={{
            text: t("translation:filters.clearAll"),
            icon: "filter-clean",
            kind: "text",
            onClick: () => clearFilters()
          }}
          small
        />
      )}
      {isClearShortlistConfirmModalOpen && (
        <Modal
          title={t("activities:show.shortlist.clearShortlist")}
          onOK={handleClearShortlist}
          onClose={() => toggleClearShortlistConfirmModalOpen()}
          labelOK={t("activities:show.shortlist.yesRemove")}
          kindOK="danger"
        >
          {t("activities:show.shortlist.wantClearPendingShortlists")}
        </Modal>
      )}
      {isAddShortlistModalOpen && <AddShortlistModal onClose={() => toggleAddShortlistModalOpen()} />}
      <TableProperties
        isVisible={isTablePropertiesVisible}
        onCloseClick={() => setTablePropertiesVisibility(false)}
        defaultColumns={availableMatchesShortlistColumns}
        selectedColumns={selectedMatchesShortlistColumns}
        onColumnsChange={setSelectedMatchesShortlistColumns}
      />
    </>
  );
};
