import classNames from "classnames";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

import css from "./display_option.module.scss";
import { CostField } from "./matches_named_resources_selector";

type OptionsTableHeaderProps = {
  costField?: CostField;
};

export const OptionsTableHeader = ({ costField }: OptionsTableHeaderProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "profilesSelector.options.header" });

  return (
    <div className={classNames(css.grid, css.header, { [css.withCostField]: !!costField })}>
      <Typography variant="labelRegular">{t("name")}</Typography>
      {!!costField && (
        <Typography variant="labelRegular" clipOverflow>
          {costField.label}
        </Typography>
      )}
      <Typography variant="labelRegular">{t("match")}</Typography>
      <Typography variant="labelRegular">{t("availability")}</Typography>
    </div>
  );
};
