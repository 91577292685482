import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { customTypesAccessLevelsAgainstProfile } from "PFCore/helpers/custom_types";
import { getMainPosition } from "PFCore/helpers/profile";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { Profile } from "PFTypes";

import css from "./grade_and_rate_cell.module.scss";

type GradeAndRateCellProps = {
  profile: Profile;
};

export const GradeAndRateCell = ({ profile }: GradeAndRateCellProps) => {
  const { data: currentProfile } = useCurrentProfile();
  const { getCustomTypeByName } = useCustomTypes();

  const gradeCustomType = getCustomTypeByName("grade");
  const gradeAccessLevel =
    !!gradeCustomType && customTypesAccessLevelsAgainstProfile(profile, currentProfile)[gradeCustomType.id];
  const canViewGrade = !!gradeAccessLevel;

  const getValue = (name: string) => {
    const values = profile[name] || getMainPosition(profile)[name] || [];
    return values[0];
  };

  const grade = getValue("grade");
  const rate = getValue("day_rate");
  const gradeLabel = gradeCustomType?.display_as || gradeCustomType?.name || "";
  const gradeText = grade ? grade.text : "-";

  if (!canViewGrade && !rate) {
    return null;
  }

  return (
    <div className={css.root}>
      {canViewGrade && (
        <Typography variant="bodyRegular" className={css.grade} clipOverflow>
          {`${gradeLabel}: `}
          <b title={gradeText}>{gradeText}</b>
        </Typography>
      )}
      {rate && (
        <div className={css.rate}>
          <Icon name="money" color="paletteNeutral3" />
          <Typography variant="bodyBold">{rate.text}</Typography>
        </div>
      )}
    </div>
  );
};
