import capitalize from "lodash/capitalize";
import { Button } from "PFComponents/button";
import { Typography } from "PFComponents/typography";
import { isRankable } from "PFCore/helpers/custom_type";
import { Experience, Importance, PureCustomValueCamelCased } from "PFTypes";
import { useTranslation } from "react-i18next";

import { useCustomValuePreviewContext } from "../../context/custom_value_preview_context";
import css from "../../custom_value_preview.module.scss";
import { Details } from "../../parts/details";
import { ExperienceToggle } from "../../parts/experience_toggle";
import { ImportanceToggle } from "../../parts/importance_toggle";

type ActivityCustomValueContentProps = {
  importance: Importance | null;
  requiredExperience: Experience | null;
  customValue: PureCustomValueCamelCased;
  allowEdit: boolean;
  templateName: string | null;
  onRequiredExperienceChange: (experience: Experience) => void;
  onImportanceChange: (importance: Importance) => void;
  onResetForm: VoidFunction;
};

export const ActivityCustomValueContent = ({
  importance,
  requiredExperience,
  allowEdit,
  customValue,
  onRequiredExperienceChange,
  templateName,
  onImportanceChange,
  onResetForm
}: ActivityCustomValueContentProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "customValueSidepanel" });
  const { isNew, customType, allowSearch } = useCustomValuePreviewContext();

  const rankable = isRankable(customType);
  const infoMessage = isNew
    ? t("activityDoesntHaveValueAddIt", { templateName: capitalize(templateName || "") })
    : allowEdit
    ? t("activityAlreadyHasValueUpdateIt", { templateName: capitalize(templateName || "") })
    : null;

  return (
    <>
      {isNew && allowSearch && (
        <Button
          kind="text"
          text={t("searchForAnotherCustomValue")}
          icon="arrow-left"
          onClick={onResetForm}
          className={css.linkButton}
        />
      )}
      {infoMessage && <Typography variant="bodyRegular">{infoMessage}</Typography>}
      <ImportanceToggle canEdit={allowEdit} importance={importance} onChange={onImportanceChange} />
      {rankable && (
        <ExperienceToggle
          experience={requiredExperience}
          onChange={onRequiredExperienceChange}
          canEdit={allowEdit}
          isRequirement
        />
      )}
      <Details customValue={customValue} />
    </>
  );
};
