import Tooltip from "PFComponents/tooltip/tooltip";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { Booking } from "PFTypes";

import { useNotApplicable } from "../hooks/use_not_applicable";

const COLLAPSED_MODE_MAX_LENGTH = 18;
const EXPANDED_MODE_MAX_LENGTH = 50;

type TextColumnProps = {
  text: string;
  booking: Booking;
  isExpanded: boolean;
};

export const TextColumn = ({ text, booking, isExpanded }: TextColumnProps) => {
  const { getCategory } = useBookingCategories();
  const NOT_APPLICABLE = useNotApplicable();
  const category = getCategory(booking);

  const maxLength = isExpanded ? EXPANDED_MODE_MAX_LENGTH : COLLAPSED_MODE_MAX_LENGTH;
  const isTextTooLong = text.length > maxLength;
  const displayedText = isTextTooLong ? `${text.slice(0, maxLength)}...` : text;

  return (
    <Tooltip content={isTextTooLong && category ? <span>{text}</span> : undefined}>
      <div>{category ? displayedText : NOT_APPLICABLE}</div>
    </Tooltip>
  );
};
