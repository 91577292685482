import { InputFieldSet } from "PFComponents/text/input_field_set";
import PropTypes from "prop-types";

const BasicInputField = (props) => {
  const {
    dummy,
    value,
    label,
    tip,
    showPasswordToggle,
    attr,
    errors = {},
    locked,
    lockedTip,
    inputType,
    className,
    handleChange,
    handleKeyDown
  } = props;

  const inputValue = dummy ? dummy.get(attr) || "" : value;

  return (
    <InputFieldSet
      label={label}
      value={inputValue}
      inputType={inputType}
      tip={tip}
      qaId={attr}
      showPasswordToggle={showPasswordToggle}
      locked={locked}
      lockedTip={lockedTip}
      className={className}
      error={errors[attr]}
      onKeyDown={handleKeyDown}
      onChange={(val) => {
        dummy && dummy.set(attr, val);
        handleChange && handleChange(val);
      }}
    />
  );
};

BasicInputField.propTypes = {
  dummy: PropTypes.any,
  label: PropTypes.string.isRequired,
  attr: PropTypes.string.isRequired,
  locked: PropTypes.bool,
  lockedTip: PropTypes.string,
  className: PropTypes.string,

  errors: PropTypes.object,
  inputType: PropTypes.string,
  value: PropTypes.string,
  tip: PropTypes.string,
  showPasswordToggle: PropTypes.bool,
  handleChange: PropTypes.func,
  handleKeyDown: PropTypes.func
};

export default BasicInputField;
