import classNames from "classnames";
import { Typography } from "PFComponents/typography";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

import css from "./matches_chart.module.scss";

type MatchesChartXAxisTitleProps = {
  style?: CSSProperties;
};

export const MatchesChartXAxisTitle = ({ style }: MatchesChartXAxisTitleProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.matches" });

  return (
    <Typography
      withMargin
      variant="labelSmallBold"
      className={classNames(css.axisTitle, css.yAxisTitle)}
      style={style}
    >
      {t("chart.profiles")}
    </Typography>
  );
};
