import { Avatar } from "PFComponents/avatar";
import { ProfileAvatar } from "PFComponents/profile_avatar/profile_avatar";
import { getProfileName } from "PFCore/helpers/profile";
import { profileHash } from "PFCore/helpers/profile_hash";
import { IconName } from "PFTheme/graphics/icons";
import { Profile } from "PFTypes";
import { SyntheticEvent } from "react";

import Headline from "./headline";
import ProfileCardName from "./profile_card_name";
import css from "./profile_card_title.module.scss";

type Props = {
  avatarSize: number;
  currentProfile: Profile;
  href?: string;
  handleClick?: () => void;
  handleIconClick?: (e: SyntheticEvent) => void;
  icon?: IconName;
  isBlindModeOn?: boolean;
  onAvatarClick?: (e: SyntheticEvent) => void;
  outlook?: boolean;
  profile: Profile;
};

const ProfileCardTitle = ({
  avatarSize,
  currentProfile,
  href,
  handleClick,
  handleIconClick,
  isBlindModeOn,
  profile,
  onAvatarClick,
  outlook,
  icon
}: Props) => {
  const { custom_types: customTypes } = currentProfile;
  const name = getProfileName(profile);

  const onClick: (ev: SyntheticEvent) => void = (event) => {
    event.preventDefault();
    if (handleClick) {
      handleClick();
    }
  };

  return (
    <div className={css.title}>
      <div className={css.avatar}>
        {isBlindModeOn ? (
          <Avatar size={avatarSize || 80} backgroundColor="#ffc0cb" text={profileHash(profile.id)} />
        ) : (
          <ProfileAvatar
            profile={profile}
            size={avatarSize || 80}
            icon={icon}
            onClick={onAvatarClick}
            handleIconClick={handleIconClick}
          />
        )}
      </div>

      <div className={css.info}>
        <ProfileCardName
          href={href}
          isBlindModeOn={isBlindModeOn}
          profile={profile}
          name={name}
          outlook={outlook}
          onClick={outlook ? onAvatarClick : onClick}
        />
        {customTypes && (
          <span className={css.headline} data-qa-id="ProfileCard.info">
            <Headline profile={profile} customTypes={customTypes} />
          </span>
        )}
      </div>
    </div>
  );
};

export default ProfileCardTitle;
