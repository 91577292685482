import moment from "moment";
import { getWeekdays, getWeekdaysShort } from "PFCore/helpers/date";

import { Typography } from "../../../typography";
import { CalendarSelectProps, MonthSelector } from "../../month_selector";
import css from "./calendar_header.module.scss";
import { ChangeMonthButton } from "./change_month_button";

type CalendarHeaderProps = CalendarSelectProps & {
  showArrows: boolean;
  label: string;
};

export const CalendarHeader = ({
  showArrows,
  label,
  minDate,
  maxDate,
  displayMonth,
  setDisplayMonth
}: CalendarHeaderProps) => {
  const selectProps = { minDate, maxDate, displayMonth, setDisplayMonth };
  const buttonProps = { displayMonth, setDisplayMonth };
  const weekDays = getWeekdays();
  const weekDaysShort = getWeekdaysShort();

  return (
    <div className={css.root} data-qa-id="CalendarHeader">
      {label && (
        <Typography withMargin variant="labelBold" data-qa-id="CalendarHeader.label">
          {label}
        </Typography>
      )}
      <div className={css.title} role="row">
        <MonthSelector {...selectProps} />

        {showArrows && (
          <div className={css.changeMonthContainer} role="gridcell">
            <ChangeMonthButton {...buttonProps} dir={-1} dateLow={displayMonth} dateHigh={moment(minDate)} />
            <div className={css.separator} />
            <ChangeMonthButton {...buttonProps} dir={1} dateLow={maxDate} dateHigh={displayMonth} />
          </div>
        )}
      </div>

      <div className={css.daynames} role="row">
        {weekDays.map((dayName, index) => (
          <span role="columnheader" aria-label={dayName} className={css.dayname} key={dayName}>
            {weekDaysShort[index]}
          </span>
        ))}
      </div>
    </div>
  );
};
