import isEmpty from "lodash/isEmpty";
import some from "lodash/some";
import { BookingDetailActivity } from "PFApp/booking/components/booking_detail_activity";
import { setTimeToMidnight } from "PFApp/booking/parts/overview/calendar/calendar.utils";
import SidePanel from "PFComponents/side_panel/side_panel";
import { useBooking } from "PFCore/hooks/queries";
import { OverbookedDates, OverbookingsManagementMode } from "PFCore/services/bookings_suggested_changes";
import { BasicProfile, BookingCategory } from "PFTypes";
import { useEffect } from "react";
import { useFormState, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useBookingActivityContext } from "../../parts/providers/booking_activity_context_provider";
import { useDetailsPanelOrderContext } from "../details_panel";
import { AvailabilityInfo } from "./availability_info";
import css from "./booking_form.module.scss";
import { getHeaderTitleTranslationKey } from "./booking_form.utils";
import { useBookingFormContext } from "./booking_form_context_provider";
import { BookingFormGlobalInputs } from "./booking_form_global_inputs";
import { BookingFormValues } from "./booking_form_provider";
import { BookingRangeColumn } from "./booking_range_column";
import { BookingTypeSelector } from "./booking_type_selector";
import { EngagementSelector, useShouldDisplayEngagementSelect } from "./engagement_selector";
import { Footer } from "./footer/footer";
import { useOverbookingsManagementApiContext } from "./overbookings_management_context/overbookings_management_context";
import { useAreBookingsValid } from "./use_are_bookings_valid";
import { BookingFormData, BookingFormDataEditOrCloneMode, BookingFormMode } from "./use_booking_form";
import { usePotentialWarnings } from "./use_potential_warnings";
import { WorkforceMemberSelect } from "./workforce_member_select";
import { isGroupOption } from "./workforce_member_select/workforce_member_select_dropdown";

export type BookingFormProps = {
  show: boolean;
  onClose?: () => void;
  onOpenOverbookingsManagementModal: (
    mode: OverbookingsManagementMode,
    overbookedDates: OverbookedDates
  ) => void;
  initialData?: BookingFormData;
  mode: BookingFormMode;
  goToDate?: (date: Date) => void;
};

export const BookingForm = ({
  show,
  onClose,
  initialData = {},
  mode,
  onOpenOverbookingsManagementModal,
  goToDate
}: BookingFormProps) => {
  const { t } = useTranslation(["bookingModule", "translation"]);
  const { activity, parentActivity, isLoading: isActivityLoading } = useBookingActivityContext();
  const { loadingState, profileId, activeBookingIndex, setActiveBookingsIndex } = useBookingFormContext();
  const { setOverbookedBookings, clearOverbookedBookings } = useOverbookingsManagementApiContext();
  const areBookingsValid = useAreBookingsValid();

  const isCreateMode = mode === BookingFormMode.Create;
  const bookingId = isCreateMode ? undefined : (initialData as BookingFormDataEditOrCloneMode).bookingId;
  const { data: initialBooking } = useBooking(bookingId, { enabled: !!bookingId });

  const { findIndexOfBookingForm } = useDetailsPanelOrderContext();
  const [workforceMember, bookings, activityId, globalCategory] = useWatch<
    BookingFormValues,
    ["workforceMember", "bookings", "activityId", "globalCategory"]
  >({
    name: ["workforceMember", "bookings", "activityId", "globalCategory"]
  });
  const { isSubmitting, isValid, errors } = useFormState();

  const isGroupBooking = !!initialBooking?.booking_group_id || isGroupOption(workforceMember);
  const shouldDisplayEngagementSelect = useShouldDisplayEngagementSelect(isGroupBooking);

  const { overbookings, misalignments, overbookingIntersectionsDates } = usePotentialWarnings({
    mode
  });

  const sidePanelTitleKey = getHeaderTitleTranslationKey(mode);

  const isBookingFormLoading = !isEmpty(loadingState) && some(loadingState, (value) => !!value);
  const isFormValid = isValid && areBookingsValid;
  const isSubmitDisabled = !isFormValid || isSubmitting || isBookingFormLoading || !isEmpty(errors);

  useEffect(() => {
    if (mode === BookingFormMode.Edit) {
      return;
    }

    const categoryAffectsAvailability = isCreateMode
      ? (globalCategory as BookingCategory)?.affects_availability
      : (bookings ?? [])
          .map((booking) =>
            booking?.category ? (booking?.category as BookingCategory)?.affects_availability : false
          )
          .reduce((acc, curr) => acc || curr, false);

    if (
      (overbookingIntersectionsDates ?? []).length > 0 &&
      bookings.length > 0 &&
      categoryAffectsAvailability
    ) {
      const overbookedBookings = bookings.map((booking) =>
        isCreateMode ? { ...booking, category: globalCategory } : booking
      );
      // @ts-ignore
      setOverbookedBookings(workforceMember?.id, activityId, overbookedBookings);
      if (goToDate) {
        goToDate(setTimeToMidnight(new Date(bookings[activeBookingIndex || 0].startDate)));
      }
    } else {
      clearOverbookedBookings();
    }
  }, [mode, overbookingIntersectionsDates, bookings]);

  return (
    <SidePanel
      show={show}
      zIndex={findIndexOfBookingForm() + 1}
      onClose={onClose}
      title={t(sidePanelTitleKey)}
      footerRenderer={({ onSidePanelClose }) => (
        <Footer
          profileId={(workforceMember as BasicProfile)?.id}
          activity={activity}
          misalignments={misalignments ?? []}
          overbookings={overbookings ?? []}
          overbookingIntersectionsDates={overbookingIntersectionsDates}
          onClose={onSidePanelClose}
          isSubmitDisabled={isSubmitDisabled}
          isLoading={isBookingFormLoading}
          mode={mode}
          onOpenOverbookingsManagementModal={onOpenOverbookingsManagementModal}
        />
      )}
    >
      <div className={css.content}>
        <div className={css.section}>
          <WorkforceMemberSelect
            initialData={initialData}
            mode={mode}
            booking={initialBooking}
            readonly={!!profileId}
          />
          {!!activity && !shouldDisplayEngagementSelect && (
            <BookingDetailActivity
              activity={activity}
              parentActivity={parentActivity}
              isLoading={isActivityLoading}
            />
          )}
          {activity?.metadata.availability && (
            <AvailabilityInfo availability={activity.metadata.availability} />
          )}
          {parentActivity?.id && (
            <BookingDetailActivity activity={parentActivity} isLoading={isActivityLoading} />
          )}
          {shouldDisplayEngagementSelect && (
            <EngagementSelector titleFormValueName="globalTitle" mode={mode} />
          )}
        </div>
        <BookingTypeSelector initialData={initialData} mode={mode} />
        <div className={css.section}>
          <BookingRangeColumn
            initialData={initialData}
            mode={mode}
            initialBooking={initialBooking}
            activeBookingIndex={activeBookingIndex}
            setActiveBookingIndex={setActiveBookingsIndex}
          />
        </div>
        {isCreateMode && <BookingFormGlobalInputs />}
      </div>
    </SidePanel>
  );
};
