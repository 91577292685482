/* eslint-disable camelcase */
import { Camelized, decamelizeKeys } from "humps";
import omit from "lodash/omit";
import { PER_PAGE_UNPAGINATED } from "PFApp/constants/unpaginated";
import api from "PFCore/api";
import { fetchPaginatedCollection } from "PFCore/helpers/collection";
import { BookingPartPayload } from "PFCore/hooks/queries/bookings/part_update/use_booking_part_update";
import { CalendarGranularity } from "PFCore/hooks/queries/profile/use_profiles_availabilities";
import { ApiRoute } from "PFCore/utilities/routes";
import {
  BookingTemplate,
  CalendarRange,
  Collection,
  Currency,
  Filters,
  Id,
  OrderOption,
  PaginatedCollection,
  Value
} from "PFTypes";
import { Booking } from "PFTypes";

import { getAPIFilters } from "../../use_filtered_collection";
import { BookingResponse } from "../components/booking_form/use_handle_submit/use_handle_submit";
import { ActivityMisalignments, Overbooking } from "../types";

export type FetchBookings = {
  profileId: number;
  activityId?: number | boolean;
  bookingTemplateId?: number;
  bookingCategoryId?: number[];
  dateRange?: { min: string; max: string };
  withDurationBreakdown?: boolean;
  perPage?: number;
};

/* /duration_breakdown endpoint should return the same response, at most with additional fields */
export const fetchBookings = ({
  profileId,
  activityId,
  bookingTemplateId,
  bookingCategoryId,
  dateRange,
  withDurationBreakdown,
  perPage = PER_PAGE_UNPAGINATED
}: FetchBookings): Promise<PaginatedCollection<Omit<Booking, "activity">[]>> =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(
      withDurationBreakdown
        ? `/api/booking_module/bookings/duration_breakdown`
        : `/api/booking_module/bookings`
    ),
    method: "GET",
    api_version: 2,
    data: {
      activity_id: activityId,
      profile_id: profileId,
      per_page: perPage,
      ...(bookingTemplateId ? { booking_template_id: bookingTemplateId } : {}),
      ...(bookingCategoryId ? { booking_category_id: bookingCategoryId } : {}),
      ...(dateRange ? { date_range: dateRange } : {})
    }
  });

export const fetchBooking = (id) =>
  api({
    url: `booking_module/bookings/${id}`
    // TODO: [PROF-4641] - remove decamelizeKeys when app is ready
    // converted to axios api to be working on outlook plugin
  }).then((resp) => decamelizeKeys(resp) as Booking);

export type DeleteBookingProps = {
  id?: number;
  profile_id?: number;
  date_range?: CalendarRange;
  activity_id?: number;
};

export const deleteBooking = (data: DeleteBookingProps): Promise<void> =>
  api({
    url: `booking_module/bookings`,
    method: "DELETE",
    data
  });

export const undoBooking = (shortlistId) =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(`/api/shortlists/${shortlistId}/reset`),
    method: "PUT",
    api_version: 2
  });

export type BookingDataToUpdate = Partial<
  Camelized<
    Pick<
      Booking,
      | "start_date"
      | "end_date"
      | "requirement_type"
      | "requirement_value"
      | "booking_category_id"
      | "title"
      | "description"
      | "overrides_calendar"
      | "overrides_non_working_days"
      | "phase_source_id"
      | "booking_group_id"
      | "profile_id"
    >
  >
>;

export type CreateBookingPayload = ({ profileId: number; activityId?: number } | { searchId: number }) &
  Camelized<{
    sourceType: "ui";
    bookings: BookingDataToUpdate[];
  }>;

export const createBooking = (payload: CreateBookingPayload) =>
  api({
    url: `booking_module/bookings`,
    method: "POST",
    data: payload
  });

export type UpdateBookingPayload = BookingDataToUpdate & Pick<Booking, "id">;

export const updateBooking = (payload: UpdateBookingPayload) =>
  api({
    url: `booking_module/bookings/${payload.id}`,
    method: "PUT",
    data: omit(payload, "id")
  });

export type UpdateBookingsPayload = (
  | { profileId: number; activityId?: number }
  | { bookingGroupId: number }
) &
  Camelized<{
    bookings: (BookingDataToUpdate & Pick<Booking, "id">)[];
  }>;

export const updateManyBookings = (payload: UpdateBookingsPayload): Promise<Collection<BookingResponse[]>> =>
  api({
    url: "booking_module/bookings/update_many",
    method: "PUT",
    data: payload
  });

export const updateBookingPart = (id: number, payload: BookingPartPayload) =>
  api({
    url: `booking_module/bookings/${id}/update_part`,
    method: "PUT",
    data: payload
  });

type FetchProfileWarnings = {
  profileId: number;
  activityId?: number;
  dateRange?: CalendarRange;
  newBooking?: boolean;
};
export const fetchProfileWarnings = ({
  profileId,
  dateRange,
  newBooking = false,
  activityId
}: FetchProfileWarnings) =>
  api({
    url: "booking_module/profile_warnings",
    params: {
      profileId,
      activityId,
      dateRange,
      newBooking
    }
  });

export const fetchActivityWarnings = (activityId: number) =>
  api({
    url: "booking_module/activity_warnings",
    params: { activityId }
  });

export const fetchProfileAvailabilities = ({
  profileId,
  dateRange,
  perPage = PER_PAGE_UNPAGINATED,
  page = 1,
  calendarGranularity
}) =>
  api({
    url:
      calendarGranularity === CalendarGranularity.Daily
        ? "booking_module/availabilities"
        : "booking_module/availabilities/weekly",
    params: {
      profileId,
      dateRange,
      page,
      perPage
    }
  });

type FetchWorkforceMetrics = {
  dateRange: CalendarRange;
  filters?: Filters<Value>;
  previousSearchId?: number;
};
type WorkforceMetricsData = {
  availableMinutes: number | null;
  billableUtilization: number | null;
  bookedMinutes: number | null;
  nominalWorkingMinutes: number | null;
  totalUtilization: number | null;
};

export const fetchWorkforceMetrics = ({
  dateRange,
  filters,
  previousSearchId
}: FetchWorkforceMetrics): Promise<WorkforceMetricsData> =>
  api({
    url: "booking_module/utilizations/index",
    method: "post",
    data: {
      dateRange,
      filters: getAPIFilters(filters),
      previousSearchId
    }
  });

export type PotentialWarningsResponse = {
  overbookings: Overbooking[];
  misalignments: ActivityMisalignments;
  exeedsCalendarHours: boolean;
  includesNonWorkingDays: boolean;
  overbookingIntersectionsDates: string[];
};

export const fetchPotentialWarningsForSingleBookings = (
  profileId: number,
  bookings: Partial<Booking>[]
): Promise<PotentialWarningsResponse> =>
  api({
    url: "booking_module/bookings/potential_warnings",
    method: "post",
    data: {
      profileId,
      bookings
    }
  });
export const fetchPotentialWarningsForBookingTemplates = (
  profileId: number,
  bookingTemplates: Partial<BookingTemplate>[]
): Promise<PotentialWarningsResponse> =>
  api({
    url: "booking_module/booking_templates/potential_warnings",
    method: "post",
    data: {
      profileId,
      bookingTemplates
    }
  });

export const fetchBookingHistory = ({ bookingId, page, perPage }) =>
  fetchPaginatedCollection(ApiRoute(`/api/booking_module/bookings/${bookingId}/history`), {
    page,
    perPage
  });

export type BookingTemplateDataToUpdate = Camelized<
  Pick<
    BookingTemplate,
    | "start_date"
    | "end_date"
    | "start_time"
    | "end_time"
    | "booking_category_id"
    | "title"
    | "description"
    | "overrides_calendar"
    | "overrides_non_working_days"
    | "wday_mask"
  >
>;

export type CreateBookingTemplatePayload = ({ profileId: number } | { searchId: number }) &
  BookingTemplateDataToUpdate;

export const createBookingTemplate = (payload: CreateBookingTemplatePayload) =>
  api({
    url: `booking_module/booking_templates`,
    method: "POST",
    data: payload
  });

export const fetchBookingTemplate = (id) =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(`/api/booking_module/booking_templates/${id}`),
    method: "GET",
    api_version: 2
  });

export type UpdateBookingTemplatePayload = {
  id: number;
  profileId: number;
} & BookingTemplateDataToUpdate &
  Partial<{ dateRange: CalendarRange; bookingGroupId: number }>;

export const updateBookingTemplate = (payload: UpdateBookingTemplatePayload) =>
  api({
    url: `booking_module/booking_templates${payload.bookingGroupId ? "" : `/${payload.id}`}`,
    method: "PUT",
    data: omit(payload, "id")
  });

export const deleteBookingTemplate = (id?: number, payload = {}) =>
  /* @ts-ignore type */
  $.ajax({
    url: ApiRoute(`/api/booking_module/booking_templates${id ? `/${id}` : ""}`),
    method: "DELETE",
    api_version: 2,
    contentType: "application/json; charset=utf-8",
    data: JSON.stringify(payload)
  });

export const fetchBookingApprovals = (params: { [key: string]: any } = {}): Promise<any> =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(`/api/booking_module/booking_approvals`),
    method: "GET",
    api_version: 2,
    data: { ...params, page: params.page || 1 }
  });

export const approveBookingApproval = (id, data) =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(`/api/booking_module/booking_approvals/${id}/approve`),
    method: "PUT",
    api_version: 2,
    data
  });

export const rejectBookingApproval = (id, data) =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(`/api/booking_module/booking_approvals/${id}/reject`),
    method: "PUT",
    api_version: 2,
    data
  });

export const requestBookingApproval = (id, data) =>
  /* @ts-ignore type */
  $.ajax({
    url: ApiRoute(`/api/booking_module/booking_approvals/${id}/request_approval`),
    method: "PUT",
    api_version: 2,
    data
  });

const fetchProjects = (url, { page, perPage, order, filters }) =>
  fetchPaginatedCollection(
    ApiRoute(url),
    {
      page,
      perPage,
      order,
      filters: getAPIFilters(filters)
    },
    null,
    {
      method: "POST",
      contentType: "application/json; charset=utf-8",
      stringify: true
    }
  );

export const fetchProjectsEngagements = (params) =>
  fetchProjects(`/api/booking_module/engagements/index`, params);
export const fetchProjectsRoles = (params) => fetchProjects(`/api/booking_module/roles/index`, params);

type FetchWorkforceData = {
  page: number;
  perPage: number;
  order: Partial<OrderOption>;
  filters: any;
  dateRange: CalendarRange;
  previousSearchId?: number;
};

export const fetchWorkforce = ({
  page,
  perPage,
  order,
  filters,
  dateRange,
  previousSearchId
}: FetchWorkforceData) =>
  fetchPaginatedCollection(
    ApiRoute(`/api/booking_module/profiles/index`),
    {
      page,
      perPage,
      order,
      filters: getAPIFilters(filters),
      date_range: dateRange,
      previous_search_id: previousSearchId
    },
    null,
    {
      method: "POST",
      contentType: "application/json; charset=utf-8",
      stringify: true
    }
  );

export const fetchAdminBookingCategories = (page, perPage, params) =>
  fetchPaginatedCollection(ApiRoute(`/api/admin/booking_module/booking_categories`), {
    page,
    perPage,
    ...params
  });

export const fetchBookingsFromAvailability = (activity_id, profile_id) =>
  /* @ts-ignore type */
  $.ajax({
    url: ApiRoute(`/api/booking_module/activities/${activity_id}/query_bookings`),
    method: "GET",
    api_version: 2,
    stringify: true,
    contentType: "application/json; charset=utf-8",
    data: {
      profile_id
    }
  });

export type BookingEngagementsEconomicsResponse = Collection<{
  id: number;
  budget: number | null;
  cost: { value: number; currency: Currency };
}>;

export const fetchBookingEngagementsEconomics = (engagement_id: Id[]) =>
  api<BookingEngagementsEconomicsResponse>({
    url: "booking_module/engagements/economics",
    params: { engagement_id }
  });
