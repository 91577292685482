import classNames from "classnames";
import { useGrowl } from "PFApp/use_growl";
import { FileUpload } from "PFComponents/file_upload";
import { Icon } from "PFComponents/icon";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { Typography } from "PFComponents/typography";
import useDebounce from "PFCore/helpers/use_debounce";
import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import { deleteAttachment } from "PFCore/services/attachments/delete_attachment";
import { fetchUrlMetadata, FetchUrlMetadataResponse } from "PFCore/services/common";
import { Id } from "PFTypes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./upload_accomplishment.module.scss";

export type UploadPayload = {
  url?: string | null;
  attachmentId?: Id | null;
  imageUrl?: string | null;
};

type UploadedFile = {
  fileName: string;
  url: string;
  id: number;
};

type UploadAccomplishmentProps = {
  setUploadPayload: (payload: UploadPayload | null) => void;
  uploadPayload?: UploadPayload;
  setUrlRetrievedData: (data: FetchUrlMetadataResponse | null) => void;
  errors: any[];
};

const UploadAccomplishment = ({
  setUploadPayload,
  uploadPayload,
  setUrlRetrievedData,
  errors
}: UploadAccomplishmentProps) => {
  const { t } = useTranslation("profiles", { keyPrefix: "common.certificates" });
  const growlErrors = useErrorsGrowl();
  const growl = useGrowl();

  const [link, setLink] = useState(uploadPayload?.url);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);

  const isValid = (url) => /\.(jpg|jpeg|png|pdf)$/i.test(url);

  useEffect(() => {
    setLink(uploadPayload?.url);
    setUploadPayload({
      url: uploadPayload?.url,
      attachmentId: uploadPayload?.attachmentId,
      imageUrl: uploadPayload?.imageUrl
    });
    uploadPayload?.imageUrl && setPreviewImage(uploadPayload?.imageUrl);
  }, [uploadPayload?.url]);

  useEffect(
    useDebounce(() => {
      if (link) {
        fetchUrlMetadata(link)
          .then((resp) => {
            if (resp) {
              setPreviewImage(resp?.image);
              setUrlRetrievedData(resp);
              setUploadPayload({
                url: link,
                attachmentId: null,
                imageUrl: resp?.image
              });
            } else if (isValid(link)) {
              setPreviewImage(link);
              setUrlRetrievedData(null);
              setUploadPayload({
                url: link,
                attachmentId: null,
                imageUrl: link
              });
            } else {
              setUploadPayload({
                url: link,
                attachmentId: null,
                imageUrl: link
              });
              setPreviewImage(null);
              setUrlRetrievedData(null);
            }
          })
          .catch(() => {
            setUploadPayload({
              url: link,
              attachmentId: null,
              imageUrl: link
            });
            setPreviewImage(null);
            setUrlRetrievedData(null);
          });
      }
    }, 500),
    [link]
  );

  const uploadFile = (data: UploadedFile) => {
    setUploadedFile(data);
    if (data) {
      setUploadPayload({
        url: null,
        attachmentId: data.id,
        imageUrl: null
      });
    }
  };

  const handleReset = () => {
    deleteAttachment(uploadedFile?.id);
    setUploadPayload({
      url: null,
      attachmentId: null,
      imageUrl: null
    });
    setUploadedFile(null);
  };

  const findError = (key) => errors?.find((error) => error?.source?.pointer?.search(key) > 0)?.detail;

  const handleError = (resp) => {
    growlErrors(resp, { display_all: true, version: "v2" });
  };

  const handleTypeError = () => {
    growl({
      message: t("invalidFileType"),
      kind: "error"
    });
  };

  return (
    <div className={css.wrapper}>
      <div className={classNames({ [css.faded]: link })}>
        <FileUpload<UploadedFile>
          url="attachments"
          name="attachment"
          qaId="certificate-file-upload"
          header={t("uploadFile")}
          uploadedFile={uploadedFile?.fileName}
          onLoad={uploadFile}
          onError={handleError}
          allowedFileTypes={["jpg", "jpeg", "png", "pdf"]}
          onTypeError={handleTypeError}
          onReset={handleReset}
        />
      </div>
      <div className={classNames(css.fileCard, css.fileCardBody, { [css.faded]: uploadedFile })}>
        <Typography variant="h4">{t("orPasteURL")}</Typography>
        <div
          className={classNames({
            [css.link]: !previewImage,
            [css.imageLinked]: previewImage
          })}
        >
          {previewImage ? (
            <div className={css.imageLinked}>
              <img alt="certificate" src={previewImage} onError={() => setPreviewImage(null)} />
            </div>
          ) : (
            <Icon name="link" size="xl" />
          )}
        </div>
        <div>
          <Typography variant="bodyRegular">{t("enterCertificateURL")}</Typography>
        </div>
        <InputFieldSet
          placeholder={t("pasteURLHere")}
          value={link || undefined}
          error={!uploadedFile && findError("url")}
          onChange={(url) => setLink(url)}
          disabled={!!uploadedFile}
        />
      </div>
    </div>
  );
};

export default UploadAccomplishment;
