import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

export type RequirementType = "load_percentage" | "total_duration" | "duration_per_day";

type RequirementTypeAttributeProps = {
  value: RequirementType;
};

export const RequirementTypeAttribute = ({ value }: RequirementTypeAttributeProps) => {
  const { t } = useTranslation("bookingModule");
  return (
    <Typography withMargin variant="bodyRegular" tag="span">
      {t(`bookings.requirement.${value}`)}
    </Typography>
  );
};
