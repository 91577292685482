import { TimeRuleRange } from "PFTypes";

import { ReadableAvailabilityRulesProps } from "../../readable_availability_rules";
import { getSortedRanges, getVisibleRangesData } from "../../readable_availability_rules.utils";
import { ReadableRangesWrapper } from "../readable_ranges_wrapper";
import { ReadableTimeRange } from "./readable_time_range";

type ReadableTimeRuleAvailabilityProps = Required<Omit<ReadableAvailabilityRulesProps, "rangesLimit">> &
  Pick<ReadableAvailabilityRulesProps, "rangesLimit">;

export const ReadableTimeRuleAvailability = ({
  availability,
  multiline,
  rangesLimit
}: ReadableTimeRuleAvailabilityProps) => {
  const sortedRanges = getSortedRanges(availability.ranges);
  const { rangesToDisplay, isAnyRangeHidden } = getVisibleRangesData(sortedRanges, rangesLimit);

  return (
    <ReadableRangesWrapper availability={availability} showEllipsis={isAnyRangeHidden}>
      {rangesToDisplay.map((range, index) => (
        <ReadableTimeRange
          key={`${range.start}_${range.end}_${index}`}
          range={range as TimeRuleRange}
          multiline={multiline}
        />
      ))}
    </ReadableRangesWrapper>
  );
};
