import { InterestedContextProvider } from "./interested_context_provider";
import InterestedFilters from "./interested_filters";
import InterestedPageHeader from "./interested_page_header";
import InterestedTable from "./interested_table";
import PropertiesPanel from "./properties_panel";

type ActivityInterestedProps = {
  handleInterestsCountChange: (count: number) => void;
};

export const ActivityInterested = ({ handleInterestsCountChange }: ActivityInterestedProps) => (
  <InterestedContextProvider onInterestsCountChange={handleInterestsCountChange}>
    <InterestedPageHeader />
    <InterestedFilters />
    <InterestedTable />
    <PropertiesPanel />
  </InterestedContextProvider>
);
