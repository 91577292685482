import { InfiniteData } from "@tanstack/query-core";
import { keepPreviousData } from "@tanstack/react-query";
import { UseInfiniteQueryResult } from "@tanstack/react-query";
import isNil from "lodash/isNil";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useStorageTableColumns } from "PFApp/components/table/hooks/use_storage_table_columns";
import { useTermFilteredItems } from "PFApp/components/table/hooks/use_term_filtered_items";
import { TableColumn } from "PFApp/components/table/table_types";
import { getAPIFilters, getValueFilters } from "PFApp/use_filtered_collection";
import useStorageFilters, { FiltersStorage } from "PFApp/use_filtered_collection/use_storage_filters";
import useStorageOrder, { OrderStorage } from "PFApp/use_filtered_collection/use_storage_order";
import { getProfileName } from "PFCore/helpers/profile";
import { stringify } from "PFCore/helpers/use_deterministic_stringify";
import useStorage from "PFCore/helpers/use_storage";
import { useInfiniteActivityWatchers } from "PFCore/hooks/queries/activity/use_activity_watchers";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { convertToPercentage } from "PFCore/utilities/math";
import { Meta, ProfileMinimized } from "PFTypes";
import { Activity } from "PFTypes/activity";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useTranslation } from "react-i18next";

import { IRoleWatcher, IRoleWatchersResponse } from "../../../../../types/watchers";
import defaultColumns from "./constants/columns";
import useCustomInterestColumns from "./hooks/use_custom_interest_columns";
import { useExcludeHiddenColumns } from "./hooks/use_exclude_hidden_columns";

type InterestedContext = {
  selectedProfiles: IRoleWatcher[];
  setSelectedProfiles: Dispatch<SetStateAction<IRoleWatcher[]>>;
  pinSelected: boolean;
  togglePinSelected: () => void;
  showTableProperties: boolean;
  setTablePropertiesVisibility: (isVisible: boolean) => void;
  columns: any[];
  selectedColumns: TableColumn[];
  setSelectedColumns: (columnsToStore: TableColumn[]) => void;
  showFilters: boolean;
  toggleShowFilters: () => void;
  filtersStorage: FiltersStorage;
  watchersQuery: UseInfiniteQueryResult<InfiniteData<IRoleWatchersResponse>>;
  watchersMetadata: Meta | undefined;
  watchers: IRoleWatcher[];
  sortedWatchers: IRoleWatcher[];
  filteredSortedWatchers: IRoleWatcher[];
  orderStorage: OrderStorage;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
};

const InterestedContext = createContext({} as InterestedContext);

type InterestedContextProviderProps = PropsWithChildren<{
  onInterestsCountChange: (count: number) => void;
}>;

export const InterestedContextProvider = ({
  onInterestsCountChange,
  children
}: InterestedContextProviderProps) => {
  const { shortlists, task } = useActivityPageContext() as {
    task: Activity;
    shortlists?: { profile: ProfileMinimized }[];
  };
  const { formatDate } = useDateFormatter();
  const [selectedProfiles, setSelectedProfiles] = useState<IRoleWatcher[]>([]);
  const [pinSelected, setPinSelected] = useState(false);
  const [showTableProperties, setTablePropertiesVisibility] = useState(false);
  const excludeHiddenColumns = useExcludeHiddenColumns();
  const { t } = useTranslation("activities");

  const { customColumns } = useCustomInterestColumns();
  const columns = defaultColumns.concat(customColumns);
  const { selectedColumns, setSelectedColumns } = useStorageTableColumns(
    `activity_interested_columns_v2`,
    columns,
    defaultColumns.filter(({ isDefault }) => isDefault)
  );

  const filtersStorage = useStorageFilters({
    storagePrefix: "activity_interested",
    defaultValue: {}
  });

  const orderStorage = useStorageOrder({ storagePrefix: "activity_interested", defaultValue: {} });

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useStorage<string>("activity_interested_search_term", "");

  const { selectedFilters, setFilters } = filtersStorage;
  const { selectedOrder } = orderStorage;
  const { watchers, ...watchersQuery } = useInfiniteActivityWatchers(
    task,
    15,
    getAPIFilters(selectedFilters),
    selectedOrder,
    { placeholderData: keepPreviousData }
  );

  const watchersMetadata = watchersQuery?.data?.pages?.[0].meta;
  const interestsCount = watchersQuery?.data?.pages?.[0].meta.total;

  useEffect(() => {
    if (!isNil(interestsCount)) {
      onInterestsCountChange(interestsCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interestsCount]);

  useEffect(
    () => setFilters(getValueFilters(watchersMetadata?.filters || {})),
    [stringify(getAPIFilters(getValueFilters(watchersMetadata?.filters)))]
  );

  const toggleShowFilters = useCallback(() => {
    setShowFilters((prev) => !prev);
    setTablePropertiesVisibility(false);
  }, []);
  const togglePinSelected = useCallback(() => setPinSelected((prev) => !prev), []);

  const sortedWatchers = useMemo(() => {
    if (!pinSelected) {
      return watchers;
    }

    // eslint-disable-next-line id-length
    return [...watchers].sort((a, b) => {
      const selectedProfileA = selectedProfiles.find((profile) => profile.id === a.id) ? 1 : 0;
      const selectedProfileB = selectedProfiles.find((profile) => profile.id === b.id) ? 1 : 0;
      return selectedProfileB - selectedProfileA;
    });
  }, [watchers, selectedProfiles, pinSelected]);

  const formatShortlist = useCallback(
    (profile) => {
      if (!profile) {
        return "";
      }
      const isShortlisted = !!(shortlists || []).find(
        (shortlist) => shortlist.profile && shortlist.profile.id === profile.id
      );
      return isShortlisted ? t("show.parts.shortlisted") : t("show.parts.shortlist");
    },
    [shortlists, t]
  );

  const filterOptions = useMemo(
    () => ({
      selectedColumns,
      formatValues: {
        created_at: formatDate, // eslint-disable-line camelcase
        name: getProfileName,
        // eslint-disable-next-line camelcase
        normalized_score: (rawValue) => convertToPercentage(rawValue?.normalized_score),
        // eslint-disable-next-line camelcase
        availability_score: (rawValue) => convertToPercentage(rawValue?.availability_score),
        shortlist: formatShortlist
      }
    }),
    [formatDate, formatShortlist, selectedColumns]
  );

  const { filteredItems: filteredSortedWatchers } = useTermFilteredItems(
    sortedWatchers,
    String(searchTerm || ""),
    filterOptions
  );

  return (
    <InterestedContext.Provider
      value={{
        selectedProfiles,
        setSelectedProfiles,
        pinSelected,
        togglePinSelected,
        showTableProperties,
        setTablePropertiesVisibility,
        columns: excludeHiddenColumns(columns),
        selectedColumns: excludeHiddenColumns(selectedColumns),
        setSelectedColumns,
        showFilters,
        toggleShowFilters,
        filtersStorage,
        watchersQuery,
        watchersMetadata,
        watchers,
        sortedWatchers,
        filteredSortedWatchers,
        orderStorage,
        searchTerm,
        setSearchTerm
      }}
    >
      {children}
    </InterestedContext.Provider>
  );
};

export const useInterestedContext = () => useContext(InterestedContext);
