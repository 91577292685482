import { Button } from "PFComponents/button";
import { Card } from "PFComponents/card";
import { Select } from "PFComponents/select/select";
import { getProfileName } from "PFCore/helpers/profile";
import Tracking from "PFCore/helpers/tracking";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import css from "./task_from_matches_card.less";

const TaskFromMatchesCard = ({ matchToInvite, searchTask }) => {
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("search");
  const navigate = useNavigate();

  const templates = useMemo(
    () =>
      currentProfile.templates.filter(
        ({ create_permitted, hidden, need_matches }) => !hidden && create_permitted && need_matches
      ),
    [currentProfile]
  );

  const options = useMemo(
    () =>
      templates.map((template) => ({
        id: template.id,
        displayElement: template.name,
        item: template
      })),
    []
  );
  const [currentTemplate, setCurrentTemplate] = useState(templates[0]);

  if (!matchToInvite) {
    return false;
  }

  const { profile } = matchToInvite;

  const createActivityWithModel = () => {
    const attributes = {
      description: searchTask.description,
      custom_fields: searchTask.custom_fields,
      communication_type: "group"
    };

    new Tracking(currentAccount, currentProfile.id).recordEvent("search-matches-invite-created", {
      profile_id: matchToInvite.profile.id
    });

    navigate(
      {
        pathname: `/activities/${currentTemplate.key}/new`
      },
      {
        state: {
          attributes,
          template_type: currentTemplate.key
        }
      }
    );
  };
  return (
    <Card className={css.root}>
      <div style={{ display: "inline-block", padding: "10px 0" }}>
        <Trans
          i18nKey="results.toInviteCreateFirst"
          t={t}
          values={{ name: getProfileName(profile) }}
          components={[
            <span key="1" />,
            <div className={css.selectWrap} key="2">
              <Select
                controlledValue
                value={currentTemplate.name}
                options={options}
                onChange={setCurrentTemplate}
              />
            </div>,
            <span key="3" />
          ]}
        />
        <div className={css.buttonWrap}>
          <Button onClick={createActivityWithModel}>{t("create")}</Button>
        </div>
      </div>
    </Card>
  );
};

TaskFromMatchesCard.propTypes = {
  matchToInvite: PropTypes.shape({
    profile: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string
    })
  }),
  searchTask: PropTypes.shape({
    description: PropTypes.string,
    custom_fields: PropTypes.array
  })
};

export default TaskFromMatchesCard;
