import compact from "lodash/compact";
import { ActionIcon } from "PFComponents/action_icon";
import { Typography } from "PFComponents/typography";
import { Filter, Value } from "PFCore/types/filters";
import { PageTarget } from "PFTypes/saved_filters";
import { forwardRef, useCallback, useMemo } from "react";

import { useSavedViewsContext } from "../saved_views_context/saved_views_context";
import css from "./filters_preview.module.scss";
import { FilterWithChildrenFlag } from "./filters_preview.utils";

const INFO_DELIMITER = ": ";
const getTitle = (infoText: string | null, text) =>
  [...(infoText ? [infoText] : []), text].join(INFO_DELIMITER);

export type FilterChangeFn = (
  filter: Filter,
  values: Value | null,
  options?: { clear?: boolean; children?: boolean }
) => void;

type FiltersItemProps = {
  filter: FilterWithChildrenFlag;
  displayValue: { id: Value; text: string };
  onFilterChange: FilterChangeFn;
  viewsKey?: PageTarget | null;
  disabled: boolean;
  filterSectionLabels?: { root: string; children: string };
};

export const FiltersItem = forwardRef<HTMLDivElement, FiltersItemProps>(
  ({ filter, displayValue, onFilterChange, viewsKey, disabled, filterSectionLabels }, ref) => {
    const { setSelectedViewId } = useSavedViewsContext(viewsKey);
    const handleDelete = useCallback(() => {
      setSelectedViewId(null);
      onFilterChange(filter, null, { children: filter.children });
    }, [filter, onFilterChange]);

    const infoText = useMemo(() => {
      const filterTitle = filter.type !== "checkboxes" ? filter.title : null;
      if (!filterSectionLabels) {
        return filterTitle;
      }
      const sectionLabel = filter.children ? filterSectionLabels.children : filterSectionLabels.root;
      return compact([sectionLabel, filterTitle]).join(INFO_DELIMITER);
    }, [filterSectionLabels, filter]);

    const { id, text } = displayValue;
    return (
      <>
        <div key={JSON.stringify(id)} ref={ref} className={css.item}>
          <ActionIcon name="cross" size="xs" onClick={() => handleDelete()} disabled={disabled} />
          <div className={css.itemTextContainer} title={getTitle(infoText, text)}>
            {!!infoText && (
              <Typography variant="labelRegular" clipOverflow tag="p">
                {infoText}
              </Typography>
            )}
            <Typography variant="labelBold" clipOverflow tag="p">
              {text}
            </Typography>
          </div>
        </div>
      </>
    );
  }
);

FiltersItem.displayName = "FiltersItem";
