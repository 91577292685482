import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { DragEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../button";
import { Icon } from "../../icon";
import { Typography } from "../../typography";
import { DraggedOverlay } from "../dragged_overlay";
import css from "../file_upload.module.scss";

export type EmptyContentProps = {
  inputRef?: React.RefObject<HTMLInputElement | null>;
  name: string;
  qaId?: string;
  disabled?: boolean;

  header?: string;
  description?: React.ReactNode;
  uploadButtonText?: string;
  downloadButtonText?: string;
  allowedFileTypes?: string[];
  acceptMediaTypes?: string[];

  onChange?: (file: File) => void;
  onTypeError?: () => void;
  onDownload?: () => void;
};

export const EmptyContent = ({
  inputRef,
  name,
  qaId,
  disabled,
  header,
  description,
  uploadButtonText,
  downloadButtonText,
  allowedFileTypes = [],
  acceptMediaTypes,
  onChange,
  onTypeError,
  onDownload
}: EmptyContentProps) => {
  const { t } = useTranslation("core", { keyPrefix: "components.fileUpload" });

  const uploadRef = useRef<HTMLInputElement>(null);
  const [dragging, setDragging] = useState(false);

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setDragging(false);

    const file = event.dataTransfer ? event.dataTransfer.files[0] : null;

    if (file) {
      validateFileType(file);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      event.target.value = "";
      validateFileType(file);
    }
  };

  const validateFileType = (file: File) => {
    const isValidType = allowedFileTypes.some((type) => file.name.endsWith(type));
    if (!allowedFileTypes.length || isValidType) {
      onChange?.(file);
    } else {
      onTypeError?.();
    }
  };

  return (
    <>
      <div
        role="button"
        tabIndex={-1}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={classNames(css.wrapper, { [css.dragging]: dragging })}
      >
        <Icon name="import" size="xxl" />
        <Typography variant="h4" clipOverflow>
          {header || t("header")}
        </Typography>
        <Button
          text={uploadButtonText || t("uploadButton")}
          disabled={disabled}
          onClick={() => (inputRef || uploadRef)?.current?.click()}
        />
        {downloadButtonText && <Button kind="text" text={downloadButtonText} onClick={onDownload} />}
        {description || (
          <span className={css.description}>
            <Typography variant="bodyRegular">{t("description")}</Typography>
            {!isEmpty(allowedFileTypes) && (
              <Typography variant="bodyRegular">
                {t("acceptedFormats", { formats: allowedFileTypes.join(", ") })}
              </Typography>
            )}
          </span>
        )}
      </div>
      <input
        id="file-upload-input"
        ref={inputRef || uploadRef}
        onChange={handleInputChange}
        name={name}
        type="file"
        accept={acceptMediaTypes?.join(",")}
        disabled={disabled}
        data-qa-id={qaId}
        className={css.input}
      />
      {dragging && <DraggedOverlay />}
    </>
  );
};
