import classNames from "classnames";
import { decamelizeKeys } from "humps";
import { InviteConfirmModal } from "PFApp/activities/parts/invite_confirm_modal";
import { useAppContext } from "PFApp/app_context";
import ActivityPreviewLink from "PFApp/components/activity_commons/activity_preview_link";
import { ShareWrapper } from "PFApp/components/activity_share/share_bar";
import { TemplateKey } from "PFApp/constants/templates";
import { Button } from "PFComponents/button";
import ButtonGroup from "PFComponents/button_group/button_group";
import { LoadingDots } from "PFComponents/loading_dots";
import Pill from "PFComponents/pill/pill";
import ProfileCardSkills from "PFComponents/profile_card/parts/profile_card_skills";
import { MatchGauge } from "PFComponents/resource_metrics/match_gauge";
import { useRoleName } from "PFCore/helpers/activities";
import { useIsShortlistingPermitted } from "PFCore/helpers/shortlists";
import useWindowSize from "PFCore/helpers/use_window_size";
import { useActivityShortlists } from "PFCore/hooks/queries/shortlists/use_activity_shortlists";
import {
  useShortlistAssign,
  useShortlistDecision
} from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import { useShortlistCreate } from "PFCore/hooks/queries/shortlists/use_shortlist_create";
import { useShortlistDelete } from "PFCore/hooks/queries/shortlists/use_shortlist_delete";
import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import DraftIcon from "PFIcons/draft.svg";
import OkIcon from "PFIcons/ok.svg";
import ShareIcon from "PFIcons/share.svg";
import WarningIcon from "PFIcons/warning.svg";
import { Activity, Id, MarketplaceActivityStatistics, MatchedActivity, Profile } from "PFTypes";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { useTemplate } from "../../hooks";
import { PageSection } from "../show/parts/page_section";
import css from "./profile_matching_roles_page.module.scss";

type MatchingRoleProps = {
  profile: Profile;
  match: MatchedActivity;
  statistics?: MarketplaceActivityStatistics;
  onInvite: VoidFunction;
  isBlindModeOn?: boolean;
  rolesToShare: Id[];
  handleShare: (id: Id) => void;
};

export const MatchingRole = ({
  profile,
  match,
  statistics,
  onInvite,
  isBlindModeOn,
  handleShare,
  rolesToShare
}: MatchingRoleProps) => {
  const {
    store: { themeVars }
  } = useAppContext();
  const { t } = useTranslation(["translation", "profiles"]);
  const growlErrors = useErrorsGrowl();
  const getRoleName = useRoleName();
  const isShortlistingPermitted = useIsShortlistingPermitted();
  const { windowWidth } = useWindowSize();
  const role = match.activity;
  const activityId = role.id;
  const isOwner = role.coowned;
  const canShortlist = isShortlistingPermitted(role);
  const roleTemplate = useTemplate(TemplateKey.Role);

  const onError = (response) => {
    growlErrors(response);
  };

  const { data: activityShortlists } = useActivityShortlists(
    activityId,
    {},
    { enabled: canShortlist, onError }
  );
  const { mutateAsync: createShortlist, isPending: isCreating } = useShortlistCreate({ onError });
  const { mutateAsync: deleteShortlist, isPending: isDeleting } = useShortlistDelete({
    options: { onError },
    activityId
  });
  const { mutateAsync: assignShortlist, isPending: isAssignLoading } = useShortlistAssign({ onError });
  const { mutateAsync: makeShortlistDecision, isPending: isDecisionLoading } = useShortlistDecision(
    { onError },
    false
  );
  const shortlistLoading = isCreating || isDeleting;
  const inviteLoading = isAssignLoading || isDecisionLoading;

  const [displayConfirm, setDisplayConfirm] = useState(false);
  const otherCandidateInvited = !!activityShortlists?.entries?.find(
    (item) => item.state === "assigned" && item.profile.id !== profile.id
  );

  const shortlist = activityShortlists?.entries?.find((item) => item.profile.id === profile.id) ?? null;

  const shortlistProfile = useCallback(() => createShortlist({ activityId, profileId: profile.id }), []);

  const removeShortlist = useCallback(() => {
    if (!shortlist) {
      return;
    }
    deleteShortlist(shortlist.id);
  }, [deleteShortlist, shortlist]);

  const assignShortlistAndInvite = (shortlistId) =>
    assignShortlist(shortlistId).then(() => {
      if (onInvite) {
        onInvite();
      }
    });

  const inviteProfile = useCallback(
    async (reasons) => {
      const invite = (shortlist) => {
        if (shortlist?.state === "accepted") {
          return assignShortlistAndInvite(shortlist.id);
        } else {
          return makeShortlistDecision({ action: "accept", reasons, shortlistId: shortlist.id }).then(() =>
            assignShortlistAndInvite(shortlist.id)
          );
        }
      };

      if (shortlist) {
        await invite(shortlist);
      } else {
        await shortlistProfile().then((newShortlist) => {
          if (newShortlist) {
            invite(newShortlist);
          }
        });
      }
    },
    [shortlist]
  );

  const isAssigned = shortlist?.state === "assigned";
  const isDeclined = shortlist?.state === "declined";
  const isDraft = role.state === "draft";

  const canShare = role.state !== "draft" && ((role.coowned && role.private) || !role.private);
  const shareMode = !!rolesToShare?.length;
  const shortlistInvites = !!roleTemplate?.shortlist_invites;

  const canInvite = (role.reviewer || isOwner) && shortlistInvites && !isDraft;

  return (
    <ShareWrapper
      activitiesToShare={rolesToShare || []}
      onShare={handleShare}
      activityId={activityId}
      disabled={role.state === "draft"}
    >
      <PageSection
        className={css.section}
        title={
          <ActivityPreviewLink activity={role as unknown as Activity} profile={profile}>
            <h3>{getRoleName(role)}</h3>
          </ActivityPreviewLink>
        }
        tags={
          isDraft ? (
            <Pill style={{ margin: "0px 10px 0px 0px" }}>
              <DraftIcon width={20} height={20} style={{ fill: themeVars["--Palette-base"], opacity: 0.4 }} />
              {t("translation:draft")}
            </Pill>
          ) : undefined
        }
        action={<MatchGauge match={decamelizeKeys(match)} className={css.matchAccuracy} />}
        actionWrapStyle={{ margin: "0 0 10px -5px" }}
      >
        <p className={css.matchingRoleDescription}>{role.description}</p>
        {/* @ts-ignore */}
        <ProfileCardSkills
          profileResponse={{ ...match, profile: { id: profile.id } }}
          isBlindModeOn={isBlindModeOn}
        />
        <div className={css.alertAndButtons}>
          <div className={css.infoAndAlert}>
            {isOwner && <div className={css.info}>{t("profiles:matchingRoles.youAreOwner")}</div>}
            {otherCandidateInvited && (
              <div className={css.alert}>
                <WarningIcon width={20} height={20} />
                {t("profiles:matchingRoles.otherCandidateInvited")}
              </div>
            )}
          </div>
          <span style={{ display: "flex" }}>
            {!shareMode && !isDraft && (
              <div className={classNames(css.share, { [css.disabled]: !canShare })}>
                <ShareIcon
                  height={20}
                  width={20}
                  onClick={() => {
                    if (!canShare) {
                      return;
                    }
                    handleShare(activityId);
                  }}
                />
                {(statistics?.shares || 0) > 0 && <div className={css.label}>{statistics?.shares}</div>}
              </div>
            )}
            {canShortlist && (
              <ButtonGroup
                style={{
                  display: "flex",
                  width: canInvite ? 350 : 175,
                  maxWidth: "100%",
                  flexWrap: windowWidth < 620 ? "wrap" : "nowrap",
                  justifyContent: "flex-end"
                }}
                mobileBreak={windowWidth < 620}
              >
                {canInvite && (
                  <Button
                    kind={isAssigned ? "primary" : "secondary"}
                    qaId="invite-button"
                    disabled={isDeclined || isAssigned || inviteLoading || shortlistLoading}
                    onClick={() => setDisplayConfirm(true)}
                  >
                    {inviteLoading && <LoadingDots />}
                    {!inviteLoading &&
                      (isAssigned ? (
                        <>
                          {t("translation:invited")}
                          <OkIcon width={20} height={15} />
                        </>
                      ) : (
                        t("translation:invite")
                      ))}
                  </Button>
                )}
                <Button
                  kind={shortlist ? "primary" : "secondary"}
                  qaId="shortlist-button"
                  disabled={isAssigned || shortlistLoading}
                  onClick={shortlist ? removeShortlist : shortlistProfile}
                >
                  {shortlistLoading && <LoadingDots />}
                  {!shortlistLoading &&
                    (shortlist ? (
                      <>
                        {t("profiles:matchingRoles.shortlisted")}
                        <OkIcon width={20} height={15} />
                      </>
                    ) : (
                      t("profiles:matchingRoles.shortlist")
                    ))}
                </Button>
              </ButtonGroup>
            )}
          </span>
        </div>
        {displayConfirm && (
          <InviteConfirmModal setDisplayConfirm={setDisplayConfirm} onSubmit={inviteProfile} />
        )}
      </PageSection>
    </ShareWrapper>
  );
};
