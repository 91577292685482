import isEmpty from "lodash/isEmpty";
import { Modal } from "PFComponents/modal";
import AutoSelect from "PFComponents/select/autoselect";
import { fetchCustomValues } from "PFCore/services/custom_values";
import { CustomValue } from "PFTypes/custom_value";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./sections.module.scss";

type Audience = Pick<CustomValue, "global_id" | "id" | "text" | "value">;

type AudienceData = Record<string, Audience[]>;

type ResponseData = {
  entries: Audience[];
  meta: {
    total: number;
  };
};

type BroadcastType = {
  id: number;
  name: string;
  display_as?: string;
};

type AudienceSelectorProps = {
  broadcastTypes: BroadcastType[];
  audiences: AudienceData[];
  handleAudiencesClose: () => void;
  handleAudiencesChange: (audience: AudienceData) => void;
};

const AudienceSelector = ({
  broadcastTypes,
  audiences,
  handleAudiencesClose,
  handleAudiencesChange
}: AudienceSelectorProps) => {
  const [selectedFields, setSelectedFields] = useState<AudienceData>({});
  const { t } = useTranslation(["translation", "activities"]);

  const handleSelection = useCallback(
    (value: Audience[], name: string): void => {
      const updatedSelectedFields = { ...selectedFields };

      if (isEmpty(value) || value === undefined) {
        delete updatedSelectedFields[name];
      } else {
        updatedSelectedFields[name] = value;
      }

      setSelectedFields(updatedSelectedFields);
    },
    [selectedFields]
  );

  const handleOk = useCallback(() => {
    handleAudiencesChange(selectedFields);
  }, [selectedFields]);

  const handleResponse = useCallback(
    (response: ResponseData, broadcastType: BroadcastType): Audience[] => {
      const audiencesToFilter = audiences
        .flatMap((audience) => audience[broadcastType.name])
        .filter((item) => item)
        .map(({ id }) => id);

      return response.entries.filter((audience) => !audiencesToFilter.includes(audience.id));
    },
    [audiences]
  );

  return (
    <Modal
      title={t("activities:edit.sections.selectAudienceGroups")}
      labelOK={t("translation:confirm")}
      onOK={!isEmpty(selectedFields) ? handleOk : undefined}
      onClose={handleAudiencesClose}
    >
      <ul style={{ paddingLeft: 0 }}>
        {broadcastTypes.map((broadcastType) => (
          <li key={broadcastType.id} className={css.listItem}>
            <AutoSelect
              label={broadcastType?.display_as}
              closeOnChange
              multi
              values={selectedFields[broadcastType.name]}
              query={(term) => fetchCustomValues({ term, customTypeId: broadcastType.id })}
              parseResponse={(response) => handleResponse(response, broadcastType)}
              handleChange={(value) => handleSelection(value, broadcastType.name)}
              handleRemove={(value) => handleSelection(value, broadcastType.name)}
            />
          </li>
        ))}
      </ul>
    </Modal>
  );
};

export default AudienceSelector;
