import css from "./carousel.module.scss";

export type CarouselItem = {
  id: string | number;
  content: React.ReactElement;
};

export type CarouselProps = {
  items: CarouselItem[];
  activeIndex: number;
  visibleItems?: number;
  qaId?: string;
};

/**
 * Carousel is meant for items with equal width.
 * Although you can try to feed any item, they should be properly centered based on available space
 */
export const Carousel = ({ items, activeIndex, visibleItems = 1, qaId }: CarouselProps) => {
  const itemWidth = 100 / visibleItems; // as %
  const offset = `${activeIndex * itemWidth * -1}%`;

  return (
    <div className={css.wrapper}>
      <div
        className={css.carousel}
        style={{
          transform: `translate(${offset}, 0)`
        }}
        data-qa-id={`${qaId}Items`}
      >
        {items.map(({ id, content }) => (
          <div className={css.itemContainer} style={{ width: `${itemWidth}%` }} key={id}>
            {content}
          </div>
        ))}
      </div>
    </div>
  );
};
