import { Button } from "PFComponents/button";
import { Icon } from "PFComponents/icon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import css from "./add_skills_button.module.scss";

type AddSkillsButtonProps = {
  skillsType?: string;
};

export const AddSkillsButton = ({ skillsType }: AddSkillsButtonProps) => {
  const { t } = useTranslation("profiles");
  const navigate = useNavigate();

  return (
    <div className={css.root}>
      <Button tag="a" onClick={() => navigate(`/profiles/me/summary/skills`)}>
        <div className={css.headerWrapper}>
          <div className={css.textWrapper}>
            <div className={css.icon}>
              <Icon name="add" size="md" />
            </div>
            <span className={css.text}>{t("show.parts.tagSkillsType", { skillsType })}</span>
          </div>
        </div>
      </Button>
    </div>
  );
};
