import { getWeekdays, getWeekdaysMin } from "PFCore/helpers/date";

import css from "./calendar_header.module.scss";

export const CalendarHeader = () => {
  const weekDays = getWeekdays();
  const weekDaysShort = getWeekdaysMin();

  return (
    <div className={css.root} data-qa-id="CalendarHeader">
      <div className={css.daynames}>
        {weekDays.map((weekDay, index) => (
          <div key={weekDay} className={css.dayNameContainer} role="columnheader">
            <span className={css.dayname} title={weekDay} role="gridcell">
              {weekDaysShort[index]}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
