import { useMockNotesData } from "PFApp/components/activity_commons/activity_details_notes";
import { Notes } from "PFComponents/notes/notes";
import { getProfileName } from "PFCore/helpers/profile";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";

import { MOCK_NOTES } from "./mock_notes";
import css from "./shortlist_item_notes.module.scss";

export const ShortlistItemNotes = () => {
  const { data: currentProfile } = useCurrentProfile();
  const currentUserName = getProfileName(currentProfile);

  const { notes, handleCreateNote, handleDeleteNote, handleEditNote } = useMockNotesData(MOCK_NOTES);

  return (
    <div className={css.root}>
      <Notes
        notes={notes}
        onCreateNote={(content) => handleCreateNote(currentUserName, currentProfile.id, content)}
        onDeleteNote={handleDeleteNote}
        onEditNote={handleEditNote}
        canUserEditNoteStrategy={(note) => note.authorId === currentProfile.id}
        canUserAddNote
        bottomLineDisabled
      />
    </div>
  );
};
