/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import classNames from "classnames";
import { getProfileName } from "PFCore/helpers/profile";
import CheckIcon from "PFIcons/check.svg";
import { ActivityParticipant, ParticipatedRole } from "PFTypes/activity_participant";

import css from "./participant.module.scss";

type ParticipantProps = {
  participant: ActivityParticipant;
  role: ParticipatedRole;
  onClick: (profile: ActivityParticipant, role: ParticipatedRole) => void;
  isActive: boolean;
  isComplete: boolean;
};

export const Participant = ({ participant, onClick, isActive, isComplete, role }: ParticipantProps) => (
  <div
    key={participant.id}
    onClick={() => onClick(participant, role)}
    className={classNames(css.listElement, isActive ? css.activeElement : css.inactiveElement)}
  >
    {isComplete && <CheckIcon width={15} height={15} />}
    <div className={css.namesContainer}>
      {getProfileName(participant)}
      <div className={css.roleName}>{role.name}</div>
    </div>
  </div>
);
