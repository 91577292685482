import { useActivityTemplateType } from "PFApp/activities/hooks";
import { TemplateKey } from "PFApp/constants/templates";
import { useTranslation } from "react-i18next";

import { useBookingActivityContext } from "../../../parts/providers/booking_activity_context_provider";
import { EntityIcon } from "../../entity_icon";
import { DetailsHeader } from "./details_header";

export const EngagementDetailHeader = () => {
  const { t } = useTranslation();
  const { activity, id } = useBookingActivityContext();

  const { isAudit, isEngagement } = useActivityTemplateType(activity);
  const isAuditEngagement = isAudit && isEngagement;

  return (
    <DetailsHeader
      title={activity?.name}
      subtitle={isAuditEngagement ? t("auditEngagement") : t("engagement")}
      linkUrl={`/activities/${id}`}
    >
      <EntityIcon templateId={activity?.template_id} type={TemplateKey.Engagement} />
    </DetailsHeader>
  );
};
