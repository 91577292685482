import classNames from "classnames";
import { Button } from "PFComponents/button";
import { Flex, Inline } from "PFComponents/containers/flex";
import { ProfileAvatar } from "PFComponents/profile_avatar/profile_avatar";
import { isProfileActive } from "PFCore/helpers/profile";
import { IconName } from "PFTheme/graphics/icons";
import { CurrentProfile, ProfileMinimized } from "PFTypes";
import { SyntheticEvent, useEffect, useState } from "react";

import css from "./profiles_avatar_stack.module.scss";

type ProfilesAvatarStackProps = {
  profiles?: ProfileMinimized[];
  currentProfile?: CurrentProfile;
  style?: React.CSSProperties;
  avatarSize?: number;
  max?: number;
  handleIconClick?: (profile: ProfileMinimized) => void;
  handleAvatarClick?: (profile: ProfileMinimized, event: SyntheticEvent) => void;
  noOverlap?: boolean;
  getTooltipContent?: (profile: ProfileMinimized) => React.ReactNode;
  getIsAvatarHighlighted?: (profile: ProfileMinimized) => boolean;
  icon?: IconName;
};

export const ProfilesAvatarStack = ({
  profiles = [],
  currentProfile,
  style,
  avatarSize = 40,
  max = 3,
  handleAvatarClick,
  noOverlap = false,
  icon,
  handleIconClick,
  getTooltipContent,
  getIsAvatarHighlighted
}: ProfilesAvatarStackProps) => {
  const plusProfiles = profiles.length - max;

  const [displayPlus, setDisplayPlus] = useState(plusProfiles > 0);

  useEffect(() => {
    setDisplayPlus(plusProfiles > 0);
  }, [plusProfiles]);

  if (profiles.length <= 0) {
    return null;
  }

  const displayedProfiles = displayPlus ? profiles.slice(0, max) : profiles;
  const isCurrentProfile = (id: number) => id === currentProfile?.id;

  return (
    <Inline style={style} gap="spacingXs" className={css.wrapper}>
      {displayedProfiles.map((profile: ProfileMinimized) => (
        <Flex
          key={profile.id}
          className={classNames(css.avatar, {
            [css.collapsed]: !noOverlap && displayPlus,
            [css.pointy]: handleAvatarClick
          })}
        >
          <ProfileAvatar
            profile={profile}
            size={avatarSize}
            onClick={
              isProfileActive(profile) && handleAvatarClick
                ? (event) => handleAvatarClick(profile, event)
                : undefined
            }
            icon={!isCurrentProfile(profile.id) && isProfileActive(profile) ? icon : undefined}
            handleIconClick={
              handleIconClick && isProfileActive(profile) ? () => handleIconClick(profile) : undefined
            }
            isHighlighted={getIsAvatarHighlighted?.(profile)}
            tooltipContent={getTooltipContent?.(profile)}
          />
        </Flex>
      ))}
      {plusProfiles > 0 && (
        <Button kind="text" onClick={() => setDisplayPlus(!displayPlus)}>
          {displayPlus ? `+ ${plusProfiles}` : "-"}
        </Button>
      )}
    </Inline>
  );
};
