import { keepPreviousData } from "@tanstack/react-query";
import compact from "lodash/compact";
import every from "lodash/every";
import { stringify } from "PFCore/helpers/use_deterministic_stringify";
import { useCallback, useMemo } from "react";

import {
  FetchOptionsResponse,
  Option,
  OptionOriginal,
  ResultOption,
  SelectOptions,
  SelectV2Props
} from "../select_v2.types";
import { getIds } from "../select_v2.utils";
import { areOptionsGrouped as areOptionsGroupCheck } from "../select_v2.utils";
import { useOptions, useSelectedOptions } from "../use_options";
import { getSelectAllOption } from "./get_select_all_option";

type UseDropdownOptions<T> = {
  searchTerm: string;
  isDropdownOpen: boolean;
  dropdownId: string;
  isSearchable: boolean;
} & Pick<SelectV2Props<T>, "onChange" | "value" | "multiple" | "options" | "fetchOptions" | "cacheTime">;

export type UseDropdownOptionsReturn<T> = {
  resultOptions: SelectOptions<T>;
  selectedOptions: Option<T>[];
  isFetchingAvailableOptions: boolean;
  isFetchingSelectedOptions: boolean;
  isFetchingOptionsEnabled: boolean;
  isMoreOptions: boolean;
  areOptionsGrouped: boolean;
  selectedOptionsLimit?: number;
  isSelectedLimitReached: boolean;
};

export const useDropdownOptions = <T extends OptionOriginal = OptionOriginal>({
  value,
  options,
  fetchOptions,
  searchTerm,
  isDropdownOpen,
  dropdownId,
  cacheTime,
  multiple,
  onChange,
  isSearchable
}: UseDropdownOptions<T>): UseDropdownOptionsReturn<T> => {
  const stringifiedValue = stringify(value);
  const selectedOptionIds = useMemo(() => getIds(value), [stringifiedValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    data: selectedOptions,
    isFetching: isFetchingSelectedOptions,
    limit: selectedOptionsLimit,
    isLimitReached: isSelectedLimitReached
  } = useSelectedOptions({
    dropdownId,
    selectedOptionIds,
    options,
    fetchOptions,
    queryOptions: {
      placeholderData: keepPreviousData,
      gcTime: cacheTime
    }
  });

  const maybeDisableUnselectedOptions = useCallback(
    (data: FetchOptionsResponse<T>) => ({
      ...data,
      entries: data.entries.map((entry) => ({
        ...entry,
        disabled: !selectedOptionIds.includes(entry.id) && isSelectedLimitReached
      }))
    }),
    [selectedOptionIds, isSelectedLimitReached]
  );

  const {
    data: availableOptions,
    isFetching: isFetchingAvailableOptions,
    total: totalAvailableOptions
  } = useOptions({
    dropdownId,
    searchTerm,
    options,
    fetchOptions,
    queryOptions: {
      enabled: isDropdownOpen,
      gcTime: cacheTime,
      select: maybeDisableUnselectedOptions
    }
  });

  const isFetchingOptionsEnabled = !!fetchOptions;

  const isMoreOptions = availableOptions.length < totalAvailableOptions;

  const areOptionsGrouped = useMemo(
    () => !fetchOptions && areOptionsGroupCheck(options),
    [options, fetchOptions]
  );

  const isEverySelectedOptionsIncludedInAvailableOptions = useMemo(() => {
    const availableOptionsIds = availableOptions.map(({ id }) => id);
    return every(selectedOptionIds, (id) => availableOptionsIds.includes(id));
  }, [availableOptions, selectedOptionIds]);

  const resultOptions = useMemo(() => {
    if (areOptionsGrouped) {
      return availableOptions;
    }
    if (
      !isFetchingAvailableOptions &&
      !isEverySelectedOptionsIncludedInAvailableOptions &&
      !searchTerm.trim() &&
      isFetchingOptionsEnabled &&
      multiple
    ) {
      return selectedOptions;
    }
    if (isFetchingAvailableOptions) {
      return [];
    }
    const selectAllOption = getSelectAllOption<T>({
      availableOptions,
      multiple,
      selectedOptions,
      selectedOptionIds,
      onChange,
      isSearchable,
      selectedOptionsLimit
    });
    return compact([selectAllOption, ...availableOptions]) as ResultOption<T>[];
  }, [
    areOptionsGrouped,
    availableOptions,
    isEverySelectedOptionsIncludedInAvailableOptions,
    searchTerm,
    selectedOptions,
    selectedOptionsLimit,
    selectedOptionIds,
    isFetchingAvailableOptions,
    isFetchingOptionsEnabled,
    isSearchable,
    multiple,
    onChange
  ]);

  return {
    resultOptions,
    selectedOptions,
    selectedOptionsLimit,
    isFetchingAvailableOptions,
    isFetchingSelectedOptions,
    isFetchingOptionsEnabled,
    isMoreOptions,
    isSelectedLimitReached,
    areOptionsGrouped
  };
};
