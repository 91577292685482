import { ChangeRequestPage } from "PFApp/change_requests/change_request_page";
import ContactView from "PFApp/contact/contact_view";
import { EmailConfirmationController } from "PFApp/email_confirmation/email_confirmation_controller";
import EditForgottenPasswordPage from "PFApp/forgot_password/edit_forgotten_password_page";
import ActivitiesRoutes from "PFApp/routes/activities_routes";
import { MsTeamsRoutes } from "PFApp/routes/ms_teams_routes";
import NotFoundRoute from "PFApp/routes/not_found_route";
import { ProfileRoutes } from "PFApp/routes/profile_routes";
import { ProfilesRoutes } from "PFApp/routes/profiles_routes";
import { RootRoute } from "PFApp/routes/root_route";
import {
  EnsureLoggedIn,
  EnsureLoggedOut,
  EnsureRegistrationComplete,
  WithLayout
} from "PFApp/routes/route_helpers";
import { SearchRoutes } from "PFApp/routes/search_routes";
import UsersRoutes, { SignOutRoute, TokenResetRoute, WelcomeRoute } from "PFApp/routes/users_routes";
import SamlSignInPage from "PFApp/signin/saml_sign_in_page";
import RequestAccessPage from "PFApp/signup/request_access_page";
import { TermsOfServicePage } from "PFApp/terms_of_service";
import { auto, headerOnly } from "PFCore/base/page_layouts";
import { PermissionRule } from "PFTypes";
import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { EnsureProfilePermission } from "./routes/route_helpers";

const AdminRoutes = lazy(() => import(/* webpackChunkName: "admin" */ "PFApp/routes/admin_routes"));
const AnalyticsRoutes = lazy(() =>
  import(/* webpackChunkName: "analytics" */ "PFApp/routes/analytics_routes").then(({ AnalyticsRoutes }) => ({
    default: AnalyticsRoutes
  }))
);
const AuditPlannerRoutes = lazy(() =>
  import(/* webpackChunkName: "audit_planner" */ "PFApp/routes/audit_planner_routes").then(
    ({ AuditPlannerRoutes }) => ({ default: AuditPlannerRoutes })
  )
);
const BookingRoutes = lazy(() =>
  import(/* webpackChunkName: "booking_module" */ "PFApp/routes/booking_routes").then(
    ({ BookingRoutes }) => ({ default: BookingRoutes })
  )
);
const FeedRoutes = lazy(() =>
  import(/* webpackChunkName: "feed" */ "PFApp/routes/feed_routes").then(({ FeedRoutes }) => ({
    default: FeedRoutes
  }))
);
const MarketplaceRoutes = lazy(() =>
  import(/* webpackChunkName: "marketplace" */ "PFApp/routes/marketplace_routes").then(
    ({ MarketplaceRoutes }) => ({
      default: MarketplaceRoutes
    })
  )
);
const OnboardingRoutes = lazy(() =>
  import(/* webpackChunkName: "onboarding" */ "PFApp/routes/onboarding_routes").then(
    ({ OnboardingRoutes }) => ({ default: OnboardingRoutes })
  )
);

const SaasAdminRoutes = lazy(() =>
  import(/* webpackChunkName: "saas_admin" */ "PFApp/routes/saas_admin_routes").then(
    ({ SaasAdminRoutes }) => ({ default: SaasAdminRoutes })
  )
);
const WorkflowRoutes = lazy(() =>
  import(/* webpackChunkName: "workflow" */ "PFApp/routes/workflow_routes").then(({ WorkflowRoutes }) => ({
    default: WorkflowRoutes
  }))
);

const MainRouter = () => (
  <Routes>
    <Route path="/" element={<RootRoute />} />
    <Route
      path="authsaml"
      element={
        <EnsureLoggedOut>
          <SamlSignInPage />
        </EnsureLoggedOut>
      }
    />
    <Route path="email_confirmation" element={<EmailConfirmationController />} />
    <Route
      path="request_access"
      element={
        <WithLayout layout={{ sidebar: headerOnly }}>
          <RequestAccessPage />
        </WithLayout>
      }
    />
    <Route path="contact-us" element={<Navigate to="contact_us" replace />} />
    <Route
      path="contact_us"
      element={
        <EnsureLoggedIn>
          <EnsureProfilePermission permittedTo={PermissionRule.ContactForm}>
            <WithLayout layout={{ sidebar: auto }}>
              <ContactView />
            </WithLayout>
          </EnsureProfilePermission>
        </EnsureLoggedIn>
      }
    />
    <Route
      path="terms"
      element={
        <WithLayout layout={{ sidebar: auto }}>
          <TermsOfServicePage />
        </WithLayout>
      }
    />
    <Route path="users/*" element={<UsersRoutes />} />
    <Route path="ms_teams/*" element={<MsTeamsRoutes />} />
    <Route
      path="password_edit"
      element={
        <WithLayout layout={{ sidebar: headerOnly }}>
          <EditForgottenPasswordPage />
        </WithLayout>
      }
    />
    <Route path="edit_password/from_welcome/:resetToken" element={<TokenResetRoute />} />
    <Route path="onboarding/*" element={<OnboardingRoutes />} />
    <Route path="welcome/*" element={<WelcomeRoute />} />
    <Route path="workflow/*" element={<WorkflowRoutes />} />
    <Route path="audit_planner/*" element={<AuditPlannerRoutes />} />
    <Route path="activities/*" element={<ActivitiesRoutes />} />
    <Route path="booking/*" element={<BookingRoutes />} />
    <Route path="analytics/*" element={<AnalyticsRoutes />} />
    <Route path="feed/*" element={<FeedRoutes />} />
    <Route path="marketplace/*" element={<MarketplaceRoutes />} />
    <Route path="profiles/*" element={<ProfilesRoutes />} />
    <Route path="profile/*" element={<ProfileRoutes />} />
    <Route path="search/*" element={<SearchRoutes />} />
    <Route path="change_requests/:id" element={<ChangeRequestPage />} />
    <Route path="admin/*" element={<AdminRoutes />} />
    <Route path="saas_admin/*" element={<SaasAdminRoutes />} />
    <Route
      path="/sign_out"
      element={
        <EnsureLoggedIn>
          <SignOutRoute />
        </EnsureLoggedIn>
      }
    />
    <Route
      path="*"
      element={
        <EnsureRegistrationComplete>
          <NotFoundRoute />
        </EnsureRegistrationComplete>
      }
    />
  </Routes>
);

export default MainRouter;
