import className from "classnames";
import { useGrowl } from "PFApp/use_growl";
import { Button } from "PFComponents/button";
import isIOS from "PFCore/helpers/ios";
import isSafari from "PFCore/helpers/is_safari";
import { downloadAttachment } from "PFCore/services/attachments/download_attachment";
import AttachmentIcon from "PFIcons/download_file.svg";
import React, { PropsWithChildren, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./download_link.module.scss";

type DownloadLinkProps = PropsWithChildren & {
  attachment: any;
  useIcon?: boolean;
  rootClassName?: string;
};

const DownloadLink = ({ attachment, children, useIcon = true, rootClassName }: DownloadLinkProps) => {
  const { t } = useTranslation(["core", "translation"]);
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const timeoutRef = useRef<NodeJS.Timeout>(null);

  const growl = useGrowl();

  const useAnchorTag = isIOS() || isSafari();

  const handleClick = (e) => {
    window.clearTimeout(Number(timeoutRef.current));

    e.stopPropagation();

    setLoading(true);

    // Because of business reasons we do not get the url to the resource,
    // but /api/attachments/:id/download enpoint that provides it.
    // The response is out-of-date in short time.
    downloadAttachment({ id: attachment.id }).then(
      (resp) => {
        if (useAnchorTag) {
          setFileUrl(resp.file_url);
          setLoading(false);
          timeoutRef.current = setTimeout(() => {
            setFileUrl("");
          }, 10 * 1000);
        } else {
          const iframe = document.createElement("iframe");
          iframe.style.display = "none";
          iframe.setAttribute("src", resp.file_url);
          document.body.appendChild(iframe);
        }
      },
      (resp) => {
        const error = resp && resp.responseJSON && resp.responseJSON.errors[0];
        const title = error ? error.title : null;

        growl({
          kind: "error",
          title: title,
          message: t("core:fileCannotBeOpen")
        });
      }
    );
  };

  return (
    <div>
      <Button kind="blank" onClick={handleClick} className={className(css.link, rootClassName)}>
        {useIcon && <AttachmentIcon width={16} height={16} />}
        {children}
      </Button>
      {useAnchorTag && (
        <div className={css.link2}>
          {loading && " ↳ ..."}
          {!loading && fileUrl && (
            <React.Fragment>
              {" ↳ "}
              <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                {t("translation:download")}
              </a>
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default DownloadLink;
