import { Checkbox } from "PFComponents/checkbox";
import { ExperienceLevel } from "PFComponents/experience_level";
import CheckIcon from "PFIcons/check.svg";

import { SkillToRank, useFeedbackFormContext } from "../../feedback_form_context_provider";
import { useFeedbackFormMutations } from "../../hooks/use_feedback_form";
import css from "./skills_evaluation.module.scss";

type SkillRowProps = {
  skill: SkillToRank;
  readOnly?: boolean;
};

export const SkillRow = ({ skill, readOnly }: SkillRowProps) => {
  const { formData } = useFeedbackFormContext();
  const { handleRankChange, handleActivation } = useFeedbackFormMutations();
  const skillId = skill.id || skill.globalId;
  const skillExperience = formData?.skills.find(
    (rankedSkill) => (rankedSkill.id || rankedSkill.globalId) === skillId
  )?.experience;

  return (
    <div className={css.skillRow}>
      <div className={css.skillCheckboxCell}>
        {readOnly ? (
          <CheckIcon width={10} height={10} />
        ) : (
          <Checkbox checked={!!skill.isActive} onChange={() => handleActivation(skill, !skill.isActive)} />
        )}
      </div>
      <div className={css.skillNameCell}>{skill.name}</div>
      <div className={css.skillRankCell}>
        <ExperienceLevel
          onChange={!readOnly ? (newRank) => handleRankChange(newRank, skillId) : undefined}
          experience={skillExperience}
        />
      </div>
    </div>
  );
};
