import compact from "lodash/compact";
import { Flex } from "PFComponents/containers";
import { Typography } from "PFComponents/typography";
import { getWeekdaysMin } from "PFCore/helpers/date";
import roundToDecimals from "PFCore/helpers/round_to_decimals";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useDateFormatter } from "PFCore/hooks";
import { usePhases } from "PFCore/hooks/queries/phases/use_phases";
import { FeatureFlag, TimeRuleRange } from "PFTypes";

import { getFormattedRangeDates, getRangePhaseText } from "../../readable_availability_rules.utils";

type ReadableTimeRangeProps = {
  range: TimeRuleRange;
  multiline: boolean;
};

export const ReadableTimeRange = ({ range, multiline }: ReadableTimeRangeProps) => {
  const { formatDate } = useDateFormatter();
  const hasActivityPhases = useIsFeatureEnabled()(FeatureFlag.ActivityPhases);

  const { data: phases } = usePhases(null, {
    enabled: hasActivityPhases,
    placeholderData: { entries: [], meta: { total: 0 } }
  });

  const dateRange = getFormattedRangeDates(range, formatDate);
  const daysOfWeek = getWeekdaysMin()
    .map((day, index) => `${day}: ${roundToDecimals(range.day_of_week[index] / 60)}`)
    .join(", ");
  const phase = getRangePhaseText(phases?.entries || [], range);

  const phaseWithDateRange = compact([phase, dateRange]).join(": ");
  const details = `(${daysOfWeek})`;

  return (
    <Flex direction={multiline ? "column" : "row"} gap="spacingSm">
      <Typography variant="bodyRegular">{phaseWithDateRange}</Typography>
      <Typography variant="labelRegular">{details}</Typography>
    </Flex>
  );
};
