import last from "lodash/last";
import split from "lodash/split";

import { ChangesLogItem } from "../skills_modal";

export const sanitizeDraggableId = (id: string): string => last(split(id, "-")) || "";

export const reduceChangesLog = (changesLog: ChangesLogItem[]): ChangesLogItem[] =>
  changesLog.reduce<ChangesLogItem[]>((acc, curr) => {
    const changedAlready = acc.find(({ id }) => String(id) === String(curr.id));

    return (
      changedAlready
        ? acc.map((change) =>
            String(change.id) === String(curr.id)
              ? { ...change, data: { ...change.data, ...curr.data } }
              : change
          )
        : [...acc, curr]
    ) as ChangesLogItem[];
  }, []);
