import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import css from "./profile_edit_nav_item.less";

const ProfileEditNavItem = ({ text, icon, href, qaId, danger, current, onClick, id }) => {
  const Tag = onClick ? "div" : Link;
  return (
    <li data-qa-id={`profile-edit-section-button-${qaId}`}>
      <Tag
        to={onClick ? null : href}
        onClick={onClick}
        className={classNames(css.root, { [css.danger]: danger, [css.current]: current })}
        id={id}
      >
        {icon}
        <span>{text}</span>
      </Tag>
    </li>
  );
};

ProfileEditNavItem.propTypes = {
  text: PropTypes.string.isRequired,
  qaId: PropTypes.string.isRequired,
  current: PropTypes.bool.isRequired,
  href: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,

  icon: PropTypes.node,
  danger: PropTypes.bool
};

export default ProfileEditNavItem;
