import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { Activity, Id } from "PFTypes";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useActivityEditPageContext, useActivityEditPageFlags } from "../activity_edit_page_context";
import { useEngagementPagination } from "../sections/hooks/use_engagement_pagination";

export type EngagementWizardState =
  | {
      engagementId: Id;
      roles: number[];
      newRoleUrl: string;
    }
  | undefined;

export const useEngagementWizard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: currentProfile } = useCurrentProfile();
  const { activity, parentActivity: engagement, template } = useActivityEditPageContext();
  const { isNew, isAudit, isRole, isEngagement } = useActivityEditPageFlags();

  const activityType = ["engagement", "role"].includes(template.key) ? "role" : "audit_role";

  const storageKey = (activityId) => `workflow_wizard_${activityId}`;

  const engagementWizardState: EngagementWizardState = useMemo(
    () => window.storage.getItem(storageKey(engagement?.id)),
    [engagement, activity]
  );

  const { canNext, goToNextRole } = useEngagementPagination(engagementWizardState);

  /* update current new role url */
  useEffect(() => {
    if (isRole && isNew && engagementWizardState) {
      window.storage.setItem(storageKey(engagement?.id), {
        ...engagementWizardState,
        newRoleUrl: location.pathname
      });
    }
  }, [activity.id]);

  const canCreateRole = currentProfile.templates?.find(({ key }) =>
    ["role", "audit_role"].includes(key)
  )?.create_permitted;

  const isEngagemementFlow: boolean =
    !!canCreateRole && !!((isEngagement && isNew) || (isRole && engagementWizardState));

  const handleNavigation = (activity: Activity, options: { [key: string]: boolean } = {}) => {
    if (options.cancel) {
      window.storage.removeItem(`workflow_wizard_${activity.parent_activity_id}`);
      navigate(isAudit ? "/audit_planner/control_centre" : "/workflow/engagements");
      return;
    }

    if (!isNew) {
      navigate(`/activities/${activity.id}/edit?_t=${Date.now()}`);
    }

    if (isEngagement) {
      window.storage.setItem(
        storageKey(activity.id),
        { roles: [], engagementId: activity.id },
        { preserveTime: true, expiresIn: 3600 }
      );
      navigate(`/activities/${activityType}/new/parent_activity/${activity.id}`);
      return;
    }

    if (!isRole) {
      return;
    }

    const engagementId = activity.parent_activity_id;
    const wizardState = window.storage.getItem(storageKey(engagementId));

    if (!wizardState) {
      return;
    }

    if (options.goNext && canNext) {
      goToNextRole();
      return;
    }

    /* add new role to storage */
    const newRoles = [...wizardState.roles, activity.id];
    window.storage.setItem(storageKey(engagementId), {
      ...wizardState,
      roles: newRoles
    });

    if (options.clone) {
      navigate(`/activities/${activityType}/new/${activity.id}?_t=${Date.now()}`);
      return;
    }

    if (options.add_new) {
      navigate(`/activities/${activityType}/new/parent_activity/${engagement?.id}`);
      return;
    }

    window.storage.removeItem(`workflow_wizard_${engagementId}`);
    navigate(
      isAudit
        ? `/audit_planner/control_centre/${engagementId}/${activity.id}`
        : `/activities/${engagementId}/roles`
    );
  };

  return {
    engagementWizardState,
    handleEngagementWizardNavigation: isEngagemementFlow ? handleNavigation : undefined
  };
};
