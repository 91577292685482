import { Id } from "PFTypes";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const useScenarioNavigate = () => {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const openScenario = useCallback(
    (scenarioId: Id) => {
      navigate(`/activities/${id}/economics/${scenarioId}`, { replace: true });
    },
    [navigate, id]
  );

  const closeScenario = useCallback(() => {
    navigate(`/activities/${id}/economics`, { replace: true });
  }, [navigate, id]);

  return { openScenario, closeScenario };
};
