import { Card } from "PFComponents/card";
import { Typography } from "PFComponents/typography";
import { ChangeRequest, ChangeRequestPosition } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "../change_request_page.module.scss";
import { DisabledItems, MergeData, SetDisabledItems } from "../change_request_page.types";
import { Position } from "./position";

type PositionsSectionProps = {
  data: ChangeRequest["data"];
  mergeData: MergeData;
  disabledItems: DisabledItems;
  setDisabledItems: SetDisabledItems;
  errors: any;
};

export const PositionsSection = ({
  data,
  mergeData,
  disabledItems,
  setDisabledItems,
  errors
}: PositionsSectionProps) => {
  const { t } = useTranslation("changeRequests");

  if (!data || !data.positions?.length) {
    return null;
  }

  // positions don't have any ids and all other props are changable so you can only
  // refer to them by the index
  const makeHandleChangePosition = (index: number) => (change: ChangeRequestPosition) => {
    if (change.main) {
      data.positions.forEach((pos) => (pos.main = false));
    }
    data.positions[index] = { ...data.positions[index], ...change };
    mergeData({ ...data, positions: [...data.positions] });
  };

  return (
    <Card className={css.positionsCard} qaId="ChangeRequest.Positions">
      <Typography withMargin variant="h2" className={css.header}>
        {t("parts.positions")}
      </Typography>
      {data.positions.map((position, index) => (
        <Position
          key={index}
          errors={errors?.find(({ ordinal }) => ordinal === position.ordinal)?.errors || {}}
          position={position}
          index={index}
          handleChangePosition={makeHandleChangePosition(index)}
          disabledItems={disabledItems}
          setDisabledItems={setDisabledItems}
        />
      ))}
    </Card>
  );
};
