import { ReadableAvailabilityRules } from "PFApp/components/readable_availability_rules";
import { useReadableAvailability } from "PFCore/helpers/use_readable_availability";
import { AvailabilityRule } from "PFTypes";
import { useTranslation } from "react-i18next";

import { BookingDetailItem } from "../../booking_detail_item";
import css from "./availability_info.module.scss";

type AvailabilityInfoProps = {
  availability?: AvailabilityRule;
};

export const AvailabilityInfo = ({ availability }: AvailabilityInfoProps) => {
  const { t } = useTranslation("bookingModule");
  const { readableAvailability } = useReadableAvailability({ availability: availability });

  return (
    <BookingDetailItem
      label={t("bookings.availabilityRules")}
      value={readableAvailability ?? ""}
      infoContent={
        <div className={css.availabilityTooltip}>
          <ReadableAvailabilityRules availability={availability} />
        </div>
      }
      tooltipMaxWidth={400}
      tooltipInteractive
    />
  );
};
