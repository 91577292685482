import classNames from "classnames";
import css from "PFComponents/dropdown/dropdown.module.scss";
import { Typography } from "PFComponents/typography";
import { MouseEvent, useEffect, useRef } from "react";

import Tooltip from "../tooltip/tooltip";
import { DropdownOption } from "./dropdown";

export const NO_ITEM = "NO_ITEM"; // Symbol to match in other places

interface DropDownItemProps {
  selected?: boolean;
  hovered?: boolean;
  value: DropdownOption;
  handleChange: (item: any) => void;
  dropDownItemClassName?: string;
  tooltipMsg?: string;
  highlightSelected?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

export const DropDownItem = ({
  selected = false,
  hovered = false,
  value,
  handleChange,
  dropDownItemClassName,
  tooltipMsg,
  highlightSelected = true,
  onKeyDown
}: DropDownItemProps) => {
  const itemRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (hovered && itemRef.current) {
      itemRef.current.scrollIntoView({ block: "nearest" });
    }
  }, [hovered]);

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    handleChange(value.item);
  };

  const children = () => {
    const isDisplayElementString = typeof value.displayElement === "string";
    const buttonTitle = isDisplayElementString ? value.displayElement : value?.item?.text;
    const ariaLabel = value.ariaLabel || buttonTitle;
    const displayElement = isDisplayElementString ? (
      <Typography variant="bodyRegular" clipOverflow>
        {value.displayElement}
      </Typography>
    ) : (
      value.displayElement
    );

    return (
      <div
        tabIndex={-1}
        role="menuitem"
        aria-label={ariaLabel}
        aria-current={selected}
        aria-disabled={value.disabled}
        title={buttonTitle}
        data-dropdown-item="true"
        className={classNames(css.item, dropDownItemClassName, {
          [css.selected]: (selected && highlightSelected) || hovered,
          [css.disabled]: value.disabled
        })}
        onClick={handleClick}
        onKeyDown={onKeyDown}
      >
        {displayElement}
      </div>
    );
  };

  return (
    <li ref={itemRef}>
      {tooltipMsg ? (
        <Tooltip content={tooltipMsg} placement="top">
          {children()}
        </Tooltip>
      ) : (
        children()
      )}
    </li>
  );
};
