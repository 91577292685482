import classNames from "classnames";
import Color from "color";
import { Icon, IconSize } from "PFComponents/icon";
import { getContrastingTextColor } from "PFCore/utilities/color";
import { validateCategoryIcon } from "PFModules/admin/booking/categories/booking_categories.utils";
import { IconName } from "PFTheme/graphics/icons";
import { BookingCategory } from "PFTypes";
import { CSSProperties, PropsWithChildren } from "react";

import { BasicPill } from "./basic_pill";
import css from "./pill.module.scss";

export const getCategoryBorderColor = (category?: BookingCategory) =>
  Color(category?.color).darken(0.5).hex();
export const NO_CATEGORY_COLOR = "paletteWhite2";

type BookingPillProps = PropsWithChildren<{
  category: BookingCategory | undefined;
  className?: string;
  style?: CSSProperties;
  withCategoryIcon?: boolean;
  iconSize?: IconSize;
  center?: boolean;
  title?: string;
}>;

export const BookingPill = ({
  children,
  className,
  style,
  category,
  withCategoryIcon = true,
  iconSize,
  center
}: BookingPillProps) => {
  const isNoCategory = !category;
  const textColor = category ? getContrastingTextColor(category.color, 1).hex() : undefined;
  const finalStyle = isNoCategory
    ? { ...style, background: `var(--${NO_CATEGORY_COLOR})` }
    : {
        background: category.color,
        border: `2px solid ${getCategoryBorderColor(category)}`,
        color: textColor,
        ...style
      };
  const categoryIcon = isNoCategory ? ("locked" as IconName) : validateCategoryIcon(category.icon);

  return (
    <BasicPill
      className={classNames(css.bookingPill, className, { [css.center]: isNoCategory || !!center })}
      style={finalStyle}
    >
      {withCategoryIcon && !!categoryIcon && (
        <Icon name={categoryIcon} size={iconSize} fill={textColor} className={css.categoryIcon} />
      )}
      {children}
    </BasicPill>
  );
};
