import { datadogRum } from "@datadog/browser-rum";
import { DropdownOption } from "PFComponents/dropdown/dropdown";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { FeatureFlag } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { humanize } from "underscore.string";

import { CONTACT_TYPE_TO_ICON_MAP } from "../../constants/contact_type_icon_map";
import css from "./use_contact_information_edit_field.module.scss";

export const useProfileInformationEditField = () => {
  const { t } = useTranslation("profiles", { keyPrefix: "edit.fields" });
  const isEnabled = useIsFeatureEnabled();

  const availableTypes = useMemo(() => {
    const types = ["phone"];
    if (isEnabled(FeatureFlag.Website)) {
      types.push("website");
    }
    if (isEnabled(FeatureFlag.Facebook)) {
      types.push("facebook");
    }
    if (isEnabled(FeatureFlag.Instagram)) {
      types.push("instagram");
    }
    if (isEnabled(FeatureFlag.Twitter)) {
      types.push("twitter");
    }
    if (isEnabled(FeatureFlag.Skype)) {
      types.push("skype");
    }
    if (isEnabled(FeatureFlag.SkypeBusiness)) {
      types.push("skype_business");
    }
    types.push("ms_teams");

    return types;
  }, [isEnabled]);

  const typeOptions = useMemo(
    () =>
      availableTypes.map<DropdownOption>((type) => {
        const typeLabel = t(`contactLabel.${type}` as unknown as TemplateStringsArray, {
          defaultValue: humanize(type)
        });

        return {
          id: type,
          displayElement: (
            <div className={css.option}>
              <Icon name={CONTACT_TYPE_TO_ICON_MAP[type]} size="sm" />
              <Typography variant="bodyRegular">{typeLabel}</Typography>
            </div>
          ),
          item: type,
          ariaLabel: typeLabel
        };
      }),
    [availableTypes, t]
  );

  const getValueLabel = (type: string) => {
    switch (type) {
      case "twitter":
      case "facebook":
      case "instagram":
      case "skype":
        return t("contactInfo.username");
      case "ms_teams":
      case "skype_business":
        return t("contactInfo.email");
      case "website":
        return t("contactInfo.url");
      case "phone":
        return t("contactInfo.phoneNumber");
      default:
        datadogRum.addError(new Error("Unknown contact_information type"), { type: type });
        return t("contactInfo.contact");
    }
  };

  return {
    typeOptions,
    getValueLabel
  };
};
