import classNames from "classnames";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

import css from "./page_title.module.scss";

type PageTitleProps = PropsWithChildren & {
  parentActivityId?: number;
  actions?: React.ReactNode;
  subtitle?: boolean;
  qaId?: string;
  classes?: {
    root?: string;
    actions?: string;
  };
};

export default function PageTitle({
  qaId,
  actions,
  children,
  subtitle = false,
  parentActivityId,
  classes
}: PageTitleProps) {
  const navigate = useNavigate();
  return (
    <div
      className={classNames(css.root, classes?.root, { [css.subtitle]: subtitle })}
      data-qa-id={qaId}
      onClick={subtitle ? undefined : () => subtitle && navigate(`/activities/${parentActivityId}`)}
      onKeyDown={subtitle ? undefined : () => subtitle && navigate(`/activities/${parentActivityId}`)}
    >
      {actions && <div className={classNames(css.actions, classes?.actions)}>{actions}</div>}
      <h1 className={css.children}>{children}</h1>
    </div>
  );
}
