import classNames from "classnames";
import { Icon } from "PFComponents/icon";
import TooltipIcon from "PFComponents/tooltip_icon/tooltip_icon";
import { Typography } from "PFComponents/typography";
import { PropsWithChildren } from "react";

import css from "./info_section.module.scss";

type InfoSectionProps = {
  title: React.ReactElement | string;
  titleGray?: boolean;
  tooltipContent?: string;
};

export const InfoSection = ({
  title,
  titleGray,
  tooltipContent,
  children
}: PropsWithChildren<InfoSectionProps>) => (
  <span className={css.infoSection}>
    <span className={css.title}>
      <Typography withMargin variant="labelRegular" className={classNames({ [css.gray]: titleGray })}>
        {title}
      </Typography>
      {tooltipContent && (
        <TooltipIcon IconComponent={() => <Icon name="info" size="sm" />} content={tooltipContent} />
      )}
    </span>
    {children}
  </span>
);
