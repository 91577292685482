import { LoadingDots } from "PFComponents/loading_dots";
import NotFoundComponent from "PFComponents/not_found/not_found";
import { CSSProperties, useMemo } from "react";

type BasicPropsType = { [key: string]: any };
type InnerPageComponentProps<T extends BasicPropsType> = {
  errors?: any[];
  loading?: boolean;
  loadingComponent?: React.ReactElement<any>;
  errorComponent?: React.ReactElement<any>;
  fade?: boolean;
  fadeStrength?: number;
  wrapperStyle?: CSSProperties;
} & T;

type WrappedComponentProps<T extends BasicPropsType> = {
  loading?: boolean;
} & T;

const usePageWrapper = <T extends BasicPropsType>(WrappedComponent: React.FC<WrappedComponentProps<T>>) =>
  useMemo(() => {
    const InnerPageComponent = ({
      loading,
      loadingComponent = <LoadingDots circlesEnabled pageViewEnabled />,
      errors,
      errorComponent = <NotFoundComponent />,
      fade,
      fadeStrength = 0.5,
      wrapperStyle = {},
      ...otherProps
    }: InnerPageComponentProps<T>) => {
      if (loading && loadingComponent) {
        return loadingComponent;
      } else if (errors && errors.length > 0 && errorComponent) {
        return errorComponent;
      } else {
        return (
          <div style={{ opacity: fade ? fadeStrength : 1, ...wrapperStyle }}>
            <WrappedComponent loading={loading || fade} {...(otherProps as T)} />
          </div>
        );
      }
    };

    return InnerPageComponent;
  }, [WrappedComponent]);

export default usePageWrapper;
