import { useDateFormatter } from "PFCore/hooks/use_date_formatter";

import { isBookingWithTime } from "../../../../booking/parts/helpers";

type DatesColumnProps = {
  startDate: string;
  endDate: string;
};

export const DatesColumn = ({ startDate, endDate }: DatesColumnProps) => {
  const { formatDate, formatDateTime, utc } = useDateFormatter();
  const isWithTime = isBookingWithTime(utc(startDate), utc(endDate));
  const formattedStartDate = isWithTime ? formatDateTime(utc(startDate)) : formatDate(utc(startDate));
  const formattedEndDate = isWithTime ? formatDateTime(utc(endDate)) : formatDate(utc(endDate));
  const dates = `${formattedStartDate} - ${formattedEndDate}`;
  return <span>{dates}</span>;
};
