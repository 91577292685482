import isNumber from "lodash/isNumber";
import some from "lodash/some";
import ActivityPageContext from "PFApp/activities/show/activity_page_context";
import { Alert } from "PFComponents/alert";
import { DropdownOption } from "PFComponents/dropdown/dropdown";
import { Select } from "PFComponents/select/select";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { Id } from "PFTypes/id";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { ClosureReason } from "../../../activity_vacancies.types";
import { requiresProfileId } from "../../../activity_vacancies.utils";
import { CandidateSelect } from "../../candidate_select";
import css from "./open_vacancy.module.scss";

export type OpenVacancyProps = {
  closeOptions: DropdownOption[];
  closureReasons: ClosureReason[];
  reasonValue: string;
  candidateValue: string | number | null;
  selectedProfileIds: Id[];
  onReasonChange: (value: string) => void;
  onCandidateChange: (value: string | number | null) => void;
};

export const OpenVacancy = ({
  closeOptions,
  closureReasons,
  reasonValue,
  candidateValue,
  selectedProfileIds,
  onReasonChange,
  onCandidateChange
}: OpenVacancyProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.vacancies" });
  const { handleChangeTab, shortlists } = useContext(ActivityPageContext);

  const reason = closureReasons.find(({ key }) => key === reasonValue);
  const isAutoComplete = reason && requiresProfileId(reason);
  const showWarning =
    isAutoComplete &&
    isNumber(candidateValue) &&
    !some(shortlists, (shortlist) => shortlist.profile.id === candidateValue);

  return (
    <div className={css.detailsField}>
      <Select
        placeholder={t("pleaseSelect")}
        value={reason?.label}
        controlledValue
        options={closeOptions}
        onChange={onReasonChange}
      />
      {t("enterNameOrEmail")}
      {isAutoComplete ? (
        <CandidateSelect
          candidateId={candidateValue as number}
          selectedProfileIds={selectedProfileIds}
          onCandidateChange={onCandidateChange}
        />
      ) : (
        <InputFieldSet
          label={t("candidateNameOrEmail")}
          onChange={onCandidateChange}
          value={candidateValue || ""}
          maxLength={255}
        />
      )}

      {showWarning && (
        <Alert
          action={{
            onClick: () => handleChangeTab("shortlist"),
            text: t("pickSomeoneFromShortlist")
          }}
          title={t("candidateNotOptimalMatch")}
        />
      )}
    </div>
  );
};
