import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

import { AttributeValue } from "../../../../../../types/booking_history_item";
import css from "./single_change_item.module.scss";

type SingleChangeItemProps = {
  data: {
    valueFrom: AttributeValue;
    valueTo: AttributeValue;
  };
  valueRenderer: (value: AttributeValue) => React.ReactNode;
};

export const SingleChangeItem = ({ data, valueRenderer }: SingleChangeItemProps) => {
  const { t } = useTranslation("bookingModule");
  const shouldDisplayValueFrom = data.valueFrom !== null && data.valueFrom !== "";
  const shouldDisplayValueTo = data.valueTo !== null && data.valueTo !== "";

  return (
    <div className={css.root}>
      {shouldDisplayValueFrom && (
        <div className={css.change}>
          <Typography withMargin variant="labelRegular">
            {t("details.history.from")}
          </Typography>
          {valueRenderer(data.valueFrom)}
        </div>
      )}
      {shouldDisplayValueTo && (
        <div className={css.change}>
          <Typography withMargin variant="labelRegular">
            {t("details.history.to")}
          </Typography>
          {valueRenderer(data.valueTo)}
        </div>
      )}
    </div>
  );
};
