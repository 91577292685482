import { Button } from "PFComponents/button";
import { Stack } from "PFComponents/containers";
import { Divider } from "PFComponents/divider";
import { Typography } from "PFComponents/typography";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useTranslation } from "react-i18next";

import css from "../custom_value_preview.module.scss";

const SKILL_PLACEHOLDER = "<TERM>";

type LearningPlatformLinkProps = {
  skillName: string;
};

export const LearningPlatformLink = ({ skillName }: LearningPlatformLinkProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "customValueSidepanel" });
  const {
    data: { customization }
  } = useCurrentAccount();

  const learningPlatformLink = customization.learning_platform_link;
  const isLearningPlatformLinkValid =
    !!learningPlatformLink && learningPlatformLink.includes(SKILL_PLACEHOLDER);
  const linkToTheSkill = learningPlatformLink?.replace(SKILL_PLACEHOLDER, skillName);

  if (!isLearningPlatformLinkValid) {
    return null;
  }

  return (
    <>
      <Divider />
      <Stack>
        <Typography variant="h5">{t("learning")}</Typography>
        <Typography variant="bodyRegular">{t("youCanDevelopMissingSkill")}</Typography>
        <Button kind="text" tag="a" href={linkToTheSkill} target="_blank" className={css.linkButton}>
          {t("openCourse")}
        </Button>
      </Stack>
    </>
  );
};
