import ProfilesMatchingRolesController from "PFApp/profiles/matching_roles/profile_matching_roles_controller";
import ProfileShowController from "PFApp/profiles/show/profile_show_controller";
import NotFoundRoute from "PFApp/routes/not_found_route";
import {
  EnsureLoggedInAndRegistered,
  EnsureWorkflowPermission,
  WithLayout
} from "PFApp/routes/route_helpers";
import { Navigate, Route, Routes } from "react-router-dom";

import ProfileEditController from "../profiles/edit/profile_edit_controller";
import { ProfilesPageControllerWrapper } from "../profiles/list/profiles_page_controller";

export const ProfilesRoutes = () => (
  <EnsureLoggedInAndRegistered>
    <WithLayout>
      <Routes>
        <Route path="me/suggested_skills" element={<Navigate to="../me/summary/skills" replace />} />
        <Route path="edit/:section" element={<ProfileEditController />} />
        <Route
          path=":id/matching_roles"
          element={
            <EnsureWorkflowPermission>
              <ProfilesMatchingRolesController />
            </EnsureWorkflowPermission>
          }
        />
        <Route path=":id" element={<ProfileShowController />}>
          <Route path=":section" element={<ProfileShowController />}>
            <Route path=":additionalSection" element={<ProfileShowController />} />
          </Route>
        </Route>
        <Route path="/" element={<ProfilesPageControllerWrapper />} />
        <Route path="*" element={<NotFoundRoute />} />
      </Routes>
    </WithLayout>
  </EnsureLoggedInAndRegistered>
);
