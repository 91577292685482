import classNames from "classnames";
import css from "PFApp/booking/parts/overview/detail_view/detail_view.module.scss";
import { IconProps } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { useGetFormattedPostUntil } from "PFCore/helpers/use_get_formatted_post_until";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Activity } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Label } from "../parts/label";

type DetailsProps = { activity: Activity };

export const Details = ({ activity }: DetailsProps) => {
  const { t } = useTranslation();
  const { formatDate, utc } = useDateFormatter();

  const { created_at: createdAt, post_until: postUntil, vacancies, shortlists } = activity;
  const { timeHeader, timeValue } = useGetFormattedPostUntil(postUntil);

  const vacanciesTotal = vacancies?.total || 0;
  const shortlistsBooked = (shortlists?.booked || 0) + (shortlists?.confirmed || 0);

  const detailsData: { title: string; icon: IconProps["name"]; text: string | number }[] = useMemo(
    () => [
      {
        title: timeHeader,
        icon: "hourglass-half",
        text: timeValue
      },
      {
        title: t("createdAt"),
        icon: "calendar",
        text: formatDate(utc(createdAt))
      },
      {
        title: t("vacancies"),
        icon: "role",
        text: vacanciesTotal
      },
      {
        title: t("booked"),
        icon: "check",
        text: shortlistsBooked
      }
    ],
    [t, createdAt, timeHeader, timeValue, shortlistsBooked, vacanciesTotal, formatDate, utc]
  );

  return (
    <span className={classNames(css.details, css.item)}>
      {detailsData.map(({ title, icon, text }) => (
        <span key={title} className={css.item}>
          <Label text={title} icon={icon} />
          <Typography variant="bodyBold" tag="span">
            {text}
          </Typography>
        </span>
      ))}
    </span>
  );
};
