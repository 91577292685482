import { ActionIcon } from "PFComponents/action_icon";
import { Select } from "PFComponents/select/select";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useProfileInformationEditField } from "../../common/use_contact_information_edit_field";
import css from "./contact_information_field.less";

const ContactInformationEditField = ({ error, locked, lockedTip, onChange, onRemove, contact }) => {
  const { t } = useTranslation("profiles", { keyPrefix: "edit.fields" });

  const { typeOptions, getValueLabel } = useProfileInformationEditField();

  const [state, setState] = useState({
    input_id: contact.input_id,
    type: contact.type,
    value: contact.value,
    label: contact.label
  });

  const handleChange = (field, value) => {
    const newState = { ...state, [field]: value };
    setState(newState);
    onChange?.(newState);
  };

  const handleRemove = () => {
    onRemove?.(contact);
  };

  const { type, value, label } = state;

  const allowLabel = ["phone", "website"].includes(type);

  const valueLabel = getValueLabel(type);

  return (
    <div className={css.root} data-qa-id="profile-edit-contact-info-link">
      <div className={css.type} data-qa-id="select-component-type">
        <Select
          label={t("type")}
          readOnly
          locked={locked}
          lockedTip={lockedTip}
          displayValues={typeOptions.find(({ item }) => item === type)?.displayElement}
          controlledValue
          options={typeOptions}
          onChange={(value) => handleChange("type", value)}
        />
      </div>
      <div className={css.text} data-qa-id="profile-edit-contact-info-link-input">
        <InputFieldSet
          kind="bordered"
          label={valueLabel}
          value={value}
          error={error}
          onChange={(value) => handleChange("value", value)}
          locked={locked}
          lockedTip={lockedTip}
        />
      </div>
      {allowLabel && (
        <div className={css.text} data-qa-id="profile-edit-contact-info-label-input">
          <InputFieldSet
            kind="bordered"
            label={t("label")}
            value={label}
            onChange={(value) => handleChange("label", value)}
            locked={locked}
            lockedTip={lockedTip}
          />
        </div>
      )}
      {!locked && (
        <div className={css.remove} data-qa-id="profile-edit-contact-info-link-remove">
          <ActionIcon name="remove" onClick={handleRemove} disabled={locked} />
        </div>
      )}
    </div>
  );
};

ContactInformationEditField.propTypes = {
  contact: PropTypes.shape({
    type: PropTypes.string.isRequired,
    value: PropTypes.string,
    label: PropTypes.string,
    input_id: PropTypes.number
  }).isRequired,
  error: PropTypes.string,
  locked: PropTypes.bool,
  lockedTip: PropTypes.string,

  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default ContactInformationEditField;
