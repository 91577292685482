import classNames from "classnames";
import isUndefined from "lodash/isUndefined";
import { RequiredText } from "PFComponents/required_text";
import { Id } from "PFTypes";
import { AriaAttributes, useState } from "react";

import { ErrorMessage } from "../error_message/error_message";
import { Typography } from "../typography";
import { Radio, RadioItem } from "./radio";
import css from "./radios.module.scss";

type Props<ID_TYPE extends Id> = {
  items: RadioItem<ID_TYPE>[];
  defaultValue?: ID_TYPE;
  value?: ID_TYPE;
  onChange: (id: ID_TYPE) => void;
  inline?: boolean;
  errorMessage?: string;
  required?: boolean;
  label?: string;
  verticalItems?: boolean;
  classes?: {
    root?: string;
    radioGroup?: string;
  };
  name: string;
  disabled?: boolean;
} & AriaAttributes;

export const Radios = <ID_TYPE extends Id = string>({
  defaultValue,
  value,
  items,
  inline,
  onChange,
  errorMessage,
  required,
  label,
  verticalItems,
  classes,
  name,
  disabled,
  "aria-label": ariaLabel
}: Props<ID_TYPE>) => {
  const [selectedOption, setSelectedOption] = useState<ID_TYPE | undefined>(defaultValue);

  const handleOnClick = (id: ID_TYPE) => {
    setSelectedOption(id);
    onChange(id);
  };

  return (
    <div className={classNames(css.root, classes?.root)}>
      {label && (
        <Typography withMargin variant="bodyRegular" tag="span" className={css.label}>
          {required ? <RequiredText label={label} /> : label}
        </Typography>
      )}
      <fieldset
        className={classNames(css.radioGroup, classes?.radioGroup)}
        style={{
          flexDirection: inline ? "row" : "column"
        }}
        role="group"
        aria-label={ariaLabel || label}
      >
        {items.map((item) => {
          const isChecked = (isUndefined(defaultValue) ? value : selectedOption) === item.id;

          return (
            <Radio
              key={`${item.id}`}
              item={item}
              checked={isChecked}
              onChange={handleOnClick}
              verticalItems={verticalItems}
              name={name}
              disabled={item.disabled || disabled}
            />
          );
        })}
      </fieldset>

      {errorMessage && <ErrorMessage iconSize="sm" message={errorMessage} className={css.errorMessage} />}
    </div>
  );
};
