import { useTemplate } from "PFApp/hooks";
import { Typography } from "PFComponents/typography";
import { Activity, BookingCategory } from "PFTypes";
import { useTranslation } from "react-i18next";

import { ActivityCategory } from "./activity_category";
import css from "./activity_details_category_section.module.scss";

type ActivityDetailsCategoryProps = {
  task: Activity;
  category: BookingCategory;
};

export const ActivityDetailsCategorySection = ({ task, category }: ActivityDetailsCategoryProps) => {
  const { t } = useTranslation("activities");

  const { template_id } = task;
  const template = useTemplate(template_id);

  const bookingCategoryTitle =
    template?.schema.properties?.booking_category_id?.title || t("show.details.bookingCategory");

  return (
    <div className={css.root}>
      <Typography variant="h5">{bookingCategoryTitle}</Typography>
      <ActivityCategory category={category} />
    </div>
  );
};
