import { useAppContext } from "PFApp/app_context";
import ForgotPasswordPage from "PFApp/forgot_password/forgot_password_page";
import NotFoundRoute from "PFApp/routes/not_found_route";
import { EnsureLoggedOut, WithLayout } from "PFApp/routes/route_helpers";
import SignInPage from "PFApp/signin/sign_in_page";
import SignUpPage from "PFApp/signup/sign_up_page";
import { useSignOut } from "PFApp/use_session";
import { LoadingDots } from "PFComponents/loading_dots";
import api from "PFCore/api";
import { headerOnly } from "PFCore/base/page_layouts";
import { uriDeparam } from "PFCore/helpers/deparam";
import i18n from "PFCore/i18n";
import { GROWL_ACTIONS } from "PFReducers/growl_reducer";
import { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";

export const TokenResetRoute = () => {
  const { resetToken } = useParams<{ resetToken: string }>();
  window.storage.setItem("reset_password_token", resetToken);
  return <Navigate to="/password_edit" />;
};

export const SignOutRoute = () => {
  const { signOut } = useSignOut();
  useEffect(() => {
    signOut();
  }, []);
  return <LoadingDots circlesEnabled pageViewEnabled />;
};

export const WelcomeRoute = () => {
  const navigate = useNavigate();
  const { dispatch } = useAppContext();
  const params = uriDeparam({ cleanup: true });
  const signInType = params.get("sign_in_type");
  const welcomeToken = params.get("welcome_token");

  if (!welcomeToken) {
    return <Navigate to="/" replace />;
  }

  // When user clicks link in "Welcome mail" and account uses SSO
  if (signInType === "sso") {
    dispatch({
      type: GROWL_ACTIONS.GROWL_RENDER,
      payload: {
        message: i18n.t("welcome.sso"),
        kind: "success"
      }
    });
    return <Navigate to="/" replace />;
  }

  api({ url: "welcome", headers: { apiVersion: 1 }, params: { welcomeToken } }).then(
    (resp) => {
      const url = `/edit_password/from_welcome/${resp.resetPasswordToken}`;
      navigate(url);
    },
    () => {
      dispatch({
        type: GROWL_ACTIONS.GROWL_RENDER,
        payload: {
          message: i18n.t("welcome.failure"),
          kind: "error",
          onClick: () => navigate("/")
        }
      });
      navigate("/");
    }
  );

  return null;
};

const UsersRoutes = () => (
  <WithLayout layout={{ sidebar: headerOnly }}>
    <Routes>
      <Route path="password/edit" element={<Navigate to="/password_edit" replace />} />
      <Route path="welcome" element={<Navigate to="/welcome" replace />} />
      <Route
        path="signin"
        element={
          <EnsureLoggedOut>
            <SignInPage adminPath={false} />
          </EnsureLoggedOut>
        }
      />
      <Route
        path="signin_admin"
        element={
          <EnsureLoggedOut>
            <SignInPage adminPath={true} />
          </EnsureLoggedOut>
        }
      />
      <Route
        path="reset_password"
        element={
          <EnsureLoggedOut>
            <ForgotPasswordPage />
          </EnsureLoggedOut>
        }
      />
      <Route
        path="signup"
        element={
          <EnsureLoggedOut>
            <SignUpPage />
          </EnsureLoggedOut>
        }
      />
      <Route path="*" element={<NotFoundRoute />} />
    </Routes>
  </WithLayout>
);

export default UsersRoutes;
