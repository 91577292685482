import { datadogRum } from "@datadog/browser-rum";
import { Button } from "PFComponents/button";
import { Icon } from "PFComponents/icon";
import { Modal } from "PFComponents/modal";
import { Typography } from "PFComponents/typography";
import { ErrorInfo, ReactNode } from "react";
import { useState } from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";

import css from "./error_boundary.module.scss";

type ErrorBoundaryProps = {
  children?: ReactNode;
};

const FallBackComponent = () => {
  const [showCleanStorageModal, setShowCleanStorageModal] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={css.container}>
      <Icon name="error" size="xxxl" />
      <Typography variant="h2">{t("somethingWentWrong")}</Typography>
      <Typography variant="h5">{t("errorBoundary.actionMessage")}</Typography>
      <div className={css.buttons}>
        <Button onClick={() => window.location.reload()}>{t("errorBoundary.reload")}</Button>
        <Button kind="secondary" onClick={() => (window.location.href = "mailto:support@profinda.com")}>
          {t("errorBoundary.contactSupport")}
        </Button>
        <Button kind="secondary" onClick={() => setShowCleanStorageModal(true)}>
          {t("errorBoundary.cleanLocalStorage")}
        </Button>

        {showCleanStorageModal && (
          <Modal
            title={t("errorBoundary.cleanLocalStorage")}
            labelOK={t("errorBoundary.clean")}
            onOK={() => {
              window.storage?.clear();
              window.location.reload();
            }}
            onClose={() => setShowCleanStorageModal(false)}
          >
            {t("errorBoundary.cleanStorageWarning")}
          </Modal>
        )}
      </div>
    </div>
  );
};

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  const handleError = (error: Error, errorInfo: ErrorInfo) => {
    if (datadogRum) {
      datadogRum.addError(error, errorInfo);
    }
  };

  return (
    <ReactErrorBoundary onError={handleError} FallbackComponent={FallBackComponent}>
      {children}
    </ReactErrorBoundary>
  );
};
