import find from "lodash/find";
import { useAppContext } from "PFApp/app_context";
import ProfileEditPage from "PFApp/profiles/edit/profile_edit_page";
import { getSectionForTip, profileEditSections } from "PFApp/profiles/edit/profile_edit_sections";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { ApiRoute } from "PFCore/utilities/routes";
import { FeatureFlag } from "PFTypes";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ProfileEditController = () => {
  const navigate = useNavigate();
  const { tip, tipName } = useParams();

  const { dispatch } = useAppContext();
  const isEnabled = useIsFeatureEnabled();
  const { data: currentProfile } = useCurrentProfile();

  let { section = isEnabled(FeatureFlag.PasswordSignin) ? "credentials" : "notifications" } = useParams();

  useEffect(() => {
    if (section === undefined && tip !== undefined && tipName !== undefined) {
      const tipSection = getSectionForTip(tip);

      let sectionTip = tipSection && tipSection.section;

      if (!sectionTip) {
        sectionTip = tip;
      }
      navigate(`/profile/edit/${sectionTip}/tip/${tip}/${tipName}`);
      return null;
    }

    const sectionComponent = find(profileEditSections(dispatch), { section });

    if (
      !sectionComponent ||
      (sectionComponent.requireFeature && !isEnabled(sectionComponent.requireFeature)) ||
      (sectionComponent.if && !sectionComponent.if())
    ) {
      navigate("/profile/edit");
      return null;
    }
  }, [section]);

  if (section === "suggested_skills") {
    section = "credentials";
  }

  const id = currentProfile?.id;

  const [profile, setProfile] = useState({});

  useEffect(() => {
    setProfile(currentProfile);
  }, [currentProfile]);

  const handleProfileUpdate = (attributes = {}) => {
    const isMe = id === "me" || id === currentProfile.id;
    const url = ApiRoute(isMe ? "/api/me" : `/api/profiles/${id}`);
    const noUpdate = Object.keys(attributes).length === 0;

    return $.ajax({
      url,
      method: noUpdate ? "GET" : "PUT",
      api_version: 2,
      contentType: "application/json; charset=utf-8",
      data: noUpdate ? null : JSON.stringify(attributes)
    }).then((resp) => setProfile(resp));
  };

  return (
    <ProfileEditPage
      profile={profile}
      sections={profileEditSections(dispatch)}
      section={section}
      tip={tip}
      tipName={tipName}
      permissionsGroup={currentProfile.permissions_group}
      handleProfileUpdate={handleProfileUpdate}
    />
  );
};

export default ProfileEditController;
