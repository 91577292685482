import PropTypes from "prop-types";
import { Component, createRef } from "react";

import css from "./chat_body.module.scss";

export default class ChatBody extends Component {
  constructor(props) {
    super(props);
    this.wrap = createRef();
    this._prevHeight = 0;
    this._prevScrollTop = 0;
  }

  componentDidMount() {
    this.wrap.current.addEventListener("scroll", this.handleScroll.bind(this), { passive: true });
  }

  componentWillUnmount() {
    this.wrap.current.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  isBottom() {
    if (this.isUnmounted) {
      return false;
    }

    const wrap = this.wrap.current;

    return wrap.scrollTop + wrap.offsetHeight === wrap.scrollHeight;
  }

  savePosition() {
    if (this.isUnmounted) {
      return;
    }

    this._prevHeight = this.wrap.current.scrollHeight;
    this._prevScrollTop = this.wrap.current.scrollTop;
  }

  restorePosition() {
    if (this.isUnmounted) {
      return;
    }

    const wrap = this.wrap.current;
    wrap.scrollTop = wrap.scrollHeight - this._prevHeight + this._prevScrollTop;
  }

  cleanScrollPosition() {
    if (this.isUnmounted) {
      return;
    }

    this._prevHeight = 0;
    this._prevScrollTop = 0;
  }

  hasSavedScrollPosition() {
    return this._prevHeight > 0 && this._prevScrollTop > 0;
  }

  handleScroll() {
    const { onScroll } = this.props;

    if (onScroll) {
      onScroll();
    }
  }

  render() {
    const { opacity = 1, top = 32, bottom = 0, children } = this.props;

    return (
      <div
        ref={this.wrap}
        className={css.root}
        style={{ opacity, top, bottom }}
        data-qa-id="chat-window-body"
      >
        {children}
      </div>
    );
  }
}

ChatBody.propTypes = {
  top: PropTypes.number,
  bottom: PropTypes.number,
  opacity: PropTypes.number,
  children: PropTypes.any,
  onScroll: PropTypes.func
};
