import { Typography } from "PFComponents/typography";
import { AIDescriptionResponse } from "PFCore/services/ai/generate_role_description";

type GeneratorDataPreviewProps = {
  data: AIDescriptionResponse;
};

export const GeneratorDataPreview = ({ data }: GeneratorDataPreviewProps) => (
  <Typography withMargin variant="bodyRegular">
    <span dangerouslySetInnerHTML={{ __html: data.roleDescription }} />
  </Typography>
);
