import css from "./card_header.scss";

type CardHeaderProps = {
  title?: React.ReactNode;
  titleQaId?: string;
  action?: React.ReactNode;
  style?: React.CSSProperties;
};

const CardHeader = ({ title = "", titleQaId = "", action = null, style = {} }: CardHeaderProps) => (
  <div className={css.header} style={style}>
    <h2 data-qa-id={titleQaId} className={css.title}>
      {title}
      {action && <div className={css.action}>{action}</div>}
    </h2>
  </div>
);

export default CardHeader;
