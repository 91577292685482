import { ColumnHelper } from "@tanstack/react-table";
import { ProfileAvatarConnected } from "PFApp/components/profile_avatar_connected";
import { TemplateKey } from "PFApp/constants/templates";
import { useTemplate } from "PFApp/hooks";
import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";
import { EconomicsVacancy, EconomicsVacancyProfile, ProfileMinimized } from "PFTypes";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import css from "../vacancies_table.module.scss";

type UseWorkforceMemberColumn = {
  enableSorting: boolean;
  getVacancyProfile: (vacancy: EconomicsVacancy) => EconomicsVacancyProfile;
};

export const useWorkforceMemberColumn = ({ enableSorting, getVacancyProfile }: UseWorkforceMemberColumn) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario.assignmentTable" });
  const roleTemplate = useTemplate(TemplateKey.Role);

  return useCallback(
    (columnHelper: ColumnHelper<EconomicsVacancy>) =>
      columnHelper.accessor((row) => row, {
        id: "workforceMember",
        header: t("workforceMember"),
        cell: (info) => {
          const vacancy = info.getValue();
          const profile = getVacancyProfile(vacancy);

          if (!profile && !vacancy.closureReason) {
            return "-";
          }

          if (!profile && vacancy.closureReason) {
            const closureReasons = roleTemplate?.vacancy_closure_reasons || [];
            return (
              closureReasons.find(({ key }) => key === vacancy.closureReason)?.label ?? vacancy.closureReason
            );
          }

          return (
            <span className={css.profileCellContent}>
              <ProfileAvatarConnected profile={profile as unknown as ProfileMinimized} size={32} />
              <Typography variant="bodyButtons">{getProfileName(profile)}</Typography>
            </span>
          );
        },
        size: 250,
        enableSorting
      }),
    [enableSorting, getVacancyProfile, roleTemplate?.vacancy_closure_reasons, t]
  );
};
