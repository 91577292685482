import capitalize from "lodash/capitalize";
import ActivityPageContext from "PFApp/activities/show/activity_page_context";
import useActivityPageCompare from "PFApp/activities/show/hooks/use_activity_page_compare";
import { useActivityPageMatches } from "PFApp/activities/show/hooks/use_activity_page_matches";
import useActivityPageShortlists from "PFApp/activities/show/hooks/use_activity_page_shortlists";
import useActivityPageTabs from "PFApp/activities/show/hooks/use_activity_page_tabs";
import { ProfilesViewMode } from "PFApp/activities/types/profiles_view_mode";
import { useAppContext } from "PFApp/app_context";
import useBookingModulePermissions from "PFApp/use_booking_module_permissions";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import NotFoundComponent from "PFComponents/not_found/not_found";
import { activityNotificationTypes } from "PFCore/helpers/activities";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useQueryParams } from "PFCore/helpers/use_query_params";
import useStorage from "PFCore/helpers/use_storage";
import { useBookings } from "PFCore/hooks/queries";
import { useActivity, useActivityInvalidate, useHasOpenVacancy } from "PFCore/hooks/queries/activity";
import { useNotificationsMarkAsRead } from "PFCore/hooks/queries/notifications/use_notifications_mark_as_read";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { GROWL_ACTIONS } from "PFReducers/growl_reducer";
import { Activity, FeatureFlag, Id, MetaWithPagination } from "PFTypes";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import { TemplateKey } from "../../constants/templates";
import { useTemplateFind } from "../../hooks";
import { isActivityPending } from "../helpers";
import { useActivityTemplateType } from "../hooks";
import { useMatchesShortlistTablesColumns } from "./hooks/use_matches_shortlist_tables_columns";

type ActivityContextProviderProps = {
  id: Id;
  children: React.ReactNode;
  onActivityUpdate: () => void;
};

const ActivityContextProvider = ({ id, children, onActivityUpdate }: ActivityContextProviderProps) => {
  const { data: activity, isFetched: loaded, isError } = useActivity(id, { onSuccess: onActivityUpdate });

  if (!loaded) {
    return <LoadingDots circlesEnabled pageViewEnabled />;
  }

  if (isError || !activity) {
    return <NotFoundComponent />;
  }

  return <LoadedActivity activity={activity}>{children}</LoadedActivity>;
};

type LoadedActivityProps = React.PropsWithChildren & {
  activity: Activity;
};

const LoadedActivity = ({ activity, children }: LoadedActivityProps) => {
  const { t } = useTranslation("activities");

  const { section, tab } = useParams<{ section: string; tab: string }>();
  const queryParams = useQueryParams();
  const location = useLocation();
  const utmSource = location?.state?.utmSource;
  const params = {
    section,
    tab,
    page: queryParams.get("page") || 1,
    utmSource
  };

  const { dispatch } = useAppContext();
  const isEnabled = useIsFeatureEnabled();
  const findTemplate = useTemplateFind();

  const {
    availableMatchesShortlistColumns,
    selectedMatchesShortlistColumns,
    setSelectedMatchesShortlistColumns
  } = useMatchesShortlistTablesColumns();
  const { isAudit, isEngagement, isRole } = useActivityTemplateType(activity);
  const { showTabs, tabs, tabActiveId, handleChangeTab } = useActivityPageTabs(
    activity,
    params.tab || params.section
  );
  const { matches, matchesMeta, matchesFetchState, canSeeMatches, matchesParams, updateMatchesParams } =
    useActivityPageMatches(activity, params);
  const {
    shortlists,
    shortlistsMeta,
    suspendedShortlists,
    suspendedShortlistsMeta,
    allShortlists,
    isLoadingShortlists,
    rankedShortlists,
    updateShortlistsParams
  } = useActivityPageShortlists(activity);
  const {
    isEnabled: isBMEnabled,
    isPermittedToAccess: isPermittedToAccessBM,
    isPermittedToEdit: isPermittedToEditInBM
  } = useBookingModulePermissions();
  const {
    compareIds,
    setCompareIds,
    isProfileInComparison,
    addProfileToComparison,
    removeProfileFromComparison,
    clearComparisonToast
  } = useActivityPageCompare(activity.id, tabActiveId, isAudit);

  const { data: currentProfile } = useCurrentProfile();
  const { invalidate: invalidateActivities } = useActivityInvalidate();
  const { mutate: markNotificationsAsRead } = useNotificationsMarkAsRead();
  const { data: hasOpenVacancy, isFetching } = useHasOpenVacancy({ activityId: activity.id });
  const { data: bookings } = useBookings(
    { profileId: currentProfile.id, activityId: activity.id, perPage: 0 },
    { enabled: !isPermittedToEditInBM }
  );

  const [profilesViewMode, setProfilesViewMode] = useStorage<ProfilesViewMode>(
    "activity_profiles_view_mode",
    ProfilesViewMode.Cards
  );
  const [refetchedAt, setRefetchedAt] = useState<null | string>(null);

  // Permissions
  const canCreateRoles = useMemo(
    () => !!findTemplate({ key: TemplateKey.Role, create_permitted: true, hidden: false }),
    [findTemplate]
  );
  const canManageBookings =
    isEnabled(FeatureFlag.BookingModule) && isRole && activity.coowned && canCreateRoles;
  const canSeeBookingModule = isBMEnabled && isPermittedToAccessBM;
  const isCurrentProfileBooked = (bookings?.meta.total || 0) > 0;
  const canSeeViewInBookingModuleButton =
    canSeeBookingModule && (isCurrentProfileBooked || isPermittedToEditInBM);

  const template = findTemplate({ id: activity.template_id });
  const templateName = capitalize(template?.name);
  const roleKey = isAudit ? TemplateKey.AuditRole : TemplateKey.Role;
  const isFillDisabled = isFetching || !hasOpenVacancy;

  useEffect(() => {
    if (["workflow", "email"].includes(utmSource || "")) {
      markNotificationsAsRead({
        activityId: activity.id,
        targetTypes: activityNotificationTypes
      });
    }
  }, []);

  useEffect(() => {
    isActivityPending(activity, true) &&
      dispatch({
        type: GROWL_ACTIONS.GROWL_RENDER,
        payload: {
          message: (
            <div>
              <Trans
                i18nKey={"show.patientAsDetailsCurate"}
                t={t}
                components={[<></>]}
                values={{ templateName }}
              />
              <Button
                kind="text"
                text={t("show.clickToReload")}
                onClick={() =>
                  invalidateActivities([activity.id]).then(() => setRefetchedAt(new Date().toISOString()))
                }
              />
            </div>
          ),
          kind: "alert",
          ttl: null
        }
      });
  }, [refetchedAt]);

  return (
    <ActivityPageContext.Provider
      value={{
        task: activity,
        isAudit,
        isEngagement,
        isRole,
        roleKey,
        isFillDisabled,
        // Tabs
        showTabs,
        tabs,
        tabActiveId,
        handleChangeTab,
        // Matches
        matches,
        matchesMeta: matchesMeta as MetaWithPagination,
        matchesFetchState,
        matchesParams,
        updateMatchesParams,
        // Shortlists
        shortlists,
        shortlistsMeta,
        suspendedShortlists,
        suspendedShortlistsMeta,
        allShortlists,
        isLoadingShortlists,
        rankedShortlists,
        updateShortlistsParams,
        // Compare profiles
        compareIds,
        setCompareIds,
        clearComparisonToast,
        isProfileInComparison,
        addProfileToComparison,
        removeProfileFromComparison,
        // Permissions
        canCreateRoles,
        canSeeMatches,
        canManageBookings,
        canSeeBookingModule,
        canSeeViewInBookingModuleButton,
        // Matches/Shortlist profiles mode
        profilesViewMode,
        setProfilesViewMode,
        availableMatchesShortlistColumns,
        selectedMatchesShortlistColumns,
        setSelectedMatchesShortlistColumns
      }}
    >
      {children}
    </ActivityPageContext.Provider>
  );
};

export default ActivityContextProvider;
