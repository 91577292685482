import classNames from "classnames";
import map from "lodash/map";
import { ActionIcon } from "PFComponents/action_icon";
import { Alert } from "PFComponents/alert";
import { Button, ButtonKind } from "PFComponents/button";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { IconName } from "PFTheme/graphics/icons";
import React, { ReactNode } from "react";

import css from "./subsidiary_mode_alert.module.scss";

export type Action = {
  icon: IconName;
  onClick: () => void;
  disabled?: boolean;
  title?: ReactNode;
  buttonKind?: ButtonKind;
};

type SubsidiaryModeAlertProps = {
  className?: string;
  title: string | React.ReactNode;
  actions?: Action[];
  onClose: () => void;
};

export const SubsidiaryModeAlert = ({ className, title, actions, onClose }: SubsidiaryModeAlertProps) => (
  <Alert className={classNames(css.content, className)} small hideIcon kind="info">
    <ActionIcon name="cross" onClick={onClose} disableHover />
    <Typography variant="bodyRegular">{title}</Typography>
    {actions && (
      <span className={css.actions}>
        {map(actions, ({ icon, buttonKind, onClick, disabled, title }) =>
          title ? (
            <Tooltip content={title} placement="top">
              <span>
                <Button kind={buttonKind ?? "ghost"} icon={icon} onClick={onClick} disabled={disabled} />
              </span>
            </Tooltip>
          ) : (
            <Button kind={buttonKind ?? "ghost"} icon={icon} onClick={onClick} disabled={disabled} />
          )
        )}
      </span>
    )}
  </Alert>
);
