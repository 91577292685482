import compact from "lodash/compact";
import isArray from "lodash/isArray";
import reverse from "lodash/reverse";
import Tooltip from "PFComponents/tooltip/tooltip";

import css from "./assessment_checkmarks.module.scss";

type AssessmentCheckmarksProps = {
  title: string;
  tooltipContent?: React.ReactElement | string | null;
  showTooltip?: boolean;
};

export const AssessmentCheckmarks = ({
  children,
  title,
  tooltipContent,
  showTooltip
}: React.PropsWithChildren<AssessmentCheckmarksProps>) => {
  const icons = reverse(compact(isArray(children) ? children : [children]));

  return (
    <Tooltip content={tooltipContent} disabled={!showTooltip} theme="pf-dark-blue">
      <div className={css.leftIconsContainer}>
        {icons.map((icon, i) => (
          <div key={`${title}-${i}`} className={css.leftIconWrapper}>
            {icon}
          </div>
        ))}
      </div>
    </Tooltip>
  );
};
