import classNames from "classnames";
import css from "PFApp/booking/parts/overview/detail_view/detail_view.module.scss";
import { ReadableAvailabilityRules } from "PFApp/components/readable_availability_rules";
import { Icon } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { useReadableAvailability } from "PFCore/helpers/use_readable_availability";
import { AvailabilityRule } from "PFTypes";
import { useTranslation } from "react-i18next";

import { Label } from "../parts/label";

type DurationProps = { availability: AvailabilityRule };

export const Duration = ({ availability }: DurationProps) => {
  const { t } = useTranslation();

  const { readableAvailability } = useReadableAvailability({
    availability: availability as AvailabilityRule
  });

  return (
    <span className={classNames(css.item, css.duration)}>
      <span>
        <div className={css.alignCenter}>
          <Label icon="calendar" text={t("duration")} />
          {availability?.ranges && (
            <Tooltip
              maxWidth={400}
              content={
                <div className={css.availabilityTooltip}>
                  <ReadableAvailabilityRules availability={availability} />
                </div>
              }
              appendTo="parent"
              interactive
            >
              <span className={css.alignCenter}>
                <Icon name="info" size="sm" />
              </span>
            </Tooltip>
          )}
        </div>
        <Typography variant="bodyBold">{readableAvailability}</Typography>
      </span>
    </span>
  );
};
