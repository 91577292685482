import { Typography } from "PFComponents/typography";
import { minutesToHours } from "PFCore/helpers/date";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import React from "react";
import { useTranslation } from "react-i18next";

import { useErrorTypeLabels } from "../use_error_type_labels";
import css from "./misalignment_item.module.scss";

type MisalignmentItemProps = {
  errorType: string;
  errorValues: (string | number)[];
};

export const MisalignmentItem = ({ errorType, errorValues }: MisalignmentItemProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "details.misalignments" });
  const { formatDate, utc } = useDateFormatter();

  const getFormattedError = (errorType: string, errorValue: number | string) =>
    errorType === "duration" ? `${minutesToHours(errorValue as number)}h` : formatDate(utc(errorValue));

  const errorTypeLabels = useErrorTypeLabels();
  const expected = getFormattedError(errorType, errorValues[0]);
  const current = errorValues[1] != null ? getFormattedError(errorType, errorValues[1]) : null; // eslint-disable-line eqeqeq
  return (
    <div className={css.root}>
      <div>
        <Typography withMargin variant="bodyBold" tag="span">
          {errorTypeLabels[errorType]}
        </Typography>
      </div>
      <div className={css.valuesContainer}>
        <Typography withMargin variant="bodyRegular" tag="span">
          {t("misalignmentExpected", { expected })}
        </Typography>
        <Typography withMargin variant="bodyRegular" tag="span">
          {t("misalignmentCurrent", { current })}
        </Typography>
      </div>
    </div>
  );
};
