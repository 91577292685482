import { useActivityParent } from "PFApp/activities/show/hooks/use_activity_parent";
import { Button, ButtonProps } from "PFComponents/button";
import Tooltip from "PFComponents/tooltip/tooltip";
import { useShortlistsInvalidate } from "PFCore/hooks/queries/shortlists/use_shortlists_invalidate";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { useBookingFormContext } from "../../../../booking/components/booking_form";
import { BookingFormMode } from "../../../../booking/components/booking_form/use_booking_form";
import ActivityPageContext from "../../activity_page_context";

type BookButtonProps = {
  profileId?: number;
  shortlistId: number;
  buttonKind?: ButtonProps["kind"];
  disabled?: boolean;
  small?: boolean;
  className?: string;
};

export const BookButton = ({
  profileId,
  shortlistId,
  buttonKind,
  disabled,
  small,
  className
}: BookButtonProps) => {
  const { t } = useTranslation(["activities", "translation"]);
  const { modal } = useBookingFormContext();
  const { task } = useContext(ActivityPageContext);

  const parentActivity = useActivityParent(task);
  const { invalidateShortlists } = useShortlistsInvalidate();

  const isCompleted = task?.state === "complete" || parentActivity?.state === "complete";

  const renderButton = () => (
    <Button
      text={t("activities:show.shortlist.bookManually")}
      kind={buttonKind || "secondary"}
      disabled={disabled || isCompleted}
      small={small}
      className={className}
      onClick={(event) => {
        event.stopPropagation();
        modal.open(
          {
            profileId,
            activityId: task?.id,
            shortlistId
          },
          {
            mode: BookingFormMode.Create,
            onSuccess: () => invalidateShortlists(task?.id)
          }
        );
      }}
    />
  );

  return !isCompleted ? (
    renderButton()
  ) : (
    <Tooltip content={t("translation:bookingNotAvailable")}>
      <div>{renderButton()}</div>
    </Tooltip>
  );
};
