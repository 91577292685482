import isEmpty from "lodash/isEmpty";
import LegacyForm from "PFApp/components/form/legacy_form";
import ProfileEditPanel from "PFApp/profiles/edit/profile_edit_panel";
import { getProfileSectionText } from "PFApp/profiles/edit/profile_edit_sections";
import css from "PFApp/profiles/edit/sections/section.less";
import ProfileLinkedInConnectButton from "PFApp/profiles/profile_linkedin_connect_button";
import { useGrowl } from "PFApp/use_growl";
import { useSignOut } from "PFApp/use_session";
import { Alert } from "PFComponents/alert";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useForgotPassword } from "PFCore/hooks/queries/auth/use_forgot_password";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { editCurrentProfile } from "PFCore/services/current_profile";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const CredentialsSection = ({ profile, handleProfileUpdate }) => {
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const growl = useGrowl();
  const { t } = useTranslation("profiles", { keyPrefix: "edit.sections" });

  const [email, setEmail] = useState(currentProfile.email);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [errors, setErrors] = useState({});
  const [emailErrors, setEmailErrors] = useState({});

  const { signOut } = useSignOut();

  const {
    mutate: forgotPassword,
    isLoading: isForgotPasswordLoading,
    isSuccess: isForgotPasswordSuccess,
    isError: isForgotPasswordError,
    isIdle: isForgotPasswordIdle
  } = useForgotPassword();

  const handleEditEmail = () => editCurrentProfile({ email });
  const handleEditPassword = () =>
    editCurrentProfile({
      current_password: password,
      password: newPassword,
      password_confirmation: newPassword
    });

  const resetPassword = () => {
    forgotPassword({
      accountId: currentAccount.id,
      email: profile.email
    });
  };

  const handleEmailSuccess = () => {
    handleProfileUpdate();
    setEmailErrors({});
    growl({ message: t("credentials.successes.email") });
  };

  const handleEmailError = (errors) => setEmailErrors(errors);

  const handleSuccess = () => {
    handleProfileUpdate();
    setEmailErrors({});
    growl({ message: t("credentials.successes.password") });
  };

  const handleError = (errors) => {
    if (errors.current_password) {
      signOut({
        error: t("credentials.passwordError")
      });
      return null;
    } else {
      setErrors(errors);
    }
  };

  const renderForgotLink = () =>
    isForgotPasswordIdle ? (
      <small>
        {t("credentials.forgotPassword")}{" "}
        <Button kind="text" data-qa-id="profile-edit-password-forgot" onClick={resetPassword}>
          {t("credentials.resetPassword")}
        </Button>
      </small>
    ) : (
      <Alert style={{ display: "inline-block" }} kind={isForgotPasswordError ? "error" : "warning"}>
        {isForgotPasswordLoading && <LoadingDots />}
        {isForgotPasswordSuccess && t("credentials.willReceiveEmail")}
        {isForgotPasswordError && t("credentials.anErrorOccured")}
      </Alert>
    );

  return (
    <ProfileEditPanel title={getProfileSectionText("credentials")}>
      {profile && (
        <div className={css.connectButton}>
          <ProfileLinkedInConnectButton profile={profile} />
        </div>
      )}

      <h3 className={css.header} style={{ paddingTop: 0 }}>
        {t("credentials.changeYourEmail")}
      </h3>
      <LegacyForm
        onSuccess={handleEmailSuccess}
        onError={handleEmailError}
        onSubmit={handleEditEmail}
        qaIdPrefix="profile-edit-credentials-email"
        isDirty={profile.email !== email}
      >
        <div data-qa-id="profile-edit-credentials-email" className={css.wrap}>
          <InputFieldSet
            label={t("credentials.emailAddress")}
            error={emailErrors.email}
            tip={t("credentials.changeEmailTip")}
            value={email}
            onChange={setEmail}
          />
        </div>
      </LegacyForm>

      <h3 className={css.header}>{t("credentials.changeYourPassword")}</h3>
      <LegacyForm
        isDirty={!isEmpty(password) || !isEmpty(newPassword)}
        onSuccess={handleSuccess}
        onError={handleError}
        onSubmit={handleEditPassword}
        qaIdPrefix="profile-edit-credentials-password"
      >
        <div style={{ textAlign: "right", marginTop: 12, marginBottom: 20 }}>{renderForgotLink()}</div>

        <div data-qa-id="profile-edit-credentials-current-password" className={css.wrap}>
          <InputFieldSet
            label={t("credentials.currentPassword")}
            error={errors.current_password}
            inputType="password"
            value={password}
            onChange={setPassword}
          />
        </div>
        <div data-qa-id="profile-edit-credentials-new-password" className={css.wrap}>
          <InputFieldSet
            label={t("credentials.newPassword")}
            attr="password"
            error={errors.password}
            tip={t("credentials.newPasswordTip")}
            showPasswordToggle={true}
            inputType="password"
            onChange={setNewPassword}
          />
        </div>
      </LegacyForm>
    </ProfileEditPanel>
  );
};

CredentialsSection.propTypes = {
  profile: PropTypes.object,
  handleProfileUpdate: PropTypes.func
};

export default CredentialsSection;
