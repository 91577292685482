import classNames from "classnames";
import { BookingPill } from "PFApp/components/pills/booking_pill";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { BookingCategory } from "PFTypes";
import React from "react";

import { UpdatedBookingChange } from "../overbookings_management_modal";
import { BookingPillContent } from "./components/booking_pill_content";
import { DeleteBookingAction } from "./components/delete_booking_action";
import css from "./suggested_changes.module.scss";

type SuggestedChangesDeleteProps = {
  bookingChange: UpdatedBookingChange;
  category: BookingCategory;
  onToggleRemoveBooking: (index: number) => void;
};

export const SuggestedChangesDelete = ({
  bookingChange,
  category,
  onToggleRemoveBooking
}: SuggestedChangesDeleteProps) => {
  const { booking } = bookingChange;

  return (
    <div className={css.flexColumnContainer}>
      <div className={css.categoryContainer}>
        <Icon name="warning" color="paletteOrange0" />
        <Typography withMargin variant="bodyBold" tag="span">
          {category.display_as}
        </Typography>
      </div>

      <div className={classNames(css.flexColumnContainer, css.singleChangeContainer)}>
        <DeleteBookingAction
          shouldBeRemoved={bookingChange.shouldBeRemoved}
          onToggle={() => onToggleRemoveBooking(bookingChange.bookingChangeIndex)}
        />
        <BookingPill
          category={category}
          className={css.bookingPill}
          style={{
            borderWidth: 1
          }}
          withCategoryIcon={false}
        >
          <BookingPillContent startDate={booking.startDate} endDate={booking.endDate} />
        </BookingPill>
      </div>
    </div>
  );
};
