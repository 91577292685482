import { Typography } from "PFComponents/typography";
import { getProfileName, ObjectWithProfileName } from "PFCore/helpers/profile";
import { Profile } from "PFTypes";
import React from "react";

import css from "./workforce_member_column.module.scss";

type ProfileWithNameAndEmail = ObjectWithProfileName &
  Pick<Profile, "email"> & {
    [key: string]: any;
  };

type WorkforceMemberProps = {
  profile: ProfileWithNameAndEmail;
};

export const WorkforceMemberColumn = ({ profile }: WorkforceMemberProps) => {
  const profileFullName = getProfileName(profile);

  return (
    <div className={css.root}>
      <Typography withMargin variant="bodyBold" tag="span">
        {profileFullName}
      </Typography>
      <Typography withMargin variant="labelRegular" tag="span" clipOverflow>
        {profile.email}
      </Typography>
    </div>
  );
};
