import { isRegistrationComplete } from "PFApp/routes/route_helpers";
import useIsAuditPlannerPermitted from "PFApp/use_is_audit_planner_permitted";
import useIsWorkflowPermitted from "PFApp/use_is_workflow_permitted";
import { isMsTeams } from "PFCore/helpers/ms_teams";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { Navigate, useLocation } from "react-router-dom";

import { useDeeplink } from "../use_deeplink";

const SignedInRootRoute = () => {
  const { getDeeplink } = useDeeplink();
  const { data: currentProfile } = useCurrentProfile();

  const isWorkflowPermitted = useIsWorkflowPermitted();
  const isAuditPlannerPermitted = useIsAuditPlannerPermitted();

  if (!isRegistrationComplete(currentProfile)) {
    return <Navigate to="/onboarding" replace />;
  }

  const deeplink = getDeeplink({ remove: true });
  if (deeplink) {
    return <Navigate to={deeplink} replace />;
  }
  if (isWorkflowPermitted) {
    return <Navigate to="/workflow" replace />;
  }
  if (isAuditPlannerPermitted) {
    return <Navigate to="/audit_planner" replace />;
  }
  return <Navigate to="/profiles/me" replace />;
};

export const RootRoute = () => {
  const location = useLocation();
  const { isSignedIn } = useCurrentProfile();

  // Fallback if using an old hash based URL
  if (location.hash && location.hash.charAt(0) === "#" && location.pathname === "/") {
    const url = location.hash.slice(1, location.hash.length);
    return <Navigate to={url} replace />;
  }
  if (isSignedIn) {
    return <SignedInRootRoute />;
  }
  if (isMsTeams()) {
    return <Navigate to="/ms_teams/signin" replace />;
  }

  return <Navigate to="/users/signin" replace />;
};
