import { Inline } from "PFComponents/containers/flex/inline";
import { Stack } from "PFComponents/containers/flex/stack";
import { Divider } from "PFComponents/divider";
import { Icon } from "PFComponents/icon";
import Pill from "PFComponents/pill/pill";
import { Typography } from "PFComponents/typography";
import { useCertificates } from "PFCore/hooks/queries/certificates";
import { useTranslation } from "react-i18next";

import { useCustomValuePreviewContext } from "../../context/custom_value_preview_context";
import { CertificateTile } from "./certificate_tile";

const PAGE = 1;
const PER_PAGE = 15;

export const CertificatesList = () => {
  const { profileId, customValueId } = useCustomValuePreviewContext();
  const { t } = useTranslation("translation", { keyPrefix: "customValueSidepanel.certificates" });
  const { data } = useCertificates({
    profileId: profileId!,
    page: PAGE,
    perPage: PER_PAGE,
    customValueIds: [customValueId!]
  });

  const certificates = data?.entries || [];

  if (certificates.length === 0) {
    return null;
  }

  return (
    <>
      <Divider />
      <Stack as="section" gap="spacingMd">
        <Inline as="header" gap="spacingMd" alignItems="center">
          <Icon name="certificate" />
          <Typography variant="h5">{t("title")}</Typography>
          <Pill small>{data?.meta.total ?? 0}</Pill>
        </Inline>
        {certificates.map((certificate) => (
          <CertificateTile key={certificate.id} certificate={certificate} />
        ))}
      </Stack>
    </>
  );
};
