import get from "lodash/get";
import { Radios } from "PFComponents/radios";
import { Typography } from "PFComponents/typography";
import BubbleChartIcon from "PFIcons/bubble.svg";
import StockMarketIcon from "PFIcons/tree_map.svg";
import { PropsWithChildren } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MyInformationFormData } from "../../my_information_edit_modal.types";
import { FormSection } from "../form_section";
import css from "./skill_insights_section.module.scss";

type RadioLabelProps = {
  label: string;
};

const RadioLabel = ({ label, children }: PropsWithChildren<RadioLabelProps>) => (
  <div className={css.radioLabel}>
    <Typography withMargin variant="bodyRegular" tag="span" className={css.radioLabelText}>
      {label}
    </Typography>
    {children}
  </div>
);

export const SkillInsightsSection = () => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.myInformationModal" });

  const {
    control,
    formState: { errors }
  } = useFormContext<MyInformationFormData>();

  return (
    <FormSection title={t("mySkillsInsights")}>
      <Controller
        name="skillsInsights"
        control={control}
        render={({ field: { name, onChange, value } }) => (
          <Radios
            name={name}
            errorMessage={get(errors, name)?.message}
            items={[
              {
                id: "bubble",
                customLabel: (
                  <RadioLabel label={t("bubbleChart")}>
                    <BubbleChartIcon className={css.stockIcon} height={20} width={20} />
                  </RadioLabel>
                )
              },
              {
                id: "drilldown",
                customLabel: (
                  <RadioLabel label={t("treeMapChart")}>
                    <StockMarketIcon className={css.stockIcon} height={20} width={20} />
                  </RadioLabel>
                )
              }
            ]}
            value={value}
            onChange={onChange}
            aria-label={t("mySkillsInsights")}
          />
        )}
      />
    </FormSection>
  );
};
