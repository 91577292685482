import { Typography } from "PFComponents/typography";
import { convertToPercentage } from "PFCore/utilities/math";
import React from "react";

type ScoreColumnProps = {
  value: string | undefined;
};

export const ScoreColumn = ({ value }: ScoreColumnProps) => (
  <Typography withMargin variant="bodyRegular">
    {convertToPercentage(value, { isNormalized: true })}
  </Typography>
);
