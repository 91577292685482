import { minutesToHours } from "PFCore/helpers/date";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { Booking } from "PFTypes";
import { useTranslation } from "react-i18next";

import { useNotApplicable } from "../hooks/use_not_applicable";

type TotalHoursColumnProps = {
  booking: Booking;
};

export const TotalHoursColumn = ({ booking }: TotalHoursColumnProps) => {
  const { t } = useTranslation("availability");
  const { getCategory } = useBookingCategories();
  const NOT_APPLICABLE = useNotApplicable();
  const category = getCategory(booking);
  const hoursCount = minutesToHours(booking.duration);

  return <span>{category ? t("bookings.list.totalHoursValue", { count: hoursCount }) : NOT_APPLICABLE}</span>;
};
