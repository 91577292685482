export const stopHeartbeat = () => {
  const heartbeatId = sessionStorage.getItem("heartbeatId");
  heartbeatId && window.clearInterval(heartbeatId);
  sessionStorage.removeItem("heartbeatId");
};

export const startHeartbeat = (callback: VoidFunction) => {
  const heartbeatId = window.setInterval(callback, 30 * 1000);
  sessionStorage.setItem("heartbeatId", String(heartbeatId));
  return heartbeatId;
};
