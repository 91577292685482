import { RowSelectionState } from "@tanstack/react-table";
import classNames from "classnames";
import {
  BookingsToReassignSelector,
  ReassignSelectOption
} from "PFApp/booking/components/reassign_booking_modal/bookings_to_reassign_selector";
import { SearchEntity } from "PFApp/components/search_autocomplete/search_autocomplete.types";
import { SearchInput } from "PFApp/components/search_input";
import { Alert } from "PFComponents/alert";
import BrainLoading from "PFComponents/brain_loading/brain_loading";
import { MultiToggle, Option } from "PFComponents/multi_toggle";
import { Typography } from "PFComponents/typography";
import { getTermFilterData } from "PFCore/helpers/get_term_filter_data";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { useActivity } from "PFCore/hooks/queries/activity";
import { useMatchesInvalidate } from "PFCore/hooks/queries/matches/use_matches_invalidate";
import { fetchMatches } from "PFCore/services/matches";
import { Activity, Booking, PermissionRule } from "PFTypes";
import { RecentSearchesPageKey } from "PFTypes/recent_searches";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useReassignMatches } from "../hooks/use_reassign_matches";
import { MatchesActions } from "./matches_actions";
import { MatchesTable } from "./matches_table";
import { NamedResourceTable } from "./named_resource_table";
import { NoMatchesPlaceholder } from "./no_matches_placeholder";
import css from "./reassign_content.module.scss";

export enum ProfilesTab {
  Matches = "matches",
  NamedResource = "namedResource"
}

export type RowSelection = {
  onTargetProfileChange: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  rowSelectionState: RowSelectionState;
};

type ReassignContentProps = {
  booking: Booking;
  activity: Activity | undefined;
  isEngagement: boolean | undefined;
  profileFullName: string;
  reassignOption: ReassignSelectOption;
  setReassignOption: React.Dispatch<React.SetStateAction<ReassignSelectOption>>;
} & RowSelection;

export const ReassignContent = ({
  booking,
  activity,
  isEngagement,
  profileFullName,
  onTargetProfileChange,
  rowSelectionState,
  reassignOption,
  setReassignOption
}: ReassignContentProps) => {
  const { t } = useTranslation(["bookingModule", "activities", "translation"]);

  const canReassignBookingsNamedResources = useIsCurrentUserPermittedTo(
    PermissionRule.ReassignBookingsNamedResource
  );
  const canReassignBookingsMatches = useIsCurrentUserPermittedTo(PermissionRule.ReassignBookingsMatches);

  const [selectedTab, setSelectedTab] = useState<ProfilesTab | undefined>(() => {
    if (isEngagement) {
      return ProfilesTab.NamedResource;
    } else if (canReassignBookingsMatches) {
      return ProfilesTab.Matches;
    } else if (canReassignBookingsNamedResources) {
      return ProfilesTab.NamedResource;
    } else {
      return undefined;
    }
  });
  const [isLoading, setIsLoading] = useState(false);

  const { invalidate: invalidateMatches } = useMatchesInvalidate();

  const { matchesResponse, matchesFilters, matchesOrder, restoreFilters, updateFilter } = useReassignMatches({
    booking,
    enabled: canReassignBookingsMatches
  });

  const { termFilter, termValue, isTermFilterAvailable } = getTermFilterData(matchesResponse.matchesMeta);

  const [namedResourcesSearchValue, setNamedResourcesSearchValue] = useState("");

  const handleSearchChange = (value: string) => {
    if (selectedTab === ProfilesTab.Matches) {
      updateFilter(termFilter!, value);
    } else {
      setNamedResourcesSearchValue(value);
    }
  };

  const matchesResponseIsEmpty = !matchesResponse.isFetching && matchesResponse.matchesData.length === 0;

  const { data: parentActivity } = useActivity(activity?.parent_activity_id ?? -1, {
    enabled: !!activity?.parent_activity_id && matchesResponseIsEmpty
  });

  useEffect(() => {
    onTargetProfileChange({});
    setIsLoading(false);
  }, [selectedTab]);

  const profilesTabOptions: Option<ProfilesTab>[] = [
    {
      id: ProfilesTab.Matches,
      value: t("bookingModule:bookings.reassign.sidePanel.matches"),
      disabled: !canReassignBookingsMatches
    },
    {
      id: ProfilesTab.NamedResource,
      value: t("bookingModule:bookings.reassign.sidePanel.namedResource"),
      disabled: !canReassignBookingsNamedResources
    }
  ];

  const handleMatchesRefresh = () => {
    setIsLoading(true);

    fetchMatches(booking.activity_id, {
      refresh: true,
      ...matchesFilters
    })
      .then(() => {
        invalidateMatches();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const content = useMemo(() => {
    if (isLoading) {
      return <BrainLoading text={t("bookingModule:bookings.reassign.sidePanel.calculationsStarted")} />;
    }
    if (isEngagement || selectedTab === ProfilesTab.NamedResource) {
      return canReassignBookingsNamedResources ? (
        <NamedResourceTable
          searchValue={namedResourcesSearchValue}
          onTargetProfileChange={onTargetProfileChange}
          rowSelectionState={rowSelectionState}
        />
      ) : null;
    }

    if (matchesResponseIsEmpty) {
      return canReassignBookingsMatches ? (
        <NoMatchesPlaceholder
          activity={activity}
          parentActivity={parentActivity}
          changeSelectedTab={setSelectedTab}
          canReassignBookingsNamedResources={canReassignBookingsNamedResources}
        />
      ) : null;
    }

    return canReassignBookingsMatches ? (
      <MatchesTable
        onTargetProfileChange={onTargetProfileChange}
        rowSelectionState={rowSelectionState}
        matchesResponse={matchesResponse}
      />
    ) : null;
  }, [
    isLoading,
    selectedTab,
    isEngagement,
    canReassignBookingsNamedResources,
    canReassignBookingsMatches,
    namedResourcesSearchValue,
    matchesResponse,
    matchesResponseIsEmpty,
    activity,
    parentActivity
  ]);

  const tabsVisible = !isEngagement && canReassignBookingsNamedResources && canReassignBookingsMatches;

  const isCompleted = activity?.state === "complete";

  const matchesSearchEnabled =
    selectedTab === ProfilesTab.Matches &&
    isTermFilterAvailable &&
    !isCompleted &&
    matchesResponse.matchesData.length > 0;

  const searchEnabled = selectedTab === ProfilesTab.NamedResource || matchesSearchEnabled;

  const searchValue = selectedTab === ProfilesTab.Matches ? termValue : namedResourcesSearchValue;

  return (
    <div className={css.root}>
      <div className={classNames(css.root, css.stickyContainer)}>
        <BookingsToReassignSelector
          activityId={booking.activity_id ?? -1}
          profileId={booking.profile_id}
          currentBooking={{
            id: booking.id,
            startDate: booking.start_date,
            endDate: booking.end_date
          }}
          reassignOptionSelected={reassignOption}
          setReassignOptionSelected={setReassignOption}
          dividerEnabled
        />

        <Typography withMargin variant="bodyRegular" tag="span">
          {t("bookingModule:bookings.reassign.sidePanel.reassignFromTo", { profileFrom: profileFullName })}
        </Typography>
        {tabsVisible && (
          <MultiToggle<ProfilesTab>
            controlledValue={selectedTab}
            onChange={(id) => setSelectedTab(id)}
            options={profilesTabOptions}
          />
        )}
        <SearchInput
          term={searchValue}
          pageKey={RecentSearchesPageKey.ReassignProfile}
          onTermChange={handleSearchChange}
          classes={{
            root: css.search
          }}
          disabled={!searchEnabled}
          autocompleteConfig={[{ entity: SearchEntity.Profile, useAsTerm: true }]}
        />
        {selectedTab === ProfilesTab.Matches && restoreFilters.letRestoreAll && (
          <Alert
            title={t("activities:show.matches.filtersChangedWarning")}
            action={{
              text: t("translation:filters.restoreAll"),
              icon: "history",
              kind: "text",
              onClick: restoreFilters.restoreAllFilters
            }}
            multiLineTitle
            small
          />
        )}
        {selectedTab === ProfilesTab.Matches && !isCompleted && (
          <MatchesActions
            onRefresh={handleMatchesRefresh}
            isLoading={isLoading || matchesResponse.isFetching}
            lastRefreshAt={matchesResponse.lastRefreshAt}
            orderMeta={matchesOrder.orderMeta}
            onOrderChange={matchesOrder.handleChangeOrder}
          />
        )}
      </div>
      {content}
    </div>
  );
};
