import { ActionIcon } from "PFComponents/action_icon";
import { Button } from "PFComponents/button";
import { Booking } from "PFTypes";

import { BookingSelectOption } from "../booking_questions/booking_questions";
import { DeleteMode } from "./delete_booking.types";
import { useDeleteBookingModalContext } from "./delete_booking_modal_context_provider";

type BookingDeleteButtonProps = {
  className?: string;
  kind?: "icon" | "button";
  deleteMode: DeleteMode;
  booking?: Booking;
  profileFullName: string;
  onDeleted?: (booking: Booking, deleteOptionSelected: BookingSelectOption) => Promise<void>;
  isSingleDayBookingTemplate?: boolean;
  disabled?: boolean;
  title?: string;
};

const BookingDeleteButton = ({
  className,
  kind = "button",
  deleteMode,
  booking,
  profileFullName,
  onDeleted,
  isSingleDayBookingTemplate,
  disabled,
  title
}: BookingDeleteButtonProps) => {
  const { modal } = useDeleteBookingModalContext();

  const handleClick = () =>
    !!booking &&
    modal.open({
      booking,
      deleteMode,
      profileFullName,
      onDeleted,
      isSingleDayBookingTemplate
    });

  return kind === "icon" ? (
    <ActionIcon
      className={className}
      name="remove"
      size="sm"
      onClick={handleClick}
      disabled={disabled || !booking}
      title={title}
    />
  ) : (
    <Button
      className={className}
      icon="remove"
      onClick={handleClick}
      kind="secondary"
      disabled={disabled || !booking}
      title={title}
    />
  );
};

export default BookingDeleteButton;
