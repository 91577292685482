import classNames from "classnames";

import { Icon, IconSize } from "../icon";
import { Typography } from "../typography";
import css from "./error_message.module.scss";

type ErrorMessageProps = {
  message: string;
  className?: string;
  iconSize?: IconSize;
};

export const ErrorMessage = ({ message, className, iconSize }: ErrorMessageProps) => (
  <div className={classNames(css.root, className)}>
    <Icon name="error" size={iconSize} color="paletteRed1" />
    <Typography withMargin variant="labelRegular" tag="span" className={css.message}>
      {message}
    </Typography>
  </div>
);
