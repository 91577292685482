import { useExperienceLabelMap } from "PFApp/hooks/use_experience_label_map";
import { IconSizeTable } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import colors from "PFTheme/tokens/colors";
import { CustomValue, Experience } from "PFTypes";
import { useTranslation } from "react-i18next";

import { RatingDots, RatingDotsProps } from "../rating_dots/rating_dots";
import css from "./experience_level_tooltip_content.module.scss";
import { ExperienceLevelTooltipContentRequired } from "./experience_level_tooltip_content_required";

type ExperienceLevelTooltipContentProps = Pick<RatingDotsProps, "shape"> & {
  customValue?: CustomValue;
  experience?: Experience | null;
  requiredExperience?: Experience | null;
};

export const ExperienceLevelTooltipContent = ({
  customValue,
  experience,
  requiredExperience,
  shape
}: ExperienceLevelTooltipContentProps) => {
  const { t } = useTranslation("core", { keyPrefix: "skillRatings" });
  const experienceLabelMap = useExperienceLabelMap();
  const label = experience ? experienceLabelMap[experience] : undefined;
  const ariaLabel = customValue
    ? t("setCustomValueExperience", {
        name: customValue.text,
        experience: label
      })
    : t("setExperience", { experience: label });

  return requiredExperience ? (
    <ExperienceLevelTooltipContentRequired
      experience={experience}
      requiredExperience={requiredExperience}
      label={label}
      ariaLabel={ariaLabel}
    />
  ) : (
    <Typography
      aria-label={ariaLabel}
      tag="p"
      className={css.text}
      variant={label ? "labelSemibold" : "labelRegular"}
    >
      <RatingDots
        className={css.marginTop}
        shape={shape}
        experience={experience}
        color={colors.paletteWhite0}
        colorSecondary={colors.paletteBlue2}
        size={IconSizeTable.xs}
      />
      {label || t("experienceMissing")}
    </Typography>
  );
};
