import capitalize from "lodash/capitalize";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

type BooleanAttributeProps = {
  value: boolean;
};

export const BooleanAttribute = ({ value }: BooleanAttributeProps) => {
  const { t } = useTranslation("bookingModule");
  const text = value
    ? t("details.history.boolean.overscheduling")
    : t("details.history.boolean.noOverscheduling");
  return (
    <Typography withMargin variant="bodyRegular" tag="span">
      {capitalize(text)}
    </Typography>
  );
};
