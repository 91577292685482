import classNames from "classnames";

import css from "../match_gauge.module.scss";

type DonutSegmentProps = {
  startScore?: number;
  score: number;
  name: string;
  hoveredSegment: string | null;
};

export const DonutSegment = ({ startScore, score, name, hoveredSegment }: DonutSegmentProps) => {
  const strokeWidth = 4;
  const radius = 40 - strokeWidth;
  const circuit = 2 * Math.PI * radius;
  const offset = circuit / 4; // -90deg

  const strokeDashArray = `${circuit * score} ${circuit * (1 - score)}`;

  const style = () => {
    const styleObject: React.CSSProperties = {};
    if (startScore) {
      styleObject.transform = `rotate(${startScore * 360}deg)`;
    }
    return styleObject;
  };

  const isHovered = hoveredSegment === name;

  return (
    <>
      {!!score && (
        <circle
          style={style()}
          className={classNames(css.donutSegment, css[name])}
          cx="43"
          cy="43"
          r={radius}
          fill="transparent"
          strokeWidth={isHovered ? 5.5 : 4}
          strokeDasharray={strokeDashArray}
          strokeDashoffset={offset}
          strokeLinecap="butt"
          strokeOpacity={isHovered || !hoveredSegment ? 1 : 0.8}
        ></circle>
      )}
    </>
  );
};
