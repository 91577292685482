import { Typography } from "PFComponents/typography";

import css from "./table_cells.module.scss";

type CustomValueCellProps = {
  text?: string;
};

export const CustomValueCell = ({ text = "-" }: CustomValueCellProps) => (
  <Typography variant="bodyRegular" className={css.customValue} title={text} clipOverflow>
    {text}
  </Typography>
);
