import classNames from "classnames";
import { CSSProperties, PropsWithChildren } from "react";

import { BasicPill } from "./basic_pill";
import css from "./pill.module.scss";

type RolePillProps = PropsWithChildren & {
  onClick?: () => void;
  className?: string;
  style?: CSSProperties;
  state: string;
};

const RolePill = ({ children, className, style, state }: RolePillProps) => {
  const roleClassName =
    {
      booked: "roleBooked",
      partially_booked: "rolePartiallyBooked"
    }[state] || "roleNotBooked";

  return (
    <BasicPill className={classNames(css[roleClassName], className)} style={style}>
      {children}
    </BasicPill>
  );
};

export default RolePill;
