import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import React from "react";
import { useTranslation } from "react-i18next";

import css from "./booking_not_permitted_message.module.scss";

export const BookingNotPermittedMessage = () => {
  const { t } = useTranslation("bookingModule");
  return (
    <div className={css.root}>
      <Icon name="locked" />
      <Typography variant="labelRegular">{t("bookings.tooltip.noCategory")}</Typography>
    </div>
  );
};
