import { Button } from "PFComponents/button";
import { Card } from "PFComponents/card";
import { LoadingDots } from "PFComponents/loading_dots";
import { SimplePagination } from "PFComponents/pagination/simple_pagination";
import { Typography } from "PFComponents/typography";
import { useCertificates } from "PFCore/hooks/queries/certificates";
import { Profile } from "PFTypes";
import { Certificate } from "PFTypes/certificate";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { CertificateCard } from "./parts/certificate_card";
import { EditCertificate } from "./parts/edit_certificate";
import css from "./section_accomplishments.module.scss";

type AccomplishmentsSectionProps = {
  profile: Profile;
  currentProfile: Profile;
  handleProfileUpdate: () => void;
};

export const AccomplishmentsSection = ({
  currentProfile,
  profile,
  handleProfileUpdate
}: AccomplishmentsSectionProps) => {
  const { t } = useTranslation("profiles");

  const [page, setPage] = useState(1);
  const [addNewCertificate, setAddNewCertificate] = useState(false);
  const [editedCertificate, setEditedCertificate] = useState<Certificate | null>(null);

  const { isLoading, data: certificates } = useCertificates({ profileId: profile.id, page });

  const handleEditFormClose = () => {
    setAddNewCertificate(false);
    setEditedCertificate(null);
  };

  const handleNewCertificate = () => {
    setEditedCertificate(null);
    setAddNewCertificate(true);
  };

  const handleEditCertificate = (certificate: Certificate) => {
    setAddNewCertificate(false);
    setEditedCertificate(certificate);
  };

  const handleDelete = () => {
    setPage(1);
    handleEditFormClose();
  };

  const isMe = useMemo(
    () => !!(profile?.id && profile?.id === currentProfile?.id),
    [profile, currentProfile]
  );

  return (
    <Card className={css.container} paddingVariant="xl">
      <header className={css.header}>
        <Typography variant="h2">{t("show.parts.certificates")}</Typography>
        {isMe && (
          <Button
            aria-controls="edit-certificate"
            aria-expanded={addNewCertificate}
            aria-haspopup="dialog"
            aria-label={t("show.parts.addCertificate")}
            onClick={handleNewCertificate}
            text={t("show.parts.addCertificate")}
          />
        )}
      </header>

      {isLoading ? (
        <LoadingDots />
      ) : (
        <div className={css.certificates}>
          {certificates?.entries.map((certificate) => (
            <CertificateCard
              key={certificate.id}
              certificate={certificate}
              isMe={isMe}
              onEdit={handleEditCertificate}
            />
          ))}
        </div>
      )}
      <SimplePagination currentPage={page} totalPages={certificates?.meta.totalPages} handleClick={setPage} />

      {addNewCertificate && (
        <EditCertificate onClose={handleEditFormClose} fetchProfile={() => handleProfileUpdate()} />
      )}

      {editedCertificate && (
        <EditCertificate
          onClose={handleEditFormClose}
          onDelete={handleDelete}
          fetchProfile={() => handleProfileUpdate()}
          certificate={editedCertificate}
        />
      )}
    </Card>
  );
};
