import moment from "moment";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import useWindowSize from "PFCore/helpers/use_window_size";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { FeatureFlag } from "PFTypes";
import PropTypes from "prop-types";
import { useMemo } from "react";

import { useActivityCurrentUserRole } from "../hooks";
import CompareAvailabilityMobilePage from "./availability/compare_availability_mobile_page";
import CompareAvailabilityPage from "./availability/compare_availability_page";
import CompareHeader from "./common/compare_header";
import { CompareDetailsPage } from "./details/compare_details_page";

const ComparePage = ({ task, comparableProfiles, tab, changeTab, removeProfileId }) => {
  const isEnabled = useIsFeatureEnabled();
  const { formatISODate } = useDateFormatter();

  const profiles = (comparableProfiles || []).map((sh) => sh.profile);
  const availability = task?.metadata?.availability || null;

  const startDate = useMemo(() => {
    let availabilityStartDate = moment();
    if (availability) {
      if (availability.mode === "time_rule") {
        availabilityStartDate = moment.min(availability.ranges.map((range) => moment(range.start)));
      } else {
        availabilityStartDate = moment(availability.start);
      }
    }
    return formatISODate(moment.max(moment(), availabilityStartDate).startOf("isoWeek").set("hour", 12));
  }, [availability, formatISODate]);

  const { windowWidth } = useWindowSize();

  const isBlindModeEnabled = isEnabled(FeatureFlag.UnbiasedAllocation);
  const { isReviewerUnbiased } = useActivityCurrentUserRole(task);

  const isBlindModeOn = isBlindModeEnabled && isReviewerUnbiased;

  return (
    <>
      <CompareHeader
        task={task}
        tabActiveId={tab}
        changeTab={changeTab}
        comparableProfiles={comparableProfiles}
        isBlindModeOn={isBlindModeOn}
      />

      {
        {
          details: (
            <CompareDetailsPage
              task={task}
              comparableProfiles={comparableProfiles}
              removeProfileId={removeProfileId}
              changeTab={changeTab}
              isBlindModeOn={isBlindModeOn}
            />
          ),
          availability:
            windowWidth > 800 ? (
              <CompareAvailabilityPage
                startDate={startDate}
                comparableProfiles={comparableProfiles}
                availabilityInfo={availability}
                removeProfileId={removeProfileId}
                isBlindModeOn={isBlindModeOn}
              />
            ) : (
              <CompareAvailabilityMobilePage profiles={profiles} isBlindModeOn={isBlindModeOn} />
            )
        }[tab]
      }
    </>
  );
};

ComparePage.propTypes = {
  task: PropTypes.object.isRequired,
  comparableProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      profile: PropTypes.shape({
        id: PropTypes.number,
        isAvailable: PropTypes.bool,
        availabilityMatch: PropTypes.number
      }),
      applicable_custom_values: PropTypes.arrayOf(
        PropTypes.shape({
          required_experience: PropTypes.number,
          experience: PropTypes.number,
          text: PropTypes.string,
          importance: PropTypes.number
        })
      )
    })
  ).isRequired,
  tab: PropTypes.oneOf(["details", "availability"]).isRequired,
  changeTab: PropTypes.func.isRequired,
  removeProfileId: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date)
};

export default ComparePage;
