import find from "lodash/find";
import map from "lodash/map";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useTemplate } from "PFApp/hooks";
import { useCustomValuePreviewActions } from "PFApp/hooks/use_custom_value_preview_actions";
import { Accordion } from "PFComponents/accordion";
import { Button } from "PFComponents/button";
import { Icon } from "PFComponents/icon";
import TooltipIcon from "PFComponents/tooltip_icon/tooltip_icon";
import { Typography } from "PFComponents/typography";
import { canEditOrRemoveMatchableTypeValues, useMatchableTypes } from "PFCore/helpers/activities";
import { isRankable } from "PFCore/helpers/custom_type";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { ActivityPureCustomValue, CustomValueScope, Id, ParsedSchemaProperty } from "PFTypes";
import { AccessLevel } from "PFTypes";
import { useTranslation } from "react-i18next";

import { AttributeTile } from "./attribute_tile";
import css from "./match_attributes.module.scss";
import { canAddNewAttribute, canEditAttribute, canRemoveAttribute } from "./match_attributes.utils";

export const MatchAttributes = () => {
  const { t } = useTranslation("activities", { keyPrefix: "matchAttributes" });

  const { getCustomTypeByName } = useCustomTypes();
  const { task } = useActivityPageContext();
  const template = useTemplate(task.template_id);
  const { showPreview } = useCustomValuePreviewActions();
  const { data: currentProfile } = useCurrentProfile();

  const matchableTypes = useMatchableTypes(task.template_id, [AccessLevel.ReadWrite, AccessLevel.ReadOnly]);

  const isOwner = task.coowned;

  const handleMatchAttributeClick = (
    customTypeId: Id,
    customValueId: Id,
    matchableType: ParsedSchemaProperty,
    keywords: ActivityPureCustomValue[] = []
  ) => {
    const canRemove =
      canRemoveAttribute(keywords, matchableType, template!) &&
      canEditOrRemoveMatchableTypeValues(matchableType, matchableTypes);

    showPreview({
      scope: CustomValueScope.Activity,
      customTypeId,
      customValueId,
      activityId: task.id,
      canEdit: isOwner,
      canRemove
    });
  };

  const handleAddButtonClick = (customTypeId: Id, customValuesIdsBlacklist: Id[]) => {
    showPreview({
      scope: CustomValueScope.Activity,
      customTypeId,
      isNew: true,
      allowSearch: true,
      activityId: task.id,
      canEdit: isOwner,
      customValuesIdsBlacklist
    });
  };

  const renderHeader = () => (
    <span className={css.accordionHeader}>
      {t("title")}
      {isOwner && (
        <TooltipIcon
          content={t("disclaimer")}
          IconComponent={() => <Icon name="info" />}
          width={20}
          height={20}
          tooltipProps={{
            maxWidth: 360
          }}
        />
      )}
    </span>
  );

  return (
    <>
      <Accordion
        header={renderHeader()}
        defaultState="closed"
        headerVariant="bodyBold"
        headerFullWidth
        iconSet="chevron"
      >
        <div className={css.root}>
          {matchableTypes.map((matchableType) => {
            const { name, kind } = matchableType;
            const customType = getCustomTypeByName(name);

            if (!customType) {
              return;
            }

            const { values } = find(task.custom_fields, ["type.id", customType.id]) || { values: [] };
            const customTypeKeywordsIds = map(values, "id");
            const canAdd = canAddNewAttribute(values, kind, matchableTypes, matchableType);
            const canEdit = isOwner && canEditAttribute(currentProfile, customType);

            if (!canEdit && values.length === 0) {
              return;
            }

            return (
              <div key={customType.name}>
                <span className={css.header}>
                  <Typography variant="h5" title={customType.display_as}>
                    {customType.display_as}
                  </Typography>
                  {canEdit && canAdd && (
                    <Button
                      aria-label={t("addAriaLabel", { name: customType.name })}
                      kind="text"
                      text={t("add")}
                      onClick={() => handleAddButtonClick(customType.id, customTypeKeywordsIds)}
                    />
                  )}
                </span>
                <AttributeTile
                  isRankable={isRankable(customType)}
                  onAttributeClick={(value) =>
                    handleMatchAttributeClick(customType.id, value.id, matchableType, values)
                  }
                  customType={customType}
                  matchKeywords={values}
                />
              </div>
            );
          })}
        </div>
      </Accordion>
    </>
  );
};
